import React from 'react'
import FromMingaku from './from_mingaku.module.scss'
import Footer from '../../components/Common/Footer'
import Header from '../../components/Header/Header'
import AppModule from '../../App.module.scss'
import { useSearchParams, useNavigate } from 'react-router-dom'
import ai_talk_man from '../../assets/ai_talk_man.png'
import ai_analysis from '../../assets/ai_analysis.png'

function ModeSelect() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const schoolId = searchParams.get('school_id')
  const studentId = searchParams.get('student_id')

  const selectSeeStudentConversation = () => {
    console.log(`${schoolId}/${studentId}生徒の会話履歴を見る`)
    navigate(`/thread_list?school_id=${schoolId}&student_id=${studentId}`)
  }
  const selectAnalyzeStudentConversation = () => {
    console.log(`${schoolId}/${studentId}生徒の会話を分析する`)
    navigate(`/analysis_student?school_id=${schoolId}&student_id=${studentId}`)
  }
  return (
    <div className={AppModule.main}>
      <Header title="モード一覧" />
      <div className={FromMingaku.cardContainer}>
        {/* <Modes chatPath={'/chat'} conversationType={'conversations'} serviceType="gpt-teacher" checkAudioPage={false} /> */}
        <div className={FromMingaku.card} onClick={() => selectSeeStudentConversation()}>
          生徒の会話履歴を見る
          <img className={FromMingaku.aiTalkImage} src={ai_talk_man} alt="arrow_right" />
        </div>
        <div className={FromMingaku.card} onClick={() => selectAnalyzeStudentConversation()}>
          生徒の会話を分析する
          <img className={FromMingaku.aiTalkImage} src={ai_analysis} alt="arrow_right" />
        </div>
      </div>
      <Footer homePath="/" addChatPath="/mode" userPath="/mypage" />
    </div>
  )
}
export default ModeSelect
