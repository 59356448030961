import React, { useEffect, useState } from 'react'
import AppModule from '../../App.module.scss'
import { auth } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import Footer from '../../components/Common/Footer'
import Header from '../../components/Header/Header'
import ChatDataScreen from '../../components/ChatDataScreen/ChatDataTableScreen'
import { useSearchParams } from 'react-router-dom'
import { _table_filter_periods, _logs_filter_periods } from '../../localConstant'
function ChatData() {
  const navigate = useNavigate()
  const path = window.location.pathname
  const queryParams = window.location.search
  const [searchParams] = useSearchParams()
  const filter = parseInt(searchParams.get('filter'), 10)
  // useAuthCheck(path, queryParams)
  useEffect(() => {
    if (filter || filter === 0) {
      const period = _table_filter_periods.find((p) => p.id === filter)
      if (period) {
        setDisplayName(period.displayName)
      }
    }
  })
  const [displayName, setDisplayName] = useState('')
  return (
    <div className={AppModule.main}>
      <div>
        <Header title={`会話データ出力 ${displayName}`} />
        <ChatDataScreen />
        <Footer homePath="/" addChatPath="/mode" userPath="/mypage" />
      </div>
    </div>
  )
}
export default ChatData
