import React, { useState, useEffect } from 'react'
import TopModule from './NameList.module.scss'
import { auth } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import { db, functions } from '../../firebase'
import { collection, getDocs, query, orderBy } from 'firebase/firestore'
import { useSearchParams } from 'react-router-dom'
import { httpsCallable } from 'firebase/functions'

function NameList(props) {
  const {
    onSelectSubmitter,
    readOnly,
    submittionBox,
    onDefaultSubmitter,
    submitters,
    setSubmitters,
    onNoConversation
  } = props
  const [searchParams] = useSearchParams()
  const filter_id = searchParams.get('filter')
  const [filterId, setFilterId] = useState(filter_id ? filter_id : '0')
  const schoolId = searchParams.get('school_id')
  const [schoolIdName, setSchoolIdName] = useState({}) // schoolIdとschoolNameの対応表
  const modeNameClassName = 'modeName'
  const chatListClassName = 'chat-list'
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [selectedSubmitterId, setSelectedSubmitterId] = useState(null)

  const getSchoolsInfo = async () => {
    const getSchools = httpsCallable(functions, 'get_schools_info')
    const res = await getSchools() // schoolAIのschook情報を全件取得用
    const { data } = res
    return data
  }
  const unixtime2datetimeForSubmission = (unixtime) => {
    const time = new Date(unixtime)
    // 日本のロケールを指定し、短縮された曜日も含むようにフォーマットオプションを設定
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }
    const dateWithShortDayOfWeek = time.toLocaleDateString('ja-JP', options)

    // 括弧をつけた曜日を作成
    const dayOfWeekWithBrackets = dateWithShortDayOfWeek.replace(/曜日$/, '）').replace(/(.*)\s(.*)/, '$1（$2')

    // 時間を '時:分' の形式で取得
    const hour = time.getHours().toString().padStart(2, '0')
    const minute = time.getMinutes().toString().padStart(2, '0')

    return `${dayOfWeekWithBrackets} ${hour}:${minute}`
  }
  useEffect(() => {
    ;(async () => {
      const { schoolInfo } = await getSchoolsInfo() // schoolAIのschool情報を全件取得用
      const schoolsOfSchoolAI = schoolInfo.filter((school) => !!school.data) // dataがないschoolは除外
      let schoolIdName = {} // schoolIdとschoolNameの対応表
      schoolsOfSchoolAI.map((school) => {
        return (schoolIdName[school.schoolId] = school.data.name) //
      })
      setSchoolIdName(schoolIdName)
    })()
  }, [])

  useEffect(() => {
    const newFilterId = searchParams.get('filter')
    if (newFilterId !== filterId) {
      setFilterId(newFilterId)
    }
  }, [searchParams, filterId]) // searchParamsが変更されたときに実行
  // useAuthCheck()

  const getConversations = async (query) => {
    const querySnapshot = await getDocs(query)
    return querySnapshot.docs.map((doc) => ({
      schoolId: doc.data().schoolId,
      submitterRef: doc.ref,
      submittedAt: doc.data().submittedAt,
      userId: doc.data().userId,
      userName: doc.data().userName
    }))
  }

  const updateURLWithSubmitterId = (submitterId) => {
    const currentUrl = new URL(window.location)
    currentUrl.searchParams.set('submitter_id', submitterId)
    window.history.pushState({}, '', currentUrl)
  }

  useEffect(() => {
    if (readOnly === false) return

    const fetchConversations = async () => {
      setLoading(true)
      try {
        const allConversations = []

        if (window.location.pathname === '/submitted_conversations') {
          const submittersDocRef = collection(
            db,
            `/externalServices/mingaku/schools/${schoolId}/submissionBoxes/${submittionBox}/submitters`
          )
          const submittersQueryForUpdatedAt = query(submittersDocRef, orderBy('submittedAt', 'desc'))
          const namelist = await getConversations(submittersQueryForUpdatedAt)
          if (namelist.length === 0) {
            onNoConversation(true)
            return
          }
          const submitterId = new URLSearchParams(window.location.search).get('submitter_id')
          if (!submitterId && !namelist[0].userId) return
          const selectedSubmitterId = submitterId || namelist[0].userId
          onDefaultSubmitter(selectedSubmitterId)
          setSelectedSubmitterId(selectedSubmitterId)

          if (!submitterId) {
            updateURLWithSubmitterId(namelist[0].userId)
          }
          allConversations.push(...namelist)
        }

        setSubmitters(allConversations)
      } catch (error) {
        console.error('Failed to fetch conversations:', error)
        setLoading(false)
      } finally {
        setLoading(false)
      }
    }

    fetchConversations()
  }, [readOnly, window.location.pathname, window.location.search])

  const handleItemClick = (submitter) => {
    onSelectSubmitter(submitter.userId)
    setSelectedSubmitterId(submitter.userId)
    const currentUrl = new URL(window.location)
    currentUrl.searchParams.set('submitter_id', submitter.userId)
    currentUrl.searchParams.delete('conversation_id')
    window.history.pushState({}, '', currentUrl)
  }

  return (
    <div className={TopModule.container}>
      <>
        {loading ? (
          <></>
        ) : (
          <>
            <div className={TopModule['ul-container']}>
              <ul className={TopModule['chat-list']}>
                {submitters &&
                  submitters.map((conversation, index) => {
                    return (
                      <li
                        className={
                          conversation.userId === selectedSubmitterId
                            ? TopModule['selected-chat-list']
                            : TopModule[chatListClassName]
                        }
                        key={index}
                        onClick={() => handleItemClick(conversation)}
                      >
                        <p className={TopModule.time}>
                          最終提出時間：<br></br>
                          {unixtime2datetimeForSubmission(conversation.submittedAt)}
                        </p>
                        <div className={TopModule.icon}>
                          {conversation.mode && <p className={TopModule[modeNameClassName]}>{conversation.mode}</p>}
                          {readOnly === true && (
                            <p className={TopModule.userName}>
                              {conversation.schoolId ? schoolIdName[conversation.schoolId] : 'みんがく'}
                              <br></br>
                              {conversation.userName ? conversation.userName : conversation.userFullName}
                            </p>
                          )}
                        </div>
                      </li>
                    )
                  })}
              </ul>
            </div>
          </>
        )}
      </>
    </div>
  )
}

export default NameList
