import React, { useEffect, useState } from 'react'
import AppModule from '../../../App.module.scss'
import Footer from '../../../components/Common/Footer'
import { useNavigate } from 'react-router-dom'
import Header from '../../../components/Header/Header'
import MainLayoutWithNameList from '../../../components/ChatMainLayoutWithNameList/MainLayoutWithNameList'
import { useSearchParams } from 'react-router-dom'
import { _table_filter_periods, _logs_filter_periods } from '../../../localConstant'
import { collection, getDoc, doc } from 'firebase/firestore'
import { db } from '../../../firebase'

function SubmittedConversations() {
  const [searchParams] = useSearchParams()
  const filter = parseInt(searchParams.get('filter'), 10)
  const submittion_box = searchParams.get('submittion_box')
  const [displayName, setDisplayName] = useState('')
  const navigate = useNavigate()
  const path = window.location.pathname
  const queryParams = window.location.search
  // useAuthCheck(path, queryParams)
  useEffect(() => {
    if (filter || filter === 0) {
      const period = _table_filter_periods.find((p) => p.id === filter)
      if (period) {
        setDisplayName(period.displayName)
      }
    }
  })
  const paramSchoolId = searchParams.get('school_id')
  const [title, setTitle] = useState('')
  useEffect(() => {
    ;(async () => {
      // Promptsすべて
      let boxRef

      // const { parentSchools } = await fetchDocumentIds()
      if (paramSchoolId) {
        const mingakuDocRef = doc(db, 'externalServices', 'mingaku')
        const schoolsCollectionRef = collection(mingakuDocRef, 'schools')
        boxRef = doc(schoolsCollectionRef, paramSchoolId)
      } else {
        boxRef = doc(db, 'service_type', 'gpt-teacher')
      }
      // あとは自分の塾のpromptsを取得
      const submissionBoxRef = doc(boxRef, 'submissionBoxes', submittion_box)
      const submissionBoxData = await getDoc(submissionBoxRef)
      setTitle(submissionBoxData.data().submission_box_title)
    })()
  }, [submittion_box])

  return (
    <div className={AppModule.main}>
      <Header title={`提出物 ${title}`} />
      <MainLayoutWithNameList
        submittionBox={submittion_box}
        conversationType={'conversations'}
        displayList={true}
        displayFooter={true}
        readOnly={true}
      />
      <Footer homePath="/" addChatPath="/mode" userPath="/mypage" />
    </div>
  )
}
export default SubmittedConversations
