import Footer from '../../components/Common/Footer'
import Header from '../../components/Header/Header'
import AppModule from '../../App.module.scss'
import SubmissionManagementScreen from '../../components/SubmissionManagementScreen/SubmissionManagementScreen'
import { _prompt_type_choice_NANDE } from '../../localConstant'
import TocIcon from '@mui/icons-material/Toc'
import { useParams } from 'react-router-dom'
import React from 'react'

function SubmissionManagement() {
  const { schoolId } = useParams()
  const listItem = [
    { name: '会話データ', path: schoolId ? `/chat_data?school_id=${schoolId}` : `/chat_data`, icon: <TocIcon /> }
  ]

  return (
    <div className={AppModule.main}>
      <Header title="提出管理画面" listItem={listItem} />
      <SubmissionManagementScreen serviceType="gpt-teacher" prompt_type_choice={_prompt_type_choice_NANDE} />
      <Footer homePath="/" addChatPath="/mode" userPath="/mypage" />
    </div>
  )
}

export default SubmissionManagement
