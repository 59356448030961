import AnalysisStudentModule from './analysis_student.module.scss'
import Footer from '../../components/Common/Footer'
import Header from '../../components/Header/Header'
import AppModule from '../../App.module.scss'
import { _prompt_type_choice_NANDE } from '../../localConstant'
import TocIcon from '@mui/icons-material/Toc'
import { useSearchParams } from 'react-router-dom'
import { db } from '../../firebase'
import { useState } from 'react'
import kuromoji from 'kuromoji'
import { listOfExcludedWords } from '../../localConstant.js'
import { collection, getDocs, doc, query, orderBy, limit, getDoc } from 'firebase/firestore'
import { useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import { TagCloud } from 'react-tagcloud'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import AnalysisCard from '../../components/AnalysisCard/AnalysisCard.jsx'
import ManagementModule from '../../components/SubmissionManagementScreen/SubmissionManagementScreen.module.scss'
import ChatBubble from '../../components/Bubble/ChatBubble'
import ai from '../../assets/ai.png'
import ai2 from '../../assets/ai2.png'
import { getGptStreamingMessageReaderAndDecoder2 } from '../../utils/gpt'
import LoadingGrid from '../../components/LoadingGrid/LoadingGrid.jsx'
import { processGptStream } from '../../utils/processGptStream.js'
import { auth } from '../../firebase'

import { Grid } from 'react-loader-spinner'
function SubmissionManagement() {
  const [searchParams] = useSearchParams()
  const schoolId = searchParams.get('school_id')
  const studentId = searchParams.get('student_id')
  const [conversations, setConversations] = useState([])
  const [messages, setMessages] = useState([])
  const [dataForTagCloud, setDataForTagCloud] = useState([])
  const wordCloudMaxDisplayCount = 150
  const [rowData, setRowData] = useState([])
  const [maxFrequency, setMaxFrequency] = useState(999)
  const [minFrequency, setMinFrequency] = useState(1)
  const [filteredData, setFilteredData] = useState([])
  const [dailyMessageCount, setDailyMessageCount] = useState([])
  const [avatorImages, setAvatorImages] = useState([ai, ai2])
  const [isGenerating, setIsGenerating] = useState(false) // LLMが生成中かどうかの状態
  const [summaryMessage, setSummaryMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [abortController, setAbortController] = useState(null)

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Dataset',
        data: [],
        backgroundColor: 'rgba(75, 192, 192, 0.7)'
      }
    ]
  })
  const handleCancelStream = () => {
    if (abortController) {
      abortController.abort()
      setAbortController(null) // コcントローラのリセット
    }
  }
  const optionsForHistgram = {
    indexAxis: 'x',
    scales: {
      x: {
        title: {
          display: true,
          text: '単語'
        }
      },
      y: {
        title: {
          display: true,
          text: '出現頻度'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  }
  const options = {
    luminosity: 'bright',
    hue: 'blue'
  }

  const listItem = [
    { name: '会話データ', path: schoolId ? `/chat_data?school_id=${schoolId}` : `/chat_data`, icon: <TocIcon /> }
  ]

  const analyzeConversations = async () => {
    setLoading(true)
    setIsGenerating(true)
    const csvData = allData
    console.log('csvData', csvData)
    if (csvData.length === 0) {
      alert('提出物がないので、解析できませんでした')
      setLoading(false)
      return
    }
    const MAX_LENGTH = 10000
    const text = csvData.map((item) => `${item.role}: ${item.message}`).join('\n---\n')
    let trimmedText = text
    // 100,000文字を超える場合、古いメッセージから削る
    if (trimmedText.length > MAX_LENGTH) {
      const messages = text.split('\n---\n')
      while (trimmedText.length > MAX_LENGTH && messages.length > 0) {
        messages.shift()
        trimmedText = messages.join('\n---\n')
      }
    }
    const content = `
    #指示
    下記の 生徒とGPTの会話データ を分析して、先生に対して有益なフィードバックを提供してください。
    有益なフィードバックとして、
    ・【会話データからわかる生徒の特徴】
    ・【先生へのアドバイス】
    を日本語で提要してください。
    補足情報：systemはあらかじめ設定されているgptの振る舞い、userは生徒の発言、assistantはgptの発言です。

    #生徒とGPTの会話データ
    ----
    ${trimmedText}
    `
    const prompt = [
      {
        role: 'system',
        content
      }
    ]
    console.log('prompt', content.length, prompt)

    await processGptStream({
      model: 'azure-gpt-4o',
      prompt,
      setLoading,
      setIsGenerating,
      setSummaryMessage,
      handleCancelStream
    })
  }
  const [userName, setUserName] = useState('')
  const getConversations = async () => {
    const userDocRef = doc(db, 'users', studentId)
    const userDoc = await getDoc(userDocRef)
    const user_name = userDoc.data().name
      ? userDoc.data().name
      : userDoc.data().lastName || userDoc.data().firstName
      ? userDoc.data().lastName + ' ' + userDoc.data().firstName
      : userDoc.data().schoolDocId
      ? '先生用アカウント'
      : 'みんがく管理人'
    setUserName(user_name)
    const conversationsDocRef = collection(userDocRef, 'conversations')
    const q = query(conversationsDocRef, orderBy('createdAt', 'desc'), limit(1000)) // ここでlimitを設定

    try {
      const querySnapshot = await getDocs(q)
      if (querySnapshot.docs.length > 0) {
        let conversationData = []
        let allMessages = []
        conversationData = querySnapshot.docs.map((doc) => doc.data())
        console.log('conversationData', conversationData)
        setConversations(conversationData) // 状態に全ての会話を設定
        // 各 conversation ドキュメントの messages コレクションを非同期に取得
        const messagesPromises = querySnapshot.docs.map(async (doc) => {
          const messagesCollectionRef = collection(doc.ref, 'messages')
          const messagesSnapshot = await getDocs(messagesCollectionRef)
          return messagesSnapshot.docs.map((messageDoc) => messageDoc.data())
        })
        // 全てのメッセージを取得する
        const allMessagesArray = await Promise.all(messagesPromises)
        allMessages = allMessagesArray.flat() // 二次元配列を一次元に平坦化
        console.log('allMessages', allMessages)
        setMessages(allMessages) // 状態に全てのメッセージを設定
        return [conversationData, allMessages]
      } else {
        console.log('no data')
        return []
      }
    } catch (error) {
      console.error('Error getting documents: ', error)
      return []
    }
  }

  const runWordCloud = async (messageList = []) => {
    const csvData = messageList
    const csvDataWithoutSystemMesaage = csvData.filter((item) => item.role !== 'system')
    if (csvData.length === 0) {
      alert('会話データがないので、解析できませんでした')
    }
    const text = csvDataWithoutSystemMesaage.map((item) => item.message).join(' ')
    const truncatedText = text.length > 10000 ? text.substring(text.length - 10000) : text
    const DIC_URL = '/dict'
    // const TARGET_POS = ['名詞', '動詞', '形容詞']
    const TARGET_POS = ['名詞', '形容詞']
    const TARGET_POS_COLOR = {
      名詞: '#3965f5cd',
      動詞: '#f84343cd',
      形容詞: '#00b51eff'
    }
    const NO_CONTENT = '*'
    kuromoji.builder({ dicPath: '/dict' }).build((err, tokenizer) => {
      if (err) {
        console.log(err)
      } else {
        const tokens = tokenizer.tokenize(text)
        console.log('tokens', tokens)
        const dataForD3Cloud = tokens
          .filter(
            (t) =>
              TARGET_POS.includes(t.pos) &&
              !(t.basic_form === '*' && t.word_type === 'UNKNOWN' && t.pos_detail_1 === 'サ変接続')
          )
          .map((t) =>
            t.basic_form === NO_CONTENT ? { word: t.surface_form, pos: t.pos } : { word: t.basic_form, pos: t.pos }
          )
          .reduce((data, item) => {
            const target = data.find((c) => c.text === item.word)
            if (target) {
              target.value = target.value + 1
            } else {
              data.push({
                text: item.word,
                value: 1,
                pos: item.pos
              })
            }
            return data
          }, [])

        const dataForTagCloud = dataForD3Cloud
          .filter((word) => !listOfExcludedWords.includes(word.text))
          .map((word) => ({
            value: word.text,
            count: word.value,
            color: TARGET_POS_COLOR[word.pos],
            pos: word.pos,
            単語: word.text, // AG Grid用のデータ
            品詞: word.pos, // AG Grid用のデータ
            出現頻度: word.value // AG Grid用のデータ
          }))
          .sort((a, b) => b.count - a.count)
        setDataForTagCloud(dataForTagCloud.slice(0, wordCloudMaxDisplayCount))
        setFilteredData(dataForTagCloud.slice(0, wordCloudMaxDisplayCount))
        setRowData(dataForTagCloud)
      }
    })
  }

  // 日付ごとの件数を集計
  const processData = (conversationData, messageList) => {
    const userMessageCounts = {}
    const assistantMessageCounts = {}
    const userCharacterCounts = {}
    const assistantCharacterCounts = {}
    const conversationCounts = {}

    messageList.forEach((item) => {
      const date = new Date(item.createdAt).toLocaleDateString()

      if (item.role === 'user') {
        if (!userMessageCounts[date]) userMessageCounts[date] = 0
        if (!userCharacterCounts[date]) userCharacterCounts[date] = 0

        userMessageCounts[date]++
        userCharacterCounts[date] += item.characterCount
      } else if (item.role === 'assistant') {
        if (!assistantMessageCounts[date]) assistantMessageCounts[date] = 0
        if (!assistantCharacterCounts[date]) assistantCharacterCounts[date] = 0

        assistantMessageCounts[date]++
        assistantCharacterCounts[date] += item.characterCount
      }
    })

    // 会話データを集計
    conversationData.forEach((item) => {
      const date = new Date(item.createdAt).toLocaleDateString()

      if (!conversationCounts[date]) conversationCounts[date] = 0
      conversationCounts[date]++
    })

    const result = []
    Object.keys(userMessageCounts).forEach((date) => {
      result.push({
        date,
        userMessages: userMessageCounts[date] || 0,
        assistantMessages: assistantMessageCounts[date] || 0,
        userCharacters: userCharacterCounts[date] || 0,
        assistantCharacters: assistantCharacterCounts[date] || 0,
        conversations: conversationCounts[date] || 0
      })
    })

    const sortedResult = result.sort((a, b) => new Date(a.date) - new Date(b.date))

    console.log('sortedResult', sortedResult)
    setDailyMessageCount(sortedResult)
    return sortedResult
  }
  const [allData, setAllData] = useState([])
  useEffect(() => {
    ;(async () => {
      const [conversationData, allMessages] = await getConversations()
      setAllData(allMessages)
      runWordCloud(allMessages)
      processData(conversationData, allMessages)
    })()
  }, [])
  useEffect(() => {
    const newData = dataForTagCloud.filter((word) => word.count >= minFrequency && word.count <= maxFrequency)
    const newSlicedData = newData.slice(0, wordCloudMaxDisplayCount)
    console.log('newSlicedData', newSlicedData)
    setFilteredData(newSlicedData)
    const labels = newSlicedData.map((item) => item.value)
    const counts = newSlicedData.map((item) => item.count)
    setChartData({
      labels,
      datasets: [
        {
          label: 'Dataset',
          data: counts,
          backgroundColor: 'rgba(75, 192, 192, 0.7)'
        }
      ]
    })
  }, [maxFrequency, minFrequency, dataForTagCloud])
  const cardData = [
    {
      title: 'スレッド数',
      number: conversations.length,
      dataKey: 'conversations',
      stroke: '#8884d8',
      name: 'スレッド数'
    },
    {
      title: '生徒が送信したメッセージ数',
      number: messages.filter((message) => message.role === 'user').length,
      dataKey: 'userMessages',
      stroke: '#8884d8',
      name: '生徒が作成したメッセージ数'
    },
    {
      title: 'AIが作成したメッセージ数',
      number: messages.filter((message) => message.role === 'assistant').length,
      dataKey: 'assistantMessages',
      stroke: '#82ca9d',
      name: 'AIが作成したメッセージ数'
    },
    {
      title: '生徒が送信した文字数',
      number: messages
        .filter((message) => message.role === 'user')
        .reduce((sum, msg) => sum + (msg.message?.length || 0), 0),
      dataKey: 'userCharacters',
      stroke: '#ffc658',
      name: '生徒が送信した文字数'
    },
    {
      title: 'AIが作成した文字数',
      number: messages
        .filter((message) => message.role === 'assistant')
        .reduce((sum, msg) => sum + (msg.message?.length || 0), 0),
      dataKey: 'assistantCharacters',
      stroke: '#ff7300',
      name: 'AIが送信した文字数'
    }
  ]
  return (
    <div className={AppModule.main}>
      {/* <Header title="生徒会話分析" listItem={listItem} /> */}
      {/* <SubmissionManagementScreen serviceType="gpt-teacher" prompt_type_choice={_prompt_type_choice_NANDE} /> */}
      {/* <div>メッセージ数: {messages.filter((message) => ['user', 'assistant'].includes(message.role)).length}</div> */}
      <div className={AppModule.main}>
        <Header title={`生徒会話分析【${userName}】`} listItem={listItem} />

        {/* The rest of your component remains the same */}
        <div className={AnalysisStudentModule.cardContainer}>
          {cardData.map((card, index) => (
            <AnalysisCard
              key={index}
              title={card.title}
              number={card.number}
              dataKey={card.dataKey}
              data={dailyMessageCount}
              stroke={card.stroke}
              name={card.name}
            />
          ))}
        </div>
      </div>
      <div className={AnalysisStudentModule.analysisContainer}>
        {dataForTagCloud.length === 0 ? (
          <>
            {/* <button className={AnalysisStudentModule['analyze-button']} onClick={() => runWordCloud()}>
              会話を解析する
            </button>
            <p className={AnalysisStudentModule['sub-discription-of-analyze']}>
              単語出現頻度を解析したり、ワードクラウドを可視化することができます。
            </p> */}
          </>
        ) : (
          <>
            <div className={AnalysisStudentModule.analysisItem}>
              <p className={AnalysisStudentModule['title-of-word-cloud']}>単語出現頻度解析</p>
              <p className={AnalysisStudentModule['sub-discription-of-word-cloud']}>
                文章中に出現する名詞、形容詞の単語の出現頻度のヒストグラムです。(最大で上位
                {wordCloudMaxDisplayCount}個を表示)
              </p>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div>
                  <label>
                    最低出現頻度:
                    <input
                      type="number"
                      value={minFrequency}
                      onChange={(e) => setMinFrequency(Number(e.target.value))}
                    />
                  </label>
                  ~
                  <label>
                    最高出現頻度:
                    <input
                      type="number"
                      value={maxFrequency}
                      onChange={(e) => setMaxFrequency(Number(e.target.value))}
                    />
                  </label>
                </div>
              </div>
              <div className={AnalysisStudentModule['word-cloud']}>
                {filteredData.length === 0 ? (
                  <>条件に一致するデータがありません</>
                ) : (
                  <>
                    <Bar data={chartData} options={optionsForHistgram} />
                  </>
                )}
              </div>
            </div>

            <div className={AnalysisStudentModule.analysisItem}>
              <p className={AnalysisStudentModule['title-of-word-cloud']}>ワードクラウド</p>
              <p className={AnalysisStudentModule['sub-discription-of-word-cloud']}>
                出現頻度に応じた大きさで単語を可視化しています。(最大で上位{wordCloudMaxDisplayCount}
                個を表示)
                <br />
                単語の色は品詞によって異なります。青色は名詞を、緑色は形容詞を表します。
                (現在、英語の単語は全て青色で表示されています)
              </p>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div>
                  <label>
                    最低出現頻度:
                    <input
                      type="number"
                      value={minFrequency}
                      onChange={(e) => setMinFrequency(Number(e.target.value))}
                    />
                  </label>
                  ~
                  <label>
                    最高出現頻度:
                    <input
                      type="number"
                      value={maxFrequency}
                      onChange={(e) => setMaxFrequency(Number(e.target.value))}
                    />
                  </label>
                </div>
              </div>

              <div className={AnalysisStudentModule['word-cloud']}>
                {filteredData.length === 0 ? (
                  <>条件に一致するデータがありません</>
                ) : (
                  <>
                    <TagCloud
                      minSize={12}
                      maxSize={56}
                      tags={filteredData}
                      colorOptions={options}
                      onClick={(tag) => alert(`選択した単語：  ${tag.value} \n出現頻度：  ${tag.count}`)}
                      randomSeed={50}
                      // renderer={customRenderer}
                    />
                  </>
                )}
              </div>
            </div>
            <div className={AnalysisStudentModule.analysisItemBottom}>
              {summaryMessage === '' ? (
                <>
                  <p className={AnalysisStudentModule['title-of-word-cloud']}>AI分析</p>
                  <p className={AnalysisStudentModule['sub-discription-of-word-cloud']}>
                    {userName}さんの会話を分析します。
                  </p>
                  <button className={AnalysisStudentModule['analyze-button']} onClick={() => analyzeConversations()}>
                    分析を開始する
                  </button>
                  {loading ? (
                    <Grid
                      height="80"
                      width="80"
                      color={'#1e90ff'}
                      ariaLabel="grid-loading"
                      radius="12.5"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : null}
                </>
              ) : (
                <div>
                  <p className={AnalysisStudentModule['title-of-word-cloud']}>AI分析</p>
                  <p className={AnalysisStudentModule['sub-discription-of-word-cloud']}>
                    直近1万文字分の会話の分析結果を表示しています。
                  </p>
                  <div style={{ display: 'flex' }}>
                    <ChatBubble
                      index={1}
                      lastIndex={1}
                      chatLength={2}
                      key={1}
                      id={`chat-bubble-1`}
                      playingIndex={1}
                      role={'assistant'}
                      messageText={summaryMessage}
                      avator_images={avatorImages}
                      isLargeIcon={false}
                      isGenerating={isGenerating}
                      displayMenuButton={false}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {/* {messages.map((message) => (
        <>
          <div key={message.id}>{message.model}</div>
          <div key={message.id}>{message.role}</div>
          <div key={message.id}>{message.message}</div>
        </>
      ))} */}
      <Footer homePath="/" addChatPath="/mode" userPath="/mypage" />
    </div>
  )
}

export default SubmissionManagement
