import React from 'react'
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts'
import AnalysisCardModule from './AnalysisCard.module.scss'
const AnalysisCard = ({ title, number, dataKey, data, stroke, name }) => {
  const graphHight = 150
  return (
    <div className={AnalysisCardModule.card}>
      <div className={AnalysisCardModule['card-title']}>{title}</div>
      <div className={AnalysisCardModule['card-number']}>{number}</div>
      <ResponsiveContainer width="100%" height={graphHight}>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey={dataKey} stroke={stroke} name={name} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default AnalysisCard
