import React, { createContext, useState, useContext } from 'react'

// コンテキストの作成
const ImageContext = createContext()

export const useImageContext = () => {
  return useContext(ImageContext)
}

export const ImageProvider = ({ children }) => {
  const [imageData, setImageData] = useState(null)

  return <ImageContext.Provider value={{ imageData, setImageData }}>{children}</ImageContext.Provider>
}
