import React, { useEffect } from 'react'
import ModalForPictureModuel from './ModalForPicture.module.scss'
const ModalForPicture = ({ image, onClose }) => {
  // モーダルの外側がクリックされたときに閉じる
  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose() // onCloseプロパティに渡された関数を呼び出す
    }
  }
  return (
    <div className={ModalForPictureModuel['container']} onClick={handleOutsideClick}>
      <img src={image}></img>
    </div>
  )
}

export default ModalForPicture
