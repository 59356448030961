import React, { useState, useEffect } from 'react'
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import HeaderModule from '../Header2.module.scss'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../../firebase'
import LogoutIcon from '@mui/icons-material/Logout'
import { Button } from 'antd'
import { db, functions } from '../../../firebase'
import {
  collection,
  getDocs,
  doc,
  setDoc,
  query,
  getDoc,
  updateDoc,
  deleteDoc,
  addDoc,
  where,
  deleteField,
  collectionGroup
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { _gpt_model } from '../../../localConstant'

const HambergerMenu = ({ listItem, isSchoolId, onLogout }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate()
  const logout = () => {
    auth.signOut().then(() => {
      navigate('/login') // Replace '/login' with the appropriate login path
    })
  }

  const pageNavigation = (path) => {
    console.log('path', path)
    if (path.includes('chat_data') || path.includes('thread_list')) {
      return
    }
    navigate(path, {})
  }
  const handleMenuItemEnter = (event, index) => {
    if (listItem[index].children.length > 0) {
      setAnchorEl(event.currentTarget)
      setSelectedIndex(index)
      setMenuOpen(true)
    }
  }
  const childMenuPageNavigation = async (path, additionalParamName, param, term) => {
    if (isSchoolId) {
      // schoolIdがある場合

      navigate(`${path}&${additionalParamName}=${param}`, {})
    } else {
      // schoolIdがない場合
      navigate(`${path}?${additionalParamName}=${param}`, {})
    }
    window.location.reload()
  }
  const handleMenuItemLeave = () => {
    setMenuOpen(false)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setSelectedIndex(null)
    setMenuOpen(false)
  }

  const extractSchoolModeModel = async () => {
    const querySnapshot = await getDocs(collection(db, '/externalServices/mingaku/schools'))
    const schoolRefs = querySnapshot.docs.map((doc) => {
      return doc.ref
    })
    const schoolPromptsPromises = schoolRefs.map(async (schoolRef) => {
      const promptsCollectionRef = collection(schoolRef, 'prompts')
      return await getDocs(promptsCollectionRef)
    })
    const schoolPromptsSnapshots = await Promise.all(schoolPromptsPromises)
    const schoolPrompts = schoolPromptsSnapshots.map((snapshot) => {
      const schoolId = snapshot.query.parent.id
      return snapshot.docs.map((doc) => {
        const { prompt_title, model } = doc.data()
        return { schoolId, prompt_title, model }
      })
    })
    const { schoolInfo } = await getSchoolsInfo() // schoolAIのschool情報を全件取得用
    const schoolsOfSchoolAI = schoolInfo.filter((school) => !!school.data) // dataがないschoolは除外
    const schoolIdName = schoolsOfSchoolAI.map((school) => {
      return { schoolId: school.schoolId, name: school.data.name }
    })
    const extractData = schoolPrompts.flat().map((schoolPrompt) => {
      const school = schoolIdName.find((school) => school.schoolId === schoolPrompt.schoolId)
      if (school) {
        schoolPrompt.schoolName = school.name
        const modelName = _gpt_model.find((model) => model.value === schoolPrompt.model)
        return {
          schoolName: schoolPrompt.schoolName,
          schoolId: schoolPrompt.schoolId,
          prompt_title: schoolPrompt.prompt_title,
          model: modelName.displayName
        }
      }
    })
    exportToCSV(extractData)
  }

  const exportToCSV = (data) => {
    const headers = ['schoolName', 'schoolId', 'prompt_title', 'model']
    const csvRows = []
    csvRows.push(headers.join(','))
    data.forEach((row) => {
      console.log('row', row)
      if (row === undefined) {
        return
      }
      const values = headers.map((header) => {
        console.log('header', header)
        console.log('row[header]', row[header])
        const value = row[header] !== undefined ? row[header] : ''
        const escaped = ('' + value).replace(/"/g, '\\"') // Escape double quotes
        return `"${escaped}"`
      })
      csvRows.push(values.join(','))
    })
    const csvString = csvRows.join('\n')
    const blob = new Blob([csvString], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('hidden', '')
    a.setAttribute('href', url)
    a.setAttribute('download', 'data.csv')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const getSchoolsInfo = async () => {
    const getSchools = httpsCallable(functions, 'get_schools_info')
    const res = await getSchools() // schoolAIのschook情報を全件取得用
    const { data } = res
    return data
  }

  useEffect(() => {
    if (!menuOpen) {
      setAnchorEl(null)
      setSelectedIndex(null)
    }
  }, [menuOpen])
  const [drawerOpened, setDrawerOpened] = useState(false)

  return (
    <>
      <MenuIcon onClick={(e) => setDrawerOpened(true)}></MenuIcon>

      <Drawer anchor={'right'} open={drawerOpened} onClose={(e) => setDrawerOpened(false)}>
        <List>
          {listItem.map((item, index) => (
            <ListItem
              key={index}
              disablePadding
              onMouseEnter={(e) => handleMenuItemEnter(e, index)}
              onMouseLeave={handleMenuItemLeave}
            >
              <ListItemButton onClick={() => pageNavigation(item.path)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>

              {item.children.length > 0 && (
                <>
                  {Boolean(anchorEl) && selectedIndex === index && (
                    <ol onMouseLeave={handleMenuItemLeave} className={HeaderModule.subMenu}>
                      {item.children.map((child, childIndex) => (
                        <li
                          key={childIndex}
                          onClick={() =>
                            childMenuPageNavigation(item.path, item.additionalParamName, child.id, child.displayName)
                          }
                        >
                          {child.displayName}
                        </li>
                      ))}
                    </ol>
                  )}
                </>
              )}
            </ListItem>
          ))}
          <ListItem disablePadding>
            <ListItemButton onClick={logout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="ログアウト" />
            </ListItemButton>
          </ListItem>
        </List>
        {/* <Button onClick={() => extractSchoolModeModel()}>データ抽出</Button> */}
        <div className={HeaderModule.closeButton}>
          <CloseIcon className={HeaderModule.closeIcon} onClick={() => setDrawerOpened(false)} />
        </div>
      </Drawer>
    </>
  )
}

export default HambergerMenu
