import ManagementModule from './SubmissionManagementScreen.module.scss'
import { getAssistantApi } from '../../utils/assistantApi'
import { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import MenuIcon2 from '../MenuIcon/MenuIcon'
import LoadingGrid from '../LoadingGrid/LoadingGrid.jsx'
import {
  collection,
  getDocs,
  doc,
  setDoc,
  query,
  getDoc,
  updateDoc,
  deleteDoc,
  addDoc,
  where,
  orderBy
} from 'firebase/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { db, auth, functions } from '../../firebase'
import { useParams, useSearchParams } from 'react-router-dom'
import { httpsCallable } from 'firebase/functions'
import { signInWithCustomToken } from 'firebase/auth'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import ModeSearch from '../ModeSearch/ModeSearch' // 新しいコンポーネントのパスは適宜調整してください
import Switch2 from '../Switch/Switch.jsx'
import kuromoji from 'kuromoji'
import { TagCloud } from 'react-tagcloud'
import { AgGridReact } from 'ag-grid-react' // AG Grid Component
import 'ag-grid-community/styles/ag-grid.css' // Mandatory CSS required by the grid
import 'ag-grid-community/styles/ag-theme-quartz.css' // Optional Theme applied to the grid
import { listOfExcludedWords } from '../../localConstant.js'
import { getGptStreamingMessageReaderAndDecoder2 } from '../../utils/gpt'
import { getClaudeStreamingMessageReaderAndDecoder } from '../../utils/claude'
import { Bar } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import ai from '../../assets/ai.png'
import ai2 from '../../assets/ai2.png'
import ChatBubble from '../../components/Bubble/ChatBubble'
import { processGptStream } from '../../utils/processGptStream.js'

Chart.register(...registerables)

function useEditedState(initialValue) {
  const [value, setValue] = useState(initialValue)
  const [isEdited, setIsEdited] = useState(false)

  // setValueをラップする関数
  const setEditedValue = useCallback((newValue) => {
    setValue(newValue)
    setIsEdited(true)
  }, [])

  return [value, setEditedValue, isEdited, setIsEdited]
}

function ManagementScreen({ serviceType }) {
  const [rowData, setRowData] = useState([])
  const [colDefs, setColDefs] = useState([{ field: '単語' }, { field: '品詞' }, { field: '出現頻度' }])
  const [dataForTagCloud, setDataForTagCloud] = useState([])
  const options = {
    luminosity: 'bright',
    hue: 'blue'
  }
  const defaultColDef = useMemo(() => {
    return {
      filter: 'agTextColumnFilter',
      floatingFilter: true
    }
  }, [])
  const wordCloudMaxDisplayCount = 150

  const [maxFrequency, setMaxFrequency] = useState(999)
  const [minFrequency, setMinFrequency] = useState(1)
  const [filteredData, setFilteredData] = useState([])

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Dataset',
        data: [],
        backgroundColor: 'rgba(75, 192, 192, 0.7)'
      }
    ]
  })

  const optionsForHistgram = {
    indexAxis: 'x',
    scales: {
      x: {
        title: {
          display: true,
          text: '単語'
        }
      },
      y: {
        title: {
          display: true,
          text: '出現頻度'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  }

  useEffect(() => {
    const newData = dataForTagCloud.filter((word) => word.count >= minFrequency && word.count <= maxFrequency)
    const newSlicedData = newData.slice(0, wordCloudMaxDisplayCount)
    console.log('newSlicedData', newSlicedData)
    setFilteredData(newSlicedData)
    const labels = newSlicedData.map((item) => item.value)
    const counts = newSlicedData.map((item) => item.count)
    setChartData({
      labels,
      datasets: [
        {
          label: 'Dataset',
          data: counts,
          backgroundColor: 'rgba(75, 192, 192, 0.7)'
        }
      ]
    })
  }, [maxFrequency, minFrequency, dataForTagCloud])

  const [summaryMessage, setSummaryMessage] = useState('')
  const [avatorImages, setAvatorImages] = useState([ai, ai2])
  const [isGenerating, setIsGenerating] = useState(false) // LLMが生成中かどうかの状態
  const [abortController, setAbortController] = useState(null)
  const [isBubbleLoading, setIsBubbleLoading] = useState(false)

  useEffect(() => {
    // コンポーネントのクリーンアップ時にストリームをキャンセル
    return () => {
      if (abortController) {
        abortController.abort()
      }
    }
    // conversationIdが変更された時もabortされるようにした
  }, [abortController])
  const handleCancelStream = () => {
    if (abortController) {
      abortController.abort()
      setAbortController(null) // コcントローラのリセット
    }
  }

  const runSummary = async (submissionBox) => {
    setLoading(true)
    setIsGenerating(true)
    const csvData = await fetchConversations(submissionBox.id)
    if (csvData.length === 0) {
      alert('提出物がないので、解析できませんでした')
      setLoading(false)
    }
    const text = csvData.map((item) => item.message).join('\n----\n')
    const content = `
#指示
以下のデータや情報を箇条書きで読みやすく要約してください。

#データや情報
----
${text}
`
    const prompt = [
      {
        role: 'system',
        content
      }
    ]
    console.log('prompt', prompt)

    await processGptStream({
      model: 'azure-gpt-4o',
      prompt,
      setLoading,
      setIsGenerating,
      setSummaryMessage,
      handleCancelStream
    })
    // const controller = new AbortController()
    // setAbortController(controller)

    // let reader, decoder
    // let count = 0
    // let message = ''

    // const model = 'azure-gpt-4o'
    // try {
    //   ;[reader, decoder] = await getGptStreamingMessageReaderAndDecoder2({
    //     model,
    //     prompt,
    //     controller
    //   })
    // } catch (error) {
    //   setLoading(false)
    //   setIsGenerating(false)
    //   setSummaryMessage('')
    //   handleCancelStream()
    //   console.log('error.name', error.name)
    //   console.log('Error fetching GPT stream:', error)
    //   console.log('生成を途中で中断しました。')
    //   return
    // }

    // try {
    //   const read = async (missingJson = '') => {
    //     const { done, value } = await reader.read()
    //     if (done) {
    //       return reader.releaseLock()
    //     }
    //     const chunk = decoder.decode(value, { stream: true })
    //     const jsons = chunk
    //       .split('data:')
    //       .map((data) => {
    //         const trimData = data.trim()
    //         if (trimData === '' || trimData === '[DONE]') return undefined
    //         try {
    //           const jsonObj = JSON.parse(missingJson + trimData)
    //           missingJson = ''
    //           return jsonObj
    //         } catch (e) {
    //           missingJson = trimData
    //           return undefined
    //         }
    //       })
    //       .filter((data) => data)
    //     for (let test of jsons) {
    //       await new Promise((resolve) => {
    //         setTimeout(() => {
    //           if ((test.choices && test.choices.length !== 0) || test.text !== undefined) {
    //             if ((test.choices && test.choices[0].delta.content !== undefined) || test.text !== undefined) {
    //               message += test.choices ? test.choices[0].delta.content : test.text
    //               console.log(message)
    //               count = count + 1
    //               if (count !== 0) {
    //                 setLoading(false)
    //                 setIsGenerating(true)
    //               }
    //               setSummaryMessage(message)
    //             }
    //           }
    //           resolve(null)
    //         }, 33)
    //       })
    //     }
    //     return read(missingJson)
    //   }
    //   await read()
    //   setIsGenerating(false)
    // } catch (error) {
    //   setLoading(false)
    //   setIsGenerating(false)
    //   setSummaryMessage('')
    //   handleCancelStream()
    //   console.log('error.name', error.name)
    //   console.log('Error fetching GPT stream:', error)
    //   console.log('生成を途中で中断しました。')
    //   return
    // }
  }

  const runWordCloud = async (submissionBox) => {
    setLoading(true)
    const csvData = await fetchConversations(submissionBox.id)
    const csvDataWithoutSystemMesaage = csvData.filter((item) => item.role !== 'system')
    console.log('csvData', csvData)
    console.log('csvcsvDataWithoutSystemMesaageData', csvDataWithoutSystemMesaage)

    if (csvData.length === 0) {
      alert('提出物がないので、解析できませんでした')
    }
    setLoading(false)
    const text = csvDataWithoutSystemMesaage.map((item) => item.message).join(' ')
    console.log('text', text)
    const DIC_URL = '/dict'
    // const TARGET_POS = ['名詞', '動詞', '形容詞']
    const TARGET_POS = ['名詞', '形容詞']
    const TARGET_POS_COLOR = {
      名詞: '#3965f5cd',
      動詞: '#f84343cd',
      形容詞: '#00b51eff'
    }
    const NO_CONTENT = '*'
    kuromoji.builder({ dicPath: '/dict' }).build((err, tokenizer) => {
      if (err) {
        console.log(err)
      } else {
        const tokens = tokenizer.tokenize(text)
        console.log('tokens', tokens)
        const dataForD3Cloud = tokens
          .filter(
            (t) =>
              TARGET_POS.includes(t.pos) &&
              !(t.basic_form === '*' && t.word_type === 'UNKNOWN' && t.pos_detail_1 === 'サ変接続')
          )
          .map((t) =>
            t.basic_form === NO_CONTENT ? { word: t.surface_form, pos: t.pos } : { word: t.basic_form, pos: t.pos }
          )
          .reduce((data, item) => {
            const target = data.find((c) => c.text === item.word)
            if (target) {
              target.value = target.value + 1
            } else {
              data.push({
                text: item.word,
                value: 1,
                pos: item.pos
              })
            }
            return data
          }, [])

        const dataForTagCloud = dataForD3Cloud
          .filter((word) => !listOfExcludedWords.includes(word.text))
          .map((word) => ({
            value: word.text,
            count: word.value,
            color: TARGET_POS_COLOR[word.pos],
            pos: word.pos,
            単語: word.text, // AG Grid用のデータ
            品詞: word.pos, // AG Grid用のデータ
            出現頻度: word.value // AG Grid用のデータ
          }))
          .sort((a, b) => b.count - a.count)
        setDataForTagCloud(dataForTagCloud.slice(0, wordCloudMaxDisplayCount))
        setFilteredData(dataForTagCloud.slice(0, wordCloudMaxDisplayCount))
        setRowData(dataForTagCloud)
      }
    })
  }

  const customRenderer = (tag, size, color) => (
    <span
      key={tag.value}
      className={ManagementModule['blinking-span']}
      style={{
        '--fontSize': `${size}px`,
        '--color': tag.color,
        '--animationDelay': `${Math.random() * 2}s`
      }}
    >
      {tag.value}
    </span>
  )

  const [text, setText] = useState('親譲りの無鉄砲で小供の時から損ばかりしている')
  const [words, setWords] = useState([])
  const tokenizerPath = 'node_modules/kuromoji/dict/' // kuromojiの辞書のパスを指定
  const handleAnalyze = () => {
    kuromoji.builder({ dicPath: tokenizerPath }).build((err, tokenizer) => {
      if (err) {
        console.error(err)
        return
      }

      const tokens = tokenizer.tokenize(text)
      const wordCounts = tokens.reduce((acc, token) => {
        if (token.pos === '名詞') {
          // 主に名詞のみをカウント
          const word = token.surface_form
          acc[word] = (acc[word] || 0) + 1
        }
        return acc
      }, {})

      const wordCloudData = Object.keys(wordCounts).map((key) => ({
        text: key,
        value: wordCounts[key]
      }))
      setWords(wordCloudData)
    })
  }

  const gridColor = '#1e90ff'
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 150
    }
  })
  const handleIconClick = (item) => {
    const url = createUrl(item)
    window.open(url, '_blank') // 新しいタブでURLを開く
  }
  const getSubmittionBoxTitle = async (ref) => {
    const submissionBoxRef = ref
    const submissionBoxData = await getDoc(submissionBoxRef)
    return submissionBoxData.data().submission_box_title
  }

  const handleDownloadSubmittedConversations = async (submissionBox) => {
    console.log('submissionBox', submissionBox)
    const boxTitle = await getSubmittionBoxTitle(submissionBox.ref)
    const csvData = await fetchConversations(submissionBox.id)
    const csvString = convertToCSV(csvData)
    const currentDate = formatDate(new Date().getTime(), 'jp')
    downloadCSV(csvString, `${boxTitle}_${currentDate}.csv`)
  }

  const downloadCSV = (csvData, fileName) => {
    const BOM = '\uFEFF' // BOMを追加
    const blob = new Blob([BOM + csvData], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const formatDate = (date, format) => {
    if (!date) return ''
    const d = new Date(date)
    const year = d.getFullYear()
    const month = ('0' + (d.getMonth() + 1)).slice(-2)
    const day = ('0' + d.getDate()).slice(-2)
    const hours = ('0' + d.getHours()).slice(-2)
    const minutes = ('0' + d.getMinutes()).slice(-2)
    const seconds = ('0' + d.getSeconds()).slice(-2)
    if (format === 'jp') {
      return `${year}年${month}月${day}日_${hours}時${minutes}分${seconds}秒`
    } else {
      return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`
    }
  }

  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
    let str = ''
    let columnDelimiter = ','
    let lineDelimiter = '\r\n'

    // Add column titles (keys) as the first row based on headersOrder and headersMap
    str += headersOrder.map((header) => `"${headersMap[header]}"`).join(columnDelimiter) + lineDelimiter

    // Loop over the array of objects and convert each to a CSV row based on headersOrder
    array.forEach((item) => {
      let line = headersOrder
        .map((field) => {
          let value = item[field] === null || item[field] === undefined ? '' : item[field]
          value = String(value)

          // Check if the value contains a comma, double-quote, or newline
          if (value.includes(',') || value.includes('\n') || value.includes('\r') || value.includes('"')) {
            // Escape existing double quotes
            value = value.replace(/"/g, '""')
            // Wrap the value in double quotes
            value = `"${value}"`
          }
          return value
        })
        .join(columnDelimiter)
      str += line + lineDelimiter
    })

    return str
  }
  const getSchoolName = async (schoolDocId) => {
    const getSchoolsInfo = httpsCallable(functions, 'get_schools_info')
    const [schoolInfo] = await Promise.all([getSchoolsInfo({ schoolIds: [schoolDocId] })])
    return schoolInfo ? schoolInfo.data.schoolInfo[0].data.name : ''
  }
  const headersMap = {
    schoolId: 'スクールID',
    schoolName: 'スクール名',
    userId: 'ユーザーID',
    userName: 'ユーザー名',
    type: '提出タイプ',
    submittedAt: '提出日時',
    submission_title: '提出タイトル',
    conversationId: '提出ID',
    messageId: 'メッセージID',
    createdAt: '作成日時',
    role: 'ロール',
    message: 'メッセージ',
    // tokenCountOfMessages: 'メッセージのトークン数',
    // tokenCount: 'トークン数',
    characterCount: '文字数'
    // actualMessageLength: '実際のメッセージ長',
    // model: 'モデル'
  }
  const headersOrder = [
    'schoolId',
    'schoolName',
    'userId',
    'userName',
    'type',
    'submittedAt',
    'submission_title',
    'conversationId',
    'messageId',
    'createdAt',
    'role',
    'message',
    // 'tokenCountOfMessages',
    // 'tokenCount',
    'characterCount'
    // 'actualMessageLength',
    // 'model'
  ]
  const createUrl = (item) => {
    const baseUrl = `/submitted_conversations?school_id=${paramSchoolId}&submittion_box=${item.id}`
    return `${baseUrl}`
  }
  const selectedItemClassName = 'selected-item'
  const defaultAutoSending = false
  const defaultDefaultMute = true
  const navigate = useNavigate()
  const [user] = useAuthState(auth)
  const [selectedPrompt, setSelectedPrompt, isEdited, setIsEdited] = useEditedState('')
  const [prompt, setPrompt] = useState([])
  const [inputPromptTitle, setInputPromptTitle] = useState('')
  const initialState = {
    inputPromptTitle: ''
  }
  const [addBody, setAddBody] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [promptTitle, setPromptTitle] = useState('')
  const [searchParams] = useSearchParams()
  const uid = searchParams.get('uid')
  const muid = searchParams.get('muid')
  const schoolIdfromQueryParam = searchParams.get('school_id')
  const roleFromQueryParam = searchParams.get('role')
  const { schoolId } = useParams()
  const paramSchoolId = searchParams.get('school_id') ? searchParams.get('school_id') : schoolId
  const [loading, setLoading] = useState(false)
  const [schoolIdName, setSchoolIdName] = useState({}) // schoolIdとschoolNameの対応表
  const [searchText, setSearchText] = useState('')
  const [filteredModes, setFilteredModes] = useState([])
  const path = window.location.pathname + window.location.search
  const getSchoolsInfo = async () => {
    const getSchools = httpsCallable(functions, 'get_schools_info')
    const res = await getSchools() // schoolAIのschook情報を全件取得用
    const { data } = res
    return data
  }
  const signInWithUid = async (uid) => {
    const signIn = httpsCallable(functions, 'sign_in_with_uid')
    try {
      const res = await signIn({ uid })
      const { customToken } = res.data
      const userCredential = await signInWithCustomToken(auth, customToken)
      updateDoc(doc(db, 'users', userCredential.user.uid), {
        userId: userCredential.user.uid,
        email: userCredential.user.email ?? '',
        createdAt: userCredential.user.metadata.createdAt ?? null,
        lastLoginAt: userCredential.user.metadata.lastLoginAt ?? null
      })
    } catch (e) {
      navigate('/login')
    } finally {
    }
  }

  /*****  useEffect *****/
  useEffect(() => {
    const filtered = prompt.filter((mode) => mode.submission_box_title.toLowerCase().includes(searchText.toLowerCase()))
    setFilteredModes(filtered)
  }, [searchText, prompt])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // 生徒アカウントがモード管理画面にアクセスした場合、top画面に強制遷移
    if (user) {
      const userDocRef = doc(db, 'users', user.uid)
      getDoc(userDocRef).then((docSnapshot) => {
        if (docSnapshot.exists() && docSnapshot.data().studentDocId) {
          navigate('/')
        }
      })
    }
  }, [user])

  useEffect(() => {
    ;(async () => {
      const { schoolInfo } = await getSchoolsInfo() // schoolAIのschool情報を全件取得用
      const schoolsOfSchoolAI = schoolInfo.filter((school) => !!school.data) // dataがないschoolは除外
      let schoolIdName = {} // schoolIdとschoolNameの対応表
      schoolsOfSchoolAI.map((school) => {
        return (schoolIdName[school.schoolId] = school.data.name) //
      })
      setSchoolIdName(schoolIdName)
    })()
  }, [])

  useEffect(() => {
    if (!roleFromQueryParam || !muid) return
    auth.onAuthStateChanged(async (user) => {
      setLoading(true)
      const getAdminInfo = httpsCallable(functions, 'get_admin_info_of_mingaku')
      const { data } = await getAdminInfo({ userId: muid })
      const { adminAuthData, adminDocData } = data
      const email = data.adminAuthData.email
      const checkRegisteredEmail = httpsCallable(functions, 'check_regsitered_email')
      const res = await checkRegisteredEmail({ email })
      const emailRegisterd = res.data.exists
      const matchingUserDocRef = query(
        collection(db, 'users'),
        where('email', '==', email),
        where('type', '==', 'mingaku'),
        where('role', '==', 'admin')
      )
      const matchingDocSnapshot = await getDocs(matchingUserDocRef)
      const isAlreadyLinked = matchingDocSnapshot.docs.length !== 0
      const linkageData = {
        mingakuUserId: muid,
        type: 'mingaku',
        role: 'admin'
      }
      if (user) {
        if (!emailRegisterd) {
          setLoading(false)
          navigate('/signup_for_mingaku_tutor', {
            state: {
              redirectPath: `${path}`,
              email,
              adminAuthData,
              adminDocData,
              muid,
              role: roleFromQueryParam,
              linkageData
            }
          })
        } else {
          if (user.email === email) {
            const userDocRef = doc(db, 'users', user.uid)
            await setDoc(userDocRef, linkageData, { merge: true })
            setLoading(false)
          } else {
            setLoading(false)
            navigate('/login_for_mingaku_tutor', {
              state: {
                redirectPath: `${path}`,
                email,
                adminAuthData,
                adminDocData,
                muid,
                role: roleFromQueryParam,
                isAlreadyLinked,
                linkageData
              }
            })
          }
        }
      } else {
        if (!emailRegisterd) {
          setLoading(false)
          navigate('/signup_for_mingaku_tutor', {
            state: {
              redirectPath: `${path}`,
              email,
              adminAuthData,
              adminDocData,
              muid,
              role: roleFromQueryParam,
              linkageData
            }
          })
        } else {
          setLoading(false)
          navigate('/login_for_mingaku_tutor', {
            state: {
              redirectPath: `${path}`,
              email,
              adminAuthData,
              adminDocData,
              muid,
              role: roleFromQueryParam,
              isAlreadyLinked,
              linkageData
            }
          })
        }
      }
    })
  }, [muid, path, roleFromQueryParam])

  useEffect(() => {
    if (!schoolIdfromQueryParam || !muid) return
    auth.onAuthStateChanged(async (user) => {
      const getTutorInfo = httpsCallable(functions, 'get_tutor_info_of_mingaku')
      setLoading(true)
      const { data } = await getTutorInfo({ userId: muid, schoolId: schoolIdfromQueryParam })
      const { tutorAuthData, tutorDocData } = data
      console.log('tutorDocData', tutorDocData)
      const emailFromTutorAuthData = data.tutorAuthData.email
      const checkRegisteredEmail = httpsCallable(functions, 'check_regsitered_email')
      const res = await checkRegisteredEmail({ email: emailFromTutorAuthData })
      const emailRegisterd = res.data.exists
      const matchingUserDocRef = query(
        collection(db, 'users'),
        where('email', '==', emailFromTutorAuthData),
        where('schoolDocId', '==', schoolIdfromQueryParam),
        where('type', '==', 'mingaku'),
        where('role', '==', 'tutor')
      )
      const matchingDocSnapshot = await getDocs(matchingUserDocRef)
      const isAlreadyLinked = matchingDocSnapshot.docs.length !== 0
      const linkageData = {
        schoolDocId: schoolIdfromQueryParam,
        tutorDocId: muid,
        mingakuUserId: muid,
        type: 'mingaku',
        role: 'tutor'
      }
      if (user) {
        if (user.email !== emailFromTutorAuthData) {
          if (!emailRegisterd) {
            setLoading(false)
            navigate('/signup_for_mingaku_tutor', {
              state: {
                redirectPath: `${path}`,
                email: emailFromTutorAuthData,
                isAlreadyLinked,
                linkageData,
                tutorAuthData,
                tutorDocData,
                schoolIdfromQueryParam,
                muid,
                role: 'tutor'
              }
            })
          } else {
            setLoading(false)
            navigate('/login_for_mingaku_tutor', {
              state: {
                redirectPath: `${path}`,
                email: emailFromTutorAuthData,
                isAlreadyLinked,
                linkageData,
                tutorAuthData,
                tutorDocData,
                schoolIdfromQueryParam,
                muid,
                role: 'tutor'
              }
            })
          }
        } else {
          const userDocRef = doc(db, 'users', user.uid)
          await setDoc(userDocRef, linkageData, { merge: true })
          const schoolDocRef = doc(db, 'externalServices', 'mingaku', 'schools', schoolIdfromQueryParam)
          setDoc(schoolDocRef, { docId: schoolIdfromQueryParam }, { merge: true })
          const tutorDocRef = doc(schoolDocRef, 'tutors', muid)
          await setDoc(tutorDocRef, { ...data.tutorDocData }, { merge: true })
          setLoading(false)
          navigate(`/management?school_id=${schoolIdfromQueryParam}`)
        }
      } else {
        setLoading(false)
        if (!!uid) {
          ;(async () => {
            await signInWithUid(uid)
          })()
        } else {
          if (!emailRegisterd) {
            setLoading(false)
            navigate('/signup_for_mingaku_tutor', {
              state: {
                redirectPath: `${path}`,
                email: emailFromTutorAuthData,
                isAlreadyLinked,
                linkageData,
                tutorAuthData,
                tutorDocData,
                schoolIdfromQueryParam,
                muid,
                role: 'tutor'
              }
            })
          } else {
            setLoading(false)
            navigate('/login_for_mingaku_tutor', {
              state: {
                redirectPath: `${path}`,
                email: emailFromTutorAuthData,
                isAlreadyLinked,
                linkageData,
                tutorAuthData,
                tutorDocData,
                schoolIdfromQueryParam,
                muid,
                role: 'tutor'
              }
            })
          }
        }
      }
    })
  }, [muid, navigate, path, schoolIdfromQueryParam, signInWithUid, uid])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      // Promptsすべて
      let promptDocRef
      if (paramSchoolId) {
        const mingakuDocRef = doc(db, 'externalServices', 'mingaku')
        const schoolsCollectionRef = collection(mingakuDocRef, 'schools')
        promptDocRef = doc(schoolsCollectionRef, paramSchoolId)
      } else {
        promptDocRef = doc(db, 'service_type', serviceType)
      }

      // あとは自分の塾のpromptsを取得
      const propmts_q = query(collection(promptDocRef, 'submissionBoxes'))
      const querySnapshot = await getDocs(propmts_q)
      let prompts = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          return {
            submission_box_title: doc.data().submission_box_title ? doc.data().submission_box_title : '',
            enable: doc.data().enable,
            id: doc.id,
            ref: doc.ref,
            updatedAt: doc.data().updatedAt ? doc.data().updatedAt : '',
            // 編集可能
            editable: true
          }
        })
      )
      // prompts = prompts.concat(additionalPrompts)
      if (prompts.length !== 0) {
        const results = prompts.sort(function (a, b) {
          return a.updatedAt > b.updatedAt ? -1 : 1
        })
        let additionalData = {}
        setSelectedPrompt({
          ...results[0],
          ...additionalData
        })
        setIsEdited(false)
        setSelectedItem(results[0].id)
        setPromptTitle(results[0].submission_box_title)
        setPrompt(results)
      }
      setLoading(false)
    })()
  }, [defaultAutoSending, defaultDefaultMute, paramSchoolId, serviceType])

  const updatePromptTitle = (title) => {
    setSelectedPrompt({
      ...selectedPrompt,
      submission_box_title: title
    })
  }

  const updateActive = async (e, id, item) => {
    e.stopPropagation()

    if (id === selectedPrompt.id) {
      setSelectedPrompt({
        ...selectedPrompt,
        enable: !selectedPrompt.enable
      })
    }

    setPrompt(
      prompt.map((i) => {
        if (i.id === id) {
          return {
            ...i,
            enable: !i.enable
          }
        } else {
          return {
            ...i
          }
        }
      })
    )

    prompt.forEach(async (i) => {
      if (i.id === id) {
        // 管理者編集　selectedSchoolsがundefinedの場合、フィールドを削除または別の値に設定
        const updatedItem = {
          ...i,
          enable: !i.enable,
          updatedAt: new Date().getTime()
          // 管理者編集　selectedSchoolsがundefinedでなければその値を使用し、そうでなければnullまたは空の配列を設定
          // selectedSchools: i.selectedSchools ? [...i.selectedSchools] : null // または []
        }
        const { selectedSchools, viewableSelectedSchools, ...newUpdatedItem } = updatedItem

        await updateDoc(i.ref, newUpdatedItem)
      }
    })
    setIsEdited(false)
  }

  const updateActiveForTeacher = async (e, item) => {
    // 関数内でのイベント伝播を防ぐ
    e.stopPropagation()

    // selectedSchoolsコレクションの参照を取得（適切なFirebaseの参照を設定してください）
    const collectionRef = collection(item.ref, 'selectedSchools')
    const selectedSchoolsRef = doc(collectionRef, paramSchoolId)
    const docSnap = await getDoc(selectedSchoolsRef)
    // selectedSchoolsドキュメントを更新
    if (docSnap.exists()) {
      await updateDoc(selectedSchoolsRef, {
        active: !item.active,
        updatedAt: new Date().getTime()
      })
    } else {
      await setDoc(selectedSchoolsRef, {
        active: !item.active,
        updatedAt: new Date().getTime(),
        id: paramSchoolId
      })
    }

    // ローカルのstateも更新
    setPrompt(
      prompt.map((i) => {
        if (i.id === item.id && item.editable === false) {
          return {
            ...i,
            active: !i.active
          }
        } else {
          return i
        }
      })
    )
  }

  /******* 新規用ハンドラ ********/

  /* モード選択・新規作成時に画面が切り替わるときのハンドラ */
  const switchBody = async (item) => {
    console.log('item', item)
    handleCancelStream()
    setSummaryMessage('')
    if (addBody === true) {
      const currentState = {
        inputPromptTitle
      }
      const differences = Object.keys(initialState).reduce((acc, key) => {
        const initial = initialState[key]
        const current = currentState[key]

        // 配列またはオブジェクトの場合、文字列に変換して比較する
        const isDifferent =
          Array.isArray(initial) || typeof initial === 'object'
            ? JSON.stringify(initial) !== JSON.stringify(current)
            : initial !== current

        if (isDifferent) {
          acc.push({
            key: key,
            initial: initial,
            current: current
          })
        }
        return acc
      }, [])
      // 初期状態と異なるかどうかをチェック
      if (differences.length > 0) {
        if (
          !window.confirm(
            '変更がまだ保存されていないようですが、よろしいですか？\n保存しないと、変更内容は消えてしまいます。'
          )
        ) {
          return
        }
      }
    } else if (
      isEdited === true &&
      !window.confirm(
        '変更がまだ保存されていないようですが、よろしいですか？\n保存しないと、変更内容は消えてしまいます。'
      )
    ) {
      return
    }
    let additionalData = {}
    const selectedSchoolsRef = collection(item.ref, 'selectedSchools')
    const selectedSchoolsSnapshot = await getDocs(selectedSchoolsRef)
    additionalData.selectedSchools = selectedSchoolsSnapshot.docs.map((doc) => doc.id)

    const viewableSelectedSchoolsRef = collection(item.ref, 'viewableSelectedSchools')
    const viewableSelectedSchoolsSnapshot = await getDocs(viewableSelectedSchoolsRef)
    additionalData.viewableSelectedSchools = viewableSelectedSchoolsSnapshot.docs.map((doc) => doc.id)

    // AssisntantAPIの情報の取得に時間がかかるため、AssisntantAPIの情報の取得する前にselectedPromptをsetしておく。これによりモード切り替え時のラグを軽減
    let newItem = {
      ...item,
      ...additionalData
    }
    setSelectedItem(item.id)
    setSelectedPrompt({
      ...item,
      ...additionalData
    })

    // ここでAssistantAPIの情報を取得して、selectedPromptに追加する
    if (item.assistantId && item.assistantId !== '') {
      const myAssistant = await getAssistantApi(item.assistantId) // この関数は定義が必要
      additionalData.assistantApiInstruction = myAssistant && myAssistant.instructions
    }
    newItem = {
      ...newItem,
      ...additionalData
    }
    setIsEdited(false) // 新しいアイテムを選択したので編集状態をリセット
    setSelectedItem(item.id)
    setSelectedPrompt(newItem)
    setPromptTitle(item.submission_box_title)
    setAddBody(false)
    setInputPromptTitle('') // textareaのクリア
    setDataForTagCloud([])
    setFilteredData([])
    setMaxFrequency(9999)
    setMinFrequency(1)
    setIsEdited(false)
  }
  const displayAddBody = () => {
    if (
      isEdited === true &&
      !window.confirm(
        '変更がまだ保存されていないようですが、よろしいですか？\n保存しないと、変更内容は消えてしまいます。'
      )
    ) {
      return
    }
    setAddBody(true)
    setSelectedItem()
    setIsEdited(false)
  }

  /* 保存ボタンのハンドラ */
  const updatePrompt = async (item) => {
    setIsEdited(false) // 保存が完了したので編集状態をリセット

    let alertMessage = ''

    if (item.submission_box_title === '') {
      alertMessage += 'タイトルは必須入力です。\n'
    }

    if (alertMessage !== '') {
      alert(alertMessage)
      return
    }

    setLoading(true)
    const { selectedSchools, viewableSelectedSchools, ...newItem } = item

    // newItemにupdatedAtを追加
    newItem.updatedAt = new Date().getTime()
    // Firestoreドキュメントを更新（selectedSchoolsを除外）
    await updateDoc(item.ref, newItem)

    setLoading(false)
    window.location.reload()
  }

  /* 新規 追加ボタンのハンドラ */
  const addPrompt = async () => {
    let alertMessage = ''

    if (inputPromptTitle === '') {
      alertMessage += 'タイトルは必須入力です。\n'
    }

    if (alertMessage !== '') {
      alert(alertMessage)
      return
    }
    setLoading(true)

    // promptsドキュメントに新規プロンプトを追加
    let promptDocRef
    if (paramSchoolId) {
      // paramSchoolIdがあれば塾用docrefをセット
      const mingakuDocRef = doc(db, 'externalServices', 'mingaku')
      promptDocRef = collection(mingakuDocRef, 'schools', paramSchoolId, 'submissionBoxes')
    } else {
      // paramSchoolIdがなければ通常サービスごとのdocrefをセット
      promptDocRef = collection(db, 'service_type', serviceType, 'submissionBoxes')
    }

    const newDocRef = await addDoc(promptDocRef, {
      submission_box_title: inputPromptTitle,
      enable: true,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime()
    })

    await updateDoc(newDocRef, { ref: newDocRef })

    setInputPromptTitle('') // textareaのクリア

    setLoading(false)
    window.location.reload()
  }

  /* 複製ボタンのハンドラ */
  const copyPrompt = async (e, item) => {
    let alertMessage = ''

    if (item.submission_box_title === '') {
      alertMessage += 'タイトルは必須入力です。\n'
    }

    if (alertMessage !== '') {
      alert(alertMessage)
      return
    }

    // promptsドキュメントに新規プロンプトを追加
    let promptDocRef
    if (paramSchoolId) {
      // paramSchoolIdがあれば塾用docrefをセット
      const mingakuDocRef = doc(db, 'externalServices', 'mingaku')
      promptDocRef = collection(mingakuDocRef, 'schools', paramSchoolId, 'submissionBoxes')
    } else {
      // paramSchoolIdがなければ通常サービスごとのdocrefをセット
      promptDocRef = collection(db, 'service_type', serviceType, 'submissionBoxes')
    }

    const newDocRef = await addDoc(promptDocRef, {
      submission_box_title: item.submission_box_title + 'コピー',
      enable: false,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime()
    })
    await updateDoc(newDocRef, { ref: newDocRef })

    window.location.reload()
    // }
  }

  /* 削除ボタンのハンドラ */
  const deletePrompt = async (id, _ref, imagePath, imagePath2) => {
    try {
      const docSnapshot = await getDoc(_ref)
      const data = docSnapshot.data()
      if (data.enable === true) {
        alert(
          '現在使用されており削除できません。\n削除するには提出ボックスのチェックボックスの✓を外して、アクティブ状態をオフにしてください。'
        )
      } else {
        const confirmed = window.confirm('本当に削除しますか？')
        if (confirmed) {
          const { assistantId } = selectedPrompt
          if (assistantId && assistantId !== '') {
            const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/delete_assistant_and_assistant_files'
            console.log(`${assistantId}を削除中...`)
            await fetch(URL, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ assistant_id: assistantId })
            })
            console.log(`${assistantId}の削除完了`)
          }
          console.log(`モードを削除中...`)
          await deleteDoc(_ref)
          console.log(`モードの削除完了`)
          window.location.reload()
        }
      }
    } catch (error) {
      return
    }
  }

  const fetchConversations = async (submissionBoxId) => {
    try {
      const submittersDocRef = collection(
        db,
        `/externalServices/mingaku/schools/${paramSchoolId}/submissionBoxes/${submissionBoxId}/submitters`
      )
      const submittersQueryForUpdatedAt = query(submittersDocRef, orderBy('submittedAt', 'desc'))
      //  提出者取得
      const namelist = await getSubmitterData(submittersQueryForUpdatedAt)

      //  提出者それぞれの会話を取得
      let conversationslist = []
      for (const submitterData of namelist) {
        const conversationsDocRef = collection(
          db,
          `/externalServices/mingaku/schools/${paramSchoolId}/submissionBoxes/${submissionBoxId}/submitters/${submitterData.userId}/conversations`
        )
        const conversationsQueryForUpdatedAt = query(conversationsDocRef, orderBy('createdAt', 'desc'))
        let localConversations = await getConversationData(conversationsQueryForUpdatedAt)
        const schoolName = await getSchoolName(submitterData.schoolId)
        localConversations = localConversations.map((conversation) => {
          // typeの値に応じて日本語に変換
          let japaneseType = ''
          if (conversation.type === 'message') {
            japaneseType = 'メッセージ'
          } else if (conversation.type === 'conversation') {
            japaneseType = '会話'
          }

          return {
            ...submitterData,
            ...conversation,
            type: japaneseType, // 変換されたtypeを使用
            schoolName: schoolName
          }
        })

        conversationslist.push(...localConversations)
      }
      //  会話それぞれのメッセージを取得
      const messageslist = []

      for (const conversationData of conversationslist) {
        const messagesDocRef = collection(
          db,
          `/externalServices/mingaku/schools/${paramSchoolId}/submissionBoxes/${submissionBoxId}/submitters/${conversationData.userId}/conversations/${conversationData.conversationId}/messages`
        )
        const messagesQueryForUpdatedAt = query(messagesDocRef, orderBy('createdAt', 'desc'))
        let localMessages = await getMessageData(messagesQueryForUpdatedAt)
        console.log('③messageslist', localMessages)

        localMessages = localMessages.map((_message) => {
          // refとupdatedAtはcsvにいらないので除いていおく
          const { promptRef, submitterRef, createdAt, submittedAt, updatedAt, ...restConversationData } =
            conversationData
          const returnData = {
            ...restConversationData,
            ..._message,
            message: Array.isArray(_message.message) ? _message.message[0].text : _message.message,
            createdAt: formatDate(createdAt),
            submittedAt: formatDate(submittedAt)
          }
          return returnData
        })
        messageslist.push(...localMessages)
      }
      return messageslist
    } catch (error) {
      console.error('Failed to fetch conversations:', error)
    } finally {
    }
  }

  const getSubmitterData = async (query) => {
    const querySnapshot = await getDocs(query)
    return querySnapshot.docs.map((doc) => ({
      schoolId: doc.data().schoolId,
      submitterRef: doc.ref,
      // submittedAt: doc.data().submittedAt,
      userId: doc.data().userId,
      userName: doc.data().userName
    }))
  }
  const getConversationData = async (query) => {
    const querySnapshot = await getDocs(query)
    return querySnapshot.docs.map((doc) => {
      const { userId, userName, schoolId, conversationId, ...rest } = doc.data()
      return { ...rest, conversationId: doc.id }
    })
  }
  const getMessageData = async (query) => {
    const querySnapshot = await getDocs(query)
    return querySnapshot.docs.map((doc) => {
      // const { userId, userName, schoolId, ...rest } = doc.data()
      return { ...doc.data() }
    })
  }
  return (
    <div className={ManagementModule.container}>
      {loading ? <LoadingGrid /> : <></>}
      <div className={ManagementModule.sidebar}>
        <div className={ManagementModule['sidebarheader-container']}>
          <div className={ManagementModule.sidebarheader}>
            <button className={ManagementModule['new-button']} onClick={() => displayAddBody()}>
              作成 +
            </button>
            <ModeSearch
              placeholderMessage="提出ボックスを検索..."
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </div>
        </div>

        <div className={ManagementModule['list-container']}>
          <ul className={ManagementModule.list}>
            {filteredModes.map((item, index) => {
              return (
                <div key={index} className={ManagementModule.list}>
                  {item.editable === true ? (
                    <Switch2
                      id="themeDeafault"
                      value={item.id}
                      checked={item.enable}
                      onChange={(e) => updateActive(e, e.target.value, item)}
                    />
                  ) : (
                    // 先生画面　共通プロンプトのactive設定
                    <Switch2
                      id="themeDeafault"
                      value={item.id}
                      checked={item.active}
                      onChange={(e) => updateActiveForTeacher(e, item)}
                    />
                  )}
                  <li
                    onClick={() => switchBody(item)}
                    key={index}
                    className={`${ManagementModule.list} ${
                      selectedItem === item.id ? ManagementModule[selectedItemClassName] : ''
                    }`}
                  >
                    <p className={ManagementModule['list-title']}>{item.submission_box_title}</p>
                  </li>
                  <MenuIcon2
                    item={item}
                    handleIconClick={handleIconClick}
                    handleDownloadSubmittedConversations={handleDownloadSubmittedConversations}
                  />
                </div>
              )
            })}
          </ul>
        </div>
      </div>
      <div className={ManagementModule.body}>
        {addBody === false && prompt.length !== 0 ? (
          <>
            <div className={ManagementModule['prompt-body-header']}>
              <div className={ManagementModule['sub-title-container']}>
                <h3 className={ManagementModule['sub-title']}>{promptTitle}</h3>
                {selectedPrompt.editable === false && (
                  <p className={ManagementModule['sub-title-tip']}>
                    ※{' '}
                    {selectedPrompt.parentSchoolId !== 'gpt-teacher'
                      ? schoolIdName[selectedPrompt.parentSchoolId]
                      : '管理人'}
                    が作成したモードです
                  </p>
                )}
              </div>

              <div className={ManagementModule['button-container']}>
                <CustomWidthTooltip
                  title="提出ボックスをコピーします"
                  arrow
                  className={ManagementModule['tooltip-arrow']}
                  enterTouchDelay={100}
                >
                  <button className={ManagementModule['copy1']} onClick={(e) => copyPrompt(e, selectedPrompt)}>
                    複製
                  </button>
                </CustomWidthTooltip>

                {selectedPrompt.editable === true ? (
                  <button
                    className={ManagementModule['copy']}
                    onClick={() => updatePrompt(selectedPrompt)}
                    type="submit"
                  >
                    保存
                  </button>
                ) : (
                  <button
                    disabled
                    className={ManagementModule['copy-disabled']}
                    onClick={() => updatePrompt(selectedPrompt)}
                    type="submit"
                  >
                    保存
                  </button>
                )}
                {selectedPrompt.editable === true ? (
                  <button
                    className={ManagementModule['copy']}
                    onClick={() =>
                      deletePrompt(
                        selectedPrompt.id,
                        selectedPrompt.ref,
                        selectedPrompt.iconImagePath,
                        selectedPrompt.iconImagePath2
                      )
                    }
                  >
                    削除
                  </button>
                ) : (
                  <button
                    disabled
                    className={ManagementModule['copy-disabled']}
                    onClick={() =>
                      deletePrompt(
                        selectedPrompt.id,
                        selectedPrompt.ref,
                        selectedPrompt.iconImagePath,
                        selectedPrompt.iconImagePath2
                      )
                    }
                  >
                    削除
                  </button>
                )}
              </div>
            </div>
            {selectedPrompt.hidden ? (
              <div className={ManagementModule.main}>
                <p className={ManagementModule['prompt-body']}>モードの詳細は非公開です。</p>
              </div>
            ) : (
              <div className={ManagementModule.main}>
                <div className={ManagementModule['prompt-body']}>
                  <p className={ManagementModule.tips}>
                    <span className={ManagementModule['mandatory']}>＊</span>は必須入力です。
                  </p>
                  {/* タイトル */}

                  <p className={ManagementModule.label}>
                    <span className={ManagementModule['mandatory']}>＊</span>タイトル
                  </p>
                  <textarea
                    className={ManagementModule['prompt-title']}
                    value={selectedPrompt.submission_box_title}
                    onChange={(e) => updatePromptTitle(e.target.value)}
                  />
                  <div className={ManagementModule['word-cloud-container']}>
                    {dataForTagCloud.length === 0 ? (
                      <>
                        <button
                          className={ManagementModule['analyze-button']}
                          onClick={() => runWordCloud(selectedPrompt)}
                        >
                          提出物を解析する
                        </button>
                        <p className={ManagementModule['sub-discription-of-analyze']}>
                          単語出現頻度を解析したり、ワードクラウドを可視化することができます。
                        </p>
                      </>
                    ) : (
                      <>
                        <div>
                          <p className={ManagementModule['title-of-word-cloud']}>単語出現頻度解析</p>
                          <p className={ManagementModule['sub-discription-of-word-cloud']}>
                            文章中に出現する名詞、形容詞の単語の出現頻度のヒストグラムです。(最大で上位
                            {wordCloudMaxDisplayCount}個を表示)
                          </p>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div>
                              <label>
                                最低出現頻度:
                                <input
                                  type="number"
                                  value={minFrequency}
                                  onChange={(e) => setMinFrequency(Number(e.target.value))}
                                />
                              </label>
                              ~
                              <label>
                                最高出現頻度:
                                <input
                                  type="number"
                                  value={maxFrequency}
                                  onChange={(e) => setMaxFrequency(Number(e.target.value))}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className={ManagementModule['word-cloud']}>
                          {filteredData.length === 0 ? (
                            <>条件に一致するデータがありません</>
                          ) : (
                            <>
                              <Bar data={chartData} options={optionsForHistgram} />
                            </>
                          )}
                        </div>
                        <div>
                          <p className={ManagementModule['title-of-word-cloud']}>ワードクラウド</p>
                          <p className={ManagementModule['sub-discription-of-word-cloud']}>
                            出現頻度に応じた大きさで単語を可視化しています。(最大で上位{wordCloudMaxDisplayCount}
                            個を表示)
                            <br />
                            単語の色は品詞によって異なります。青色は名詞を、緑色は形容詞を表します。
                            (現在、英語の単語は全て青色で表示されています)
                          </p>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div>
                              <label>
                                最低出現頻度:
                                <input
                                  type="number"
                                  value={minFrequency}
                                  onChange={(e) => setMinFrequency(Number(e.target.value))}
                                />
                              </label>
                              ~
                              <label>
                                最高出現頻度:
                                <input
                                  type="number"
                                  value={maxFrequency}
                                  onChange={(e) => setMaxFrequency(Number(e.target.value))}
                                />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className={ManagementModule['word-cloud']}>
                          {filteredData.length === 0 ? (
                            <>条件に一致するデータがありません</>
                          ) : (
                            <>
                              <TagCloud
                                minSize={12}
                                maxSize={56}
                                tags={filteredData}
                                colorOptions={options}
                                onClick={(tag) => alert(`選択した単語：  ${tag.value} \n出現頻度：  ${tag.count}`)}
                                randomSeed={50}
                                // renderer={customRenderer}
                              />
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className={ManagementModule['word-cloud-container']}>
                    {summaryMessage === '' ? (
                      <>
                        <button
                          className={ManagementModule['analyze-button']}
                          onClick={() => runSummary(selectedPrompt)}
                        >
                          提出物を要約する
                        </button>

                        <p className={ManagementModule['sub-discription-of-analyze']}>提出された文章を要約します。</p>
                      </>
                    ) : (
                      <>
                        <div>
                          <p className={ManagementModule['title-of-word-cloud']}>提出物の要約</p>
                          <p className={ManagementModule['sub-discription-of-word-cloud']}>
                            要約結果を表示しています。
                          </p>
                          <div style={{ display: 'flex' }}>
                            {/* <div>{summaryMessage}</div> */}
                            <ChatBubble
                              index={1}
                              lastIndex={1}
                              chatLength={2}
                              key={1}
                              id={`chat-bubble-1`}
                              playingIndex={1}
                              role={'assistant'}
                              messageText={summaryMessage}
                              avator_images={avatorImages}
                              isLargeIcon={false}
                              isGenerating={isGenerating}
                              displayMenuButton={false}
                              // onButtonClick={openModalForSubmission}
                            />
                          </div>
                        </div>
                        {/* <div className={ManagementModule['word-cloud']}>
                          {filteredData.length === 0 ? (
                            <>条件に一致するデータがありません</>
                          ) : (
                            <>
                              <Bar data={chartData} options={optionsForHistgram} />
                            </>
                          )}
                        </div> */}
                        {/* <div>
                          <p className={ManagementModule['title-of-word-cloud']}>ワードクラウド</p>
                          <p className={ManagementModule['sub-discription-of-word-cloud']}>
                            出現頻度に応じた大きさで単語を可視化しています。(最大で上位{wordCloudMaxDisplayCount}
                            個を表示)
                            <br />
                            単語の色は品詞によって異なります。青色は名詞を、緑色は形容詞を表します。
                            (現在、英語の単語は全て青色で表示されています)
                          </p>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div>
                              <label>
                                最低出現頻度:
                                <input
                                  type="number"
                                  value={minFrequency}
                                  onChange={(e) => setMinFrequency(Number(e.target.value))}
                                />
                              </label>
                              ~
                              <label>
                                最高出現頻度:
                                <input
                                  type="number"
                                  value={maxFrequency}
                                  onChange={(e) => setMaxFrequency(Number(e.target.value))}
                                />
                              </label>
                            </div>
                          </div>
                        </div> */}

                        {/* <div className={ManagementModule['word-cloud']}>
                          {filteredData.length === 0 ? (
                            <>条件に一致するデータがありません</>
                          ) : (
                            <>
                              <TagCloud
                                minSize={12}
                                maxSize={56}
                                tags={filteredData}
                                colorOptions={options}
                                onClick={(tag) => alert(`選択した単語：  ${tag.value} \n出現頻度：  ${tag.count}`)}
                                randomSeed={50}
                                // renderer={customRenderer}
                              />
                            </>
                          )}
                        </div> */}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className={ManagementModule['prompt-body-header']}>
              <h3 className={ManagementModule['sub-title']}>提出ボックスを作成しましょう</h3>
              <div className={ManagementModule['button-container']}>
                <button className={ManagementModule['copy']} onClick={(e) => addPrompt(e.target.value)}>
                  保存
                </button>
              </div>
            </div>
            <div className={ManagementModule.main}>
              <div className={ManagementModule['prompt-body']}>
                <p className={ManagementModule.tips}>
                  <span className={ManagementModule['mandatory']}>＊</span>は必須入力です。
                </p>
                {/* 新規　タイトル */}
                <p className={ManagementModule.label}>
                  <span className={ManagementModule['mandatory']}>＊</span>タイトル
                </p>
                <textarea
                  className={ManagementModule['prompt-title']}
                  value={inputPromptTitle}
                  onChange={(e) => setInputPromptTitle(e.target.value)}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
export default ManagementScreen
