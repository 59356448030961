import React, { useMemo, useState, useEffect } from 'react'
import ChatDataModule from './ChatDataScreen.module.scss'
import { _gpt_input_cost, _table_filter_periods } from '../../localConstant'

const FilterContainer = ({
  filterStartDate,
  setFilterStartDate,
  filterEndDate,
  setFilterEndDate,
  schoolIdFilter,
  setSchoolIdFilter,
  handleExecute,
  handleDownload,
  school_id,
  userNameFilter, // 追加
  setUserNameFilter, // 追加
  messageFilter, // 追加
  setMessageFilter,
  modeNameFilter,
  setModeNameFilter
}) => {
  return (
    <div className={ChatDataModule['filter-container']}>
      <input
        className={ChatDataModule['filter-input']}
        type="datetime-local"
        value={filterStartDate}
        onChange={(e) => setFilterStartDate(e.target.value)}
        placeholder="Start DateTime"
      />
      〜
      <input
        className={ChatDataModule['filter-input']}
        type="datetime-local"
        value={filterEndDate}
        onChange={(e) => setFilterEndDate(e.target.value)}
        placeholder="End DateTime"
      />
      {/* {!school_id && ( */}
      <input
        className={ChatDataModule['filter-input']}
        type="text"
        value={schoolIdFilter}
        onChange={(e) => setSchoolIdFilter(e.target.value)}
        placeholder="スクール名"
      />
      <input
        className={ChatDataModule['filter-input']}
        type="text"
        value={userNameFilter}
        onChange={(e) => setUserNameFilter(e.target.value)}
        placeholder="ユーザー名"
      />
      <input
        className={ChatDataModule['filter-input']}
        type="text"
        value={messageFilter}
        onChange={(e) => setMessageFilter(e.target.value)}
        placeholder="メッセージ"
      />
      <input
        className={ChatDataModule['filter-input']}
        type="text"
        value={modeNameFilter}
        onChange={(e) => setModeNameFilter(e.target.value)}
        placeholder="モード名"
      />
      {/* )} */}
      <button className={ChatDataModule['search-button']} onClick={handleExecute}>
        検索
      </button>
      <button className={ChatDataModule['search-button']} onClick={handleDownload}>
        CSV出力
      </button>
    </div>
  )
}

export default FilterContainer
