import testModule from './test.module.scss'
import Footer from '../../components/Common/Footer'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import Header from '../../components/Header/Header'
import { httpsCallable } from 'firebase/functions'
import { collection, getDocs, getDoc, doc, query, orderBy, where, updateDoc, collectionGroup } from 'firebase/firestore'

import { db, functions } from '../../firebase'
import { useState, useEffect } from 'react'
import { InlineMath, BlockMath } from 'react-katex'
import 'katex/dist/katex.min.css' // KaTeXのスタイルをインポート

function MyPage() {
  const [selectedFile, setSelectedFile] = useState(null)
  const [uploadedFile, setUploadedFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [uploadedFileList, setUploadedFileList] = useState([])
  const [assistantId, setAssistantId] = useState('')
  const [threadId, setThreadId] = useState('')
  const [runId, setRunId] = useState('')
  const [messageList, setMessageList] = useState([])
  const [runStatus, setRunStatus] = useState('')
  const [assistatFileList, setAssistantFileList] = useState([])
  const [messageInput, setMessageInput] = useState('')

  const [name, setName] = useState('研究者')
  // const [description, setDescription] = useState('あなたは研究者です。')
  const [instructions, setInstructions] = useState(
    'ファイルからデータを取得することができます。なるべくファイルのデータを使った回答してください。日本語で回答してください。'
  )
  const [initialPrompt, setInitialPrompt] = useState('私は中学3年生です。研究内容をわかりやすく伝えてください。')

  const getFileInfo = async (fileId) => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/get_file_info'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ file_id: fileId })
    })
    const data = await res.json()
    console.log(data)
    return data.file
  }

  const createThread = async () => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_create_thread'
    const res = await fetch(URL, {
      method: 'POST'
    })
    const { emptyThread } = await res.json()
    console.log('emptyThread', emptyThread)
    setThreadId(emptyThread.id)
  }

  const createThreadAndRun = async () => {
    setMessageList([])
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_create_thread_and_run'
    const messages = [
      // これがsystem promp相当のデータ
      {
        role: 'user', // system promprだが、roleはuserにする
        content: initialPrompt
      }
    ]
    setMessageList([{ value: messages[0].content, created_at: new Date() }])

    // setMessageList([messages[0]])

    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ assistant_id: assistantId, messages })
    })
    // 実行した結果 run
    const { run } = await res.json()
    console.log('run', run)
    const runId = run.id
    const thread_id = run.thread_id
    setThreadId(thread_id)
    setRunId(runId)
    const runStatus = await getRunStatus(runId, thread_id)
    console.log('getRunStatus完了')
    console.log('runStatus', runStatus)
    if (runStatus === 'completed') {
      await _getMessages(thread_id)
    } else {
    }
  }

  const createMessage = async (messageInput) => {
    setMessageInput('')
    console.log('threadId', threadId)
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_create_message'
    // const thread_id = 'thread_WgH2mVZYVBp2LtZXmrBBt1Os'
    const message = {
      role: 'user',
      content: messageInput
    }
    setMessageList([...messageList, { value: messageInput, created_at: new Date() }])
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ thread_id: threadId, message })
    })
    const data = await res.json()
    console.log(data)
    await runThread()
  }

  const runThread = async () => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_run_thread'
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        thread_id: threadId,
        assistant_id: assistantId
      })
    })
    const { run } = await res.json()
    console.log('run', run)
    const runId = run.id
    setRunId(runId)
    const runStatus = await getRunStatus(runId, threadId)
    console.log('getRunStatus完了')
    console.log('runStatus', runStatus)
    if (runStatus === 'completed') {
      await _getMessages(threadId)
    } else {
    }
  }

  const _getMessages = async (threadId) => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_get_messages'
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        thread_id: threadId
      })
    })
    const data = await res.json()
    console.log(data)
    const messageList = data.threadMessagesData
      .map((d) => {
        const content = d.content
        const { type } = content[0]
        if (type === 'text') {
          console.log(content[0].text.value)
          return { value: content[0].text.value, created_at: d.created_at }
        } else {
          console.log(content[0])
          return null
        }
      })
      .filter((message) => message !== null)
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
    setMessageList(messageList)
  }

  const getRuns = async () => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_get_runs'
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        thread_id: 'thread_WgH2mVZYVBp2LtZXmrBBt1Os'
      })
    })
    const data = await res.json()
    console.log(data)
  }

  const getRunStatus = async (runId, threadId) => {
    let count = 0
    let status = ''
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_get_run_status'
    while (count < 30 && status !== 'completed') {
      const res = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          thread_id: threadId,
          run_id: runId
        })
      })
      const { run } = await res.json()
      console.log(run)
      status = run.status
      console.log('status', status)
      setRunStatus(status)
      await sleep(3000)
      count++
    }
    return status
  }

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      setSelectedFile(file)
      setFileName(file.name) // ファイル名を設定
    }
  }

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('ファイルが選択されていません。')
      return
    }
    console.log('ログselectedFile', selectedFile)

    const formData = new FormData()
    formData.append('file', selectedFile, encodeURIComponent(selectedFile.name))
    try {
      const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/upload_file_to_openai'
      const res = await fetch(URL, {
        method: 'POST',
        body: formData
      })
      const { response } = await res.json() // 追加: レスポンスのJSONを解析
      console.log(response) // 変更: 解析したデータを出力
      const uploadedFileId = response.id
      createAssistantAPIwithFileId(uploadedFileId)
      setUploadedFile({ ...response })
      alert('ファイルがアップロードとAssistant APIの作成に成功しました。')
      setSelectedFile(null) // ファイル選択状態のリセット
      setFileName('') // ファイル名表示のリセット
    } catch (error) {
      console.error('Error uploading file:', error)
      alert('ファイルのアップロード中にエラーが発生しました。')
    }
  }

  const createAssistantAPIwithFileId = async (fileId) => {
    try {
      console.log('createAssistantAPI実行')
      console.log('fileId', fileId)
      const requestBody = {
        name: '研究者',
        description: '',
        instructions:
          'あなたは研究者です。ファイルからデータを取得することができます。なるべくファイルのデータを使った回答してください。日本語で回答してください。',
        tools: [{ type: 'retrieval' }],
        model: 'gpt-4-turbo-preview',
        file_ids: [fileId] // csvファイルは失敗するっぽい
      }
      const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_create_assistant_api'
      const res = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...requestBody })
      })
      const { myAssistant } = await res.json() // 追加: レスポンスのJSONを解析
      console.log(myAssistant) // 変更: 解析したデータを出力
      setAssistantId(myAssistant.id)

      // const res = await createAssistantAPIOfOpenai(requestBody) // schoolAIのschook情報を全件取得用
      // console.log(res)
    } catch (error) {
      console.error('createAssistantAPI failed:', error)
    }
    console.log('createAssistantAPI終了')
  }

  const [myAssistant, setMyAssistant] = useState(null)
  const getAssistantApi = async () => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_get_assistant_api'
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        assistant_id: 'asst_xp8GjOUSkKbFxsMqX8m8Hj6n'
      })
    })
    const data = await res.json()
    console.log(data.myAssistant)
    setMyAssistant(data.myAssistant)
  }

  const getAssistantApiFiles = async () => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_get_assistant_files'
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        assistant_id: 'asst_yMngbj6nBJxAMiycI3dww3Au'
      })
    })
    const data = await res.json()
    const assistantFiles = data.assistantFiles.data
    setAssistantFileList(
      await Promise.all(
        assistantFiles.map(async (file) => ({ id: file.id, name: (await getFileInfo(file.id)).filename }))
      )
    )
  }

  const deleteFile = async (fileId) => {
    const URL1 = 'https://school-ai-r3y4on4j2a-an.a.run.app/delete_assistant_file' // AssisntantAPIからファイルを削除するAPI (物理削除ではない)
    await fetch(URL1, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ file_id: fileId, assistant_id: 'asst_yMngbj6nBJxAMiycI3dww3Au' })
    })
    const URL2 = 'https://school-ai-r3y4on4j2a-an.a.run.app/delete_file' // ファイル自体を物理削除
    await fetch(URL2, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ file_id: fileId })
    })
    getAssistantApiFiles() // ファイル一覧を再取得
  }

  const chageGpt2AzureForAdmin = async () => {
    console.log('fetchGptTeacherPrompts実行')
    const promptsRef = collection(db, '/service_type/gpt-teacher/prompts')
    const querySnapshot = await getDocs(promptsRef)
    if (querySnapshot.empty) {
      console.log('No matching documents.')
      return
    }
    for (const doc of querySnapshot.docs) {
      const { model, prompt_title } = doc.data()
      console.log('-------model-------', model)
      if (model === 'gpt-4-1106-preview') {
        console.log('azure-gpt-4-turboへの変更対象: ', doc.id, prompt_title, '=>', model)
        await updateDoc(doc.ref, {
          model: 'azure-gpt-4-turbo'
        })
        console.log('done!')
      } else if (model === 'gpt-3.5-turbo') {
        console.log('azure-gpt-3.5-turboへの変更対象: ', doc.id, prompt_title, '=>', model)
        await updateDoc(doc.ref, {
          model: 'azure-gpt-3.5-turbo'
        })
        console.log('done!')
      } else if (model === 'gpt-4') {
        console.log('azure-gpt-4-turboへの変更対象: ', doc.id, prompt_title, '=>', model)
        await updateDoc(doc.ref, {
          model: 'azure-gpt-4-turbo'
        })
        console.log('done!')
      } else if (typeof model === 'undefined') {
        console.log('undefined!!!')
        await updateDoc(doc.ref, {
          model: 'azure-gpt-4-turbo'
        })
      } else {
        console.log(doc.id, prompt_title, '=>', model)
      }
    }
    console.log('fetchGptTeacherPrompts終了')
  }

  const chageGpt2AzureForSchool = async () => {
    console.log('fetchGptTeacherPrompts実行')
    const promptsRef = collectionGroup(db, 'prompts')
    const querySnapshot = await getDocs(promptsRef)
    if (querySnapshot.empty) {
      console.log('No matching documents.')
      return
    }
    for (const doc of querySnapshot.docs) {
      const { model, prompt_title, useAssistantApi } = doc.data()
      if (doc.ref.parent.parent.id === 'gpt-teacher' || !!useAssistantApi) {
        continue
      }
      console.log('schoolId: ', doc.ref.parent.parent.id)

      if (model === 'gpt-4-1106-preview') {
        console.log('azure-gpt-4-turboへの変更対象: ', doc.id, prompt_title, '=>', model)
        await updateDoc(doc.ref, {
          model: 'azure-gpt-4-turbo'
        })
        console.log('done!')
      } else if (model === 'gpt-3.5-turbo') {
        console.log('azure-gpt-3.5-turboへの変更対象: ', doc.id, prompt_title, '=>', model)
        await updateDoc(doc.ref, {
          model: 'azure-gpt-3.5-turbo'
        })
        console.log('done!')
      } else if (model === 'gpt-4') {
        console.log('azure-gpt-4-turboへの変更対象: ', doc.id, prompt_title, '=>', model)
        await updateDoc(doc.ref, {
          model: 'azure-gpt-4-turbo'
        })
        console.log('done!')
      } else if (typeof model === 'undefined') {
        console.log('undefined!!!')
        await updateDoc(doc.ref, {
          model: 'azure-gpt-4-turbo'
        })
      } else {
        console.log('~~~変更対象外~~~', doc.id, prompt_title, '=>', model)
      }
    }
    console.log('fetchGptTeacherPrompts終了')
  }

  return (
    <div className={testModule['main-container']}>
      <Header title="マイページ" />
      <div className={testModule['title-container']}>
        <h1 className={testModule['main-title']}>アシスタントAPIのデモ画面</h1>※
        リロードするとデータがリセットされます。
      </div>

      <section className={testModule['upload-section']}>
        <div className={testModule['upload-container']}>
          <label htmlFor="file-upload" className={testModule['upload-label']}>
            ① クリックしてAIに学習させたいファイルを選択してください
          </label>
          <input id="file-upload" type="file" className={testModule['upload-input']} onChange={handleFileChange} />
          {selectedFile && <div className={testModule['file-name']}>選択されたファイル名: {selectedFile.name}</div>}
        </div>
      </section>
      <section className={testModule['form-section']}>
        <div className={testModule['input-group']}>
          <label htmlFor="name-input" className={testModule['input-label']}>
            名前:
          </label>
          <input
            id="name-input"
            type="text"
            className={testModule['text-input']}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className={testModule['input-group']}>
          <label htmlFor="instructions-textarea" className={testModule['input-label']}>
            役割:
          </label>
          <textarea
            id="instructions-textarea"
            className={testModule['textarea-input']}
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
          />
        </div>
      </section>
      <div className={testModule['action-section']}>
        <button className={testModule['submit-button']} onClick={handleUpload}>
          ② 上記の内容でAssistantAPIを作成する
        </button>
      </div>
      {assistantId && (
        <section className={testModule['assistant-info-section']}>
          <div className={testModule['run-info']} style={{ textAlign: 'left' }}>
            <p>assistantId: {assistantId}</p>
            <p>fileId: {uploadedFile && uploadedFile.id}</p>
          </div>
        </section>
      )}
      <hr className={testModule['section-divider']} />
      <div className={testModule['input-group']}>
        <label htmlFor="initial-prompt-textarea" className={testModule['input-label']}>
          最初に送信されるプロンプト:
        </label>
        <textarea
          id="initial-prompt-textarea"
          className={testModule['textarea-input']}
          value={initialPrompt}
          onChange={(e) => setInitialPrompt(e.target.value)}
        />
      </div>
      <section className={testModule['conversation-section']}>
        <button
          className={!assistantId ? `${testModule['disabled']}` : testModule['conversation-button']}
          onClick={createThreadAndRun}
          disabled={!assistantId}
        >
          ③ 会話を開始する
        </button>
      </section>
      <section className={testModule['message-list-section']}>
        {messageList.map((message, index) => (
          <div key={index} className={testModule['message-item']}>
            <p>{message.value}</p>
          </div>
        ))}
      </section>
      <section className={testModule['message-input-section']}>
        <textarea
          className={testModule['message-textarea']}
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
          placeholder="メッセージを入力"
        />
        <button
          className={!assistantId ? `${testModule['disabled']}` : testModule['conversation-button']}
          onClick={() => createMessage(messageInput)}
          disabled={!assistantId}
        >
          ④ メッセージを送信
        </button>
        <div className={testModule['run-info']} style={{ textAlign: 'left' }}>
          <p className={runStatus === 'in_progress' ? testModule['floating'] : ''}>run status: {runStatus}</p>
          <p>thread id: {threadId} </p>
          <p>run id: {runId}</p>
        </div>
      </section>
      <hr className={testModule['section-divider']} />
      <section className={testModule['assistant-files-section']}>
        <button className={testModule['conversation-button']} onClick={getAssistantApiFiles}>
          AssistantAPIのファイル一覧を取得
        </button>
        <div className={testModule['assistant-files']}>
          {assistatFileList.map((file, index) => (
            <div key={index} className={testModule['file-item']}>
              <p>fileId: {file.id}</p>
              <p>fileName: {file.name}</p>
              <button onClick={() => deleteFile(file.id)} className={testModule['delete-button']}>
                ファイル削除
              </button>
            </div>
          ))}
          <button onClick={() => chageGpt2AzureForAdmin()}>Adminのgptをazureにする</button>
          <br />
          <button onClick={() => chageGpt2AzureForSchool()}>全スクールのgptをazureにする</button>
        </div>
      </section>
      <Footer homePath="/" addChatPath="/mode" userPath="/mypage" />
    </div>
  )
}
export default MyPage
