import SignOutButton from '../../components/Button/SignOutButton'
import FaceIcon from '@mui/icons-material/Face'
import { useState, useEffect } from 'react'
import { doc, updateDoc, getDoc } from 'firebase/firestore'
import UserInfoModule from './UserInfo.module.scss'
import * as React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import { useNavigate } from 'react-router-dom'
import { _grade, _fields_of_user_data_in_mypage } from '../../localConstant'
import { auth, db, functions } from '../../firebase'
import { httpsCallable } from 'firebase/functions'
import { getUserDataFromMingaku, updateUserData } from '../../utils/getUserDataFromMingaku'
import Textarea from '@mui/joy/Textarea'
function UserInfo() {
  const buttonClassName = window.location.pathname === '/mypage' ? 'button' : 'button-interview'
  const [role, setRole] = useState('')
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [input, setInput] = useState(false)
  const handleUserDataChange = (updates) => {
    setUserData((prevData) => ({
      ...prevData,
      ...updates
    }))
  }
  const initialUserData = _fields_of_user_data_in_mypage.reduce((acc, field) => {
    acc[field.value] = ''
    return acc
  }, {})
  const [userData, setUserData] = useState(initialUserData)
  console.log(initialUserData)
  useEffect(() => {
    const getUser = async () => {
      const userDocRef = doc(db, 'users', auth.currentUser.uid)
      const userinfo = await getDoc(userDocRef)
      let role = ''
      if (!!userinfo.data().studentDocId) {
        role = 'student'
      } else if (userinfo.data().role === 'tutor') {
        role = 'tutor'
      } else {
        role = 'admin'
      }
      setRole(role)
      if (role === 'student' || role === 'tutor') {
        const retrievedData = await updateUserData(userinfo)
        handleUserDataChange(retrievedData)
      } else {
        const { mingakuUserId, email } = userinfo.data()
        if (mingakuUserId === undefined) {
          try {
            const getMingakuUserInfo = httpsCallable(functions, 'get_mingaku_user_info_by_email')
            const { data } = await getMingakuUserInfo({ email })
            try {
              navigate(`/management?role=admin&muid=${data.userAuthData.uid}`)
            } catch (e) {
              alert(
                'このアカウントはみんがくアカウントと連携されてません。連携を完了させるために、みんがく管理画面のスクールAI管理からスクールAIにアクセスしてください。(全ての機能を利用するためには、みんがくアカウントとの連携が必要です。)'
              )
            }
          } catch (e) {
            alert(
              'このアカウントはみんがくアカウントと連携されてません。連携を完了させるために、みんがく管理画面のスクールAI管理からスクールAIにアクセスしてください。(全ての機能を利用するためには、みんがくアカウントとの連携が必要です。)'
            )
          }
        } else {
          const mingakuUserData = await getUserDataFromMingaku(userinfo)
          handleUserDataChange(mingakuUserData)
        }
      }
      setLoading(false)
    }
    getUser()
    // eslint-disable-next-line
  }, [])

  const onSave = async (userId) => {
    const userDocRef = doc(db, 'users', userId)
    // 編集可能なフィールドのみを抽出
    const updates = _fields_of_user_data_in_mypage
      .filter((field) => field.editingPermission)
      .reduce((acc, field) => {
        acc[field.value] = userData[field.value] || ''
        return acc
      }, {})
    await updateDoc(userDocRef, updates)
    setInput(false)
    alert('変更を保存しました。')
  }
  const [count, setCount] = useState(0)
  const increment = () => {
    setCount(count + 1)
    if (count >= 5) {
      // navigate('/management') 顔5回クリックで管理画面に遷移
    }
  }
  const tutorFilteredFields = _fields_of_user_data_in_mypage.filter(
    (field) => field.type === 'all' || field.type === 'tutor'
  )
  const studentFilteredFields = _fields_of_user_data_in_mypage.filter(
    (field) => field.type === 'all' || field.type === 'student'
  )
  return (
    !loading && (
      <div className={UserInfoModule.container}>
        {input ? (
          <div>
            <div className={UserInfoModule['user-info']}>
              <FaceIcon className={UserInfoModule.icon} />
              <p className={UserInfoModule.p}>{auth.currentUser.displayName}</p>
              {studentFilteredFields.map((field) => (
                <div key={field.value} className={UserInfoModule.input}>
                  <p className={UserInfoModule.input}>{field.displayName}：</p>
                  <Textarea
                    minRows={field.textareaRows}
                    className={
                      field.editingPermission ? UserInfoModule['inputbox-enabled'] : UserInfoModule['inputbox-disabled']
                    }
                    sx={{ width: '80%' }}
                    value={userData[field.value]}
                    disabled={!field.editingPermission}
                    onChange={(e) => handleUserDataChange({ [field.value]: e.target.value })}
                  />
                </div>
              ))}
            </div>
            <button className={UserInfoModule[buttonClassName]} onClick={() => onSave(auth.currentUser.uid)}>
              保存
            </button>
            <button className={UserInfoModule[buttonClassName]} onClick={() => setInput(false)}>
              戻る
            </button>
          </div>
        ) : role === 'student' ? (
          <div>
            <div className={UserInfoModule['user-info']}>
              <FaceIcon onClick={increment} className={UserInfoModule.icon} />
              <p>{auth.currentUser.displayName}</p>
              {studentFilteredFields.map((field) => (
                <div key={field.value} className={UserInfoModule.input}>
                  <p className={UserInfoModule.input}>{field.displayName}：</p>
                  <div className={UserInfoModule.name}>{userData[field.value]}</div>
                </div>
              ))}
            </div>
            <button className={UserInfoModule[buttonClassName]} onClick={() => setInput(true)}>
              基本情報を変更する
            </button>
            <SignOutButton loginPath={`${role === 'tutor' ? '/login' : '/login_for_student'}`} />
          </div>
        ) : (
          <div>
            <div className={UserInfoModule['user-info']}>
              <FaceIcon onClick={increment} className={UserInfoModule.icon} />
              <p>{auth.currentUser.displayName}</p>
              {tutorFilteredFields.map((field) => (
                <div key={field.value} className={UserInfoModule.input}>
                  <p className={UserInfoModule.input}>{field.displayName}：</p>
                  <div className={UserInfoModule.name}>{userData[field.value]}</div>
                </div>
              ))}
            </div>
            {/* <button className={UserInfoModule[buttonClassName]} onClick={() => setInput(true)}>
              基本情報を変更する
            </button> */}
            <SignOutButton loginPath={`${role === 'tutor' ? '/login' : '/login_for_student'}`} />
          </div>
        )}
        {/* <p>メールアドレス認証：{auth.currentUser.emailVerified ? '認証済み✅' : '未認証❌'}</p> */}
      </div>
    )
  )
}

export default UserInfo
