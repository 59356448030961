import { httpsCallable } from 'firebase/functions'
import { auth, functions } from '../firebase'
import { _fields_of_user_data_in_mypage } from '../localConstant'
export const getUserDataFromMingaku = async (userinfo) => {
  try {
    if (!!userinfo.data().studentDocId) {
      // 生徒
      const getStudentInfo = httpsCallable(functions, 'get_student_info_of_mingaku')
      const getSchoolsInfo = httpsCallable(functions, 'get_schools_info')
      const [studentInfo, schoolInfo] = await Promise.all([
        // getStudentInfo({ nandeUserId: auth.currentUser.uid }),
        getStudentInfo({ nandeUserId: userinfo.data().userId }),
        getSchoolsInfo({ schoolIds: [userinfo.data().schoolDocId] })
      ])
      const schoolId = schoolInfo.data.schoolInfo[0].data.id
      const studentId = studentInfo.data.data.id
      return {
        name: studentInfo.data.data.name,
        grade: studentInfo.data.data.grade,
        studentId: `${schoolId}-${studentId}`,
        characteristic: studentInfo.data.data.characteristic
      }
    } else if (userinfo.data().role === 'tutor') {
      // 先生
      const getTutorInfo = httpsCallable(functions, 'get_tutor_info_of_mingaku')
      const getSchoolsInfo = httpsCallable(functions, 'get_schools_info')
      const [tutorIndo, schoolInfo] = await Promise.all([
        getTutorInfo({ userId: userinfo.data().tutorDocId, schoolId: userinfo.data().schoolDocId }),
        getSchoolsInfo({ schoolIds: [userinfo.data().schoolDocId] })
      ])
      return {
        name: tutorIndo.data.tutorDocData.name ? tutorIndo.data.tutorDocData.name : '',
        schoolName: schoolInfo.data.schoolInfo[0].data.name,
        // email: auth.currentUser.email
        email: userinfo.data().email
      }
    } else {
      // admin
      const { mingakuUserId, email } = userinfo.data()
      const getAuth = httpsCallable(functions, 'get_admin_info_of_mingaku')
      const { data } = await getAuth({ userId: mingakuUserId })
      const { adminAuthData, adminDocData } = data
      return {
        name: adminDocData.name ? adminDocData.name : '',
        // email: auth.currentUser.email
        email: userinfo.data().email
      }
    }
  } catch (error) {
    console.error('Error fetching user data from Mingaku:', error)
    // 必要に応じて、デフォルトのデータやエラーメッセージを返す
    return {
      name: '-', // デフォルトの名前
      grade: '', // デフォルトのグレード
      studentId: '', // デフォルトの生徒ID
      schoolName: '', // デフォルトの学校名,
      characteristic: '',
      email: userinfo.data().email // 既存のemailをそのまま返す
    }
  }
}

export const updateUserData = async (userinfo) => {
  const mingakuUserData = await getUserDataFromMingaku(userinfo)
  const mingakuUpdates = _fields_of_user_data_in_mypage
    .filter((field) => field.source === 'mingaku')
    .reduce((acc, field) => {
      acc[field.value] = mingakuUserData[field.value] || ''
      return acc
    }, {})
  const schoolAiUpdates = _fields_of_user_data_in_mypage
    .filter((field) => field.source === 'schoolAi')
    .reduce((acc, field) => {
      acc[field.value] = userinfo.data()[field.value] || ''
      return acc
    }, {})
  return { ...mingakuUpdates, ...schoolAiUpdates }
}
