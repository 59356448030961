import React, { useMemo, useState, useEffect } from 'react'
import { useTable } from 'react-table'
import moment from 'moment'
import ChatDataModule from './ChatDataScreen.module.scss'
import { db, functions } from '../../firebase'
import { updateDoc, collection, getDocs, getDoc, doc, query, orderBy, where } from 'firebase/firestore'
import { useSearchParams } from 'react-router-dom'
import { Grid } from 'react-loader-spinner'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
import { _gpt_input_cost, _abolished_gpt_input_cost, _table_filter_periods } from '../../localConstant'
import { httpsCallable } from 'firebase/functions'
import FilterContainer from './FilterContainer'
import Switch from '@mui/material/Switch'
import LoadingGrid from '../LoadingGrid/LoadingGrid'
import { getUserDataFromMingaku } from '../../utils/getUserDataFromMingaku'

const ChatDataTableScreen = () => {
  const [visibleDataForChildSchools, setVisibleDataForChildSchools] = useState(true)
  const [searchParams] = useSearchParams()
  const school_id = searchParams.get('school_id')
  const filter_id = searchParams.get('filter')
  const [filterId, setFilterId] = useState(filter_id)
  const [schoolIdFilter, setSchoolIdFilter] = useState('')
  const [userNameFilter, setUserNameFilter] = useState('')
  const [messageFilter, setMessageFilter] = useState('')
  const [modeNameFilter, setModeNameFilter] = useState('')
  const [schoolIdName, setSchoolIdName] = useState({}) // schoolIdとschoolNameの対応表
  const [schoolTotalCostsTableData, setSchoolTotalCostsTableData] = useState([])
  const getSchoolsInfo = async () => {
    const getSchools = httpsCallable(functions, 'get_schools_info')
    const res = await getSchools() // schoolAIのschook情報を全件取得用
    const { data } = res
    return data
  }
  const [childrenData, setChildrenData] = useState([])
  const handleVisibleDataForChildSchoolsChange = () => {
    setVisibleDataForChildSchools(!visibleDataForChildSchools)

    const mingakuSchoolsDocRef = doc(db, 'externalServices', 'mingaku', 'schools', school_id)
    updateDoc(mingakuSchoolsDocRef, {
      makeViewableDataForChildren: !visibleDataForChildSchools
    })
      .then(() => {
        console.log(`Document ${school_id} successfully updated!`)
      })
      .catch((error) => {
        console.error(`Error updating document ${school_id}: `, error)
      })

    childrenData.forEach((childData) => {
      const mingakuSchoolsDocRef = doc(db, 'externalServices', 'mingaku', childData.childSchoolDocRef)
      // Update the document using updateDoc function
      updateDoc(mingakuSchoolsDocRef, {
        dataVisible: !visibleDataForChildSchools
      })
        .then(() => {
          console.log(`Document ${childData.childSchoolDocId} successfully updated!`)
        })
        .catch((error) => {
          console.error(`Error updating document ${childData.childSchoolDocId}: `, error)
        })
    })
  }
  const fetchDocumentIds = async (paramSchoolId) => {
    try {
      const querySnapshot = await getDocs(collection(db, '/externalServices/mingaku/schools'))
      // const ids = querySnapshot.docs.map((doc) => doc.id)
      if (!!paramSchoolId) {
        const getSchoolInfoOfMingaku = httpsCallable(functions, 'get_school_info_of_mingaku')
        const schoolDataOfMingaku = await getSchoolInfoOfMingaku({ schoolId: paramSchoolId }) // schoolAIのschool情報を全件取得用
        const { data, configData } = schoolDataOfMingaku.data
        // const { role, parentSchools, childrenSchools } = configData
        const { role = [], parentSchools = [], childrenSchools = [] } = configData
        return { role: role ? role[0] : '', parentSchools, childrenSchools }
      }
    } catch (error) {
      console.error('Error fetching document IDs:', error)
    }
  }
  useEffect(() => {
    ;(async () => {
      const { schoolInfo } = await getSchoolsInfo() // schoolAIのschool情報を全件取得用
      const schoolsOfSchoolAI = schoolInfo.filter((school) => !!school.data) // dataがないschoolは除外
      let schoolIdName = {} // schoolIdとschoolNameの対応表
      schoolsOfSchoolAI.map((school) => {
        return (schoolIdName[school.schoolId] = school.data.name) //
      })
      setSchoolIdName(schoolIdName)
    })()
  }, [])

  useEffect(() => {
    const newFilterId = searchParams.get('filter')
    if (newFilterId !== filterId) {
      setFilterId(newFilterId)
      setInitialLoad(true)
    }
  }, [searchParams, filterId]) // searchParamsが変更されたときに実行

  useEffect(() => {
    setFilterStartDate(
      clearedFilter()
        ? ''
        : toLocalISOStringUnixtime(getUnixTimePeriod(_table_filter_periods[Number(filterId)]).startUnixtime)
    )
    setFilterEndDate(
      clearedFilter()
        ? ''
        : toLocalISOStringUnixtime(getUnixTimePeriod(_table_filter_periods[Number(filterId)]).endUnixtime)
    )
  }, [filterId]) // filterIdが変更されたときに実行
  const [data, setData] = useState([])
  const [user] = useAuthState(auth)
  const [triggerFetch, setTriggerFetch] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const clearedFilter = () => filter_id === '5' && initialLoad === true
  const [isLoading, setIsLoading] = useState(true)
  const [filterStartDate, setFilterStartDate] = useState(
    clearedFilter()
      ? ''
      : toLocalISOStringUnixtime(getUnixTimePeriod(_table_filter_periods[Number(filterId)]).startUnixtime)
  )
  const [filterEndDate, setFilterEndDate] = useState(
    clearedFilter()
      ? ''
      : toLocalISOStringUnixtime(getUnixTimePeriod(_table_filter_periods[Number(filterId)]).endUnixtime)
  )

  useEffect(() => {
    const startUnixtime = new Date(filterStartDate).getTime()
    const endUnixtime = new Date(filterEndDate).getTime()

    const fetchData = async () => {
      const { schoolInfo } = await getSchoolsInfo()
      const schoolsOfSchoolAI = schoolInfo.filter((school) => !!school.data)
      let schoolIdName = {}
      schoolsOfSchoolAI.forEach((school) => {
        schoolIdName[school.schoolId] = school.data.name
      })
      setIsLoading(true)

      const usersCollectionRef = collection(db, 'users')
      let userDocs = await getDocs(usersCollectionRef)
      let _d = userDocs.docs

      if (school_id) {
        const mingakuSchoolsDocRef = doc(db, 'externalServices', 'mingaku', 'schools', school_id)
        const schoolData = await getDoc(mingakuSchoolsDocRef)
        setVisibleDataForChildSchools(schoolData?.data()?.makeViewableDataForChildren || [])

        const { childrenSchools } = await fetchDocumentIds(school_id)
        let filteringSchools = childrenSchools
          ? [...childrenSchools.map((school) => school.childSchoolDocId), school_id]
          : [school_id]
        _d = _d.filter((doc) => filteringSchools.includes(doc.data().schoolDocId))
      }

      if (schoolIdFilter) {
        _d = _d.filter((doc) =>
          schoolIdName[doc.data().schoolDocId]?.toLowerCase().includes(schoolIdFilter.toLowerCase())
        )
      }

      if (userNameFilter) {
        _d = _d.filter((doc) => doc.data().name?.toLowerCase().includes(userNameFilter.toLowerCase()))
      }

      const allConversations = []
      const userDocsWithMingakuData = await Promise.all(
        _d.map(async (userDoc) => {
          const mingakuUserData = await getUserDataFromMingaku(userDoc)
          return { userDoc, mingakuUserData }
        })
      )

      for (const { userDoc, mingakuUserData } of userDocsWithMingakuData) {
        const userId = userDoc.id
        const userEmail = userDoc.data().email
        const schoolId = userDoc.data().schoolDocId || ''
        const conversationsCollectionRef = collection(userDoc.ref, 'conversations')

        const queries = [
          query(
            conversationsCollectionRef,
            where('createdAt', '>=', startUnixtime),
            where('createdAt', '<=', endUnixtime),
            orderBy('createdAt', 'desc')
          ),
          query(
            conversationsCollectionRef,
            where('updatedAt', '>=', startUnixtime),
            where('updatedAt', '<=', endUnixtime),
            orderBy('updatedAt', 'desc')
          )
        ]

        const queryResults = await Promise.all(queries.map((q) => getDocs(q)))
        let uniqueDocs = new Map()
        queryResults.forEach((result) => result.docs.forEach((doc) => uniqueDocs.set(doc.id, doc)))

        let mergedResults = Array.from(uniqueDocs.values())
        if (modeNameFilter) {
          mergedResults = await Promise.all(
            mergedResults.map(async (doc) => {
              const promptRef = doc.data().promptRef
              const promptDoc = await getDoc(promptRef)
              if (promptDoc.data()?.prompt_title?.toLowerCase().includes(modeNameFilter.toLowerCase())) {
                return doc
              }
            })
          ).then((results) => results.filter(Boolean))
        }

        for (const conversationDoc of mergedResults) {
          const conversationData = conversationDoc.data()
          const conversationId = conversationData.conversationId
          const messagesCollectionRef = collection(conversationDoc.ref, 'messages')
          const messagesQuery = query(
            messagesCollectionRef,
            where('createdAt', '>=', startUnixtime),
            where('createdAt', '<=', endUnixtime),
            orderBy('createdAt', 'asc')
          )

          let messageDocs = (await getDocs(messagesQuery)).docs

          if (messageFilter) {
            messageDocs = messageDocs.filter((doc) => {
              const messageData = doc.data()
              const messageText = Array.isArray(messageData.message) ? messageData.message[0].text : messageData.message
              return messageText?.toLowerCase().includes(messageFilter.toLowerCase())
            })
          }

          const chatMessages = await Promise.all(
            messageDocs.map(async (messageDoc) => {
              const messageData = messageDoc.data()
              const chatMessage = {
                conversation_id: conversationId,
                school_id: schoolId,
                school_name: schoolIdName[schoolId] || '',
                user_id: userId,
                user_email: userEmail,
                user_name: mingakuUserData.name,
                prompt_title: '',
                prompt_id: '',
                message: Array.isArray(messageData.message) ? messageData.message[0].text : messageData.message,
                message_length: messageData.characterCount || messageData.message.length,
                message_token: messageData.tokenCount,
                message_id: messageData.messageId,
                role: messageData.role,
                createdAt: messageData.createdAt,
                type: ['system', 'user'].includes(messageData.role) ? 'インプット' : 'アウトプット',
                actualMessageLength: ['system', 'user'].includes(messageData.role)
                  ? messageData.actualMessageLength
                  : 0,
                model: messageData.model,
                costForActualInputMessageLength:
                  messageData.actualMessageLength && ['system', 'user'].includes(messageData.role)
                    ? messageData.actualMessageLength *
                      (_gpt_input_cost.find((cost) => cost.type === messageData.model)?.cost ||
                        _abolished_gpt_input_cost.find((cost) => cost.type === messageData.model)?.cost)
                    : 0,
                actualOutputMessageLength: messageData.role === 'assistant' ? messageData.characterCount : 0,
                costForActualOutputMessageLength:
                  messageData.actualMessageLength && messageData.role === 'assistant'
                    ? messageData.actualMessageLength *
                      (_gpt_input_cost.find((cost) => cost.type === messageData.model)?.cost ||
                        _abolished_gpt_input_cost.find((cost) => cost.type === messageData.model)?.cost)
                    : 0
              }

              if (conversationDoc.data().promptRef) {
                const promptDocSnapshot = await getDoc(conversationDoc.data().promptRef)
                if (promptDocSnapshot.exists()) {
                  chatMessage.prompt_title = promptDocSnapshot.data().prompt_title || '削除されたモード'
                  chatMessage.prompt_id = promptDocSnapshot.data().id || ''
                } else {
                  chatMessage.prompt_title = '削除されたモード'
                  chatMessage.prompt_id = ''
                }
              }

              return chatMessage
            })
          )

          allConversations.push(...chatMessages)
        }
      }

      const formattedData = allConversations.map((c) => ({ ...c, createdAt: new Date(c.createdAt) }))
      setData(formattedData)
      setIsLoading(false)
    }

    if (user) {
      const userDocRef = doc(db, 'users', user.uid)
      getDoc(userDocRef).then((userDocSnapshot) => {
        if (userDocSnapshot.exists()) {
          if (initialLoad || triggerFetch) {
            if (clearedFilter()) {
              setData([])
              setIsLoading(false)
            } else {
              fetchData()
            }
            setInitialLoad(false)
            setTriggerFetch(false)
          }
        }
      })
    }
  }, [triggerFetch, user, filterId, filterEndDate, filterStartDate, initialLoad, schoolIdFilter, school_id])

  const columns = useMemo(
    () => [
      {
        Header: '作成日',
        accessor: 'createdAt',
        Cell: ({ value }) => (value instanceof Date ? value.toLocaleString() : value)
      },
      { Header: '会話ID', accessor: 'conversation_id' },
      { Header: 'スクールID', accessor: 'school_id' },
      { Header: 'スクール名', accessor: 'school_name' },
      { Header: 'ユーザーID', accessor: 'user_id' },
      { Header: 'ユーザEmail', accessor: 'user_email' },
      { Header: 'ユーザー名', accessor: 'user_name' },
      { Header: 'モード名', accessor: 'prompt_title' },
      { Header: 'モードID', accessor: 'prompt_id' },
      { Header: 'メッセージ', accessor: 'message' },
      { Header: 'トークン数', accessor: 'message_token' },
      { Header: 'ロール', accessor: 'role' },
      { Header: 'タイプ', accessor: 'type' },
      { Header: 'モデル', accessor: 'model' },
      { Header: '文字数', accessor: 'message_length' },
      { Header: '実際に送信した文字数', accessor: 'actualMessageLength' },
      { Header: '実際に送信した文字数に対する金額', accessor: 'costForActualInputMessageLength' },
      { Header: 'GPTが出力した文字数', accessor: 'actualOutputMessageLength' },
      { Header: 'GPTが出力した文字数に対する金額', accessor: 'costForActualOutputMessageLength' },
      { Header: 'メッセージID', accessor: 'message_id' }
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data })

  const filteredRows = rows.filter((row) => {
    const rowDate = moment(row.values.createdAt)
    const startDate = filterStartDate ? moment(filterStartDate) : null
    const endDate = filterEndDate ? moment(filterEndDate) : null
    return (!startDate || rowDate.isSameOrAfter(startDate)) && (!endDate || rowDate.isSameOrBefore(endDate))
  })

  const calculateTotalCost = (data) => {
    return data.reduce((acc, row) => {
      return acc + (row.costForActualInputMessageLength || 0) + (row.costForActualOutputMessageLength || 0)
    }, 0)
  }
  const totalCost = useMemo(() => {
    // filteredRowsの内容が変更されたときにのみ、合計金額を計算します。
    return calculateTotalCost(filteredRows.map((row) => row.original))
  }, [filteredRows])

  useEffect(() => {
    const calculateSchoolTotalCosts = (data) => {
      const schoolCosts = data.reduce((acc, row) => {
        const schoolId = row.school_id
        const totalCost = (row.costForActualInputMessageLength || 0) + (row.costForActualOutputMessageLength || 0)

        if (!acc[schoolId]) {
          acc[schoolId] = 0
        }
        acc[schoolId] += totalCost
        return acc
      }, {})

      return schoolCosts
    }

    const schoolTotalCosts = calculateSchoolTotalCosts(data)

    const newSchoolTotalCostsTableData = Object.entries(schoolTotalCosts).map(([schoolId, totalCost]) => {
      return {
        schoolId: schoolId,
        schoolName: schoolIdName[schoolId],
        totalCost: Math.floor(totalCost)
      }
    })

    setSchoolTotalCostsTableData(newSchoolTotalCostsTableData)
  }, [data, schoolIdName])

  const handleExecute = () => {
    setTriggerFetch(true) // データ取得をトリガー
  }

  const handleDownload = () => {
    const formatDate = (date) => {
      if (!date) return ''
      const d = new Date(date)
      const year = d.getFullYear()
      const month = ('0' + (d.getMonth() + 1)).slice(-2)
      const day = ('0' + d.getDate()).slice(-2)
      const hours = ('0' + d.getHours()).slice(-2)
      const minutes = ('0' + d.getMinutes()).slice(-2)
      const seconds = ('0' + d.getSeconds()).slice(-2)
      return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`
    }
    // CSVに変換する関数
    const convertToCSV = (data, columns) => {
      const headers = columns.map((col) => col.Header).join(',')
      const rows = data.map((row) =>
        columns
          .map((col) => {
            const cell = row[col.accessor]
            return typeof cell === 'string' ? `"${cell.replace(/"/g, '""')}"` : cell
          })
          .join(',')
      )
      return [headers, ...rows].join('\n')
    }

    // CSVをダウンロードする関数
    const downloadCSV = (data, filename) => {
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')
      link.setAttribute('href', URL.createObjectURL(blob))
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    // 合計金額のテーブルをエクスポート
    const totalCostsColumns = [{ Header: '合計金額(円)', accessor: 'totalCost' }]
    const csvDataTotalCosts = convertToCSV([{ totalCost: Math.floor(totalCost) }], totalCostsColumns)
    downloadCSV(csvDataTotalCosts, 'total-costs.csv')

    // メインテーブルのデータをエクスポート
    const formattedMainData = filteredRows.map((row) => {
      return {
        ...row.original,
        createdAt: formatDate(row.original.createdAt) // 作成日をフォーマット
      }
    })
    const csvDataMain = convertToCSV(formattedMainData, columns)
    downloadCSV(csvDataMain, 'main-data.csv')

    // 学校別合計金額のテーブルをエクスポート
    const schoolTotalCostsColumns = [
      { Header: 'スクールID', accessor: 'schoolId' },
      { Header: 'スクール名', accessor: 'schoolName' },
      { Header: '合計金額(円)', accessor: 'totalCost' }
    ]

    const csvDataSchoolTotalCosts = convertToCSV(schoolTotalCostsTableData, schoolTotalCostsColumns)
    downloadCSV(csvDataSchoolTotalCosts, 'school-total-costs.csv')
  }

  return (
    <>
      {isLoading ? (
        <LoadingGrid />
      ) : (
        <>
          <div className={ChatDataModule['top-container']}>
            <FilterContainer
              filterStartDate={filterStartDate}
              setFilterStartDate={setFilterStartDate}
              filterEndDate={filterEndDate}
              setFilterEndDate={setFilterEndDate}
              schoolIdFilter={schoolIdFilter}
              userNameFilter={userNameFilter}
              setSchoolIdFilter={setSchoolIdFilter}
              setUserNameFilter={setUserNameFilter}
              setMessageFilter={setMessageFilter}
              messageFilter={messageFilter}
              setModeNameFilter={setModeNameFilter}
              modeNameFilter={modeNameFilter}
              handleExecute={handleExecute}
              handleDownload={handleDownload}
              school_id={school_id}
            />
            {childrenData.length > 0 && (
              <div className={ChatDataModule['check-item-container']}>
                <b className={ChatDataModule['check-item']}>子スクールに使用料・計算料金項目を表示する</b>
                <Switch
                  checked={visibleDataForChildSchools}
                  onChange={() => handleVisibleDataForChildSchoolsChange()}
                />
              </div>
            )}
          </div>
          <div className={ChatDataModule['main-container']}>
            <div className={ChatDataModule['table-wrapper']}>
              <table className={ChatDataModule['table']}>
                <thead>
                  <tr>
                    <th>合計金額(円)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{Math.floor(totalCost)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={ChatDataModule['table-wrapper']}>
              <table className={ChatDataModule['table']}>
                <thead>
                  <tr>
                    <th>スクールID</th>
                    <th>スクール名</th>
                    <th>合計金額(円)</th>
                  </tr>
                </thead>
                <tbody>
                  {schoolTotalCostsTableData.map(({ schoolId, totalCost }) => (
                    <tr key={schoolId}>
                      <td>{schoolId}</td>
                      <td>{schoolIdName[schoolId]}</td>
                      <td>{Math.floor(totalCost)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={ChatDataModule['table-wrapper']}>
              <table {...getTableProps()} className={ChatDataModule['table']}>
                <thead>
                  {/* {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                      ))}
                    </tr>
                  ))} */}
                  {headerGroups.map((headerGroup) => {
                    const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps() // keyを分解
                    return (
                      <tr key={headerGroupKey} {...headerGroupProps}>
                        {headerGroup.headers.map((column) => {
                          const { key: columnKey, ...columnProps } = column.getHeaderProps() // keyを分解
                          return (
                            <th key={columnKey} {...columnProps}>
                              {column.render('Header')}
                            </th>
                          )
                        })}
                      </tr>
                    )
                  })}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {filteredRows.map((row) => {
                    prepareRow(row)
                    const { key: rowKey, ...rowProps } = row.getRowProps() // keyを分解
                    return (
                      <tr key={rowKey} {...rowProps}>
                        {row.cells.map((cell) => {
                          const { key: cellKey, ...cellProps } = cell.getCellProps() // keyを分解
                          return (
                            <td key={cellKey} {...cellProps}>
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  )
}

function toLocalISOStringUnixtime(unixTimestamp) {
  const date = new Date(unixTimestamp)
  const off = date.getTimezoneOffset()
  const adjustedDate = new Date(date.getTime() - off * 60 * 1000)
  return adjustedDate.toISOString().slice(0, 19)
}

function getUnixTimePeriod(period) {
  const now = new Date()
  let start, end

  switch (period.unit) {
    case 'day':
      start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - period.value)
      end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - period.value)
      break
    case 'week':
      start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - (period.value - 1) * 7)
      end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 6)
      break
    case 'month':
      start = new Date(now.getFullYear(), now.getMonth() - period.value, 1)
      end = new Date(now.getFullYear(), now.getMonth() - period.value + 1, 0)
      break
    case 'year':
      start = new Date(now.getFullYear() - period.value, 0, 1)
      end = new Date(now.getFullYear() - period.value + 1, 0, 0)
      break
    case 'all':
      start = new Date('2023-01-01T00:00:00')
      end = now
      break
    case 'lastWeek':
      start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6)
      end = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      break
    default:
      throw new Error('Invalid period unit')
  }

  // 終了日の23時59分59秒999ミリ秒に設定
  end.setHours(23, 59, 59, 999)

  return {
    displayName: period.displayName,
    startUnixtime: start.getTime(), // ミリ秒単位のUnixタイムスタンプ
    endUnixtime: end.getTime() // ミリ秒単位のUnixタイムスタンプ
  }
}

export default ChatDataTableScreen
