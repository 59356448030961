// // assistantApi.js
// export const getAssistantApi = async (assistantId) => {
//   const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_get_assistant_api'
//   const res = await fetch(URL, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       assistant_id: assistantId
//     })
//   })
//   const data = await res.json()
//   //   console.log('data.myAssistant', data.myAssistant)
//   return data.myAssistant
// }

export const getAssistantApi = async (assistantId) => {
  const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_get_assistant_api'
  try {
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        assistant_id: assistantId
      })
    })

    if (!res.ok) {
      // サーバーからのレスポンスがOKではない場合、エラーを投げる
      throw new Error(`Server responded with status ${res.status}`)
    }

    const data = await res.json()

    // 必要に応じてデータの検証を行う
    if (!data.myAssistant) {
      // 必要なデータが含まれていない場合、エラーを投げる
      throw new Error('No assistant data found')
    }

    return data.myAssistant
  } catch (error) {
    // エラーをキャッチして処理する
    console.error('Error fetching assistant API:', error)

    // エラー情報を含むオブジェクトを返すか、他のエラー処理を行う
    return null
  }
}
