import React from 'react'
import AppModule from '../../App.module.scss'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import Footer from '../../components/Common/Footer'
import Header from '../../components/Header/Header'
import ChatMainLayout from '../../components/ChatMainLayout/MainLayout'
import LoadingGrid from '../../components/LoadingGrid/LoadingGrid'

function Top() {
  const [user] = useAuthState(auth)
  // useAuthCheck()

  return (
    <div className={AppModule.main}>
      {user ? (
        <>
          <Header title="ホーム" />
          {user ? (
            <ChatMainLayout
              conversationType={'conversations'}
              modePath={'/mode'}
              displayList={true}
              displayFooter={true}
              readOnly={false}
            />
          ) : (
            <></>
          )}
          <Footer homePath="/" addChatPath="/mode" userPath="/mypage" />
        </>
      ) : (
        <LoadingGrid />
      )}
    </div>
  )
}
export default Top
