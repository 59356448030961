import React from 'react'
import Footer from '../../components/Common/Footer'
import Header from '../../components/Header/Header'
import RedirectPage from '../../components/Redirect/Redirect'
import AppModule from '../../App.module.scss'

function Redirect() {
  return (
    <div className={AppModule.main}>
      <Header />
      <div>
        <RedirectPage
          chatPath={'/chat'}
          conversationType={'conversations'}
          serviceType="gpt-teacher"
          checkAudioPage={false}
        />
      </div>
      <Footer homePath="/" addChatPath="/mode" userPath="/mypage" />
    </div>
  )
}
export default Redirect
