import { signInWithEmailAndPassword, signInWithCustomToken, sendPasswordResetEmail } from 'firebase/auth'
import { useState, useEffect, useRef, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { doc, updateDoc, getDoc } from 'firebase/firestore'
import { auth, db } from '../../firebase'
import SignUpModule from './SignUp.module.scss'
import OutlinedInput from '@mui/material/OutlinedInput'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../firebase'
import { useLocation } from 'react-router-dom'
import qrScanIcon from '../../assets/qr_scan_icon.png'
import Webcam from 'react-webcam'
import jsQR from 'jsqr'
import LoadingGrid from '../LoadingGrid/LoadingGrid'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
function Login({ path, signup, type }) {
  const { state } = useLocation()
  const redirectPath = state && state.redirectPath != null ? state.redirectPath : path
  const [loading, setLoading] = useState(false)
  const buttonClassName = 'button'
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isScanning, setIsScanning] = useState(false)
  const webcamRef = useRef(null)
  const [resetEmail, setResetEmail] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const handleStartScan = () => {
    setIsScanning(true)
  }

  const handleStopScan = () => {
    setIsScanning(false)
  }

  const submitPasswordResetEmail = async () => {
    const actionCodeSettings = {
      url: 'http://localhost:3000/login', // パスワード再設定後のリダイレクト URL
      handleCodeInApp: false
    }

    try {
      await sendPasswordResetEmail(auth, resetEmail, actionCodeSettings)
      alert('パスワードリセットメールを送信しました。')
    } catch (error) {
      console.error('パスワードリセットメール送信失敗:', error)
      alert('エラーが発生しました。もう一度お試しください。')
    }
    setModalOpen(false)
  }
  const scanQRCode = useCallback(() => {
    if (isScanning && webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot()
      if (imageSrc) {
        const image = new Image()
        image.onload = async () => {
          const canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
          const code = jsQR(imageData.data, imageData.width, imageData.height)

          if (code) {
            const codes = code.data.split(' ')
            const loginId = codes[0]
            const password = codes[1]
            setEmail(loginId)
            setPassword(password)
            setIsScanning(false) // QRコードが見つかった場合、スキャンを停止
            try {
              await signInWithIdAndPassword(loginId, password)
            } catch (e) {}
          } else if (isScanning) {
            requestAnimationFrame(scanQRCode) // 継続してスキャン
          }
        }
        image.src = imageSrc
      } else if (isScanning) {
        requestAnimationFrame(scanQRCode) // 継続してスキャン
      }
    }
  }, [isScanning])

  useEffect(() => {
    if (isScanning) {
      scanQRCode()
    }
  }, [isScanning, scanQRCode])

  const signIn = () => {
    // 先生ログイン
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        //ログインが成功した時の処理
        updateDoc(doc(db, 'users', userCredential.user.uid), {
          userId: userCredential.user.uid,
          email: userCredential.user.email ?? '',
          createdAt: userCredential.user.metadata.createdAt ?? null,
          lastLoginAt: userCredential.user.metadata.lastLoginAt ?? null
        })
        if (redirectPath === '/') {
          console.log('管理画面へ')
          const userDocRef = doc(db, 'users', userCredential.user.uid)
          getDoc(userDocRef).then(async (userDocSnapshot) => {
            if (userDocSnapshot.exists() && userDocSnapshot.data().schoolDocId) {
              navigate(`/management?school_id=${userDocSnapshot.data().schoolDocId}`)
            } else {
              console.log('指定画面へ')
              navigate(`${redirectPath}`)
            }
          })
        } else {
          console.log('指定画面へ')
          navigate(`${redirectPath}`)
        }
      })
      .catch((error) => {
        if (error.code === 'auth/missing-password') {
          alert('パスワードが入力されていません')
        } else if (error.code === 'auth/invalid-email') {
          alert('メールアドレスの形式が正しくありません')
        } else if (error.code === 'auth/user-disabled') {
          alert('アカウントが無効化されています')
        } else if (error.code === 'auth/wrong-password') {
          alert('パスワードが間違っています')
        } else if (error.code === 'auth/user-not-found') {
          alert('指定されたメールアドレスのユーザーアカウントがありません')
        } else if (error.code === 'auth/operation-not-allowed') {
          alert('メールとパスワードによるサインインが許可されていません')
        } else if (error.code === 'auth/too-many-requests') {
          alert('セキュリティのためにアカウントへのアクセスが一時的に制限されました')
        } else if (error.code === ' auth/network-request-failed') {
          alert('ネットワークリクエストが失敗しました')
        } else {
          alert(error.code)
        }
      })
  }

  const signInWithIdAndPassword = async (loginId, password) => {
    // 生徒ログイン
    setLoading(true)
    const signIn = httpsCallable(functions, 'sign_in_with_id_and_password')
    try {
      const res = await signIn({ loginId, password })
      const { customToken } = res.data
      const userCredential = await signInWithCustomToken(auth, customToken)
      updateDoc(doc(db, 'users', userCredential.user.uid), {
        userId: userCredential.user.uid,
        email: userCredential.user.email ?? '',
        createdAt: userCredential.user.metadata.createdAt ?? null,
        lastLoginAt: userCredential.user.metadata.lastLoginAt ?? null
      })
      navigate(`${redirectPath}`)
    } catch (e) {
      alert('生徒ログインIDとパスワードが一致しません。')
    } finally {
      setLoading(false)
    }
  }
  const handleOpenModal = () => {
    setModalOpen(true)
  }
  const navigateToOtherLogin = () => {
    if (type === 'tutor') {
      navigate('/login_for_student', { state })
    } else {
      navigate('/login', { state })
    }
  }
  const handleCloseModal = () => {
    setModalOpen(false)
  }
  const modalBody = (
    <Box
      sx={{
        bgcolor: 'background.paper'
      }}
      className={SignUpModule['box-container']}
    >
      <h2>パスワードリセット</h2>
      <p>パスワードをリセットしたいアカウントのメールアドレスを入力してください。</p>
      <OutlinedInput
        className={SignUpModule['outlined-input']}
        fullWidth
        type="email"
        placeholder="メールアドレス"
        value={resetEmail}
        onChange={(event) => setResetEmail(event.target.value)}
      />
      <button onClick={submitPasswordResetEmail} className={SignUpModule.button}>
        パスワードリセットメールを送信
      </button>
    </Box>
  )
  return (
    <div className={SignUpModule.container}>
      {loading ? <LoadingGrid /> : <></>}
      {type === 'student' ? (
        <div>
          {!isScanning ? (
            <img onClick={handleStartScan} className={SignUpModule.qrScanIcon} src={qrScanIcon}></img>
          ) : (
            <div>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                style={{ width: '80%', borderRadius: '3%' }}
                videoConstraints={{ facingMode: 'environment' }}
              />
              <button className={SignUpModule.scanStop} onClick={handleStopScan}>
                スキャンを終了する
              </button>
            </div>
          )}
          <div>または</div>
        </div>
      ) : (
        <></>
      )}
      <div className={SignUpModule.input}>
        <FormControl className={SignUpModule.inputbox} variant="outlined">
          <InputLabel htmlFor="email">{type === 'tutor' ? 'メールアドレス' : '生徒ログインID'}</InputLabel>
          <OutlinedInput
            id="email"
            className={SignUpModule.inputbox}
            label={`${type === 'tutor' ? 'メールアドレス' : '生徒ログインID'}`}
            type="email"
            value={email}
            sx={{ width: '70%', margin: '0 15%' }}
            onChange={(event) => setEmail(event.target.value)}
          />
        </FormControl>
      </div>
      <div className={SignUpModule.input}>
        <FormControl className={SignUpModule.inputbox} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">パスワード</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            className={SignUpModule.inputbox}
            label="パスワード"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </FormControl>
      </div>
      {type === 'tutor' ? (
        <button className={SignUpModule[buttonClassName]} onClick={signIn}>
          メールアドレスでログインする
        </button>
      ) : (
        <button className={SignUpModule[buttonClassName]} onClick={() => signInWithIdAndPassword(email, password)}>
          生徒ログインIDでログインする
        </button>
      )}
      {type === 'tutor' ? (
        <div>
          <p className={SignUpModule['description']}>
            パスワードを忘れた方は
            <button className={SignUpModule['simple-text']} onClick={handleOpenModal}>
              こちら
            </button>
          </p>
        </div>
      ) : (
        <></>
      )}
      <div>
        <p className={SignUpModule['description']}>
          {type === 'tutor' ? '生徒用の' : '先生用の'}
          ログイン画面は
          <button className={SignUpModule['simple-text']} onClick={navigateToOtherLogin}>
            こちら
          </button>
        </p>
      </div>
      <div>
        <p className={SignUpModule['description']}>
          {/* アカウントをまだお持ちでない方は<a href={signup}>こちら</a>から新規登録をしてください。 */}
          アカウントをまだお持ちでない方は先生に相談してください。
        </p>
      </div>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="password-reset-modal"
        aria-describedby="password-reset-form"
      >
        {modalBody}
      </Modal>
    </div>
  )
}
export default Login
