import { useState, useEffect } from 'react'
import { doc, updateDoc, setDoc, collection, getDoc, deleteField } from 'firebase/firestore'
import UISettingModule from './UISetting.module.scss'
import { db, storage } from '../../firebase'
import LoadingGrid from '../LoadingGrid/LoadingGrid'
import * as React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import { useSearchParams } from 'react-router-dom'
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import HeaderModule from '../Common/Header2.module.scss'
function UISettingScreen({}) {
  const [searchParams] = useSearchParams()
  const schoolId = searchParams.get('school_id')
  const [formData, setFormData] = useState({
    headerTitle: '',
    pageTitle: '',
    imageUrl: '',
    imagePath: ''
  })
  const [image, setImage] = useState('')
  const [uplodedImage, setUplodedImage] = useState('')
  const [schoolRef, setSchoolRef] = useState('')
  const [loading, setLoading] = useState(false)
  const handleImage = (event) => {
    const _image = event.target.files[0]
    setFormData((prevData) => ({
      ...prevData,
      imageUrl: window.URL.createObjectURL(_image)
    }))
    setImage(_image)
    setUplodedImage(_image)
  }

  const deleteImage = async () => {
    setImage('')
    setFormData((prevData) => ({
      ...prevData,
      imageUrl: ''
    }))
    setUplodedImage('')
  }

  const deleteExistingImage = () => {
    setImage('')
    setFormData((prevData) => ({
      ...prevData,
      imageUrl: ''
    }))
    setUplodedImage('')
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let promptDocRef

        if (schoolId) {
          const mingakuDocRef = doc(db, 'externalServices', 'mingaku')
          const schoolsCollectionRef = collection(mingakuDocRef, 'schools')
          promptDocRef = doc(schoolsCollectionRef, schoolId)
          setSchoolRef(promptDocRef)
        } else {
          promptDocRef = doc(db, 'service_type', 'gpt-teacher')
          setSchoolRef(promptDocRef)
        }

        const promptDocSnap = await getDoc(promptDocRef)

        if (promptDocSnap.exists()) {
          const {
            headerTitle: fetchedHeaderTitle,
            pageTitle: fetchedPageTitle,
            headerIconImagePath: fetchedIconImagePath,
            headerIconImageUrl: fetchedIconImageUrl
          } = promptDocSnap.data()
          setFormData({
            headerTitle: fetchedHeaderTitle,
            pageTitle: fetchedPageTitle,
            imagePath: fetchedIconImagePath,
            imageUrl: fetchedIconImageUrl
          })
        } else {
          // console.error('Document not found')
        }
      } catch (error) {
        console.error('Error fetching document:', error)
      }
    }

    fetchData()
  }, [schoolId])

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value
    }))
  }

  const onSave = async () => {
    setLoading(true)
    const promptDocSnap = await getDoc(schoolRef)

    let updatedData = { ...formData }
    if (promptDocSnap.exists()) {
      const promptDocData = promptDocSnap.data()

      // Preserve existing image data if not changed
      updatedData = {
        ...updatedData
        // headerIconImagePath: uplodedImage ? updatedData.imagePath : promptDocData.headerIconImagePath,
        // headerIconImageUrl: uplodedImage ? updatedData.imageUrl : promptDocData.headerIconImageUrl
      }
      console.log('updatedData', updatedData)
      // Remove any fields with undefined values
      Object.keys(updatedData).forEach((key) => {
        if (updatedData[key] === undefined) {
          delete updatedData[key]
        }
      })

      await updateDoc(schoolRef, updatedData)
    } else {
      await setDoc(schoolRef, updatedData)
    }

    if (uplodedImage) {
      const imageArray = [uplodedImage ? uplodedImage : '']
      await Promise.all(
        imageArray.map(async (image, index) => {
          if (image !== '' && image !== null) {
            if (formData.imagePath) {
              await deleteObject(ref(storage, formData.imagePath))
            }
            const iconImagePath = `headerIcons/${schoolRef.path}/${image.name}`
            const storageRef = ref(storage, iconImagePath)

            try {
              const uploadSnapshot = await uploadBytes(storageRef, image)

              let imageInfo = {
                headerIconImageUrl: await getDownloadURL(uploadSnapshot.ref),
                headerIconImagePath: uploadSnapshot.metadata.fullPath || '',
                updatedAt: new Date().getTime()
              }
              try {
                await updateDoc(schoolRef, imageInfo)
              } catch (error) {
                console.error('doc更新エラー:', error)
              }
            } catch (error) {
              console.error(`画像のエラー:`, error)
            }
          }
        })
      )
    } else if (formData.imageUrl === '') {
      await deleteObject(ref(storage, formData.imagePath)).then(async () => {
        await updateDoc(schoolRef, {
          headerIconImagePath: deleteField(),
          headerIconImageUrl: deleteField(),
          updatedAt: new Date().getTime()
        })
      })
    }
    setLoading(false)
    window.location.reload()
  }
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 150
    }
  })
  return (
    <div className={UISettingModule.container}>
      {loading ? <LoadingGrid /> : <></>}
      <div>
        <div className={UISettingModule['user-info']}>
          <div className={UISettingModule.input}>
            <p className={UISettingModule.input}>ヘッダーアイコン：</p>
            <div className={UISettingModule['input-child']}>
              <label className={UISettingModule['icon-select']} htmlFor="file_upload1">
                アイコンを選ぶ
                <input id="file_upload1" type="file" onChange={handleImage} />
              </label>

              {uplodedImage && (
                <>
                  <div className={UISettingModule['icon-container']}>
                    <img className={UISettingModule['icon-image']} src={formData.imageUrl} alt="" />
                  </div>
                  <button className={UISettingModule['icon-delete']} onClick={() => deleteImage()}>
                    アイコンを削除...
                  </button>
                </>
              )}

              {formData.imageUrl && !uplodedImage ? (
                <>
                  <div className={UISettingModule['icon-container']}>
                    <img className={UISettingModule['icon-image']} src={formData.imageUrl} alt="" />
                  </div>

                  <button className={UISettingModule['icon-delete']} onClick={() => deleteExistingImage()}>
                    アイコンを削除
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className={UISettingModule.input}>
            <p className={UISettingModule.input}>
              ページタイトル
              <CustomWidthTooltip
                title="ブラウザのタブに表示される名前です。"
                arrow
                className={HeaderModule['tooltip-arrow']}
                enterTouchDelay={100}
              >
                <span className={HeaderModule['tooltip2']}>？</span>
              </CustomWidthTooltip>
              ：
            </p>
            <div className={UISettingModule['input-child']}>
              <OutlinedInput
                className={UISettingModule.inputbox}
                sx={{ width: '100%', height: '40px' }}
                value={formData.pageTitle}
                onChange={(e) => handleChange('pageTitle', e.target.value)}
              />
            </div>
          </div>
          <div className={UISettingModule.input}>
            <p className={UISettingModule.input}>ヘッダータイトル：</p>
            <div className={UISettingModule['input-child']}>
              <OutlinedInput
                className={UISettingModule.inputbox}
                sx={{ width: '100%', height: '40px' }}
                value={formData.headerTitle}
                onChange={(e) => handleChange('headerTitle', e.target.value)}
              />
            </div>
          </div>
        </div>
        <button className={UISettingModule['button']} onClick={onSave}>
          保存
        </button>
      </div>
    </div>
  )
}

export default UISettingScreen
