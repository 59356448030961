import React, { useState, useRef, useEffect } from 'react'
import Webcam from 'react-webcam'
import InputModule from './InputScreen.module.scss'

function SelectImage({ questionData, onValueChange }) {
  const [capturedImage, setCapturedImage] = useState(null)
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const webcamRef = useRef(null)
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    // モバイルデバイスかどうかをチェック
    if (/android|iphone|ipad|iPod|iPad|iPhone|Android/i.test(userAgent)) {
      setIsMobile(true)
    }
  }, [])
  const handleImageUpload = (file) => {
    setCapturedImage(URL.createObjectURL(file))
    onValueChange(file) // Pass the file to parent component
  }

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot()
    const currentDateTime = new Date()

    // Formatting the date and time in Japanese format
    const formattedDateTime = `${currentDateTime.getFullYear()}年${(currentDateTime.getMonth() + 1)
      .toString()
      .padStart(2, '0')}月${currentDateTime.getDate().toString().padStart(2, '0')}日${currentDateTime
      .getHours()
      .toString()
      .padStart(2, '0')}時${currentDateTime.getMinutes().toString().padStart(2, '0')}分${currentDateTime
      .getSeconds()
      .toString()
      .padStart(2, '0')}秒`

    const fileName = `image_${formattedDateTime}.jpg`
    const file = dataURLtoFile(imageSrc, fileName)
    setCapturedImage(imageSrc)
    onValueChange(file) // Pass the captured file to parent component
    setIsCameraOpen(false)
  }

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  return (
    <div>
      {/* <p className={InputModule['question-description']}>{questionData.description && questionData.description}</p> */}
      <label
        htmlFor={`file_upload${questionData.order}`}
        className={InputModule['upload']}
        style={{
          backgroundColor: '#5b9aff',
          color: '#ffffff',
          opacity: 0.9,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          borderRadius: '4px',
          padding: '8px 16px',
          marginBottom: '5px',
          cursor: 'pointer',
          display: 'inline-block'
        }}
      >
        画像を選択
      </label>
      <input
        id={`file_upload${questionData.order}`}
        type="file"
        accept="image/*"
        onChange={(e) => handleImageUpload(e.target.files[0])}
        style={{ display: 'none' }}
      />

      {!isMobile && (
        <>
          <button
            onClick={() => setIsCameraOpen(!isCameraOpen)}
            className={InputModule.upload}
            style={{
              backgroundColor: '#5b9aff',
              color: '#ffffff',
              opacity: 0.9,
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              borderRadius: '4px',
              border: 'none',
              display: 'inline-block',
              fontWeight: 'bold',
              fontSize: '16px',
              borderRadius: '4px',
              padding: '8px 16px',
              marginBottom: '5px',
              cursor: 'pointer'
            }}
          >
            {isCameraOpen ? 'カメラを閉じる' : 'カメラを開く'}
          </button>
          {isCameraOpen && (
            <div>
              <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" width={320} height={240} />
              <button
                onClick={handleCapture}
                style={{
                  backgroundColor: '#5b9aff',
                  color: '#ffffff',
                  opacity: 0.9,
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                  borderRadius: '4px',
                  border: 'none',
                  display: 'inline-block',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  borderRadius: '4px',
                  padding: '8px 16px',
                  marginLeft: '5px',
                  cursor: 'pointer'
                }}
              >
                撮影
              </button>
            </div>
          )}
        </>
      )}

      {capturedImage && (
        <div>
          <img
            src={capturedImage}
            alt="Selected or Captured"
            className={InputModule['image-preview']}
            width={320}
            height={240}
          />
        </div>
      )}
    </div>
  )
}

export default SelectImage
