import React, { useState } from 'react'
import ChatBubbleModule from './ChatBubbleForSystem.module.scss'
import { ThreeDots } from 'react-loader-spinner'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditIcon from '@mui/icons-material/Edit'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import ModalForPicture from './ModalForPicture'
import SelectImage from '../Input/SelectImage'
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'
import { writeBatch, collection, getDocs, doc, setDoc, query, orderBy, getDoc, updateDoc } from 'firebase/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, db, functions, storage } from '../../firebase'
const ChatBubbleForSystem = ({
  role,
  messageDocRef,
  question,
  answer,
  isLoading,
  userInputValue,
  prompt,
  readOnly,
  onUserInputChange,
  hidedUserMessage,
  conversationId,
  userId,
  setChatData
}) => {
  const [imageFiles, setImageFiles] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [editingIndex, setEditingIndex] = useState(null)
  const [editValue, setEditValue] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [modalImageUrl, setModalImageUrl] = useState('')
  if (question) {
    question = question.split('\n').join('<br>')
  }
  if (answer) {
    answer = answer.split('\n').join('<br>')
  }
  const threeDotsColor = window.location.pathname === '/chat' ? '#1e90ff' : '#0db694'

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const alwaysExpanded = prompt?.expandInputContents && prompt?.displayInputContents

  const getFormQuestion = (key) => {
    return prompt?.formQuestions?.find((question) => question.key === key)
  }

  const handleEditClick = (index, value) => {
    setEditingIndex(index)
    setEditValue(value)
  }

  const handleSave = (index) => {
    const formQuestion = getFormQuestion(userInputValue[index].key)
    const errors = []

    if (formQuestion.mandatory && !editValue) {
      errors.push('この項目は必須項目です。')
    }

    if (
      formQuestion.answerCharacterLimit === true &&
      formQuestion.answerMaxLength != null &&
      editValue.length > formQuestion.answerMaxLength
    ) {
      errors.push(`この項目は${formQuestion.answerMaxLength}文字までです。`)
    }

    if (errors.length > 0) {
      alert(errors.join('\n'))
      return
    }

    userInputValue[index].value = editValue

    const updatedUserInputValue = [...userInputValue]
    updatedUserInputValue[index].value = editValue

    onUserInputChange(updatedUserInputValue)

    setEditingIndex(null)
  }

  const handleCancel = () => {
    setEditingIndex(null)
    setEditValue('')
    setImageFiles([])
  }

  const calculateRows = (text) => {
    const lines = text.split('\n').length
    return lines >= 3 ? lines : 3
  }
  const handleOpenModal = (url) => {
    setModalImageUrl(url)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setModalImageUrl('')
  }
  const handleImageFileChange = (file, index) => {
    const fileWithMetadata = {
      file: file,
      name: file.name
    }
    console.log('imageFiles', imageFiles)
    setImageFiles((prevFiles) => {
      // const updatedFiles = [...prevFiles]
      // updatedFiles[index] = fileWithMetadata // Store the file and its name
      // return updatedFiles
      const updatedFiles = [fileWithMetadata]
      return updatedFiles
    })
  }
  const uploadImagesToFirestore = async (item, index) => {
    const imageIndex = userInputValue.filter((item) => item.type === 'image').findIndex((item, i) => i === index)
    console.log('imageFiles', imageFiles)

    let urlPath
    let directoryPath
    if (item.url) {
      urlPath = decodeURIComponent(item.url.split('?')[0].split('/o/')[1])
      directoryPath = urlPath.split('/').slice(0, -1).join('/')
    } else {
      console.log('userId', userId)
      console.log('conversationId', conversationId)
      console.log('hidedUserMessage.messageId', hidedUserMessage)

      directoryPath = `conversationPictures/${userId}/${conversationId}/${hidedUserMessage.messageId}`
    }
    console.log('Directory path:', directoryPath)
    // conversationPictures/0DTZNk3diVWEBdQdma6OBkp94MG2/m8PeUKNG6zSmcOU5GwYV/PDBBjRRxSXlQi86DNrT2/image (7).png

    // Check if there are new images to upload
    if (imageFiles && imageFiles.length > 0) {
      // Create a reference to the file to delete
      if (urlPath) {
        const oldImageRef = ref(storage, urlPath)

        try {
          // Delete the old image
          await deleteObject(oldImageRef)
          console.log(`Image at path ${urlPath} deleted successfully.`)
        } catch (error) {
          console.error(`Failed to delete image at path ${urlPath}:`, error)
        }
      }
      const imageData = imageFiles.filter((file) => typeof file === 'object' && file !== null)[0]

      if (imageData && imageData.file) {
        // Create the new path by appending the new file name to the directory path
        const newIconImagePath = `${directoryPath}/${imageData.file.name}`
        const newStorageRef = ref(storage, newIconImagePath)
        await uploadBytes(newStorageRef, imageData.file)
        const newDownloadURL = await getDownloadURL(newStorageRef)
        console.log('New image uploaded to:', newDownloadURL)

        return newDownloadURL // Return the new image URL
      }
    }
  }
  const saveImageChange = async (item, index) => {
    console.log('userInputValue', userInputValue)
    const imageIndex = userInputValue
      .filter((item) => item.type === 'image')
      .findIndex((item) => userInputValue.indexOf(item) === index)
    console.log('imageIndex', imageIndex)

    console.log('setImageFiles', imageFiles)
    if (imageFiles.length == 0) {
      alert('この項目は必須項目です。')
      setEditingIndex(null)
      setImageFiles([])
      return
    }
    try {
      const userInputImageUrl = await uploadImagesToFirestore(item, index)
      console.log('userInputImageUrls', userInputImageUrl)

      const updatedUrl = String(userInputImageUrl)
      console.log('updatedUrl', updatedUrl)
      const updatedUserInputValue = [...userInputValue] // 配列のコピーを作成
      updatedUserInputValue[index] = {
        ...updatedUserInputValue[index],
        url: updatedUrl // URLフィールドを更新
      }
      console.log('更新するuserInputvalue', updatedUserInputValue)

      // Firestore ドキュメントを更新
      await updateDoc(messageDocRef, {
        userInputValue: updatedUserInputValue
      })

      // textタイプは除き画像オブジェクトのみ抽出
      const updatedMessage = hidedUserMessage.content.filter((item) => item.type === 'image_url')

      console.log('updatedMessage', imageIndex, updatedMessage, updatedMessage[imageIndex])

      if (updatedMessage[imageIndex].type === 'image_url') {
        updatedMessage[imageIndex].image_url.url = updatedUrl
      }

      userInputValue[index].url = updatedUrl
      // Firestoreドキュメントを更新
      await updateDoc(hidedUserMessage.ref, {
        message: updatedMessage
      })

      setChatData((prevChatData) => {
        console.log('updatedChatData開始')

        const updatedChatData = [...prevChatData] // chatDataのコピーを作成
        updatedChatData[1] = {
          ...updatedChatData[1], // 既存のデータを保持
          content: updatedMessage // messageフィールドを更新
        }
        console.log(updatedChatData)
        return updatedChatData
      })

      // 更新完了後にコンソールログを出力
      console.log('Firestoreドキュメントが正常に更新されました。')
    } catch (error) {
      console.error('更新時にエラーが発生しました:', error)
    }
    setEditingIndex(null)
    // handleChange(`panel-${index}`)
  }

  return (
    <>
      {isLoading ? (
        <div className={ChatBubbleModule[role]}>
          <ThreeDots
            height="60"
            width="60"
            radius="9"
            color={threeDotsColor}
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <div className={ChatBubbleModule[role]} id="system-bubble">
          {userInputValue.map((item, index) => {
            const formQuestion = getFormQuestion(item.key)
            const isChangeable = formQuestion?.changeable
            return (
              <Accordion
                key={index}
                expanded={alwaysExpanded || expanded === `panel-${index}`}
                onChange={handleChange(`panel-${index}`)}
                className={ChatBubbleModule.accordion}
                id={'input'}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel-${index}-content`}
                  id={`panel-${index}-header`}
                >
                  <Typography className={ChatBubbleModule.ellipsis} sx={{ width: '30%', flexShrink: 0 }}>
                    {/* {formQuestion && formQuestion.title} */}
                    {item && item.title}
                  </Typography>
                  <Typography className={ChatBubbleModule.ellipsis} sx={{ color: 'text.secondary' }}>
                    {item.type === 'image' ? (
                      <span
                        onClick={() => handleOpenModal(item.url)}
                        style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                      >
                        {item.title}
                      </span>
                    ) : item.value ? (
                      item.value
                    ) : (
                      'なし'
                    )}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {editingIndex === index ? (
                    formQuestion?.type === 'pulldown' ? (
                      <div>
                        {formQuestion.mandatory && <span className={ChatBubbleModule['mandatory']}>※必須項目</span>}
                        <Select
                          value={editValue}
                          onChange={(e) => setEditValue(e.target.value)}
                          fullWidth
                          MenuProps={{
                            PaperProps: {
                              style: {
                                whiteSpace: 'normal',
                                wordBreak: 'break-word'
                              }
                            }
                          }}
                        >
                          {formQuestion.options.map((option, i) => (
                            <MenuItem
                              key={i}
                              // value={option.value}
                              value={option.value ? option.value : option.displayName}
                              style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
                            >
                              {option.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                        <IconButton onClick={() => handleSave(index)}>
                          <CheckIcon />
                        </IconButton>
                        <IconButton onClick={handleCancel}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    ) : formQuestion?.type === 'image' ? (
                      <>
                        <SelectImage
                          questionData={formQuestion}
                          onValueChange={(file) => handleImageFileChange(file, index)} // Pass the file to save handler
                        />
                        <>
                          <IconButton onClick={() => saveImageChange(item, index)}>
                            <CheckIcon />
                          </IconButton>
                          <IconButton onClick={handleCancel}>
                            <CloseIcon />
                          </IconButton>
                        </>
                      </>
                    ) : (
                      <div>
                        {formQuestion.mandatory && <span className={ChatBubbleModule['mandatory']}>※必須項目</span>}
                        <TextField
                          value={editValue}
                          onChange={(e) => setEditValue(e.target.value)}
                          fullWidth
                          multiline
                          rows={calculateRows(item.value)}
                          variant="outlined"
                        />
                        {formQuestion.answerCharacterLimit === true && formQuestion.answerMaxLength > 0 && (
                          <span className={ChatBubbleModule['char-limit']}>
                            {editValue.length}/{formQuestion.answerMaxLength}文字まで
                          </span>
                        )}
                        <IconButton onClick={() => handleSave(index)}>
                          <CheckIcon />
                        </IconButton>
                        <IconButton onClick={handleCancel}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    )
                  ) : (
                    // <Typography>{item.value ? item.value : 'なし'}</Typography>
                    <Typography>
                      {item.value ? (
                        item.value
                      ) : item.type === 'image' && item.url ? (
                        <img
                          src={item.url}
                          alt="image"
                          style={{ maxHeight: 60, margin: 2, borderRadius: 6, cursor: 'pointer' }}
                          onClick={() => handleOpenModal(item.url)}
                        />
                      ) : (
                        'なし'
                      )}
                    </Typography>
                  )}
                  {isChangeable && editingIndex !== index && readOnly === false ? (
                    <div className={ChatBubbleModule['menu-icon-container']}>
                      <EditIcon
                        className={ChatBubbleModule['menu-icon']}
                        onClick={() => handleEditClick(index, item.value)}
                      />
                    </div>
                  ) : !isChangeable && editingIndex !== index && readOnly === false ? (
                    <Typography className={ChatBubbleModule['non-changeable-text']}>編集不可</Typography>
                  ) : null}
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
      )}
      {modalImageUrl && <ModalForPicture image={modalImageUrl} onClose={handleCloseModal} />}
    </>
  )
}

export default ChatBubbleForSystem
