import SignUp from '../../components/SignUp/SignUp'
import React from 'react'
import LoginModule from './login.module.scss'

function Login() {
  return (
    <div className={LoginModule['main']}>
      <div className={LoginModule['title-container']}>
        <p className={LoginModule['service-title']}>スクールAI</p>
      </div>
      <SignUp path={'/'} />
    </div>
  )
}
export default Login
