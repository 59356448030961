import { useState, useEffect } from 'react'
import { db, auth, storage, functions } from '../../firebase'
import { doc, getDocs, collection, getDoc, collectionGroup, where, query } from 'firebase/firestore'
import UserInfoModule from './Modes.module.scss'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { ColorRing } from 'react-loader-spinner'
import ModeSearch from '../ModeSearch/ModeSearch'
import ai from '../../assets/ai.png'
import { httpsCallable } from 'firebase/functions'
import LoadingGrid from '../LoadingGrid/LoadingGrid'
import { isWithinTimeWindow } from '../../utils/timeWindowUtils'
function Modes({ serviceType }) {
  const [user] = useAuthState(auth)
  const navigate = useNavigate()
  const [modes, setModes] = useState([])
  const [filteredModes, setFilteredModes] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [isGettingParentSchoolModes, setIsGettingParentSchoolModes] = useState(false)

  const getSchoolData = async (paramSchoolId) => {
    try {
      if (!!paramSchoolId) {
        const getSchoolInfoOfMingaku = httpsCallable(functions, 'get_school_info_of_mingaku')
        const schoolDataOfMingaku = await getSchoolInfoOfMingaku({ schoolId: paramSchoolId }) // schoolAIのschool情報を全件取得用
        const { data, configData } = schoolDataOfMingaku.data
        const { role = [], parentSchools = [], childrenSchools = [] } = configData
        return { role: role ? role[0] : '', parentSchools, childrenSchools }
      }
    } catch (error) {
      console.error('Error fetching document IDs:', error)
    }
  }

  useEffect(() => {
    setLoading(true)
    if (user) {
      const userDocRef = doc(db, 'users', user.uid)
      getDoc(userDocRef).then(async (userDocSnapshot) => {
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data()
          const type = userData.type
          const schoolId = userData.schoolDocId
          // ユーザーのスクールもしくはadminのモードのref
          let promptsCollectionRef
          let additionalPrompts = []
          let schoolsCollectionRef = null

          if (type === 'mingaku' && schoolId) {
            const mingakuDocRef = doc(db, 'externalServices', 'mingaku')
            schoolsCollectionRef = collection(mingakuDocRef, 'schools')
            // スクールであれば、スクールのpromptsコレクションのrefをセット
            promptsCollectionRef = collection(mingakuDocRef, 'schools', schoolId, 'prompts')

            // ①adminと全スクールのモードから、selectedSchoolsにschoolIdのスクールが登録されているモードを取得するロジック
            const selectedSchoolsQuery = query(
              collectionGroup(db, 'selectedSchools'),
              where('id', '==', schoolId),
              where('active', '==', true)
            )

            const selectedSchoolsPromise = getDocs(selectedSchoolsQuery)
            // ②admin全モードを取得するロジック
            const gptPromptDocRef = doc(db, 'service_type', 'gpt-teacher')
            const adminPromptsCollectionRef = collection(gptPromptDocRef, 'prompts')
            const adminPromptsPromise = getDocs(adminPromptsCollectionRef)

            try {
              const [selectedSchoolsSnapshot, adminPromptsSnapshot] = await Promise.all([
                selectedSchoolsPromise,
                adminPromptsPromise
              ])
              console.log('selectedSchoolsSnapshot', selectedSchoolsSnapshot)

              // ①のデータを取得
              const additionalPromptsFromSelectedSchools = await Promise.all(
                selectedSchoolsSnapshot.docs.map(async (doc) => {
                  const promptDocRef = doc.ref.parent.parent
                  const promptSnapshot = await getDoc(promptDocRef)
                  if (promptSnapshot.exists() && promptSnapshot.data().enable === true) {
                    return { id: promptSnapshot.id, ...promptSnapshot.data(), common: true }
                  } else {
                    return null
                  }
                })
              )

              // ②のデータを取得
              let additionalPromptsFromAdmin = adminPromptsSnapshot.docs.flatMap((doc) => doc.data())
              // const adminPromptsWithoutSelectedSchoolsPromises = additionalPromptsFromAdmin
              //   .filter((prompt) => prompt.availableAllSchools !== false)
              //   .map(async (prompt) => {
              //     const selectedSchoolsRef = collection(prompt.ref, 'selectedSchools')
              //     const selectedSchoolsSnapshot = await getDocs(selectedSchoolsRef)
              //     if (selectedSchoolsSnapshot.docs.length === 0) {
              //       return prompt
              //     } else {
              //       return null
              //     }
              //   })

              // const promptsWithoutSelectedSchoolsResults = await Promise.all(adminPromptsWithoutSelectedSchoolsPromises)

              const adminPromptsWithoutSelectedSchoolsPromises = additionalPromptsFromAdmin.map(async (prompt) => {
                if (prompt.availableAllSchools === true) {
                  return {
                    ...prompt,
                    editable: false,
                    active: true,
                    parentSchoolId: 'gpt-teacher'
                  }
                } else if (!prompt.hasOwnProperty('availableAllSchools')) {
                  const selectedSchoolsRef = collection(prompt.ref, 'selectedSchools')
                  const selectedSchoolsSnapshot = await getDocs(selectedSchoolsRef)
                  if (selectedSchoolsSnapshot.docs.length === 0) {
                    return {
                      ...prompt,
                      editable: false,
                      active: true,
                      parentSchoolId: 'gpt-teacher'
                    }
                  }
                } else {
                  return null
                }
              })

              const promptsWithoutSelectedSchoolsResults = (
                await Promise.all(adminPromptsWithoutSelectedSchoolsPromises)
              ).filter(Boolean)

              const promptsWithoutSelectedSchools = promptsWithoutSelectedSchoolsResults.filter(
                (prompt) => prompt !== null
              )
              const filteredPromptsWithoutSelectedSchools = promptsWithoutSelectedSchools.filter(
                (prompt) => prompt.availableAllSchools !== undefined
              )
              additionalPrompts = additionalPromptsFromSelectedSchools.concat(filteredPromptsWithoutSelectedSchools)
            } catch (error) {
              console.error('Error fetching data:', error)
            }
          } else {
            // adminであれば、adminのpromptsコレクションのrefをセット
            promptsCollectionRef = collection(db, 'service_type', serviceType, 'prompts')
          }

          try {
            // ③ユーザーのスクールのモードを取得
            const QuerySnapshot = await getDocs(promptsCollectionRef)
            let enablesModes = QuerySnapshot.docs.filter((doc) => doc.data().enable).map((doc) => ({ ...doc.data() }))
            // additionalPromptsと被っているものは排除
            // console.log('①-1', enablesModes)
            // console.log('①-2', additionalPrompts)
            additionalPrompts = additionalPrompts.filter((ap) => ap && !enablesModes.some((em) => em.id === ap.id))
            enablesModes = await Promise.all(
              [...enablesModes, ...additionalPrompts]
                .filter((mode) => mode.enable !== false)
                .map(async (mode) => {
                  if (await isWithinTimeWindow(null, mode.ref)) {
                    return mode
                  }
                  return null
                })
            )
              .then((modes) => modes.filter((mode) => mode !== null))
              .then((modes) => modes.sort((a, b) => (a.prompt_order < b.prompt_order ? -1 : 1)))
            const uniqueResults = enablesModes.filter(
              (result, index, self) => index === self.findIndex((r) => r.ref.path === result.ref.path)
            )
            setModes(uniqueResults)

            setLoading(false)
            if (type === 'mingaku' && schoolId) {
              let parentPromptsPromises = []
              setIsGettingParentSchoolModes(true)
              const { parentSchools } = await getSchoolData(schoolId)
              if (parentSchools && parentSchools.length > 0) {
                parentPromptsPromises = parentSchools.map(async (parentSchool) => {
                  const parentPromptDocRef = doc(schoolsCollectionRef, parentSchool.schoolDocId)
                  const parentPromptsCollectionRef = collection(parentPromptDocRef, 'prompts')
                  const querySnapshot = await getDocs(parentPromptsCollectionRef)
                  return querySnapshot.docs
                    .filter((doc) => doc.data().availableAllSchools !== false)
                    .map((doc) => ({
                      ...doc.data(),
                      id: doc.id,
                      editable: false,
                      active: true,
                      parentSchoolId: parentSchool.schoolDocId
                    }))
                })
                // ④親スクールのavailableAllSchoolsがtrueのモードを取得
                const parentPromptsResults = await Promise.all(parentPromptsPromises)
                let parentAllPrompts = []
                for (const parentPrompts of parentPromptsResults) {
                  parentAllPrompts.push(...parentPrompts)
                }
                const aaa = parentAllPrompts.filter((prompt) => prompt.availableAllSchools !== false)
                const promptsWithoutSelectedSchoolsPromises = aaa
                  .filter((prompt) => prompt.availableAllSchools !== false)
                  .map(async (prompt) => {
                    if (prompt.availableAllSchools === true) {
                      return {
                        ...prompt,
                        editable: false,
                        active: true
                      }
                    } else if (!prompt.hasOwnProperty('availableAllSchools')) {
                      const selectedSchoolsRef = collection(prompt.ref, 'selectedSchools')
                      const selectedSchoolsSnapshot = await getDocs(selectedSchoolsRef)
                      if (selectedSchoolsSnapshot.docs.length === 0) {
                        return {
                          ...prompt,
                          editable: false,
                          active: true
                        }
                      }
                    } else {
                      return null
                    }
                  })
                const promptsWithoutSelectedSchoolsResults = await Promise.all(promptsWithoutSelectedSchoolsPromises)
                const promptsWithoutSelectedSchools = promptsWithoutSelectedSchoolsResults.filter(
                  (prompt) => prompt !== null
                )
                const filteredPromptsWithoutSelectedSchools = promptsWithoutSelectedSchools.filter(
                  (prompt) => prompt.availableAllSchools !== undefined
                )

                const parentFilteredModes = await Promise.all(
                  filteredPromptsWithoutSelectedSchools.map(async (mode) => {
                    const withinTimeWindow = await isWithinTimeWindow(null, mode.ref)
                    if (mode.enable !== false && withinTimeWindow) {
                      return mode
                    }
                    return null
                  })
                )

                setModes((prev) => {
                  // Filter out modes from the previous state that are disabled
                  const filteredPrev = prev.filter((mode) => mode.enable !== false)

                  // Combine filtered previous modes with parentFilteredModes, excluding null values
                  const combinedModes = [...filteredPrev, ...parentFilteredModes.filter((mode) => mode !== null)]

                  // Remove duplicates based on ref.path
                  const uniqueCombinedModes = combinedModes.filter(
                    (mode, index, self) => index === self.findIndex((m) => m.ref.path === mode.ref.path)
                  )

                  return uniqueCombinedModes
                })
              }
              setIsGettingParentSchoolModes(false)
            }
          } catch (error) {
            console.error('Error fetching data:', error)
          }
        } else {
          // Handle if userDocSnapshot doesn't exist
        }
      })
    }
  }, [user, serviceType])

  useEffect(() => {
    const filtered = modes.filter((mode) => mode.prompt_title.toLowerCase().includes(searchText.toLowerCase()))
    setFilteredModes(filtered)
  }, [searchText, modes])

  const selectMode = async (mode) => {
    navigate(
      `/redirect?mode=${mode.ref.id}&common=${mode.common ? mode.common.toString() : 'false'}&showheader=true&ref=${
        mode.ref.path
      }`,
      {
        state: { common: mode.common ? mode.common : false }
      }
    )
  }

  return (
    <>
      {loading ? (
        <LoadingGrid />
      ) : (
        <div className={UserInfoModule['modeContainer']}>
          {modes.length === 0 ? (
            <div>まだモードがないようです...</div>
          ) : (
            <>
              <ModeSearch placeholderMessage="モードを検索..." searchText={searchText} setSearchText={setSearchText} />
              <div className={UserInfoModule['modes']}>
                {filteredModes.map((mode, index) => (
                  <div className={UserInfoModule.mode} key={index}>
                    <div className={UserInfoModule.title}>
                      <div className={UserInfoModule['rounded-icon']}>
                        <img src={mode.iconImageUrl ? mode.iconImageUrl : ai}></img>
                      </div>
                      <h4 className={UserInfoModule.title}>{mode.prompt_title}</h4>
                    </div>
                    <div className={UserInfoModule.cardBottomContainer}>
                      {mode.prompt_description && mode.prompt_description !== '' ? (
                        <div className={UserInfoModule.description}>{mode.prompt_description}</div>
                      ) : (
                        <div className={UserInfoModule.description}>{''}</div>
                      )}
                      <div onClick={() => selectMode(mode)} className={UserInfoModule.start}>
                        はじめる
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {isGettingParentSchoolModes && (
                <div className={UserInfoModule['loading-container']}>
                  <ColorRing
                    visible={true}
                    height="50"
                    width="50"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#5bc6e1', '#60caf4', '#6aa3f8', '#81b2bd', '#849b96']}
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}

export default Modes
