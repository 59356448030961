import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { db, auth } from '../../firebase'
import { doc, setDoc } from 'firebase/firestore'
import c from './signup_for_mingaku_tutor.module.scss'
import { useLocation } from 'react-router-dom'
import OutlinedInput from '@mui/material/OutlinedInput'

function SignupForMingakuTutor() {
  const { state } = useLocation()
  const { email, redirectPath, linkageData, tutorDocData, schoolIdfromQueryParam, muid, role } = state ?? ''
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const signUp = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        //登録が成功した時の処理
        await setDoc(doc(db, 'users', userCredential.user.uid), {
          userId: userCredential.user.uid,
          email: userCredential.user.email ?? '',
          createdAt: userCredential.user.metadata.creationTime ?? null,
          lastSignInAt: userCredential.user.metadata.lastSignInTime ?? null,
          ...linkageData // みんがくアカウントとの連携情報
        })
        if (role === 'tutor') {
          // みんがく先生用
          const schoolDocRef = doc(db, 'externalServices', 'mingaku', 'schools', schoolIdfromQueryParam)
          setDoc(schoolDocRef, { docId: schoolIdfromQueryParam }, { merge: true })
          const tutorDocRef = doc(schoolDocRef, 'tutors', muid)
          await setDoc(tutorDocRef, { ...tutorDocData }, { merge: true })
        }
        alert('登録に成功しました。')
        navigate(redirectPath)
      })
      .catch((error) => {
        if (error.code === 'auth/email-already-in-use') {
          alert('このメールアドレスはすでに使用されています')
        } else if (error.code === 'auth/invalid-email') {
          alert('メールアドレスの形式が正しくありません')
        } else if (error.code === 'auth/user-disabled') {
          alert('サービスの利用が停止されています')
        } else if (error.code === 'auth/weak-password') {
          alert('パスワードは6文字以上にしてください')
        } else if (error.code === 'auth/popup-blocked') {
          alert('認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください')
        } else if (
          error.code === 'auth/operation-not-supported-in-this-environment' ||
          error.code === 'auth/auth-domain-config-required' ||
          error.code === 'auth/operation-not-allowed' ||
          error.code === 'auth/unauthorized-domain'
        ) {
          alert('現在この認証方法はご利用頂けません')
        } else if (error.code === 'auth/requires-recent-login') {
          alert('認証の有効期限が切れています')
        } else {
          alert(error.code)
        }
      })
  }

  return (
    <div className={c['main']}>
      <div className={c['title-container']}>
        <p className={c['service-title']}>
          アカウント作成画面
          <br />
          {role === 'admin' ? '（みんがく管理人用）' : '（先生用）'}
        </p>
      </div>
      <p>
        みんがくアカウントのEメールアドレス「{email}」でスクールAIアカウントを作成してください。
        <br />
        （みんがくアカウントとスクールAIアカウントを連携するためには、どちらも同じメールアドレスである必要があります。）
      </p>
      <div className={c.input}>
        <p className={c.inputtitle}>メールアドレス(編集不可)</p>
        <OutlinedInput
          className={c.inputbox}
          type="email"
          sx={{
            width: '70%',
            margin: '0 15%',
            height: '40px',
            backgroundColor: 'rgba(215, 215, 215, 1)',
            color: 'rgba(127, 127, 127, 1) 1)'
          }}
          value={email}
        />
      </div>
      <div className={c.input}>
        <p className={c.inputtitle}>パスワード</p>
        <OutlinedInput
          className={c.inputbox}
          type="password"
          sx={{ width: '70%', margin: '0 15%', height: '40px' }}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </div>
      <div>
        <button className={c.button} onClick={signUp}>
          アカウントを作成する
        </button>
      </div>
    </div>
  )
}
export default SignupForMingakuTutor
