import React, { useEffect, useState } from 'react'
import ModeSelectModule from './mode_select.module.scss'
import Footer from '../../components/Common/Footer'
import Header from '../../components/Header/Header'
import Modes from '../../components/Modes/Modes'
import AppModule from '../../App.module.scss'

function ModeSelect() {
  // useAuthCheck()
  return (
    <div className={AppModule.main}>
      <Header title="モード一覧" />
      <div className={ModeSelectModule.container}>
        <Modes chatPath={'/chat'} conversationType={'conversations'} serviceType="gpt-teacher" checkAudioPage={false} />
      </div>
      <Footer homePath="/" addChatPath="/mode" userPath="/mypage" />
    </div>
  )
}
export default ModeSelect
