import React, { useState, useEffect } from 'react'
import TopModule from './ChatList.module.scss'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { db, functions } from '../../firebase'
import { useLocation, useHistory } from 'react-router-dom'
import { getUserDataFromMingaku } from '../../utils/getUserDataFromMingaku'
import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  orderBy,
  deleteDoc,
  where,
  limit,
  startAfter
} from 'firebase/firestore'
import PsychologyIcon from '@mui/icons-material/Psychology'
import FaceIcon from '@mui/icons-material/Face'
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useSearchParams } from 'react-router-dom'
import { _logs_filter_periods, _number_of_data } from '../../localConstant'
import { httpsCallable } from 'firebase/functions'
import ForumIcon from '@mui/icons-material/Forum'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import AddIcon from '@mui/icons-material/Add'
import LoadingGrid from '../LoadingGrid/LoadingGrid'

function ChatHistoryScreen(props) {
  const {
    conversationType,
    modePath,
    onSelectConversation,
    onDefaultConversationSet,
    onMenuItemClick,
    onNoConversation,
    readOnly,
    submittionBox,
    submitterUserId,
    onDefaultSubmitter
  } = props
  // useAuthCheck()

  const currentPath = window.location.pathname
  const [searchParams] = useSearchParams()
  const filter_id = searchParams.get('filter')
  const [filterId, setFilterId] = useState(filter_id ? filter_id : '0')
  const [initialLoad, setInitialLoad] = useState(true)
  const conversation_id = searchParams.get('conversation_id')
  const schoolId = searchParams.get('school_id')
  const studentId = searchParams.get('student_id')
  const [schoolIdName, setSchoolIdName] = useState({}) // schoolIdとschoolNameの対応表

  const modeNameClassName = 'modeName'
  const chatListClassName = 'chat-list'
  const unixtime2datetime = (unixtime) => {
    const time = new Date(unixtime)
    const today = new Date()
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    if (time.toLocaleDateString() === today.toLocaleDateString()) {
      return time.toLocaleTimeString().split(':')[0] + ':' + time.toLocaleTimeString().split(':')[1]
    } else if (time.toLocaleDateString() === yesterday.toLocaleDateString()) {
      return '昨日'
    } else {
      return `${time.toLocaleDateString()} ${time.toLocaleTimeString().split(':')[0]}:${
        time.toLocaleTimeString().split(':')[1]
      }`
    }
  }
  const unixtime2datetimeForSubmission = (unixtime) => {
    const time = new Date(unixtime)
    // 日本のロケールを指定し、短縮された曜日も含むようにフォーマットオプションを設定
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }
    const dateWithShortDayOfWeek = time.toLocaleDateString('ja-JP', options)

    // 括弧をつけた曜日を作成
    const dayOfWeekWithBrackets = dateWithShortDayOfWeek.replace(/曜日$/, '）').replace(/(.*)\s(.*)/, '$1（$2')

    // 時間を '時:分' の形式で取得
    const hour = time.getHours().toString().padStart(2, '0')
    const minute = time.getMinutes().toString().padStart(2, '0')

    return `${dayOfWeekWithBrackets} ${hour}:${minute}`
  }

  const navigate = useNavigate()
  const [user] = useAuthState(auth)
  const [loading, setLoading] = useState(true)
  const [conversations, setConversations] = useState([])
  const [defaultConversation, setDefaultConversation] = useState(null)
  const [selectedConversationId, setSelectedConversationId] = useState(null)
  const [filterStartDate, setFilterStartDate] = useState(
    filterId && toLocalISOStringUnixtime(getUnixTimePeriod(_logs_filter_periods[Number(filterId)]).startUnixtime)
  )
  const [filterEndDate, setFilterEndDate] = useState(
    filterId && toLocalISOStringUnixtime(getUnixTimePeriod(_logs_filter_periods[Number(filterId)]).endUnixtime)
  )

  const getSchoolsInfo = async () => {
    const getSchools = httpsCallable(functions, 'get_schools_info')
    const res = await getSchools() // schoolAIのschook情報を全件取得用
    const { data } = res
    return data
  }
  useEffect(() => {
    ;(async () => {
      const { schoolInfo } = await getSchoolsInfo() // schoolAIのschool情報を全件取得用
      const schoolsOfSchoolAI = schoolInfo.filter((school) => !!school.data) // dataがないschoolは除外
      let schoolIdName = {} // schoolIdとschoolNameの対応表
      schoolsOfSchoolAI.map((school) => {
        return (schoolIdName[school.schoolId] = school.data.name) //
      })
      setSchoolIdName(schoolIdName)
    })()
  }, [])

  useEffect(() => {
    const newFilterId = searchParams.get('filter')
    if (newFilterId !== filterId) {
      setFilterId(newFilterId)
      setInitialLoad(true)
    }
  }, [searchParams, filterId]) // searchParamsが変更されたときに実行
  useEffect(() => {
    setFilterStartDate(
      toLocalISOStringUnixtime(getUnixTimePeriod(_logs_filter_periods[Number(filterId)]).startUnixtime)
    )
    setFilterEndDate(toLocalISOStringUnixtime(getUnixTimePeriod(_logs_filter_periods[Number(filterId)]).endUnixtime))
  }, [filterId]) // filterIdが変更されたときに実行

  const deleteConversation = async (e, conversation, i) => {
    e.stopPropagation()
    if (window.confirm('削除しますか？')) {
      const conversationDocRef = conversation.conversationDocRef

      // messages サブコレクション内のすべてのメッセージを削除
      const messagesCollectionRef = collection(conversationDocRef, 'messages')
      const querySnapshot = await getDocs(messagesCollectionRef)

      const deletePromises = querySnapshot.docs.map((doc) => deleteDoc(doc.ref))
      await Promise.all(deletePromises)

      // 会話ドキュメント自体を削除
      await deleteDoc(conversationDocRef)

      const conversationsAfterDeletion = conversations.filter((item, index) => index !== i)
      setConversations(conversationsAfterDeletion)
    }
  }

  const [hasMore, setHasMore] = useState(true)
  const getConversations = async (q) => {
    const querySnapshot = await getDocs(q)
    // setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1])
    if (querySnapshot.docs.length > 0) {
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1])
      setHasMore(querySnapshot.docs.length === _number_of_data[0])
    } else {
      setHasMore(false)
    }
    const conversations = querySnapshot.docs.map((item) => {
      if (currentPath === '/submitted_conversations') {
        return {
          conversationId: item.id,
          originalConversationId: item.data().conversationId,
          conversationRef: item.ref,
          type: item.data().type ? item.data().type : 'conversation',
          submissionTitle: item.data().submission_title ? item.data().submission_title : 'タイトル無し',
          submittedAt: item.data().submittedAt ? item.data().submittedAt : ''
        }
      } else {
        return {
          conversationId: item.data().conversationId,
          conversationRef: item.ref
        }
      }
    })
    return conversations
  }

  useEffect(() => {
    // ホーム画面・チャット画面用
    if (readOnly === true) return
    setLoading(true)
    ;(async () => {
      if (user === null) {
        // ユーザーがnullの場合は何もしない
      } else {
        const userDocRef = doc(db, 'users', user.uid)
        const conversationsDocRef = collection(userDocRef, conversationType)
        const q = query(conversationsDocRef, orderBy('updatedAt', 'desc'), limit(_number_of_data[0])) // ここでlimitを設定

        const conversations = await getConversations(q)
        const conversationWithLatestInfo = await getConversationWithLatestInfo(conversations, user.uid)
        const filteredConversationWithLatestInfo = conversationWithLatestInfo
        const result = filteredConversationWithLatestInfo.sort(function (a, b) {
          return a.lastMessageTime > b.lastMessageTime ? -1 : 1
        })

        const currentUrl = currentPath + window.location.search
        if (conversation_id) {
          const conversation = result.find((c) => c.conversationId === conversation_id)
          if (conversation) {
            setDefaultConversation(conversation)
            onDefaultConversationSet(conversation)
            if (currentUrl !== '/') {
              setSelectedConversationId(conversation.conversationId)
            }
          }
        } else if (result.length > 0) {
          setDefaultConversation(result[0])
          onDefaultConversationSet(result[0])
          if (currentUrl !== '/') {
            setSelectedConversationId(result[0].conversationId)
          }
        } else {
          onNoConversation(true)
        }
        setConversations(result)
        setLoading(false)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const fetchDocumentIds = async (paramSchoolId) => {
    try {
      const querySnapshot = await getDocs(collection(db, '/externalServices/mingaku/schools'))
      // const ids = querySnapshot.docs.map((doc) => doc.id)
      if (!!paramSchoolId) {
        const getSchoolInfoOfMingaku = httpsCallable(functions, 'get_school_info_of_mingaku')
        const schoolDataOfMingaku = await getSchoolInfoOfMingaku({ schoolId: paramSchoolId }) // schoolAIのschool情報を全件取得用
        const { data, configData } = schoolDataOfMingaku.data
        const { role, parentSchools, childrenSchools } = configData
        return { role, parentSchools, childrenSchools }
      }
    } catch (error) {
      console.error('Error fetching document IDs:', error)
    }
  }

  useEffect(() => {
    // 会話ログ用
    if (readOnly === false) return

    if (currentPath === '/submitted_conversations') return
    setLoading(true)
    ;(async () => {
      const usersCollectionRef = collection(db, 'users')
      const usersQuery = query(usersCollectionRef)
      const usersSnapshot = await getDocs(usersQuery)
      const allConversations = []
      const getConversationWithLatestInfo = async (conversations, userData, userDoc) => {
        let conversationWithLatestInfo = []
        const mingakuUserData = await getUserDataFromMingaku(userDoc)
        await Promise.all(
          conversations.map(async (item) => {
            const conversationId = item.conversationId
            const conversationDocRef = item.conversationRef
            const conversationDocSnapshot = await getDoc(conversationDocRef)
            let mode = ''
            let largeIcon = false
            let displayInputContents = true
            if (!!conversationDocSnapshot.data().promptRef) {
              const promptDocRef = conversationDocSnapshot.data().promptRef
              const prompt = await getDoc(promptDocRef)
              if (!!prompt.data()) {
                mode = prompt.data() ? prompt.data().prompt_title : '削除されたモード'
                largeIcon = prompt.data().hasOwnProperty('largeIcon') ? prompt.data().largeIcon : false
                displayInputContents = prompt.data().hasOwnProperty('displayInputContents')
                  ? prompt.data().displayInputContents
                  : false
              }
            }
            const messagesCollectionRef = collection(conversationDocRef, 'messages')
            const q = query(messagesCollectionRef, orderBy('createdAt', 'asc'))
            const querySnapshot = await getDocs(q)
            const chatMessages = querySnapshot.docs.map((item) => {
              return {
                content: item.data().message,
                role: item.data().role,
                messageTime: item.data().createdAt,
                model: item.data().model,
                userInput: item.data().userInput && item.data().userInput,
                userInputValue: item.data().userInputValue && item.data().userInputValue
              }
            })

            if (chatMessages.length > 0) {
              const lastChatMessage = chatMessages[chatMessages.length - 1]
              const systemPromptOfChatMessage = chatMessages[0]
              const [role, model, content, messageTime, userInput, userInputValue] = await Promise.all([
                lastChatMessage.role,
                lastChatMessage.model,
                lastChatMessage.content,
                lastChatMessage.messageTime,
                systemPromptOfChatMessage.userInput,
                systemPromptOfChatMessage.userInputValue
              ])

              conversationWithLatestInfo.push({
                conversationId: conversationId,
                mode: mode ? mode : '問答モード',
                conversationDocRef: conversationDocRef,
                lastChatModel: model,
                lastChatMessage: Array.isArray(content) ? content[0].text : content,
                lastChatRole: role,
                lastMessageTime: messageTime,
                userName: mingakuUserData.name,
                userId: userData.userId,
                largeIcon: largeIcon,
                displayInputContents,
                schoolId: userData.schoolDocId ? userData.schoolDocId : '',
                userInput,
                userInputValue
              })
            }
          })
        )
        return conversationWithLatestInfo
      }

      const getConversations = async (q) => {
        const querySnapshot = await getDocs(q)
        const conversations = querySnapshot.docs.map((item) => {
          return {
            conversationId: item.data().conversationId,
            conversationRef: item.ref
          }
        })
        return conversations
      }

      let _d = usersSnapshot.docs
      if (schoolId && !studentId) {
        const { childrenSchools } = await fetchDocumentIds(schoolId)
        let filteringSchools
        if (childrenSchools) {
          // setChildrenData(childrenSchools)
          filteringSchools = [...childrenSchools.map((school) => school.childSchoolDocId), schoolId]
        } else {
          filteringSchools = [schoolId]
        }

        const filteredUserDocs = usersSnapshot.docs.filter((doc) => filteringSchools.includes(doc.data().schoolDocId))

        // const filteredUserDocs = usersSnapshot.docs.filter((doc) => doc.data().schoolDocId === schoolId)
        _d = filteredUserDocs
      } else if (schoolId && studentId) {
        const filteredUserDocs = usersSnapshot.docs.filter((doc) => doc.data().userId === studentId)
        _d = filteredUserDocs
      }
      const processUserConversations = async (
        userDoc,
        filterStartDate,
        filterEndDate,
        conversationType,
        schoolId,
        submittionBox
      ) => {
        let conversationsDocRef
        let userData
        if (userDoc) {
          userData = userDoc.data()
          conversationsDocRef = collection(userDoc.ref, conversationType)
        } else {
          conversationsDocRef = collection(
            db,
            `/externalServices/mingaku/schools/${schoolId}/submissionBoxes/${submittionBox}/submitters/${submitterUserId}/conversations`
          )
        }

        let conversationQueryForUpdatedAt
        const startUnixtime = new Date(filterStartDate).getTime()
        const endUnixtime = new Date(filterEndDate).getTime()

        if ((!startUnixtime && !endUnixtime) || studentId) {
          conversationQueryForUpdatedAt = query(conversationsDocRef, orderBy('updatedAt', 'desc'))
        } else {
          conversationQueryForUpdatedAt = query(
            conversationsDocRef,
            where('updatedAt', '>=', startUnixtime),
            where('updatedAt', '<=', endUnixtime),
            orderBy('updatedAt', 'desc')
          )
        }

        const conversations = await getConversations(conversationQueryForUpdatedAt)
        let conversationWithLatestInfo
        if (userData) {
          conversationWithLatestInfo = await getConversationWithLatestInfo(conversations, userData, userDoc)
        } else {
          conversationWithLatestInfo = await getConversationWithLatestInfo(conversations)
        }

        return conversationWithLatestInfo
      }
      if (currentPath === '/thread_list') {
        for (const userDoc of _d) {
          const conversationWithLatestInfo = await processUserConversations(
            userDoc,
            filterStartDate,
            filterEndDate,
            conversationType,
            schoolId,
            submittionBox
          )
          allConversations.push(...conversationWithLatestInfo)
        }
      }

      const result = allConversations.sort((a, b) => (a.lastMessageTime > b.lastMessageTime ? -1 : 1))

      if (conversation_id) {
        const conversation = result.find((c) => c.conversationId === conversation_id)
        if (conversation) {
          setDefaultConversation(conversation) // 要素が見つかった場合、それをデフォルト会話としてセット
          onDefaultConversationSet(conversation) // 親コンポーネントにデフォルト会話を通知
          setSelectedConversationId(conversation.conversationId)
        }
      } else if (result.length > 0) {
        setDefaultConversation(result[0]) // 0番目の会話をデフォルトとしてセット
        onDefaultConversationSet(result[0]) // 親コンポーネントにデフォルト会話を通知
        setSelectedConversationId(result[0].conversationId)
      } else {
        onNoConversation(true)
      }
      console.log('resutl', result)
      setConversations(result)
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter_id, searchParams])

  useEffect(() => {
    // 提出画面用
    if (currentPath !== '/submitted_conversations') return
    setLoading(true)
    ;(async () => {
      if (!submitterUserId) {
        setLoading(false)
      } else {
        const conversationsDocRef = collection(
          db,
          `/externalServices/mingaku/schools/${schoolId}/submissionBoxes/${submittionBox}/submitters/${submitterUserId}/conversations`
        )
        const q = query(conversationsDocRef, orderBy('submittedAt', 'desc'), limit(_number_of_data[0]))

        const conversations = await getConversations(q)
        const conversationWithLatestInfo = await getConversationWithLatestInfo(conversations, submitterUserId)

        const filteredConversationWithLatestInfo = conversationWithLatestInfo
        const result = filteredConversationWithLatestInfo.sort(function (a, b) {
          return a.submittedAt > b.submittedAt ? -1 : 1
        })

        const currentUrl = currentPath + window.location.search
        const currentUrlParams = new URLSearchParams(window.location.search)
        const _conversation_id = currentUrlParams.get('conversation_id')

        if (_conversation_id) {
          const conversation = result.find((c) => c.conversationId === _conversation_id)
          if (conversation) {
            setDefaultConversation(conversation) // 要素が見つかった場合、それをデフォルト会話としてセット
            onDefaultConversationSet(conversation) // 親コンポーネントにデフォルト会話を通知
            if (currentUrl !== '/') {
              setSelectedConversationId(conversation.conversationId)
            }
          }
        } else if (result.length > 0) {
          setDefaultConversation(result[0]) // 0番目の会話をデフォルトとしてセット
          const currentUrl = new URL(window.location)
          currentUrl.searchParams.set('conversation_id', result[0].conversationId)
          window.history.pushState({}, '', currentUrl)
          onDefaultConversationSet(result[0]) // 親コンポーネントにデフォルト会話を通知
          if (currentUrl !== '/') {
            setSelectedConversationId(result[0].conversationId)
          }
        } else {
          onNoConversation(true)
        }
        setConversations(result)
        setLoading(false)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation_id, submitterUserId])

  const [loadingForGetData, setLoadingForGetData] = useState(false)
  // const [userForQuery, setUserForQuery] = useState(null) // この部分は適宜調整してください
  const [lastVisible, setLastVisible] = useState(null)
  useEffect(() => {
    if (readOnly === true || user === null) return
    setLoadingForGetData(true)
    fetchConversations()
  }, [user])

  const fetchConversations = async (lastDoc = null) => {
    let q
    if (currentPath === '/submitted_conversations') {
      const conversationsDocRef = collection(
        db,
        `/externalServices/mingaku/schools/${schoolId}/submissionBoxes/${submittionBox}/submitters/${submitterUserId}/conversations`
      )
      q = query(conversationsDocRef, orderBy('submittedAt', 'desc'), limit(_number_of_data[0]))

      if (lastDoc) {
        q = query(conversationsDocRef, orderBy('submittedAt', 'desc'), startAfter(lastDoc), limit(_number_of_data[0]))
      }
    } else {
      const userDocRef = doc(db, 'users', user.uid)
      const conversationsDocRef = collection(userDocRef, 'conversations') // 'conversations'は適宜変更してください
      q = query(conversationsDocRef, orderBy('updatedAt', 'desc'), limit(_number_of_data[0]))
      if (lastDoc) {
        q = query(conversationsDocRef, orderBy('updatedAt', 'desc'), startAfter(lastDoc), limit(_number_of_data[0]))
      }
    }

    const conversations = await getConversations(q)
    const conversationWithLatestInfo = await getConversationWithLatestInfo(conversations, user.uid)
    const filteredConversationWithLatestInfo = conversationWithLatestInfo
    const result = filteredConversationWithLatestInfo.sort(function (a, b) {
      return a.lastMessageTime > b.lastMessageTime ? -1 : 1
    })

    if (!lastDoc) {
      setConversations(result)
    } else {
      setConversations((prev) => [...prev, ...result])
    }
    setLoadingForGetData(false)
  }

  const handleLoadMore = () => {
    if (lastVisible && !loadingForGetData && hasMore) {
      setLoadingForGetData(true)
      fetchConversations(lastVisible)
    }
  }
  const handleItemClick = (conversation) => {
    onSelectConversation(conversation)
    setSelectedConversationId(conversation.conversationId)
    onMenuItemClick()
    // クエリパラメータの変更
    const currentUrl = new URL(window.location)
    currentUrl.searchParams.set('conversation_id', conversation.conversationId)
    window.history.pushState({}, '', currentUrl)
    // const currentUrl = new URL(window.location)
    if (conversation.thread_id) {
      currentUrl.searchParams.set('thread_id', conversation.thread_id)
      window.history.pushState({}, '', currentUrl)
    }
  }

  const getDocDataWithDefaults = async (docRef, defaults) => {
    const docSnapshot = await getDoc(docRef)
    if (!docSnapshot.exists()) {
      return defaults
    }
    return { ...defaults, ...docSnapshot.data() }
  }

  const getMessages = async (conversationDocRef) => {
    const messagesCollectionRef = collection(conversationDocRef, 'messages')
    const querySnapshot = await getDocs(query(messagesCollectionRef, orderBy('createdAt', 'asc')))
    return querySnapshot.docs.map((doc) => ({
      content: doc.data().message,
      role: doc.data().role,
      messageTime: doc.data().createdAt,
      model: doc.data().model,
      userInput: doc.data().userInput && doc.data().userInput,
      userInputValue: doc.data().userInputValue && doc.data().userInputValue
    }))
  }

  const getConversationWithLatestInfo = async (conversations, _userId) => {
    const conversationWithLatestInfo = await Promise.all(
      conversations.map(async (item) => {
        const conversationDocSnapshot = await getDoc(item.conversationRef)
        const promptDefaults = {
          mode: '削除されたモード',
          autoSending: false,
          defaultMute: true,
          model: 'gpt-4-turbo',
          temperature: 1,
          speakingRate: '1.1',
          largeIcon: false,
          displayInputContents: true,
          useAssistantApi: '',
          assistantId: '',
          language: JSON.stringify({
            physicalName: 'ja',
            languageCode: 'ja-JP',
            type: 'ja-JP-Neural2-B',
            japaneseName: '日本語(女性)',
            shortenName: '日(女)'
          }),
          userInput: false,
          userInputValue: []
        }
        let promptData = {}
        if (conversationDocSnapshot.data().promptRef) {
          promptData = await getDocDataWithDefaults(conversationDocSnapshot.data().promptRef, promptDefaults)
        } else {
          promptData = promptDefaults
        }
        const chatMessages = await getMessages(item.conversationRef)
        if (chatMessages.length === 0) return null
        const lastChatMessage = chatMessages[chatMessages.length - 1]
        const systemPromptOfChatMessage = chatMessages[0]
        const { role, content, messageTime } = lastChatMessage
        const { userInput, userInputValue } = systemPromptOfChatMessage
        return {
          conversationId: item.conversationId,
          originalConversationId: item.originalConversationId,
          mode: promptData.prompt_title ? promptData.prompt_title : '削除されたモード',
          conversationDocRef: item.conversationRef,
          lastChatMessage: Array.isArray(content) ? content[0].text : content,
          // lastChatMessage: Array.isArray(content) && content.length > 0 ? content[0].text : '',
          lastChatRole: role,
          lastMessageTime: messageTime,
          autoSending: promptData.autoSending,
          defaultMute: promptData.defaultMute,
          model: promptData.model,
          temperature: promptData.temperature,
          speakingRate: promptData.speakingRate,
          largeIcon: promptData.largeIcon,
          displayInputContents: promptData.displayInputContents,
          useAssistantApi: promptData.useAssistantApi,
          assistantId: promptData.assistantId,
          userId: _userId,
          threadId: conversationDocSnapshot.data().threadId || '',
          promptDocRefPath: conversationDocSnapshot.data().promptRef
            ? conversationDocSnapshot.data().promptRef.path
            : '',
          type: item.type || '',
          submissionTitle: item.submissionTitle || 'タイトル無し',
          submittedAt: item.submittedAt || '',
          language: promptData.prompt_language2,
          userInput,
          userInputValue
        }
      })
    )
    return conversationWithLatestInfo.filter((info) => info !== null)
  }

  return (
    <div className={TopModule.container}>
      <>
        {loading ? (
          <LoadingGrid />
        ) : (
          <>
            {conversations.length === 0 ? (
              <div className={TopModule.introduction}>
                {currentPath === '/submitted_conversations' ? (
                  // <p className={TopModule.noData}>提出済みの会話がありません</p>
                  <></>
                ) : (
                  <>
                    {currentPath === '/thread_list' ? (
                      <p className={TopModule.noData}>ログがありません</p>
                    ) : (
                      <>
                        <p>まずは、スクールAIを使ってみましょう！</p>
                        <button
                          className={TopModule.startButton}
                          onClick={() => {
                            window.open(modePath, '_blank')
                          }}
                        >
                          はじめる
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className={TopModule['ul-container']}>
                <ul className={TopModule['chat-list']}>
                  {currentPath === '/submitted_conversations' ? (
                    <>
                      {conversations.map((conversation, index) => {
                        return (
                          <li
                            className={
                              conversation.conversationId === selectedConversationId
                                ? TopModule['selected-chat-list']
                                : TopModule[chatListClassName]
                            }
                            key={conversation.conversationId}
                            onClick={() => handleItemClick(conversation)}
                          >
                            <div className={TopModule.icon}>
                              {conversation.mode && <p className={TopModule[modeNameClassName]}>{conversation.mode}</p>}
                              {conversation.type === 'conversation' ? (
                                <ForumIcon className={TopModule['chat-list-icon']} />
                              ) : (
                                <ChatBubbleIcon className={TopModule['chat-list-icon']} />
                              )}
                              <p className={TopModule.roleName}>
                                {conversation.type === 'conversation' ? 'チャット' : 'メッセージ'}
                              </p>
                              {readOnly === true && (
                                <>
                                  <p className={TopModule.userName}>{conversation.submissionTitle}</p>
                                </>
                              )}
                            </div>
                            <div className={TopModule['chat-list']}>
                              <p className={TopModule.time}>
                                提出時間：{unixtime2datetimeForSubmission(conversation.submittedAt)}
                              </p>
                              <div className={TopModule['chat-list-item-container']}>
                                {conversation.lastChatRole === 'system' ? (
                                  <p
                                    className={readOnly === false ? TopModule['chat-list-3'] : TopModule['chat-list-5']}
                                  >
                                    {`まだ会話がありません。`}
                                  </p>
                                ) : (
                                  <p
                                    className={readOnly === false ? TopModule['chat-list-3'] : TopModule['chat-list-5']}
                                  >
                                    {conversation.lastChatMessage ?? ''}
                                  </p>
                                )}
                              </div>
                            </div>
                            {/* DeleteOutlineIconは削除しない！！ */}
                            {/* <div className={TopModule.delete} onClick={(e) => deleteConversation(e, conversation, index)}>
                            <DeleteOutlineIcon />
                          </div> */}
                          </li>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      {conversations.map((conversation, index) => {
                        return (
                          <li
                            className={
                              conversation.conversationId === selectedConversationId
                                ? TopModule['selected-chat-list']
                                : TopModule[chatListClassName]
                            }
                            key={conversation.conversationId}
                            onClick={() => handleItemClick(conversation)}
                          >
                            <div className={TopModule.icon}>
                              {conversation.mode && <p className={TopModule[modeNameClassName]}>{conversation.mode}</p>}
                              {conversation.lastChatRole === 'user' || conversation.lastChatRole === 'system' ? (
                                <FaceIcon className={TopModule['chat-list-icon']} />
                              ) : (
                                <PsychologyIcon className={TopModule['chat-list-icon']} />
                              )}
                              <p className={TopModule.roleName}>
                                {conversation.lastChatRole === 'assistant' ? 'AI' : 'ユーザー'}
                              </p>
                              {readOnly === true && (
                                <>
                                  <p className={TopModule.userName}>
                                    {conversation.schoolId ? schoolIdName[conversation.schoolId] : 'みんがく'}
                                    <br></br>
                                    {/* {conversation.userName ? conversation.userName : conversation.userFullName} */}
                                    {conversation.userName}
                                  </p>
                                </>
                              )}
                            </div>
                            <div className={TopModule['chat-list']}>
                              <p className={TopModule.time}>{unixtime2datetime(conversation.lastMessageTime)}</p>
                              <div className={TopModule['chat-list-item-container']}>
                                {conversation.lastChatRole === 'system' ? (
                                  <p
                                    className={readOnly === false ? TopModule['chat-list-3'] : TopModule['chat-list-5']}
                                  >
                                    {`まだ会話がありません。`}
                                  </p>
                                ) : (
                                  <p
                                    className={readOnly === false ? TopModule['chat-list-3'] : TopModule['chat-list-5']}
                                  >
                                    {conversation.lastChatMessage ?? ''}
                                  </p>
                                )}
                              </div>
                            </div>
                            {/* DeleteOutlineIconは削除しない！！ */}
                            {/* <div className={TopModule.delete} onClick={(e) => deleteConversation(e, conversation, index)}>
                            <DeleteOutlineIcon />
                          </div> */}
                          </li>
                        )
                      })}
                    </>
                  )}
                </ul>
                {currentPath === '/' || currentPath === '/submitted_conversations' ? (
                  <div className={TopModule['menu-icon-container']}>
                    <div
                      className={hasMore === true ? TopModule['menu-icon'] : TopModule['diabled-menu-icon']}
                      onClick={handleLoadMore}
                    >
                      <AddIcon disabled={loading} />
                      {loading && <p>読み込み中...</p>}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </>
        )}
      </>
      {/* )} */}
    </div>
  )
}

function toLocalISOStringUnixtime(unixTimestamp) {
  const date = new Date(unixTimestamp)
  const off = date.getTimezoneOffset()
  const adjustedDate = new Date(date.getTime() - off * 60 * 1000)
  return adjustedDate.toISOString().slice(0, 19)
}

function getUnixTimePeriod(period) {
  const now = new Date()
  let start, end

  switch (period.unit) {
    case 'day':
      start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - period.value)
      end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - period.value)
      break
    case 'week':
      start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - (period.value - 1) * 7)
      end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 6)
      break
    case 'month':
      start = new Date(now.getFullYear(), now.getMonth() - period.value, 1)
      end = new Date(now.getFullYear(), now.getMonth() - period.value + 1, 0)
      break
    case 'year':
      start = new Date(now.getFullYear() - period.value, 0, 1)
      end = new Date(now.getFullYear() - period.value + 1, 0, 0)
      break
    case 'all':
      start = new Date('2023-01-01T00:00:00')
      end = now
      break
    case 'lastWeek':
      start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6)
      end = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      break
    default:
      throw new Error('Invalid period unit')
  }

  // 終了日の23時59分59秒999ミリ秒に設定
  end.setHours(23, 59, 59, 999)

  return {
    displayName: period.displayName,
    startUnixtime: start.getTime(), // ミリ秒単位のUnixタイムスタンプ
    endUnixtime: end.getTime() // ミリ秒単位のUnixタイムスタンプ
  }
}
export default ChatHistoryScreen
