import { httpsCallable } from 'firebase/functions'
import { functions } from '../firebase'

// request/response形式でgptのmessageを取得
export const getGptMessage = httpsCallable(functions, 'get_gpt_message')

// テキストから画像生成
export const getGptImage = httpsCallable(functions, 'get_gpt_image')

// トークンの計算
export const tokenCounter = httpsCallable(functions, 'token_counter')

//
export const getGptStreamingMessageReaderAndDecoder = async ({ model, prompt, temperature, speakingRate }) => {
  const apiKey = process.env['REACT_APP_OPENAI_API_KEY']
  const organization = process.env['REACT_APP_OPENAI_ORGANIZATION']
  const messages = prompt
  if (model === 'gpt-4' || model === 'gpt-4-1106-preview') {
    model = 'gpt-4-0125-preview'
  }
  if (model === 'gpt-3.5-turbo') {
    model = 'gpt-3.5-turbo-0125'
  }
  const completion = await fetch('https://api.openai.com/v1/chat/completions', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiKey}`,
      'OpenAI-Organization': organization
    },
    method: 'POST',
    body: JSON.stringify({
      messages: messages,
      model: model,
      temperature: temperature,
      stream: true
    })
  })
  const reader = completion.body.getReader()
  if (completion.status !== 200 || !reader) {
    return 'error'
  }
  const decoder = new TextDecoder('utf-8')
  return [reader, decoder]
}

export const getGptStreamingMessageReaderAndDecoder2 = async ({
  model,
  messages,
  temperature = 1,
  speakingRate,
  controller
}) => {
  if (model === 'gpt-4') {
    model = 'gpt-4-1106-preview'
  }
  let URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/streaming-response'
  if (model === 'azure-gpt-4-turbo') {
    URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/streaming-response-azure'
    model = 'gpt4turbo'
  }
  if (model === 'azure-gpt-3.5-turbo') {
    URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/streaming-response-azure'
    model = 'gpt35turbo'
  }
  if (model === 'azure-gpt-4-vision') {
    URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/streaming-response-azure'
    model = 'gpt4v'
  }
  if (model === 'azure-gpt-4o') {
    URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/streaming-response-azure'
    model = 'gpt4o'
  }
  try {
    let requestJson = {
      messages,
      model,
      temperature
    }
    console.log('requestJson', requestJson)
    if (model === 'gpt-4-vision-preview' || model === 'gpt4v' || model === 'gpt-4-turbo-2024-04-09') {
      requestJson = { ...requestJson, max_tokens: 4096 }
    }
    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestJson),
      signal: controller.signal // AbortController の signal を渡す
    })
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }
    const reader = response.body.getReader()
    const decoder = new TextDecoder('utf-8')
    return [reader, decoder]
  } catch (error) {
    console.error('Error:', error)
  }
}
