import { auth, functions, db } from '../../firebase'
import { useState, useEffect } from 'react'
import { signInWithCustomToken } from 'firebase/auth'
import { doc, updateDoc, getDoc } from 'firebase/firestore'
import * as React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useSearchParams } from 'react-router-dom'
import { httpsCallable } from 'firebase/functions'
import { isWithinTimeWindow } from '../../utils/timeWindowUtils'

function Redirect({ chatPath, serviceType }) {
  const { state } = useLocation()
  const [user] = useAuthState(auth)
  const navigate = useNavigate()
  const gptType = 'gpt-4'
  const [searchParams] = useSearchParams()
  const mode_id = searchParams.get('mode')
  const uid = searchParams.get('uid')
  const use_time_window = searchParams.get('time_window')
  const showheader = searchParams.get('showheader')
  const showfooter = searchParams.get('showfooter')
  const ref = searchParams.get('ref')
  // eslint-disable-next-line
  const [redirectPageUrl, setRedirectPageUrl] = useState(window.location.pathname + window.location.search)
  const path = window.location.pathname
  const queryParams = window.location.search
  /* eslint-disable react-hooks/exhaustive-deps */
  const signInWithUid = async (uid) => {
    const signIn = httpsCallable(functions, 'sign_in_with_uid')
    try {
      const res = await signIn({ uid })
      const { customToken } = res.data
      const userCredential = await signInWithCustomToken(auth, customToken)
      updateDoc(doc(db, 'users', userCredential.user.uid), {
        userId: userCredential.user.uid,
        email: userCredential.user.email ?? '',
        createdAt: userCredential.user.metadata.createdAt ?? null,
        lastLoginAt: userCredential.user.metadata.lastLoginAt ?? null
      })
    } catch (e) {
      navigate('/login_for_student', {
        state: {
          redirectPath: `${path}${queryParams}`,
          showheader,
          showfooter,
          ref
        }
      })
    } finally {
    }
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  const redirectToPage = async (mode) => {
    const withinTimeWindow = await isWithinTimeWindow(null, mode.ref, use_time_window)
    if (!withinTimeWindow) {
      alert('現在はこのモードを利用できません。')
      navigate('/')
      return
    }
    if (mode.formQuestions && mode.formQuestions.length > 0) {
      navigate(`/input?mode=${mode.ref.id}`, {
        state: {
          showheader,
          showfooter,
          mode: mode.prompt_title,
          prompt: mode.prompt,
          promptDocRefPath: mode.ref.path, // なぜかrefを渡せなかったため、path渡す。遷移先でrefに変換したほうがよいかも
          docId: mode.ref.id,
          startButton: true,
          autoSending: mode.autoSending !== undefined ? mode.autoSending : false,
          defaultMute: mode.defaultMute !== undefined ? mode.defaultMute : true,
          model: mode.model ? mode.model : gptType,
          speakingRate: mode.speakingRate ? mode.speakingRate : '1.1',
          temperature: mode.temperature !== undefined ? mode.temperature : 1,
          formQuestions: mode.formQuestions && mode.formQuestions,
          description: mode.prompt_description ? mode.prompt_description : '',
          redirectPageUrl,
          largeIcon: mode.largeIcon ? mode.largeIcon : false,
          displayInputContents: mode.displayInputContents ?? true,
          userId: user.uid,
          displayList: false,
          useAssistantApi: mode.useAssistantApi ? mode.useAssistantApi : false,
          assistantId: mode.assistantId ? mode.assistantId : '',
          language: mode.prompt_language2
            ? mode.prompt_language2
            : JSON.stringify({
                physicalName: 'ja',
                languageCode: 'ja-JP',
                type: 'ja-JP-Neural2-B',
                japaneseName: '日本語(女性)',
                shortenName: '日(女)'
              })
        }
      })
    } else {
      navigate(`${chatPath}?mode=${mode.ref.id}&startover=true&showheader=${showheader}&showfooter=${showfooter}`, {
        state: {
          showheader,
          showfooter,
          mode: mode.prompt_title,
          startButton: true,
          autoSending: mode.autoSending !== undefined ? mode.autoSending : false,
          defaultMute: mode.defaultMute !== undefined ? mode.defaultMute : true,
          model: mode.model ? mode.model : gptType,
          speakingRate: mode.speakingRate ? mode.speakingRate : '1.1',
          temperature: mode.temperature !== undefined ? mode.temperature : 1,
          promptDocRefPath: mode.ref.path,
          replacedMessage: mode.prompt,
          redirectPageUrl,
          largeIcon: mode.largeIcon ? mode.largeIcon : false,
          displayInputContents: mode.displayInputContents ?? true,
          userId: user.uid,
          displayList: false,
          useAssistantApi: mode.useAssistantApi ? mode.useAssistantApi : false,
          assistantId: mode.assistantId ? mode.assistantId : '',
          language: mode.prompt_language2
            ? mode.prompt_language2
            : JSON.stringify({
                physicalName: 'ja',
                languageCode: 'ja-JP',
                type: 'ja-JP-Neural2-B',
                japaneseName: '日本語(女性)',
                shortenName: '日(女)'
              })
        }
      })
    }
  }

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        return
      } else {
        if (!!uid) {
          ;(async () => {
            console.log('111')
            await signInWithUid(uid)
          })()
        } else {
          navigate('/login_for_student', {
            state: {
              redirectPath: `${path}${queryParams}`,
              showheader,
              showfooter,
              ref
            }
          })
        }
      }
    })
  }, [uid, navigate, showheader, showfooter, mode_id, ref, signInWithUid])

  useEffect(() => {
    if (user) {
      const userDocRef = doc(db, 'users', user.uid)
      getDoc(userDocRef).then((userDocSnapshot) => {
        if (userDocSnapshot.exists()) {
          const docRef = doc(db, ref)
          getDoc(docRef).then((docSnapshot) => {
            if (docSnapshot.exists()) {
              const mode = docSnapshot.data()
              redirectToPage(mode)
            } else {
            }
          })
        } else {
        }
      })
    }
  }, [user, mode_id, serviceType, redirectToPage, state])

  return <></>
}

export default Redirect
