import HeaderModule from './Header2.module.scss'
import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { doc, collection, getDoc } from 'firebase/firestore'
import { auth, db, functions } from '../../firebase'
import { httpsCallable } from 'firebase/functions'
import { ProgressBar } from 'react-loader-spinner'
import HambergerMenu from './HambergerMenu/HambergerMenu'
import SettingsIcon from '@mui/icons-material/Settings'
import TocIcon from '@mui/icons-material/Toc'
import WidgetsIcon from '@mui/icons-material/Widgets'
import { _table_filter_periods, _logs_filter_periods } from '../../localConstant'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

function Header2({ headerTitle, imageUrl, dataVisible, title, userDocData, role }) {
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 150
    }
  })
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600)
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600)
  }
  const [schoolName, setSchoolName] = useState('')
  const [tutorName, setTutorName] = useState('')
  const [loading, setLoading] = useState(false)
  const [user] = useAuthState(auth)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  // const [headerTitle, setHeaderTitle] = useState('')
  const _title = title ? title : ''
  const [schoolId, setSchoolId] = useState('')
  const [searchParams] = useSearchParams()
  const _schoolId = searchParams.get('school_id')
  const [schoolIdFromDoc, setSchoolIdFromDoc] = useState('')
  const [isSchoolId, setIsSchoolId] = useState(false)
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (!userDocData) return
    ;(async () => {
      setLoading(true)
      if (userDocData) {
        if (role === 'admin') {
          const { mingakuUserId } = userDocData
          const getAuth = httpsCallable(functions, 'get_admin_info_of_mingaku')
          const { data } = await getAuth({ userId: mingakuUserId })
          const { adminDocData } = data
          setTutorName(`${adminDocData.name}`)
          setSchoolName(`みんがく管理人`)
          setLoading(false)
        } else if (role === 'tutor') {
          const { schoolDocId, tutorDocId } = userDocData
          const getTutorInfo = httpsCallable(functions, 'get_tutor_info_of_mingaku')
          const getSchoolsInfo = httpsCallable(functions, 'get_schools_info')
          const [tutorIndo, schoolInfo] = await Promise.all([
            getTutorInfo({ userId: tutorDocId, schoolId: schoolDocId }),
            getSchoolsInfo({ schoolIds: [schoolDocId] })
          ])
          setTutorName(`先生用 | ${tutorIndo.data.tutorDocData.name}`)
          setSchoolName(schoolInfo.data.schoolInfo[0].data.name)
          setLoading(false)
        } else {
          setLoading(false)
        }
      }
    })()
  }, [role, userDocData])

  useEffect(() => {
    if (user) {
      const userDocRef = doc(db, 'users', user.uid)
      getDoc(userDocRef).then((userDocSnapshot) => {
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data()
          if (userData.schoolDocId) {
            setSchoolId(userData.schoolDocId)
            setSchoolIdFromDoc(userData.schoolDocId)
            setIsSchoolId(true)
          } else {
            setIsSchoolId(false)
          }
        } else {
        }
      })
    }
  }, [user])

  useEffect(() => {
    // ウィンドウの幅が変更された時に実行される関数
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // コンポーネントがアンマウントされる際にイベントリスナーを解除する
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const truncatedTitle = windowWidth <= 768 ? (_title.length > 11 ? _title.slice(0, 11) + '...' : _title) : _title

  const generateListItems = (schoolId) => [
    {
      name: 'モード管理画面',
      path: `/management?school_id=${schoolId}`,
      icon: <SettingsIcon />
    },
    {
      name: '会話データ出力',
      path: `/chat_data?school_id=${schoolId}`,
      icon: <TocIcon />,
      additionalParamName: 'filter'
    },
    {
      name: '会話ログ閲覧',
      path: `/thread_list?school_id=${schoolId}`,
      icon: <TocIcon />,
      additionalParamName: 'filter'
    },
    {
      name: 'UI設定',
      path: `/ui_setting?school_id=${schoolId}`,
      icon: <WidgetsIcon />
    },
    {
      name: '提出管理画面',
      path: `/submission_management?school_id=${schoolId}`,
      icon: <SettingsIcon />
    }
  ]

  let listItem

  if (schoolId) {
    listItem = generateListItems(schoolId)
  } else if (_schoolId) {
    listItem = generateListItems(_schoolId)
  } else if (schoolIdFromDoc) {
    listItem = generateListItems(schoolIdFromDoc)
  } else {
    listItem = [
      {
        name: 'モード管理画面',
        path: `/management`,
        icon: <SettingsIcon />
      },
      {
        name: '会話データ出力',
        path: `/chat_data`,
        icon: <TocIcon />,
        additionalParamName: 'filter'
      },
      {
        name: '会話ログ閲覧',
        path: `/thread_list`,
        icon: <TocIcon />,
        additionalParamName: 'filter'
      },
      {
        name: 'UI設定',
        path: `/ui_setting`,
        icon: <WidgetsIcon />
      },
      {
        name: 'スクールAI管理画面',
        path: `/admin_management`,
        icon: <SettingsIcon />
      }
    ]
  }

  listItem = listItem.map((item) => ({
    ...item,
    children:
      item.name === '会話データ出力' ? _table_filter_periods : item.name === '会話ログ閲覧' ? _logs_filter_periods : []
  }))

  listItem = dataVisible ? listItem : listItem.filter((item, index) => index !== 1)

  return (
    <div className={HeaderModule.header}>
      <a className={HeaderModule.left} href="/">
        {imageUrl && <img className={HeaderModule['icon-image']} src={imageUrl} alt="" />}
        {((!isMobile && headerTitle) || (isMobile && !imageUrl)) && (
          <p className={HeaderModule['service-title']}>{headerTitle}</p>
        )}
      </a>
      <p className={HeaderModule['title']}>{truncatedTitle}</p>
      <div className={HeaderModule.right}>
        {!role ? (
          <>
            <div className={HeaderModule.rightName}>
              {loading ? (
                <ProgressBar
                  height="40"
                  width="40"
                  ariaLabel="progress-bar-loading"
                  wrapperStyle={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#a9a9a9"
                  barColor="#e3e3e3"
                />
              ) : (
                <>
                  <>
                    <div>みんがく未連携</div>
                    <div>
                      アカウント{' '}
                      <CustomWidthTooltip
                        title="このアカウントはみんがくアカウントと連携されてません。連携を完了させるために、みんがく管理画面のスクールAI管理からスクールAIにアクセスしてください。(全ての機能を利用するためには、みんがくアカウントとの連携が必要です。)"
                        arrow
                        className={HeaderModule['tooltip-arrow']}
                        enterTouchDelay={100}
                      >
                        <span className={HeaderModule['tooltip2']}>？</span>
                      </CustomWidthTooltip>
                    </div>
                  </>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div className={HeaderModule.rightName}>
              {loading ? (
                <ProgressBar
                  height="40"
                  width="40"
                  ariaLabel="progress-bar-loading"
                  wrapperStyle={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#a9a9a9"
                  barColor="#e3e3e3"
                />
              ) : (
                <>
                  {!isMobile && (
                    <>
                      {' '}
                      <div>{schoolName}</div>
                      <div>{tutorName}</div>
                    </>
                  )}
                </>
              )}
            </div>
            <HambergerMenu listItem={listItem} isSchoolId={isSchoolId} />
          </>
        )}
      </div>
    </div>
  )
}

export default Header2
