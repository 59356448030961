import * as React from 'react'
import { Grid } from 'react-loader-spinner'
import styles from './LoadingGrid.module.scss'
function LoadingGrid({ text = '' }) {
  return (
    <div className={styles.loadingGridContainer}>
      <Grid
        height="80"
        width="80"
        color={'#1e90ff'}
        ariaLabel="grid-loading"
        radius="12.5"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
      <p>{text}</p>
    </div>
  )
}

export default LoadingGrid
