import React, { useEffect, useState } from 'react'
import LargeIconAvatorModule from './LargeIconAvator.module.scss'
import ModalForPicture from './ModalForPicture'

const Avator = ({
  isActiveAvator,
  avator_images,
  isLargeIcon,
  largeIconAvatorWidth,
  isGenerating,
  imageEnlargement
}) => {
  useEffect(() => {
    const iconImage1 = document.getElementById('iconImage1')
    iconImage1.setAttribute('src', avator_images[0])
    const iconImage2 = document.getElementById('iconImage2')
    iconImage2.setAttribute('src', avator_images[1])
  }, [isActiveAvator, avator_images])
  const styles = isLargeIcon === true && LargeIconAvatorModule
  const [modalImage, setModalImage] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleImageClick = (imageSrc) => {
    if (imageEnlargement) {
      setModalImage(imageSrc)
      setIsModalOpen(true)
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setModalImage('')
  }

  return (
    <>
      <div className={styles['icon-container']}>
        {isActiveAvator % 5 === 0 ? (
          <div
            id={'largeIcon'}
            className={isGenerating ? styles['rounded-icon-generating'] : styles['image1']}
            style={{ width: `${largeIconAvatorWidth}px`, height: `${largeIconAvatorWidth}px` }}
          >
            <img
              alt={isActiveAvator}
              id={'iconImage1'}
              className={styles['image1']}
              style={{ height: `${largeIconAvatorWidth}px` }}
              onClick={() => handleImageClick(avator_images[0])}
            />
            <img
              alt={isActiveAvator}
              id={'iconImage2'}
              className={styles['image2']}
              style={{ height: `${largeIconAvatorWidth}px` }}
              onClick={() => handleImageClick(avator_images[1])}
            />
          </div>
        ) : (
          <div
            className={isGenerating ? styles['rounded-icon-generating'] : styles['image1']}
            style={{ width: `${largeIconAvatorWidth}px`, height: `${largeIconAvatorWidth}px` }}
          >
            <img
              alt={isActiveAvator}
              id={'iconImage2'}
              className={styles['image1']}
              style={{ height: `${largeIconAvatorWidth}px` }}
              onClick={() => handleImageClick(avator_images[0])}
            />
            <img
              alt={isActiveAvator}
              id={'iconImage1'}
              className={styles['image2']}
              style={{ height: `${largeIconAvatorWidth}px` }}
              onClick={() => handleImageClick(avator_images[1])}
            />
          </div>
        )}
      </div>
      {modalImage && <ModalForPicture image={modalImage} onClose={handleCloseModal} />}
    </>
  )
}

export default Avator
