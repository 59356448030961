import React from 'react'
import UserInfoModule from './Modes.module.scss' // スタイルシートのパスは適宜調整してください

const ModeSearch = ({ searchText, setSearchText, placeholderMessage }) => {
  return (
    <div className={UserInfoModule.modeSearch}>
      <input
        type="text"
        // placeholder="モードを検索..."
        placeholder={placeholderMessage}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        className={UserInfoModule.modeSearchInput}
      />
    </div>
  )
}

export default ModeSearch
