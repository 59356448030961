import * as React from 'react'
import ChatModule from './ChatScreen.module.scss'
import ChatModuleReadOnly from './ChatScreenReadOnly.module.scss'
import { useState, useEffect, useRef } from 'react'
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'
import { writeBatch, collection, getDocs, doc, setDoc, query, orderBy, getDoc, updateDoc } from 'firebase/firestore'
import ChatBubble from '../../components/Bubble/ChatBubble'
import ChatBubbleForSystem from '../../components/Bubble/ChatBubbleForSystem'
import { auth, db, functions, storage } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import IconButton from '@mui/material/IconButton'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CancelIcon from '@mui/icons-material/Cancel'
import FormControl from '@mui/joy/FormControl'
import Textarea from '@mui/joy/Textarea'
import SendIcon from '@mui/icons-material/Send'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { Bars } from 'react-loader-spinner'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { ocr } from '../../utils/ocr'
import {
  getGptMessage,
  getGptImage,
  // getGptStreamingMessageReaderAndDecoder,
  getGptStreamingMessageReaderAndDecoder2,
  tokenCounter
} from '../../utils/gpt'
import { getUserDataFromMingaku, updateUserData } from '../../utils/getUserDataFromMingaku'
import { getClaudeStreamingMessageReaderAndDecoder } from '../../utils/claude'
import { _responseOptions, _inserted_default_target_student_info } from '../../localConstant'
import { getAudioUrl, getAudioUrlFromOpenAi } from '../../utils/tts'
import ai from '../../assets/ai.png'
import ai2 from '../../assets/ai2.png'
import { _language_list_v2, _gpt_model, _fields_of_user_data_in_mypage } from '../../localConstant'
import { useLocation, useNavigate } from 'react-router-dom'
import { httpsCallable } from 'firebase/functions'
import { useSearchParams } from 'react-router-dom'
import Avator from '../Bubble/Avator'
import MenuIcon from '../MenuIcon/MenuIcon'
import LoadingGrid from '../LoadingGrid/LoadingGrid'
import { getMatchingLanguage } from '../../utils/getMachingLanguage'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

function ChatScreen(props) {
  const {
    conversation,
    conversationType,
    startButton,
    readOnly,
    displayFooter,
    chatScreenWidth,
    userInputWidth,
    chatScreenBottom,
    chatScreenHeight,
    chatScreenHeightWithTextArea,
    chatScreenHeightWithTextAreaAndFooter,
    onStartChange,
    submitterUserId
  } = props
  const {
    conversationId,
    replacedMessage,
    userInputValue,
    model,
    mode,
    defaultMute,
    autoSending,
    temperature,
    speakingRate,
    promptDocRefPath,
    userId,
    largeIcon,
    displayInputContents,
    useAssistantApi,
    assistantId,
    threadId,
    language
  } = conversation
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 150,
      marginRight: -50,
      zIndex: 0
    }
  })
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const _language = language
  const [_useAssistantApi, _setUseAssistantApi] = useState(useAssistantApi)
  const styles = readOnly === true ? ChatModuleReadOnly : ChatModule
  const determineStyle = () => {
    if (readOnly === true) {
      return { width: chatScreenWidth, height: `${chatScreenHeight}px` }
    } else if ((readOnly === false) & (displayFooter === true)) {
      return { width: chatScreenWidth, height: `${chatScreenHeightWithTextAreaAndFooter + 6}px` }
    } else if ((readOnly === false) & ((displayFooter === false) & (showheader !== 'false'))) {
      return { width: chatScreenWidth, height: `${chatScreenHeightWithTextArea}px` }
    } else if ((readOnly === false) & ((displayFooter === false) & (showheader === 'false'))) {
      return { width: chatScreenWidth, height: `${chatScreenHeightWithTextArea}px` }
    }
  }
  const determineHalfStyle = () => {
    if (readOnly === true) {
      return { width: chatScreenWidth, height: `${chatScreenHeight / 2 - 8}px` }
    } else if ((readOnly === false) & (displayFooter === true)) {
      return { width: chatScreenWidth, height: `${chatScreenHeightWithTextAreaAndFooter / 2 + 4}px` }
    } else if ((readOnly === false) & ((displayFooter === false) & (showheader !== 'false'))) {
      return { width: chatScreenWidth, height: `${chatScreenHeightWithTextArea / 2}px` }
    } else if ((readOnly === false) & ((displayFooter === false) & (showheader === 'false'))) {
      return { width: chatScreenWidth, height: `${chatScreenHeightWithTextArea / 2}px` }
    }
  }

  const currentPath = window.location.pathname
  const { state } = useLocation()
  const [searchParams] = useSearchParams()
  const startover = searchParams.get('startover')
  const showheader = searchParams.get('showheader')
  const submittionBox = searchParams.get('submittion_box')
  const schoolId = searchParams.get('school_id')
  const navigate = useNavigate()
  const isStreaming = true
  let audio
  const gridColor = '#1e90ff'
  const [newConversationId, setNewConversationId] = useState(conversationId ? conversationId : '')
  const [_audio, _setAudio] = useState(null)
  const [previousChatInput, setPreviousChatInput] = useState('')
  const sendActiveClassName = 'send-active'
  const text2imageLabelClassName = 'text2imageLabel'
  const [user] = useAuthState(auth)
  const { transcript, interimTranscript, finalTranscript, resetTranscript } = useSpeechRecognition() // ref: https://www.loginradius.com/blog/engineering/quick-look-at-react-speech-recognition/
  const isFirstRender = useRef(false)
  const [islistening, setIsListening] = useState(false)
  const [maxCharacters, setMaxCharacters] = useState(0)
  const [characterLimit, setCharacterLimit] = useState(false)
  const [maxCharactersPerMonth, setMaxCharactersPerMonth] = useState(0)
  const [characterLimitPerMonth, setCharacterLimitPerMonth] = useState(false)
  const [_start, _setStart] = useState(false)
  const [_startButton, _setStartButton] = useState(state && state.startButton ? state.startButton : startButton)
  const [avatorImages, setAvatorImages] = useState([ai, ai2])
  const [selectedLanguage, setSelectedLanguage] = useState(
    _language
      ? _language
      : JSON.stringify({
          physicalName: 'ja',
          languageCode: 'ja-JP',
          type: 'ja-JP-Neural2-B',
          japaneseName: '日本語(女性)',
          shortenName: '日(女)'
        })
  )
  const [disableSpeaker, setDisableSpeaker] = useState(false)
  const [promptData, setPromptData] = useState()
  const [isMute, setIsMute] = useState(defaultMute)
  const [isLargeIcon, setIsLargeIcon] = useState(largeIcon)
  const [responseOptions, setResponseOptions] = useState(_responseOptions)
  const [chatInput, setChatInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isBubbleLoading, setIsBubbleLoading] = useState(false)
  const [chatData, setChatData] = useState([])
  const [isActiveAvator, setIsActiveAvator] = useState(0)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isModalOpenForSubmission, setIsModalOpenForSubmission] = useState(false)
  const [generatingImage, setGeneratingImage] = useState(false)
  const [initialPromptRef, setInitialPromptRef] = useState('')
  const [sentenceWordReplacementList, setSentenceWordReplacementList] = useState([])
  const [isSendingCountLimit, setIsSendingCountLimitSet] = useState(false)
  const [maximumSendingCountLimit, setMaximumSendingCountLimit] = useState(0)
  const [isPastMessageLimitCountSet, setIsPastMessageLimitCountSet] = useState(false)
  const [maximumPastMessageLimitCount, setMaximumPastMessageLimitCount] = useState(0)
  const [file, setFile] = useState(null)
  const [previewUrl, setPreviewUrl] = useState(null) // 画像プレビューURLの状態
  const [files, setFiles] = useState([])
  const [previewUrls, setPreviewUrls] = useState([])
  const [isHovering, setIsHovering] = useState(false) // ホバー状態の管理
  const [isGenerating, setIsGenerating] = useState(false) // LLMが生成中かどうかの状態

  // ファイル選択時のハンドラ
  const handleFilesChange = (e) => {
    const FilesSizelimit = 3670016
    const selectedFiles = Array.from(e.target.files) // ファイルのリストを配列に変換
    const filesBelowLimit = selectedFiles.filter((file) => file.size <= FilesSizelimit) // 3.5MB以下のファイルのみフィルタリング
    const filesAboveLimit = selectedFiles.length - filesBelowLimit.length // 3.5MB以上のファイルの数

    if (filesAboveLimit > 0) {
      const oversizedFilesAlert = selectedFiles
        .filter((file) => file.size > FilesSizelimit)
        .map((file) => `❏ ファイル名: ${file.name}`)
        .join('\n')
      if (oversizedFilesAlert) {
        alert(
          `1つの画像のサイズ上限は3.5MBです。3.5MBを超えている以下の画像のみ除外しました。\n\n${oversizedFilesAlert}`
        )
      }
    }

    setFiles((prevFiles) => [...prevFiles, ...filesBelowLimit]) // 既存のファイルリストに3.5MB以下のファイルを追加

    // プレビューURLを生成
    const newPreviewUrls = filesBelowLimit.map((file) => {
      return URL.createObjectURL(file)
    })
    setPreviewUrls((prevUrls) => [...prevUrls, ...newPreviewUrls])
  }

  // useEffectを更新して、files配列が変更されたときにプレビューを設定
  useEffect(() => {
    // 以前のプレビューURLをクリーンアップ
    return () => {
      previewUrls.forEach((url) => URL.revokeObjectURL(url))
    }
  }, [previewUrls])

  const languageList2 = _language_list_v2
  useEffect(() => {
    setNewConversationId(conversationId)
    setPlayingIndex(null)
    setOnPlaying(false)
  }, [conversationId])
  useEffect(() => {
    setIsLargeIcon(largeIcon)
  }, [largeIcon])
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2
    if (t < 1) return (c / 2) * t * t + b
    t--
    return (-c / 2) * (t * (t - 2) - 1) + b
  }
  const containerRef = useRef(null)
  const modalRef = useRef()
  const avatorContainerRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpenForSubmission(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setIsModalOpenForSubmission])
  const changeLanguage = (e) => {
    setSelectedLanguage(e.target.value)
  }
  const stopPlaying = () => {
    if (_audio) {
      _audio.pause()
      setPlayingIndex(null)
      setOnPlaying(false)
    }
  }
  const changeMute = async () => {
    setIsMute(!isMute)
    const currentIsMute = Boolean(parseInt(localStorage.getItem('localIsMute'), 10))
    const numericValue = currentIsMute ? 0 : 1
    localStorage.setItem('localIsMute', numericValue)
    const localIsMute = localStorage.getItem('localIsMute')
    speechSynthesis.cancel()
    let text = 'コメント読み上げをオンにしました。'
    const json_lang = JSON.parse(selectedLanguage)
    if (json_lang.languageCode === 'en-US') {
      text = 'Comment reading turned on.'
    }
    if (localIsMute === '0') {
      let languageCode = 'ja-JP'
      let type = 'ja-JP-Neural2-B'
      if (json_lang.languageCode === 'en-US') {
        languageCode = 'en-US'
        type = json_lang.type
      } else if (json_lang.languageCode === 'ja-JP') {
        type = json_lang.type
      }
      audio = new Audio(await getAudioUrl({ text, languageCode, type, speakingRate }))
      _setAudio(audio)
      audio.play()
    } else {
      if (_audio) {
        _audio.pause()
        setPlayingIndex(null)
        setOnPlaying(false)
      }
    }
  }

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    alert('お使いのブラウザは音声機能に対応していません。最新版のChromeかSafariをご利用ください。')
  }

  const listenContinuously = () => {
    resetTranscript()
    setIsListening(true)
    const json_lang = JSON.parse(selectedLanguage)
    SpeechRecognition.startListening({
      continuous: true,
      language: json_lang.physicalName
    })
  }

  const resetListening = () => {
    SpeechRecognition.stopListening()
    setIsListening(false)
  }

  const onVoiceInput = () => {
    setPreviousChatInput(chatInput)
    listenContinuously()
  }
  const onChangeImage = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = await ocr(file)
      setChatInput(text)
    }
    reader.readAsDataURL(file)
  }

  const handleInputChange = (event) => {
    setChatInput(event.target.value)
  }

  const isWithinThreadSendingLimit = () => {
    // 送信回数がスレッドあたりの制限内かどうかを判断するロジック
    if (isSendingCountLimit) {
      const userSendingCount = chatData.filter((chat) => chat.role === 'user').length + 1
      if (maximumSendingCountLimit < userSendingCount) {
        alert(
          `このスレッドの送信回数上限に達しました。\n` +
            `・現在の送信回数: ${userSendingCount - 1}\n` +
            `・このモードの1スレッドあたりの送信回数の上限値: ${maximumSendingCountLimit}\n\n` +
            `引き続きご利用する場合は、モード一覧画面からモードを選択して新しい会話を作成してください。`
        )
        return true
      } else {
        return false
      }
    }
  }

  const isWithinThreadCharacterLimitPerDay = (text) => {
    // 送信回数がスレッドあたりの制限内かどうかを判断するロジック
    if (characterLimit === true && maxCharacters) {
      const startTime = new Date()
      startTime.setHours(0, 0, 0, 0) // Set to the beginning of the day
      const endTime = new Date() // Current time
      endTime.setDate(endTime.getDate() + 1) // Set to the beginning of the next day
      endTime.setHours(0, 0, 0, 0) // Set to 00:00:00
      const { userCharacterCount, totalCharacterCount } = checkCharacterCount(
        text,
        ['user', 'system', 'assistant'],
        startTime.getTime(),
        endTime.getTime()
      )
      if (totalCharacterCount > maxCharacters) {
        // maxCharacters文字以上の場合、アラートを表示するか、適切な処理を行う
        const remainingCharacterCount = maxCharacters - userCharacterCount

        if (remainingCharacterCount <= 0) {
          alert(
            `文字数制限を超えており、今日はこの会話ではメッセージの送信ができません。\n明日になると制限がリセットされ、再び送信できるようになります。`
          )
        } else {
          alert(
            `今日はこの会話では残り${remainingCharacterCount}文字までしか送信できません。\n文字数を減らしてください。\n明日になると制限がリセットされます。`
          )
        }

        return true
      } else {
        return false
      }
    }
  }
  const isWithinThreadCharacterLimitPerMonth = (text) => {
    // 送信回数がスレッドあたりの制限内かどうかを判断するロジック
    if (characterLimitPerMonth === true && maxCharactersPerMonth) {
      const startTime = new Date()
      startTime.setDate(1) // Set to the first day of the current month
      startTime.setHours(0, 0, 0, 0) // Set to 00:00:00

      const endTime = new Date(startTime)
      endTime.setMonth(endTime.getMonth() + 1) // Set to the first day of the next month
      endTime.setHours(0, 0, 0, 0) // Set to 00:00:00
      const { userCharacterCount, totalCharacterCount } = checkCharacterCount(
        text,
        ['user', 'system', 'assistant'],
        startTime.getTime(),
        endTime.getTime()
      )
      if (totalCharacterCount > maxCharactersPerMonth) {
        const remainingCharacterCount = maxCharactersPerMonth - userCharacterCount

        if (remainingCharacterCount <= 0) {
          alert(
            `文字数制限を超えており、今月はこの会話ではメッセージの送信ができません。\n来月になると制限がリセットされ、再び送信できるようになります。`
          )
        } else {
          alert(
            `今月はこの会話では残り${remainingCharacterCount}文字までしか送信できません。\n文字数を減らしてください。\n来月になると制限がリセットされます。`
          )
        }
        return true
      } else {
        return false
      }
    }
  }

  // eslint-disable-next-line
  const addMessageToDB = async (message, threadId, messagesForTokenCounter) => {
    // 初回AssistantAPIでthreadIdが作成されたときのみthreadIdを引数に渡してconversationDoc更新する

    playTextSound('')
    const userDocRef = doc(db, 'users', user.uid)
    const conversationsDocRef = doc(
      userDocRef,
      conversationType,
      newConversationId ? newConversationId : state.conversationId
    )

    const messagesCollectionRef = collection(conversationsDocRef, 'messages')
    const ref = doc(messagesCollectionRef)
    const { data } = await tokenCounter({
      text: message.content,
      role: message.role,
      model: model,
      messagesForTokenCounter: messagesForTokenCounter // 過去の会話履歴を含む
    })
    const { characterCount, tokenCount, tokenCountOfMessages } = data
    if (threadId) {
      updateDoc(conversationsDocRef, {
        updatedAt: new Date().getTime(),
        threadId: threadId
      })
    } else {
      updateDoc(conversationsDocRef, {
        updatedAt: new Date().getTime()
      })
    }

    await setDoc(ref, {
      messageId: ref.id,
      message: message.content,
      role: message.role,
      model,
      createdAt: message.messageTime,
      characterCount,
      tokenCount,
      tokenCountOfMessages, // 過去の会話履歴を含む
      actualMessageLength: message.actualMessageLength
    })
    const newMessageRef = ref
    const updatedUserChatMesasge = {
      ...message,
      messageId: ref.id,
      model,
      characterCount,
      tokenCount,
      tokenCountOfMessages // 過去の会話履歴を含む
    }
    return { newMessageRef, updatedUserChatMesasge }
  }

  const [onPlaying, setOnPlaying] = useState(false)
  const [playingIndex, setPlayingIndex] = useState(null)
  const playTextSound2 = async (text, lang = '', play, index) => {
    const localIsMute = Boolean(parseInt(localStorage.getItem('localIsMute'), 10))
    if (text === '' || (play !== true && (localIsMute === true || localIsMute === null))) {
      return
    }
    setOnPlaying(true)
    setPlayingIndex(index)
    let languageCode = 'en-US'
    let type = 'en-US-Neural2-H'
    if (lang) {
      const json_lang = JSON.parse(lang)
      languageCode = json_lang.languageCode
      type = json_lang.type
    } else if (selectedLanguage) {
      const json_lang = JSON.parse(selectedLanguage)
      languageCode = json_lang.languageCode
      type = json_lang.type
    }
    // const audioUrl = await getAudioUrl({ text, languageCode, type, speakingRate })
    if (type === 'openai-tts') {
      audio = new Audio(await getAudioUrlFromOpenAi({ text, languageCode, type, speakingRate }))
    } else {
      audio = new Audio(await getAudioUrl({ text, languageCode, type, speakingRate }))
    }
    // const audio = new Audio(audioUrl)
    _setAudio(audio) // この関数の実装は省略されていますが、恐らくグローバルなaudio変数を設定しているものと推測されます。

    return new Promise((resolve, reject) => {
      audio.onended = () => {
        // setPlaying(false)
        setPlayingIndex(null)
        setOnPlaying(false)

        resolve() // 再生が終了したらPromiseを解決する
      }
      audio.onerror = (error) => {
        console.error('Playback failed', error)
        reject(error) // エラーが発生したらPromiseを拒否する
      }
      audio.play().catch(reject) // 再生が開始できなかった場合にPromiseを拒否する
    })
  }

  const playTextSound = async (text, lang = '', play) => {
    const localIsMute = Boolean(parseInt(localStorage.getItem('localIsMute'), 10))
    if (text === '' || (play !== true && (localIsMute === true || localIsMute === null))) return
    setOnPlaying(true)
    setPlayingIndex(chatData.length + 1)
    let languageCode = 'en-US'
    let type = 'en-US-Neural2-H'
    if (lang) {
      const json_lang = JSON.parse(lang)
      languageCode = json_lang.languageCode
      type = json_lang.type
    } else if (selectedLanguage) {
      const json_lang = JSON.parse(selectedLanguage)
      languageCode = json_lang.languageCode
      type = json_lang.type
    }
    if (type === 'openai-tts') {
      audio = new Audio(await getAudioUrlFromOpenAi({ text, languageCode, type, speakingRate }))
    } else {
      audio = new Audio(await getAudioUrl({ text, languageCode, type, speakingRate }))
    }

    _setAudio(audio) // この関数の実装は省略されていますが、恐らくグローバルなaudio変数を設定しているものと推測されます。

    return new Promise((resolve, reject) => {
      audio.onended = () => {
        // setPlaying(false)
        setPlayingIndex(null)
        setOnPlaying(false)

        resolve() // 再生が終了したらPromiseを解決する
      }
      audio.onerror = (error) => {
        console.error('Playback failed', error)
        reject(error) // エラーが発生したらPromiseを拒否する
      }
      audio.play().catch(reject) // 再生が開始できなかった場合にPromiseを拒否する
    })

    // try {
    //   _setAudio(audio)
    //   await audio.play()
    //   setPlayingIndex(null)
    //   setOnPlaying(false)
    // } catch (err) {}
  }

  const [messageList, setMessageList] = useState([])
  const [runStatus, setRunStatus] = useState('')
  const [_threadId, _setThreadId] = useState(threadId && threadId)
  const [runId, setRunId] = useState('')
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
  const getRunStatus = async (runId, threadId) => {
    setIsGenerating(true)
    let count = 0
    let status = ''
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_get_run_status'
    while (count < 50 && status !== 'completed') {
      const res = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          thread_id: threadId,
          run_id: runId
        })
      })
      const { run } = await res.json()
      status = run.status
      setRunStatus(status)
      await sleep(3000)
      count++
    }
    setIsGenerating(false)
    return status
  }

  const loadingChatMessage = {
    role: 'loading',
    content: 'loading...',
    messageTime: new Date().getTime(),
    characterCount: ''
  }

  const createThreadAndRun = async ({ pastContent }) => {
    setIsLoading(false)

    setMessageList([])

    const _URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_create_thread_and_run'
    const messages = [
      // これがsystem promp相当のデータ
      {
        role: 'user', // system promprだが、roleはuserにする

        content: pastContent[0].content
      }
    ]

    setMessageList([{ value: messages[0].content, created_at: new Date() }])
    try {
      const res = await fetch(_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ assistant_id: assistantId, messages })
      }).catch((error) => {
        // ネットワークエラーなどの場合
        console.error('Fetch error:', error)
        // alert('Network error or cannot reach the server.')
        return false // 失敗を示す
      })

      if (!res.ok) {
        // HTTPエラーステータスが返された場合
        // alert(`Error: ${res.status} ${res.statusText}`)
        return false // 失敗を示す
      } else {
        setChatData([...pastContent, loadingChatMessage])
        const { run } = await res.json()
        const runId = run.id
        const thread_id = run.thread_id
        _setThreadId(thread_id)
        setRunId(runId)
        // クエリパラメータの変更
        const currentUrl = new URL(window.location) // 修正: `href`を使用
        currentUrl.searchParams.set('thread_id', thread_id)
        window.history.pushState({}, '', currentUrl)

        const runStatus = await getRunStatus(runId, thread_id)
        if (runStatus === 'completed') {
          const messageList = await _getMessages(thread_id)

          if (messageList.length > 0) {
            // messageList の最後の要素を取得
            const lastMessage = messageList[messageList.length - 1]

            // pastContent に lastMessage を結合して setChatData に適用
            setChatData([...pastContent, lastMessage])
            playTextSound(lastMessage.content, selectedLanguage)
            // 結合した結果をログに出力
            const gptChatMessage = {
              role: lastMessage.role,
              content: lastMessage.content,
              messageTime: new Date().getTime(),
              actualMessageLength: lastMessage.content.length
            }
            const messagesForTokenCounter = [{ role: gptChatMessage.role, content: gptChatMessage.content }]
            addMessageToDB(gptChatMessage, thread_id, messagesForTokenCounter)
          } else {
            // messageList が空の場合は、何らかの処理を行うか、または何もしない
            console.log('messageList is empty')
          }
        }
      }
      return true // 成功を示す
    } catch (error) {
      console.error('Fetch error:', error)
      // alert('Network error or cannot reach the server.')
      return false // 失敗を示す
    }
  }

  const createThreadAndRunForAzure = async ({ pastContent }) => {
    setIsLoading(false)
    setMessageList([])
    let thread_id = ''
    // const _URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_create_thread_and_run'
    const _URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/azure_create_thread_and_run'
    const messages = [
      // これがsystem promp相当のデータ
      {
        role: 'user', // system promprだが、roleはuserにする
        content: pastContent[0].content
      }
    ]
    setMessageList([{ value: messages[0].content, created_at: new Date() }])
    try {
      const requestJson = {
        assistant_id: assistantId,
        messages: messages.map((message) => {
          return {
            role: message.role,
            content: message.content + '\n※file_citationは出力しなくていいです'
          }
        })
      }
      const controller = new AbortController()
      const res = await fetch(_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestJson),
        signal: controller.signal // AbortController の signal を渡す
      })
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`)
      }

      if (!res.ok) {
        // HTTPエラーステータスが返された場合
        // alert(`Error: ${res.status} ${res.statusText}`)
        return false // 失敗を示す
      } else {
        const reader = res.body.getReader()
        const decoder = new TextDecoder('utf-8')
        let count = 0
        let jsons = []
        let message = ''
        const read = async (missingJson = '') => {
          const { done, value } = await reader.read()
          if (done) {
            return reader.releaseLock()
          }
          const chunk = decoder.decode(value, { stream: true })
          const trimData = chunk.trim()

          /////////////////////
          // 最初のJSONオブジェクトを抽出
          const jsonStrings = trimData.split(/(?={"event":"thread.message.delta")/)
          if (trimData === '' || trimData === '[DONE]') {
            jsons = []
          } else {
            // 各JSON文字列を個別にパースして処理
            jsons = jsonStrings.map((jsonString) => {
              try {
                const parsedJson = JSON.parse(jsonString)
                // 必要に応じてparsedJsonを操作
                return parsedJson
              } catch (error) {
                console.error('JSONパースエラー:', error, '問題のある文字列:', jsonString)
                return undefined
              }
            })
          }
          const messageTime = new Date().getTime()
          for (let json of jsons) {
            if (json && json.event === 'thread.created') {
              thread_id = json.data.id
              _setThreadId(thread_id)
              console.log('thread_id', thread_id)
              const currentUrl = new URL(window.location) // 修正: `href`を使用
              currentUrl.searchParams.set('thread_id', thread_id)
              window.history.pushState({}, '', currentUrl)
            }
            if (json && json.event === 'thread.message.delta') {
              message += json.data.delta.content[0].text.value
              const assistantChatMessage = {
                role: 'assistant',
                content: message,
                messageTime,
                characterCount: message.length
              }
              setChatData([...chatData, ...pastContent, assistantChatMessage])
              count = count + 1
              setIsActiveAvator(count)
            }
          }
          return read(missingJson)
        }
        await read()
        playTextSound(message, selectedLanguage)
        const gptChatMessage = {
          role: 'assistant',
          content: message,
          messageTime: new Date().getTime(),
          actualMessageLength: message.length
        }
        const messagesForTokenCounter = [{ role: gptChatMessage.role, content: gptChatMessage.content }]
        addMessageToDB(gptChatMessage, thread_id, messagesForTokenCounter)
        // setChatData([...pastContent, loadingChatMessage])
        // const { run } = await res.json()
        // const runId = run.id
        // const thread_id = run.thread_id
        // _setThreadId(thread_id)
        // setRunId(runId)
        // // クエリパラメータの変更
        // const currentUrl = new URL(window.location) // 修正: `href`を使用
        // currentUrl.searchParams.set('thread_id', thread_id)
        // window.history.pushState({}, '', currentUrl)

        // const runStatus = await getRunStatus(runId, thread_id)
        // if (runStatus === 'completed') {
        //   const messageList = await _getMessages(thread_id)

        //   if (messageList.length > 0) {
        //     // messageList の最後の要素を取得
        //     const lastMessage = messageList[messageList.length - 1]

        //     // pastContent に lastMessage を結合して setChatData に適用
        //     setChatData([...pastContent, lastMessage])
        //     playTextSound(lastMessage.content, selectedLanguage)
        //     // 結合した結果をログに出力
        //     const gptChatMessage = {
        //       role: lastMessage.role,
        //       content: lastMessage.content,
        //       messageTime: new Date().getTime(),
        //       actualMessageLength: lastMessage.content.length
        //     }
        //     const messagesForTokenCounter = [{ role: gptChatMessage.role, content: gptChatMessage.content }]
        //     addMessageToDB(gptChatMessage, thread_id, messagesForTokenCounter)
        //   } else {
        //     // messageList が空の場合は、何らかの処理を行うか、または何もしない
        //     console.log('messageList is empty')
        //   }
        // }
      }
      return true // 成功を示す
    } catch (error) {
      console.error('Fetch error:', error)
      // alert('Network error or cannot reach the server.')
      return false // 失敗を示す
    }
  }

  const _getMessages = async (threadId) => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_get_messages'
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        thread_id: threadId
      })
    })
    const data = await res.json()
    const messageList = data.threadMessagesData
      .map((d) => {
        const content = d.content
        const { type } = content[0]
        if (type === 'text') {
          return { role: 'assistant', content: content[0].text.value, created_at: d.created_at }
        } else {
          return null
        }
      })
      .filter((message) => message !== null)
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))

    setMessageList(messageList)
    return messageList
  }

  const createMessage = async (userChatMessage) => {
    setIsLoading(false)
    setChatData([...chatData, userChatMessage, loadingChatMessage])
    const messageInput = userChatMessage.content

    // setMessageInput('')
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_create_message'
    // const thread_id = 'thread_WgH2mVZYVBp2LtZXmrBBt1Os'
    const message = {
      role: 'user',
      content: messageInput
    }
    setMessageList([...messageList, { value: messageInput, created_at: new Date() }])
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ thread_id: _threadId, message })
    }).catch((error) => {
      // ネットワークエラーなどの場合
      console.error('Fetch error:', error)
      alert('Network error or cannot reach the server.')
      return false // 失敗を示す
    })
    if (!res.ok) {
      // HTTPエラーステータスが返された場合
      alert(`Error: ${res.status} ${res.statusText}`)
      return false // 失敗を示す
    } else {
      const data = await res.json()
      const assistantChatMessage = await runThread()
      setChatData([...chatData, userChatMessage, assistantChatMessage])
      const messagesForTokenCounter = [{ role: assistantChatMessage.role, content: assistantChatMessage.content }]
      addMessageToDB(assistantChatMessage, _threadId, messagesForTokenCounter)
      return true // 失敗を示す
    }
  }

  const createMessageForAzure = async (userChatMessage) => {
    console.log('userChatMessage', userChatMessage)
    setIsLoading(false)
    setChatData([...chatData, userChatMessage, loadingChatMessage])
    const messageInput = userChatMessage.content

    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/azure_create_message'
    const message = {
      role: 'user',
      content: messageInput
    }
    setMessageList([...messageList, { value: messageInput, created_at: new Date() }])
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        thread_id: _threadId,
        message
      })
    }).catch((error) => {
      // ネットワークエラーなどの場合
      console.error('Fetch error:', error)
      alert('Network error or cannot reach the server.')
      return false // 失敗を示す
    })
    if (!res.ok) {
      // HTTPエラーステータスが返された場合
      alert(`Error: ${res.status} ${res.statusText}`)
      return false // 失敗を示す
    } else {
      const { threadMessages } = await res.json()
      console.log(threadMessages)
      const runResult = await createRun({ userChatMessage })
      // const data = await res.json()
      // const assistantChatMessage = await runThread()
      // setChatData([...chatData, userChatMessage, assistantChatMessage])
      // const messagesForTokenCounter = [{ role: assistantChatMessage.role, content: assistantChatMessage.content }]
      // addMessageToDB(assistantChatMessage, _threadId, messagesForTokenCounter)
      return runResult // 失敗を示す
    }
  }

  const createRun = async ({ userChatMessage }) => {
    const controller = new AbortController()
    const assistant_id = assistantId
    const thread_id = _threadId
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/azure_create_run'
    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ assistant_id, thread_id }),
      signal: controller.signal // AbortController の signal を渡す
    })
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }
    const reader = response.body.getReader()
    const decoder = new TextDecoder('utf-8')
    let count = 0
    let jsons = []
    let message = ''
    const read = async (missingJson = '') => {
      const { done, value } = await reader.read()
      if (done) {
        return reader.releaseLock()
      }
      const chunk = decoder.decode(value, { stream: true })
      const trimData = chunk.trim()

      /////////////////////
      // 最初のJSONオブジェクトを抽出
      const jsonStrings = trimData.split(/(?={"event":"thread.message.delta")/)
      if (trimData === '' || trimData === '[DONE]') {
        jsons = []
      } else {
        // 各JSON文字列を個別にパースして処理
        jsons = jsonStrings.map((jsonString) => {
          try {
            const parsedJson = JSON.parse(jsonString)
            // 必要に応じてparsedJsonを操作
            return parsedJson
          } catch (error) {
            console.error('JSONパースエラー:', error, '問題のある文字列:', jsonString)
            return undefined
          }
        })
      }
      const messageTime = new Date().getTime()
      for (let json of jsons) {
        if (json && json.event === 'thread.created') {
          thread_id = json.data.id
          _setThreadId(thread_id)
          console.log('thread_id', thread_id)
          const currentUrl = new URL(window.location) // 修正: `href`を使用
          currentUrl.searchParams.set('thread_id', thread_id)
          window.history.pushState({}, '', currentUrl)
        }
        if (json && json.event === 'thread.message.delta') {
          message += json.data.delta.content[0].text.value
          const assistantChatMessage = {
            role: 'assistant',
            content: message,
            messageTime,
            characterCount: message.length
          }
          setChatData([...chatData, userChatMessage, assistantChatMessage])
          count = count + 1
          setIsActiveAvator(count)
        }
      }
      return read(missingJson)
    }
    await read()
    setDisableSpeaker(false)
    setIsGenerating(false)
    playTextSound(message, selectedLanguage)
    const gptChatMessage = {
      role: 'assistant',
      content: message,
      messageTime: new Date().getTime(),
      actualMessageLength: message.length
    }
    const messagesForTokenCounter = [{ role: gptChatMessage.role, content: gptChatMessage.content }]
    addMessageToDB(gptChatMessage, thread_id, messagesForTokenCounter)
    return true // 成功を示す
  }

  const runThread = async () => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_run_thread'
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        thread_id: _threadId,
        assistant_id: assistantId
      })
    })
    const { run } = await res.json()
    const runId = run.id
    setRunId(runId)
    const runStatus = await getRunStatus(runId, _threadId)
    if (runStatus === 'completed') {
      const messageList = await _getMessages(_threadId)

      if (messageList.length > 0) {
        // messageList の最後の要素を取得
        const lastMessage = messageList[messageList.length - 1]
        playTextSound(lastMessage.content, selectedLanguage)
        const assistantChatMessage = {
          role: 'assistant',
          content: lastMessage.content,
          messageTime: new Date().getTime(),
          actualMessageLength: lastMessage.content.length
        }
        return assistantChatMessage
      } else {
        // messageList が空の場合は、何らかの処理を行うか、または何もしない
        console.log('messageList is empty')
      }
    } else {
    }
  }

  const [abortController, setAbortController] = useState(null)
  useEffect(() => {
    // コンポーネントのクリーンアップ時にストリームをキャンセル
    return () => {
      if (abortController) {
        abortController.abort()
      }
    }
    // conversationIdが変更された時もabortされるようにした
  }, [abortController])
  useEffect(() => {
    if (window.location.pathname === '/submitted_conversations')
      // コンポーネントのクリーンアップ時にストリームをキャンセル
      return () => {
        if (abortController) {
          abortController.abort()
          setAbortController(null) // コントローラのリセット
        }
      }
    // conversationIdが変更された時もabortされるようにした
  }, [conversationId])
  // ストリームをキャンセルするためのボタンのイベントハンドラ
  const handleCancelStream = () => {
    if (abortController) {
      abortController.abort()
      setAbortController(null) // コcントローラのリセット
    }
  }
  const getGptStreamResponse = async ({ model, prompt, pastContent, lang, temperature }) => {
    const controller = new AbortController()
    let allMessages = prompt

    setAbortController(controller)
    setIsLoading(false)

    console.log('allMessages', allMessages)
    console.log('pastContent', pastContent)
    console.log('メッセ', [...chatData, ...pastContent, loadingChatMessage])
    setChatData([...chatData, ...pastContent, loadingChatMessage])
    setIsGenerating(true)
    let reader, decoder
    let count = 0
    let message = ''
    try {
      if (
        _gpt_model
          .filter((model) => model.provider === 'anthropic')
          .map((model) => model.value)
          .includes(model)
      ) {
        console.log('anthropic model: ', model)
        ;[reader, decoder] = await getClaudeStreamingMessageReaderAndDecoder({
          model,
          messages: allMessages,
          temperature: temperature,
          controller
        })
      } else {
        console.log('not anthropic model: ', model)
        ;[reader, decoder] = await getGptStreamingMessageReaderAndDecoder2({
          model,
          messages: allMessages,
          temperature: temperature,
          controller
        })
      }
    } catch (error) {
      setDisableSpeaker(false)
      setIsGenerating(false)
      console.log('error.name', error.name)
      console.log('Error fetching GPT stream:', error)
      console.log('生成を途中で中断しました。')
      const gptChatMessage = {
        role: 'assistant',
        content: '',
        messageTime: new Date().getTime(),
        actualMessageLength: 0
      }
      const messagesForTokenCounter = { role: gptChatMessage.role, content: gptChatMessage.content }
      setChatData([...chatData, ...pastContent, gptChatMessage])
      count = count + 1
      setIsActiveAvator(count)
      await addMessageToDB(gptChatMessage, '', messagesForTokenCounter)
      return
    }
    // setIsGenerating(true)
    try {
      const read = async (missingJson = '') => {
        const { done, value } = await reader.read()
        setDisableSpeaker(true)

        if (done) {
          setDisableSpeaker(false)
          setIsGenerating(false)
          return reader.releaseLock()
        }
        const chunk = decoder.decode(value, { stream: true })
        const jsons = chunk
          .split('data:')
          .map((data) => {
            const trimData = data.trim()
            if (trimData === '' || trimData === '[DONE]') return undefined
            try {
              const jsonObj = JSON.parse(missingJson + trimData)
              missingJson = ''
              return jsonObj
            } catch (e) {
              missingJson = trimData
              return undefined
            }
          })
          .filter((data) => data)
        const messageTime = new Date().getTime()
        ///////////////////////////
        //  Azure OpenAI Service の Stream 出力をスムーズに見せかけるトリック (see: https://qiita.com/nohanaga/items/eea68e0b7a0f417c5276)
        for (let test of jsons) {
          await new Promise((resolve) => {
            setTimeout(() => {
              if ((test.choices && test.choices.length !== 0) || test.text !== undefined) {
                if ((test.choices && test.choices[0].delta.content !== undefined) || test.text !== undefined) {
                  message += test.choices ? test.choices[0].delta.content : test.text
                  const assistantChatMessage = {
                    role: 'assistant',
                    content: message,
                    messageTime,
                    characterCount: message.length
                  }
                  setChatData([...chatData, ...pastContent, assistantChatMessage])
                  count = count + 1
                  setIsActiveAvator(count)
                }
              }
              resolve(null)
            }, 33)
          })
        }
        return read(missingJson)
      }
      await read()
      playTextSound(message, lang)
      const gptChatMessage = {
        role: 'assistant',
        content: message,
        messageTime: new Date().getTime(),
        actualMessageLength: message.length
      }
      const messagesForTokenCounter = [{ role: gptChatMessage.role, content: gptChatMessage.content }]
      addMessageToDB(gptChatMessage, '', messagesForTokenCounter)
    } catch (e) {
      console.log(e.name)
      if (e.name === 'AbortError') {
        console.log('生成を途中で中断しました。')
        setDisableSpeaker(false)
        setIsGenerating(false)
        reader.releaseLock()
        const gptChatMessage = {
          role: 'assistant',
          content: message,
          messageTime: new Date().getTime(),
          actualMessageLength: message.length
        }
        const messagesForTokenCounter = [{ role: gptChatMessage.role, content: gptChatMessage.content }]
        addMessageToDB(gptChatMessage, '', messagesForTokenCounter)
      } else {
        console.error(e)
        reader.releaseLock()
      }
      setDisableSpeaker(false)
      setIsGenerating(false)
    }
  }

  // eslint-disable-next-line
  const onTextInput = async (text) => {
    // firebaseに登録される前一時的にローカールデータで表示
    const content = text ? text : chatInput
    if (isWithinThreadSendingLimit()) return
    if (isWithinThreadCharacterLimitPerDay(content)) return
    if (isWithinThreadCharacterLimitPerMonth(content)) return

    if (chatInput && previewUrls.length > 0) {
      const formatPreviewUrls = (previewUrls) => {
        return previewUrls.map((url) => ({
          image_url: {
            url: url
          },
          type: 'image_url'
        }))
      }
      setChatData([
        ...chatData,
        {
          role: 'user',
          content: [
            {
              type: 'text',
              text: chatInput
            },
            ...formatPreviewUrls(previewUrls)
          ]
        }
      ])
    } else if (chatInput) {
      setChatData([
        ...chatData,
        {
          role: 'user',
          content: chatInput
        }
      ])
    }
    setChatInput('')

    const actualMessageLength = chatData.reduce((total, chat) => total + chat.content.length, 0) + content.length
    let userChatMessage = {}

    userChatMessage = {
      role: 'user',
      content: content,
      messageTime: new Date().getTime(),
      actualMessageLength: actualMessageLength
    }
    const chatDataForGpt = chatData.map((item, index) => {
      return {
        role: item.role,
        content: item.content
      }
    })
    let pastContent = []
    if (isPastMessageLimitCountSet === true) {
      const systemPrompt = chatDataForGpt.filter((item) => item.role === 'system')
      const n = maximumPastMessageLimitCount - 1
      const slicedChatDataForGPT = n > 0 ? chatDataForGpt.slice(-n) : []
      pastContent = [...systemPrompt, ...slicedChatDataForGPT]
    } else {
      pastContent = chatDataForGpt
    }
    const messagesForTokenCounter = pastContent
    let imageInfo = []
    let imageUrls
    setIsGenerating(true)
    if (files.length !== 0) {
      const { newMessageRef, updatedUserChatMesasge } = await addMessageToDB(
        userChatMessage,
        '',
        messagesForTokenCounter
      )
      // userChatMesasgeForChatData = updatedUserChatMesasge
      userChatMessage = updatedUserChatMesasge
      await Promise.all(
        files.map(async (image, index) => {
          const iconImagePath = `conversationPictures/${user.uid}/${newConversationId}/${newMessageRef.id}/${index + 1}`
          const storageRef = ref(storage, iconImagePath)
          try {
            // 画像をアップロードして、その結果を取得
            const uploadSnapshot = await uploadBytes(storageRef, image)
            // アップロード情報をオブジェクトに追加
            imageInfo.push({
              iconImageUrl: await getDownloadURL(uploadSnapshot.ref),
              iconImagePath: uploadSnapshot.metadata.fullPath || ''
            })

            imageUrls = imageInfo.map((info) => ({
              type: 'image_url',
              image_url: {
                url: info.iconImageUrl
                // url: 'https://cdn.p-nest.co.jp/c/wanchan.jp/pro/crop/1200x800/center/8/b57fa844ed0d75274b3b65188e9c7cc9.jpg'
              }
            }))
          } catch (error) {
            console.error(`画像${index + 1}のエラー:`, error)
          }
        })
      )

      try {
        // Firestore ドキュメントを更新
        const _content = [
          {
            type: 'text',
            text: content
          },
          ...imageUrls
        ]
        userChatMessage = {
          ...userChatMessage,
          content: _content,
          messageTime: new Date().getTime(),
          imageInfo: imageInfo
        }

        await updateDoc(newMessageRef, { message: _content }, { imageInfo: imageInfo })
      } catch (error) {
        console.error('doc更新エラー:', error)
      }
      setPreviewUrls([])
      setFiles([])
    } else {
      const { newMessageRef, updatedUserChatMesasge } = await addMessageToDB(
        userChatMessage,
        '',
        messagesForTokenCounter
      )

      userChatMessage = updatedUserChatMesasge
    }

    /*setChatDataにimageInfoも必要か?contentにオブジェクト配列でimageUrl入っていればOKか*/

    setChatData([...chatData, userChatMessage])
    let prompt = []

    // let imageUrls = []
    if (files.length !== 0) {
      prompt = [
        ...pastContent,
        {
          role: 'user',
          content: [
            {
              type: 'text',
              text: content
            },
            ...imageUrls
          ]
        }
      ]
    } else {
      prompt = [
        ...pastContent,
        {
          role: 'user',
          content
        }
      ]
    }

    setIsLoading(true)
    if (isStreaming) {
      if (_useAssistantApi) {
        let success
        if (!_threadId) {
          if (model && model.includes('azure')) {
            console.log('azure含む')
            success = await createThreadAndRunForAzure({ pastContent: [userChatMessage] })
          } else {
            success = await createThreadAndRun({ pastContent: [userChatMessage] })
          }
        } else {
          if (model && model.includes('azure')) {
            success = await createMessageForAzure(userChatMessage)
          } else {
            success = await createMessage(userChatMessage)
          }
        }
        if (!success) {
          _setUseAssistantApi(null)
          console.log('Fallback to GPT Stream Response')
          await getGptStreamResponse({ model, prompt, pastContent: [userChatMessage], temperature })
        }
      } else {
        await getGptStreamResponse({ model, prompt, pastContent: [userChatMessage], temperature })
      }
    } else {
      const res = await getGptMessage({ model, prompt: prompt })
      const { message } = res.data
      playTextSound(message)
      const gptChatMessage = {
        role: 'assistant',
        content: message,
        messageTime: new Date().getTime(),
        characterCount: message.length,
        actualMessageLength: message.length
      }
      const messagesForTokenCounter = [{ role: gptChatMessage.role, content: gptChatMessage.content }]
      addMessageToDB(gptChatMessage, '', messagesForTokenCounter)

      setChatData([...chatData, ...[userChatMessage, gptChatMessage]])
    }
  }

  const checkCharacterCount = (latestMessage, roles, startTime, endTime) => {
    const filteredMessages = chatData.filter(
      (message) => roles.includes(message.role) && message.messageTime >= startTime && message.messageTime < endTime
    )
    if (latestMessage.length === 0) {
      const totalCharacterCount = filteredMessages.reduce((total, message) => total + message.characterCount, 0)
      return totalCharacterCount
    } else {
      const userCharacterCount = filteredMessages.reduce((total, message) => total + message.characterCount, 0)
      const totalCharacterCount = userCharacterCount + latestMessage.length
      return { userCharacterCount, totalCharacterCount }
    }
  }

  const getImageFromGPT = async () => {
    if (!chatInput.replaceAll(/\s+/g, '')) {
      alert('タップして返信をかく の入力エリアにどのような画像を作成したいかを書いてからクリックしてね😊')
      return
    }
    const prompt = [
      {
        role: 'system',
        content: `次の文章を英訳して、英訳のみをレスポンスしてください。${chatInput}`
      }
    ]
    try {
      setIsLoading(true)
      setGeneratingImage(true)
      const res = await getGptMessage({ model, prompt: prompt })
      const { message } = res.data
      const imageURL = await getGptImage({ model, prompt: message, size: '256x256' })
      window.open(imageURL.data.image_url, '_blank')
    } catch (e) {
    } finally {
      setIsLoading(false)
      setGeneratingImage(false)
    }
  }
  // モーダルを開く関数
  const openModal = () => {
    setModalOpen(true)
  }

  // モーダルを閉じる関数
  const closeModal = () => {
    setModalOpen(false)
  }
  const [currentMessageData, setCurrentMessageData] = useState()
  const openModalForSubmission = (message) => {
    setCurrentMessageData(message && message)
    setIsModalOpenForSubmission(true)
    fetchSubmissionBoxes(user).catch(console.error)
  }
  const openModalForConversationSubmission = () => {
    setIsModalOpenForSubmission(true)
    fetchSubmissionBoxes(user).catch(console.error)
  }

  // モーダルを閉じる関数
  const closeModalForSubmission = () => {
    setIsModalOpenForSubmission(false)
  }

  useEffect(() => {
    if (!file) {
      setPreviewUrl(null)
      return
    }
    const fileReader = new FileReader()
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result)
    }
    fileReader.readAsDataURL(file)
  }, [file]) // ファイルが変更されたときにプレビューを更新

  // プレビュー画像を削除
  const removePreview = (indexToRemove) => {
    // 削除対象のプレビューURLを解放
    const urlToRemove = previewUrls[indexToRemove]
    URL.revokeObjectURL(urlToRemove)

    // プレビューURLとファイルリストから削除
    const newPreviewUrls = previewUrls.filter((_, index) => index !== indexToRemove)
    const newFiles = files.filter((_, index) => index !== indexToRemove)

    setPreviewUrls(newPreviewUrls)
    setFiles(newFiles)

    // ホバー状態のリセットまたは更新
    setIsHovering(false) // 削除操作後はホバー状態をリセット
  }
  useEffect(() => {
    if (!userId || readOnly === false) return
    // 会話ログ・提出画面用
    const fetchConversations = async () => {
      let conversationsDocRef
      let originalConversationsDocRef
      let messagesCollectionRef
      // 提出
      if (window.location.pathname === '/submitted_conversations') {
        const userDocRef = doc(db, 'users', submitterUserId)

        conversationsDocRef = doc(userDocRef, conversationType, conversationId)
        originalConversationsDocRef = doc(
          userDocRef,
          conversationType,
          conversation.originalConversationId ? conversation.originalConversationId : conversationId
        )

        messagesCollectionRef = collection(
          db,
          `/externalServices/mingaku/schools/${schoolId}/submissionBoxes/${submittionBox}/submitters/${submitterUserId}/conversations/${conversationId}/messages`
        )
        const q = query(messagesCollectionRef, orderBy('createdAt', 'asc'))
        await getMessages(q, conversationsDocRef)
        const docSnapshotForAvator = await getDoc(originalConversationsDocRef)
        await updateAvatarImages(docSnapshotForAvator)
      } else {
        //  会話ログ
        const userDocRef = doc(db, 'users', userId)
        conversationsDocRef = doc(userDocRef, conversationType, conversationId)
        messagesCollectionRef = collection(conversationsDocRef, 'messages')
        const q = query(messagesCollectionRef, orderBy('createdAt', 'asc'))
        await getMessages(q, conversationsDocRef)
      }

      // setIsLoading(false)
    }
    // setIsLoading(true)
    fetchConversations()
    // eslint-disable-next-line
  }, [conversationId, conversationType, userId, submitterUserId])
  let replacedMessage2 = replacedMessage
  const [messageDocRef, setMessageDocRef] = useState(null)
  const startHandler = async () => {
    setGeneratingImage(true)
    const lang = JSON.stringify({
      physicalName: 'ja',
      languageCode: 'ja-JP',
      type: 'ja-JP-Neural2-B',
      japaneseName: '日本語(女性)',
      shortenName: '日(女)'
    })
    // playTextSound('', lang)
    playTextSound('', selectedLanguage)
    const promptDocRef = doc(db, promptDocRefPath) // 渡されたpromptDocのfullPathからRefを作成
    setInitialPromptRef(promptDocRef)

    const role = _useAssistantApi ? 'user' : 'system'
    const userDocRef = doc(db, 'users', user.uid)
    const conversationsCollectionRef = collection(userDocRef, 'conversations')
    const conversationsDocRef = doc(conversationsCollectionRef)
    setNewConversationId(conversationsDocRef.id)
    // クエリパラメータの変更
    const currentUrl = new URL(window.location)
    currentUrl.searchParams.set('conversation_id', conversationsDocRef.id)
    window.history.pushState({}, '', currentUrl)

    await setDoc(conversationsDocRef, {
      conversationId: conversationsDocRef.id,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      model,
      promptRef: initialPromptRef
    })
    setNewConversationId(conversationsDocRef.id)
    const messagesCollectionRef = collection(conversationsDocRef, 'messages')
    const messagesDocRef = doc(messagesCollectionRef)
    setMessageDocRef(messagesDocRef)
    console.log('sentenceWordReplacementList', sentenceWordReplacementList)
    // 各オブジェクト内のkeyとvalueを使用してmessageを置き換える
    sentenceWordReplacementList.forEach((item) => {
      const placeholder = `{${item.key}}`
      const replacement = item.value
      replacedMessage2 = replacedMessage2.replaceAll(placeholder, replacement)
    })
    const messagesForTokenCounter = [{ role: role, content: replacedMessage2 }]
    const { data } = await tokenCounter({
      text: replacedMessage2,
      role: role,
      model: model,
      messagesForTokenCounter: messagesForTokenCounter // 過去の会話履歴を含む
    })
    const { characterCount, tokenCount, tokenCountOfMessages } = data
    if (userInputValue) {
      await setDoc(messagesDocRef, {
        messageId: messagesDocRef.id,
        message: replacedMessage2,
        role: 'system',
        model,
        createdAt: new Date().getTime(),
        userInput: true,
        userInputValue,
        characterCount,
        tokenCount,
        tokenCountOfMessages, // 過去の会話履歴を含む
        actualMessageLength: replacedMessage2.length
      })
    } else {
      console.log('setDoc実行　２', replacedMessage2)

      await setDoc(messagesDocRef, {
        messageId: messagesDocRef.id,
        message: replacedMessage2,
        role: 'system',
        model,
        createdAt: new Date().getTime(),
        characterCount,
        tokenCount,
        tokenCountOfMessages, // 過去の会話履歴を含む
        actualMessageLength: replacedMessage2.length
      })
    }
    // 質問でインプットされた画像のアップロード
    // if (userInputImageFiles && userInputImageFiles.length > 0) {
    console.log('userInputImages', state.existingUserInputImages)
    if (state.existingUserInputImages) {
      // typeが'image'のオブジェクトが存在する場合の処理をここに記述
      console.log('画像登録開始')

      const newMessagesDocRef = doc(messagesCollectionRef)
      const userInputImageUrls = await uploadImagesToFirestore(conversationsDocRef.id, newMessagesDocRef.id)
      console.log('userInputImageUrls', userInputImageUrls)
      const additionalInitialMessage = [
        // {
        //   type: 'text',
        //   text: '画像はこちらです。'
        // },
        ...userInputImageUrls
          .filter((url) => typeof url === 'string' && url) // URLが文字列であり、かつ空でないことを確認
          .map((url) => ({
            type: 'image_url',
            image_url: {
              url: url
            }
          }))
      ]
      await setDoc(newMessagesDocRef, {
        messageId: newMessagesDocRef.id,
        message: additionalInitialMessage,
        role: 'user',
        model,
        createdAt: new Date().getTime(),
        characterCount,
        tokenCount,
        tokenCountOfMessages, // 過去の会話履歴を含む
        actualMessageLength: additionalInitialMessage.length,
        hide: true //画像を送るrole:userのメッセージをチャット画面で隠すようにする
      })
      console.log('chatData', chatData)
      console.log('userInputImageUrls', userInputImageUrls)

      // 画像のurlが取得できたので、systemのメッセージドキュメントに保持されているuserInputValueを更新
      // userInputValueのうち、typeが'image'のものに順番にimageUrlを追加
      const updatedUserInputValue = userInputValue.map((item, index) => {
        if (item.type === 'image' && userInputImageUrls[index]) {
          console.log('userInputImageUrls[index]', userInputImageUrls[index])
          return {
            ...item,
            url: userInputImageUrls[index] // imageUrlを追加
          }
        }
        return item // それ以外はそのまま
      })
      console.log('更新するuserInputvalue', updatedUserInputValue)
      // userInputValueを更新
      await updateDoc(messagesDocRef, {
        userInputValue: updatedUserInputValue
      })
      console.log(chatData)
    }
    _setStart(true)
    onStartChange(true)
    _setStartButton(false)
    setGeneratingImage(false)
  }
  const initialUserData = _fields_of_user_data_in_mypage.reduce((acc, field) => {
    acc[field.value] = ''
    return acc
  }, {})
  const [userDataOfMypage, setUserDataOfMypage] = useState(initialUserData)
  const handleUserDataChange = (updates) => {
    setUserDataOfMypage((prevData) => ({
      ...prevData,
      ...updates
    }))
  }
  useEffect(() => {
    if (readOnly === true) return
    let replacementList = []
    ;(async () => {
      if (_start === false && _startButton === true) {
        setGeneratingImage(true)
        if (!!state.userInputValue) {
          replacementList = [...state.userInputValue]
        }
        const userDocRef = doc(db, 'users', user ? user.uid : state.uid)
        const userSnapshot = await getDoc(userDocRef)
        const userData = userSnapshot.data()
        // ここで名前とスクール名などすべて取得
        let mingakuUserData
        console.log('変換実行')
        if (!!userData.studentDocId || userData.role === 'tutor') {
          // mingakuUserData = await updateUserData(userSnapshot)
          mingakuUserData = await updateUserData(userSnapshot)

          handleUserDataChange(mingakuUserData)
        } else {
          const { mingakuUserId, email } = userSnapshot.data()
          if (mingakuUserId === undefined) {
            alert(
              'このアカウントはみんがくアカウントと連携されてません。連携を完了させるために、みんがく管理画面のスクールAI管理からスクールAIにアクセスしてください。(全ての機能を利用するためには、みんがくアカウントとの連携が必要です。)'
            )
          } else {
            mingakuUserData = await getUserDataFromMingaku(userSnapshot)
            console.log('mingakuUserData 生徒', mingakuUserData)

            handleUserDataChange(mingakuUserData)
          }
        }
        const fixedMingakuUserData = Object.entries(mingakuUserData).map(([key, value]) => ({
          title: `${key}`,
          key: `${key}`,
          value: value
        }))
        const mypageUserData = Object.entries(mingakuUserData).map(([key, value]) => ({
          title: `${key}.mypage`,
          key: `${key}.mypage`,
          value: value
        }))
        replacementList = [...replacementList, ...fixedMingakuUserData, ...mypageUserData]

        if (userData.type === 'mingaku' && !!userData.studentDocId) {
          const getStudentInfo = httpsCallable(functions, 'get_student_info_of_mingaku')
          const getSchoolsInfo = httpsCallable(functions, 'get_schools_info')
          const [studentInfo, schoolInfo] = await Promise.all([
            getStudentInfo({ nandeUserId: user ? user.uid : state.uid }),
            getSchoolsInfo({ schoolIds: [userData.schoolDocId] })
          ])

          const customProperties = schoolInfo.data.schoolInfo[0].customProperties

          if (customProperties.length !== 0) {
            const studentProperties = studentInfo.data.data.properties
            const studentCustomPropertiesReplacementList = customProperties.map((item) => {
              const foundProperty = studentProperties.find((studentProperty) => studentProperty.id === item.id)
              return {
                title: item.title,
                key: item.title, // customPropertiesは物理名を持っていないため論理名(title)をKeyとする
                value: foundProperty ? foundProperty.value : ''
              }
            })

            replacementList = [...replacementList, ...studentCustomPropertiesReplacementList]
          }
          const studentInfoReplacementList = _inserted_default_target_student_info.map((item) => {
            const value = studentInfo.data.data[item.key]
            return {
              title: item.title,
              key: item.key,
              value
            }
          })

          replacementList = [...replacementList, ...studentInfoReplacementList]
        } else if (userData.type === 'mingaku' && userData.role === 'tutor') {
          const getSchoolsInfo = httpsCallable(functions, 'get_schools_info')
          const schoolInfo = await getSchoolsInfo({ schoolIds: [userData.schoolDocId] })
          const customProperties = schoolInfo.data.schoolInfo[0].customProperties
          if (customProperties.length !== 0) {
            const studentCustomPropertiesReplacementList = customProperties.map((item) => {
              return {
                title: item.title,
                key: item.title, // customPropertiesは物理名を持っていないため論理名(title)をKeyとする
                value: '' // tutorの場合は空にする
              }
            })
            replacementList = [...replacementList, ...studentCustomPropertiesReplacementList]
          }
          const studentInfoReplacementList = _inserted_default_target_student_info.map((item) => {
            return {
              title: item.title,
              key: item.key,
              value: '' // tutorの場合は空にする
            }
          })
          replacementList = [...replacementList, ...studentInfoReplacementList]
        }
        setSentenceWordReplacementList(replacementList)
        setGeneratingImage(false)
      }
    })()
    // eslint-disable-next-line
  }, [_start, _startButton])

  useEffect(() => {
    if (readOnly === true) return
    if (promptDocRefPath !== undefined) {
      const promptDocRef = doc(db, promptDocRefPath) // 渡されたpromptDocのfullPathからRefを作成
      setInitialPromptRef(promptDocRef)
    }
    // eslint-disable-next-line
  }, [promptDocRefPath])

  useEffect(() => {
    if (readOnly === true) return

    isFirstRender.current = true
    let localIsMute = isMute ? 1 : 0
    localStorage.setItem('localIsMute', localIsMute)
    // eslint-disable-next-line
  }, [isMute])

  useEffect(() => {
    if (readOnly === true) return

    if (finalTranscript !== '') {
      // 入力完了後
      setChatInput(previousChatInput + finalTranscript)
      if (islistening) {
        chatInput.replaceAll(/\s+/g, '') !== '' && autoSending && onTextInput()
      }
      setIsListening(false)
      SpeechRecognition.stopListening()
    } else {
      // 入力中
      setChatInput(previousChatInput + transcript)
    }
    // eslint-disable-next-line
  }, [interimTranscript, finalTranscript])

  const [newConversationsDocRef, setNewConversationsDocRef] = useState()
  useEffect(() => {
    if (readOnly === true) return
    if (_audio) {
      console.log('audio pause.')

      _audio.pause()
    }
    setIsMute(defaultMute)
    if (state) {
      sessionStorage.setItem('redirectPageUrl', state.redirectPageUrl)
    }

    if (isFirstRender.current && _startButton === true) {
      isFirstRender.current = false
    } else {
      const fetchConversations = async () => {
        if (!user || !user.uid) {
          console.log('User or user.uid not available')
          return
        } // userがnullまたはundefinedの場合、早期リターン
        const userDocRef = doc(db, 'users', user.uid)

        const conversationsDocRef = doc(
          userDocRef,
          conversationType,
          conversationId ? conversationId : newConversationId
        )
        localStorage.setItem('reloadConversationId', conversationId ? conversationId : state.conversationId)

        const messagesCollectionRef = collection(conversationsDocRef, 'messages')
        const q = query(messagesCollectionRef, orderBy('createdAt', 'asc'))

        try {
          setNewConversationsDocRef(conversationsDocRef)

          await getMessages(q, conversationsDocRef)
          await getPromptTitle(conversationsDocRef)
          setIsLoading(false)
          // setIsBubbleLoading(false)
        } catch (error) {
          console.error('Error fetching conversation data: ', error)
        }
      }
      if (user && user.uid) {
        setIsLoading(true)
        fetchConversations() // user と user.uid が存在する場合のみ fetchConversations を呼び出す
      }
    }
    // eslint-disable-next-line
  }, [_start, user, conversationId])

  useEffect(() => {
    ;(async () => {
      if (!_start && !_startButton) {
        let replacementList = []
        if (!!conversation.userInputValue) {
          replacementList = [...conversation.userInputValue]
        }
        const userDocRef = doc(db, 'users', user ? user.uid : state.uid)
        const userSnapshot = await getDoc(userDocRef)
        const userData = userSnapshot.data()
        // ここで名前とスクール名などすべて取得
        let mingakuUserData
        if (!!userData.studentDocId || userData.role === 'tutor') {
          // mingakuUserData = await updateUserData(userSnapshot)
          mingakuUserData = await updateUserData(userSnapshot)
          handleUserDataChange(mingakuUserData)
        } else {
          const { mingakuUserId, email } = userSnapshot.data()
          if (mingakuUserId === undefined) {
            alert(
              'このアカウントはみんがくアカウントと連携されてません。連携を完了させるために、みんがく管理画面のスクールAI管理からスクールAIにアクセスしてください。(全ての機能を利用するためには、みんがくアカウントとの連携が必要です。)'
            )
          } else {
            mingakuUserData = await getUserDataFromMingaku(userSnapshot)
            handleUserDataChange(mingakuUserData)
          }
        }
        const fixedMingakuUserData = Object.entries(mingakuUserData).map(([key, value]) => ({
          title: `${key}`,
          key: `${key}`,
          value: value
        }))
        const mypageUserData = Object.entries(mingakuUserData).map(([key, value]) => ({
          title: `${key}.mypage`,
          key: `${key}.mypage`,
          value: value
        }))
        replacementList = [...replacementList, ...fixedMingakuUserData, ...mypageUserData]

        if (userData.type === 'mingaku' && !!userData.studentDocId) {
          const getStudentInfo = httpsCallable(functions, 'get_student_info_of_mingaku')
          const getSchoolsInfo = httpsCallable(functions, 'get_schools_info')
          const [studentInfo, schoolInfo] = await Promise.all([
            getStudentInfo({ nandeUserId: user ? user.uid : state.uid }),
            getSchoolsInfo({ schoolIds: [userData.schoolDocId] })
          ])

          const customProperties = schoolInfo.data.schoolInfo[0].customProperties

          if (customProperties.length !== 0) {
            const studentProperties = studentInfo.data.data.properties
            const studentCustomPropertiesReplacementList = customProperties.map((item) => {
              const foundProperty = studentProperties.find((studentProperty) => studentProperty.id === item.id)
              return {
                title: item.title,
                key: item.title, // customPropertiesは物理名を持っていないため論理名(title)をKeyとする
                value: foundProperty ? foundProperty.value : ''
              }
            })

            replacementList = [...replacementList, ...studentCustomPropertiesReplacementList]
          }
          const studentInfoReplacementList = _inserted_default_target_student_info.map((item) => {
            const value = studentInfo.data.data[item.key]
            return {
              title: item.title,
              key: item.key,
              value
            }
          })

          replacementList = [...replacementList, ...studentInfoReplacementList]
        } else if (userData.type === 'mingaku' && userData.role === 'tutor') {
          const getSchoolsInfo = httpsCallable(functions, 'get_schools_info')
          const schoolInfo = await getSchoolsInfo({ schoolIds: [userData.schoolDocId] })
          const customProperties = schoolInfo.data.schoolInfo[0].customProperties
          if (customProperties.length !== 0) {
            const studentCustomPropertiesReplacementList = customProperties.map((item) => {
              return {
                title: item.title,
                key: item.title, // customPropertiesは物理名を持っていないため論理名(title)をKeyとする
                value: '' // tutorの場合は空にする
              }
            })
            replacementList = [...replacementList, ...studentCustomPropertiesReplacementList]
          }
          const studentInfoReplacementList = _inserted_default_target_student_info.map((item) => {
            return {
              title: item.title,
              key: item.key,
              value: '' // tutorの場合は空にする
            }
          })
          replacementList = [...replacementList, ...studentInfoReplacementList]
        }
        setSentenceWordReplacementList(replacementList)
      }
    })()
  }, [conversation])
  const updateAvatarImages = async (docSnapshot) => {
    if (!!docSnapshot.data() && !!docSnapshot.data().promptRef) {
      const initialPromptRef = docSnapshot.data().promptRef
      const aaa = await getDoc(initialPromptRef)
      let avatorImages = [ai, ai2]
      if (aaa.data() !== undefined && !!aaa.data().iconImageUrl && !!aaa.data().iconImageUrl2) {
        avatorImages = [aaa.data().iconImageUrl, aaa.data().iconImageUrl2]
        setAvatorImages(avatorImages)
      } else if (aaa.data() !== undefined && (!!aaa.data().iconImageUrl || !!aaa.data().iconImageUrl2)) {
        const imageUrl = aaa.data().iconImageUrl ? aaa.data().iconImageUrl : aaa.data().iconImageUrl2
        avatorImages = [imageUrl, imageUrl]
        setAvatorImages(avatorImages)
      } else {
        setAvatorImages(avatorImages)
      }
    }
  }
  const getMessages = async (q, conversationsDocRef) => {
    const messageModel = model

    const matchingModel = _gpt_model.find((item) => item.value === messageModel)

    const docSnapshot = await getDoc(conversationsDocRef)

    if (window.location.pathname !== '/submitted_conversations') {
      await updateAvatarImages(docSnapshot)
    }
    const querySnapshot = await getDocs(q)
    const chatMessages = querySnapshot.docs.map((doc) => {
      let content = doc.data().message

      // If matchingModel exists and availableVision is false, filter the content array if it is an array
      if (matchingModel && !matchingModel.availableVision && Array.isArray(content)) {
        content = content.filter((item) => item.type === 'text')
        console.log('contenc', content)
      }
      return {
        // content: doc.data().message,
        content: content,
        role: doc.data().role ? doc.data().role : 'system',
        messageTime: doc.data().createdAt,
        model: doc.data().model,
        userInput: doc.data().userInput,
        userInputValue: doc.data().userInputValue,
        characterCount: doc.data().characterCount ?? '',
        readDone: true,
        messageId: doc.data().messageId,
        tokenCountOfMessages: doc.data().tokenCountOfMessages,
        actualMessageLength: doc.data().actualMessageLength,
        tokenCount: doc.data().tokenCount,
        hide: doc.data().hide ?? false,
        ref: doc.ref
      }
    })
    console.log('chatMessages', chatMessages)
    if (querySnapshot.docs.length > 0) {
      // Set the first message's reference
      setMessageDocRef(querySnapshot.docs[0].ref)
    }
    if (readOnly === true) {
      // 読み取り専用会話ログ画面用
      setChatData([...chatMessages])
    } else if (readOnly === false) {
      // ホーム画面・チャット画面用
      const lastChatMessage = chatMessages[chatMessages.length - 1]
      const { role, model } = lastChatMessage
      if (role === 'assistant') {
        const docSnapshot = await getDoc(conversationsDocRef)
        let lang = ''
        if (!!docSnapshot.data().promptRef) {
          const initialPromptRef = docSnapshot.data().promptRef
          const prompt_data = await getDoc(initialPromptRef)
          if (!!prompt_data.data()) {
            const responceOptions =
              prompt_data.data().responseOptions.length !== 0 ? prompt_data.data().responseOptions : _responseOptions
            const sortedResponceOptions = responceOptions.sort(function (a, b) {
              return a.order < b.order ? -1 : 1
            })

            const matchingLanguage = getMatchingLanguage(prompt_data.data().prompt_language2)
            lang = matchingLanguage
              ? JSON.stringify(matchingLanguage)
              : JSON.stringify({
                  physicalName: 'ja',
                  languageCode: 'ja-JP',
                  type: 'ja-JP-Neural2-B',
                  japaneseName: '日本語(女性)',
                  shortenName: '日(女)'
                })
            setPromptData(prompt_data.data())
            setSelectedLanguage(lang)
            setResponseOptions(
              sortedResponceOptions.map((item) => {
                return { option: item.option, content: item.content ? item.content : item.option }
              })
            )
          }
        }
      }
      if (role === 'user' || role === 'system') {
        setChatData(chatMessages)
        const prompt = chatMessages.map((item) => {
          return {
            role: item.role,
            content: item.content
          }
        })
        setIsLoading(true)

        const docSnapshot = await getDoc(conversationsDocRef)

        let lang = ''
        if (!!docSnapshot.data().promptRef) {
          const initialPromptRef = docSnapshot.data().promptRef
          const prompt_data = await getDoc(initialPromptRef)

          if (!!prompt_data.data().responseOptions) {
            const responceOptions =
              prompt_data.data().responseOptions.length !== 0 ? prompt_data.data().responseOptions : _responseOptions
            const sortedResponceOptions = responceOptions.sort(function (a, b) {
              return a.order < b.order ? -1 : 1
            })

            const matchingLanguage = getMatchingLanguage(prompt_data.data().prompt_language2)
            lang = matchingLanguage
              ? JSON.stringify(matchingLanguage)
              : JSON.stringify({
                  physicalName: 'ja',
                  languageCode: 'ja-JP',
                  type: 'ja-JP-Neural2-B',
                  japaneseName: '日本語(女性)',
                  shortenName: '日(女)'
                })
            setPromptData(prompt_data.data())
            setSelectedLanguage(lang)
            setResponseOptions(
              sortedResponceOptions.map((item) => {
                return { option: item.option, content: item.content ? item.content : item.option }
              })
            )
          }
        }

        if (isStreaming) {
          console.log('_useAssistantApi', _useAssistantApi)
          if (_useAssistantApi) {
            console.log('model', model)
            let success
            if (model && model.includes('azure')) {
              console.log('azure含む')
              success = await createThreadAndRunForAzure({ pastContent: chatMessages })
            } else {
              // success = await createThreadAndRun({ pastContent: chatMessages })
            }

            if (!success) {
              _setUseAssistantApi(null)
              console.log('Fallback to GPT Stream Response')
              await getGptStreamResponse({ model, prompt, pastContent: chatMessages, temperature })
            }
          } else {
            console.log('222')
            await getGptStreamResponse({ model, prompt, pastContent: chatMessages, temperature })
          }
        } else {
          console.log('333')

          const res = await getGptMessage({ model, prompt: prompt })
          const { message } = res.data
          setTimeout(function () {
            playTextSound(message, lang)
          }, 1000)
          const gptChatMessage = {
            role: 'assistant',
            content: message,
            messageTime: new Date().getTime(),
            characterCount: message.length,
            actualMessageLength: message.length
          }
          const messagesForTokenCounter = [{ role: gptChatMessage.role, content: gptChatMessage.content }]
          addMessageToDB(gptChatMessage, '', messagesForTokenCounter)
          setChatData([...chatMessages, ...[gptChatMessage]])
        }
      } else {
        setChatData([...chatMessages])
      }
    }
  }

  const formatCurrentDate = () => {
    const now = new Date()
    const year = now.getFullYear() // 年を取得
    const month = (now.getMonth() + 1).toString().padStart(2, '0') // 月を取得（getMonth()は0から始まるため+1する）
    const day = now.getDate().toString().padStart(2, '0') // 日を取得
    const hours = now.getHours().toString().padStart(2, '0') // 時を取得
    const minutes = now.getMinutes().toString().padStart(2, '0') // 分を取得
    const seconds = now.getSeconds().toString().padStart(2, '0') // 秒を取得

    return `${year}年${month}月${day}日 ${hours}:${minutes}:${seconds}`
  }
  const [anchorEl, setAnchorEl] = useState(null)
  const cleanMessageData = (data) => {
    const cleanedData = {}
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) {
        if (key === 'messageTime') {
          // messageTimeがあればcreatedAtとしてセット
          cleanedData['createdAt'] = data[key]
        }
        if (key === 'content') {
          // messageTimeがあればcreatedAtとしてセット
          cleanedData['message'] = data[key]
        } else {
          // それ以外のundefinedでない値をコピー
          cleanedData[key] = data[key]
        }
      }
    })
    return cleanedData
  }

  const getToturName = async (userDocData) => {
    if (userDocData.role === 'tutor') {
      const { schoolDocId, tutorDocId } = userDocData
      const getTutorInfo = httpsCallable(functions, 'get_tutor_info_of_mingaku')
      const getSchoolsInfo = httpsCallable(functions, 'get_schools_info')
      const [tutorIndo, schoolInfo] = await Promise.all([
        getTutorInfo({ userId: tutorDocId, schoolId: schoolDocId }),
        getSchoolsInfo({ schoolIds: [schoolDocId] })
      ])
      return `先生用 | ${tutorIndo.data.tutorDocData.name}`
    } else {
      return '先生アカウント'
    }
  }
  const submissionConversation = async (item) => {
    const userDocRef = doc(db, 'users', user ? user.uid : state.uid)
    const userSnapshot = await getDoc(userDocRef)
    const submitterRef = doc(item.ref, 'submitters', user.uid)
    const submitterSnapshot = await getDoc(submitterRef)
    const tutorName = await getToturName(userSnapshot.data())
    const submitterData = {
      userId: user.uid,
      userName: userSnapshot.data().name
        ? userSnapshot.data().name
        : (userSnapshot.data() && userSnapshot.data().lastName) ||
          (userSnapshot.data() && userSnapshot.data().firstName)
        ? userSnapshot.data().lastName + ' ' + userSnapshot.data().firstName
        : userSnapshot.data().schoolDocId
        ? tutorName
        : 'みんがく管理人',
      schoolId: userSnapshot.data() && userSnapshot.data().schoolDocId ? userSnapshot.data().schoolDocId : '',
      submittedAt: new Date().getTime()
    }

    if (!submitterSnapshot.exists()) {
      await setDoc(submitterRef, submitterData)
    } else {
      await updateDoc(submitterRef, submitterData)
    }
    // 'submitters'の下に提出する会話のドキュメントを作成）
    const idForNewLocationRef = conversationId ? conversationId : newConversationId

    const newLocationRef = doc(
      item.ref,
      'submitters',
      user.uid,
      'conversations',
      `${idForNewLocationRef}_${new Date().getTime()}`
    )
    // 元のconversationsDocRefドキュメントを取得
    const conversationsDoc = await getDoc(
      conversation.conversationDocRef ? conversation.conversationDocRef : newConversationsDocRef
    )

    if (currentMessageData) {
      // メッセージのみの提出
      const cleanedData = cleanMessageData(currentMessageData)
      const batch = writeBatch(db)

      // 新しい場所にconversationsDocRefのデータをコピー
      batch.set(newLocationRef, conversationsDoc.data())
      await batch.commit()

      const newMessageRef = doc(collection(newLocationRef, 'messages'))

      await setDoc(newMessageRef, cleanedData) // cleanedDataを使用してsetDocを呼び出し

      await updateDoc(newLocationRef, {
        submittedAt: new Date().getTime(),
        submission_title: `提出_${conversation.mode}_${formatCurrentDate()}`,
        type: currentMessageData ? 'message' : 'conversation'
      })
      alert(`${item.submission_box_title}に提出しました！`)
      setIsModalOpenForSubmission(false)
      setAnchorEl(null)
    } else if (conversationsDoc.exists()) {
      // 会話全体の提出
      // writeBatchを使用して複数の書き込み操作を一度に行う
      const batch = writeBatch(db)

      // 新しい場所にconversationsDocRefのデータをコピー
      batch.set(newLocationRef, conversationsDoc.data())

      // // 元のmessagesサブコレクションを取得
      const messagesRef = collection(
        conversation.conversationDocRef ? conversation.conversationDocRef : newConversationsDocRef,
        'messages'
      )
      const messageDocs = await getDocs(messagesRef)

      // // 各messagesドキュメントを新しい場所にコピー
      messageDocs.forEach((docSnapshot) => {
        // 新しいメッセージドキュメントの参照を自動生成された ID で作成
        const newMessageRef = doc(collection(newLocationRef, 'messages'))
        // 新しいメッセージドキュメントにデータをセット
        batch.set(newMessageRef, docSnapshot.data())
      })

      // // バッチ操作をコミット
      await batch.commit()

      // conversationドキュメント更新
      await updateDoc(newLocationRef, {
        submittedAt: new Date().getTime(),
        submission_title: `提出_${conversation.mode}_${formatCurrentDate()}`,
        type: currentMessageData ? 'message' : 'conversation'
      })
      alert(`${item.submission_box_title}に提出しました！`)
      setIsModalOpenForSubmission(false)
      setAnchorEl(null)
    } else {
      console.log('ドキュメントが存在しません')
    }
    setCurrentMessageData()
  }

  const getPromptTitle = async (conversationsDocRef) => {
    const aaa = await getDoc(conversationsDocRef)

    const promptDoc = await getDoc(aaa.data().promptRef)

    if (
      promptDoc.data() !== undefined &&
      promptDoc.data().character_limit === true &&
      promptDoc.data().maximum_character_count
    ) {
      setCharacterLimit(true)
      setMaxCharacters(promptDoc.data().maximum_character_count)
    } else {
      setCharacterLimit(false)
      setMaxCharacters(0)
    }
    if (
      promptDoc.data() !== undefined &&
      promptDoc.data().character_limit_per_month === true &&
      promptDoc.data().maximum_character_count_per_month
    ) {
      setCharacterLimitPerMonth(true)
      setMaxCharactersPerMonth(promptDoc.data().maximum_character_count_per_month)
    } else {
      setCharacterLimitPerMonth(false)
      setMaxCharactersPerMonth(0)
    }

    if (
      promptDoc.data() !== undefined &&
      promptDoc.data().sending_count_limit === true &&
      promptDoc.data().maximum_sending_count_limit
    ) {
      setIsSendingCountLimitSet(true)
      setMaximumSendingCountLimit(promptDoc.data().maximum_sending_count_limit)
    } else {
      setIsSendingCountLimitSet(false)
      setMaximumSendingCountLimit(0)
    }

    if (
      promptDoc.data() !== undefined &&
      promptDoc.data().is_past_message_limit_count_set === true &&
      promptDoc.data().maximum_past_message_limit_count
    ) {
      setIsPastMessageLimitCountSet(true)
      setMaximumPastMessageLimitCount(promptDoc.data().maximum_past_message_limit_count)
    } else {
      setIsPastMessageLimitCountSet(false)
      setMaximumPastMessageLimitCount(0)
    }
  }
  const [submissionBoxes, setSubmissionBoxes] = useState([])
  const fetchSubmissionBoxes = async (user) => {
    if (user) {
      let paramSchoolId

      const userDocRef = doc(db, 'users', user.uid)
      await getDoc(userDocRef).then(async (userDocSnapshot) => {
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data()
          paramSchoolId = userData.schoolDocId
        }
      })

      let promptDocRef
      if (paramSchoolId) {
        const mingakuDocRef = doc(db, 'externalServices', 'mingaku')
        const schoolsCollectionRef = collection(mingakuDocRef, 'schools')
        promptDocRef = doc(schoolsCollectionRef, paramSchoolId)
      } else {
        promptDocRef = doc(db, 'service_type', 'gpt-teacher')
      }

      const propmts_q = query(collection(promptDocRef, 'submissionBoxes'))
      const querySnapshot = await getDocs(propmts_q)
      let submissionBoxesData = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          return {
            submission_box_title: doc.data().submission_box_title ? doc.data().submission_box_title : '',
            enable: doc.data().enable,
            id: doc.id,
            ref: doc.ref,
            updatedAt: doc.data().updatedAt ? doc.data().updatedAt : '',
            editable: true
          }
        })
      )

      let enabledSubmissionBoxesData = submissionBoxesData.filter((item) => item.enable === true)

      if (enabledSubmissionBoxesData.length !== 0) {
        const results = enabledSubmissionBoxesData.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))
        setSubmissionBoxes(results)
      }
    }
  }

  useEffect(() => {
    const container = containerRef.current
    if (container) {
      const scrollHeight = container.scrollHeight
      const start = container.scrollTop
      const change = scrollHeight - start + 1500
      const increment = 20 // スクロール速度の設定（調整可）
      let currentTime = 0
      const animateScroll = () => {
        currentTime += increment
        const easing = easeInOutQuad(currentTime, start, change, 2500) // イージング関数の適用（調整可）
        container.scrollTop = easing
        if (currentTime < 2500) {
          requestAnimationFrame(animateScroll)
        }
      }
      animateScroll()
    }
  }, [chatData, isLoading])
  const startOverHandler = (e) => {
    e.stopPropagation()
    // 確認ダイアログを表示
    const isConfirmed = window.confirm('本当に会話をやり直しますか？')

    // ユーザーがOKを選択した場合、画面をリロード
    if (isConfirmed) {
      if (_audio) {
        _audio.pause()
      }
      const _redirectUrl = sessionStorage.getItem('redirectPageUrl')

      navigate(`${_redirectUrl}`, {})
    }
  }
  const [largeIconAvatorWidth, setLargeIconAvatorWidth] = useState(0)
  useEffect(() => {
    // chat-screen の位置を更新する関数
    const updateChatScreenPosition = () => {
      if (avatorContainerRef.current) {
        setLargeIconAvatorWidth(avatorContainerRef.current.offsetHeight - 16)
      }
    }

    // コンポーネントのマウント時とウィンドウのリサイズ時に実行
    updateChatScreenPosition()
    window.addEventListener('resize', updateChatScreenPosition)
    return () => window.removeEventListener('resize', updateChatScreenPosition)
  }, [isLargeIcon, _start, isLoading])

  const handleKeyDown = (event) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      if (!islistening && !isLoading && !isGenerating && chatInput) {
        onTextInput()
      } else {
        console.log('実行中なのでショートカットは無効')
      }
    }
  }

  const handleUserInputChange = async (newUserInputValue) => {
    setChatData((prevChatData) => {
      const updatedChatData = [...prevChatData]
      // userInputValueはChatBubbleForSystemに表示される項目(みんがくデータとマイページデータは表示されません)
      updatedChatData[0].userInputValue = newUserInputValue

      // Step 1: Replace placeholders in promptData.prompt with user input values
      let newContent = promptData.prompt
      promptData.formQuestions.forEach((question) => {
        const userInput = newUserInputValue.find((item) => item.key === question.key)
        if (userInput) {
          // Replace the placeholder with user input value
          newContent = newContent.replace(`{${question.key}}`, userInput.value)

          // Replace {key.length} with the length of the input value
          const lengthPlaceholder = `{${question.key}.length}`
          if (newContent.includes(lengthPlaceholder)) {
            newContent = newContent.replace(lengthPlaceholder, userInput.value.length.toString())
          }
        }
      })

      // Step 2: Further replace using sentenceWordReplacementList(みんがくデータやマイページデータの変換)
      const uniqueKeysInReplacementList = sentenceWordReplacementList.filter(
        (replacement) => !newUserInputValue.some((input) => input.key === replacement.key)
      )

      uniqueKeysInReplacementList.forEach((replacement) => {
        newContent = newContent.replace(`{${replacement.key}}`, replacement.value)

        const lengthPlaceholder = `{${replacement.key}.length}`
        if (newContent.includes(lengthPlaceholder)) {
          newContent = newContent.replace(lengthPlaceholder, replacement.value.length.toString())
        }
      })

      // Step 3: Update chatData[0].message
      updatedChatData[0].content = newContent
      return updatedChatData
    })

    // Update Firestore document if messageDocRef is available
    if (messageDocRef) {
      try {
        await updateDoc(messageDocRef, {
          userInputValue: newUserInputValue,
          message: chatData[0].content // Assuming this contains the updated content
        })
      } catch (error) {
        console.error('Error updating Firestore document:', error)
      }
    }
  }
  // const [imageUrls, setImageUrls] = useState([])
  const [userInputImageFiles, setUserInputImageFiles] = useState(state?.userInputImages)
  const uploadImagesToFirestore = async (conversationId, messageId) => {
    console.log('userInputImageFiles', userInputImageFiles)
    console.log('user.uid', user.uid)
    console.log('conversationId', conversationId)
    console.log('messageId', messageId)

    const urls = await Promise.all(
      userInputImageFiles.map(async (imageData, index) => {
        if (imageData && imageData.file) {
          // nullチェックとファイルの存在確認
          const iconImagePath = `conversationPictures/${user.uid}/${conversationId}/${messageId}/${imageData.name}`
          const storageRef = ref(storage, iconImagePath)
          await uploadBytes(storageRef, imageData.file)
          const downloadURL = await getDownloadURL(storageRef)
          return downloadURL
        }
        return null
      })
    )

    const validUrls = urls.filter((url) => url !== null)
    // setImageUrls(validUrls)
    return validUrls // URLリストを返す
  }

  return (
    <>
      {_start === true || _startButton === false || readOnly === true ? (
        <>
          {generatingImage ? <LoadingGrid text="ローディング中..." /> : <></>}
          <>
            {isLoading ? (
              <LoadingGrid text="ローディング中" />
            ) : (
              <div id={newConversationId ? newConversationId : conversationId}>
                {startover && (
                  <div
                    id="start-over"
                    className={showheader === 'true' ? styles['your-container-class'] : styles['your-container-class2']}
                  >
                    <button className={styles['start-over-button']} onClick={(e) => startOverHandler(e)}>
                      会話をやり直す
                    </button>
                  </div>
                )}
                {isLargeIcon && (
                  <div
                    className={showheader === 'true' || showheader === true ? styles['chat3'] : styles['chat4']}
                    id="chat-container1"
                    ref={avatorContainerRef}
                    style={determineHalfStyle()}
                  >
                    <Avator
                      imageEnlargement={true}
                      isActiveAvator={isActiveAvator}
                      avator_images={avatorImages}
                      isLargeIcon={isLargeIcon}
                      largeIconAvatorWidth={largeIconAvatorWidth}
                      isGenerating={isGenerating}
                    />
                    {isLargeIcon && (
                      <MenuIcon
                        conversationId={newConversationId ? newConversationId : conversationId}
                        className={styles['menu-icon']}
                        path={window.location.pathname}
                        type={showheader !== 'false' ? 'chat-screen' : 'chat-screen-without-header'}
                        onButtonClick={openModalForConversationSubmission}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                      />
                    )}
                  </div>
                )}

                <div
                  className={showheader === 'true' || showheader === true ? styles['chat'] : styles['chat2']}
                  id="chat-container2"
                  ref={containerRef}
                  style={isLargeIcon ? determineHalfStyle() : determineStyle()}
                >
                  {isLargeIcon !== true && (
                    <MenuIcon
                      conversationId={newConversationId ? newConversationId : conversationId}
                      className={styles['menu-icon']}
                      type={showheader !== 'false' ? 'chat-screen' : 'chat-screen-without-header'}
                      onButtonClick={openModalForConversationSubmission}
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                    />
                  )}

                  {chatData
                    .filter((item) => !item.hide) // hide プロパティが true の場合はフィルタリング
                    .map((item, index) => {
                      // 次のアイテムが存在するかどうかを確認
                      const nextItem = chatData.slice(index + 1).find((next) => next.hide === true)
                      return (
                        <React.Fragment key={index}>
                          {item.role === 'system' ? (
                            (item.userInput === true &&
                              displayInputContents !== false &&
                              currentPath !== '/thread_list') ||
                            (item.userInput === true && currentPath === '/thread_list') ? (
                              <ChatBubbleForSystem
                                setChatData={setChatData}
                                key={index}
                                userId={userId}
                                conversationId={conversationId ? conversationId : newConversationId}
                                role={item.role}
                                subject={item.subject}
                                question={item.question}
                                answer={item.answer}
                                userInputValue={item.userInputValue}
                                messageDocRef={item.ref}
                                messageTime={item.messageTime}
                                isGenerating={isGenerating}
                                prompt={promptData}
                                onButtonClick={openModalForSubmission}
                                readOnly={readOnly}
                                hidedUserMessage={nextItem ? nextItem : undefined}
                                onUserInputChange={handleUserInputChange}
                                sentenceWordReplacementList={sentenceWordReplacementList}
                                onSentenceWordReplacementListChange={setSentenceWordReplacementList}
                              />
                            ) : (
                              <></>
                            )
                          ) : (
                            <div>
                              <ChatBubble
                                index={index}
                                isGenerating={isGenerating}
                                lastIndex={index === chatData.length - 1}
                                chatLength={chatData.length}
                                isActiveAvator={isActiveAvator}
                                role={item.role}
                                messageText={Array.isArray(item.content) ? item.content[0].text : item.content}
                                messagePictures={
                                  Array.isArray(item.content)
                                    ? item.content
                                        .filter((contentItem) => contentItem.type === 'image_url')
                                        .map((imageContent) => imageContent.image_url.url)
                                    : []
                                }
                                avator_images={avatorImages}
                                disableSpeaker={disableSpeaker}
                                isLargeIcon={isLargeIcon}
                                id={`chat-bubble-${index}`}
                                onVolumeUp={() =>
                                  playTextSound2(
                                    Array.isArray(item.content) ? item.content[0].text : item.content,
                                    '',
                                    true,
                                    index
                                  )
                                }
                                onVolumeOff={() => stopPlaying()}
                                playingIndex={playingIndex}
                                onPlaying={onPlaying}
                                onButtonClick={() => {
                                  openModalForSubmission(item)
                                }}
                              />
                            </div>
                          )}
                        </React.Fragment>
                      )
                    })}
                  {isStreaming && isBubbleLoading && (
                    <div>
                      <ChatBubble
                        key={1}
                        role={'assistant'}
                        messageText={``}
                        isBubbleLoading={isBubbleLoading}
                        avator_images={avatorImages}
                        isLargeIcon={isLargeIcon}
                        isGenerating={isGenerating}
                        onButtonClick={openModalForSubmission}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
          {readOnly === false && (
            <div
              className={styles['user-input']}
              style={
                displayFooter === true
                  ? { bottom: `calc(100vh - ${chatScreenBottom}px)`, width: `calc(${userInputWidth} - 2vh)` }
                  : { bottom: `0`, width: `calc(${userInputWidth} - 2vh)` }
              }
            >
              <FormControl className={styles['form-control']}>
                {isLoading ? (
                  <></>
                ) : (
                  <div className={styles['reply']}>
                    {responseOptions.map((item, index) => (
                      <div className={styles['option']} key={index}>
                        <button className={styles['option']} onClick={() => onTextInput(item.content)}>
                          {item.option}
                        </button>
                      </div>
                    ))}
                  </div>
                )}

                <Textarea
                  disabled={isLoading}
                  autoFocus={true}
                  className={styles['mui-textarea']}
                  onChange={handleInputChange}
                  value={chatInput}
                  placeholder="タップして返信をかく"
                  minRows={1}
                  onKeyDown={handleKeyDown}
                  startDecorator={
                    // previewUrl && (
                    //   <img src={previewUrl} alt="Preview" style={{ maxHeight: '50px', paddingLeft: '10px' }} />
                    // )
                    <>
                      {previewUrls.length !== 0 && (
                        <div>
                          {previewUrls.map((url, index) => (
                            <Box
                              sx={{ position: 'relative', display: 'inline-block' }}
                              onMouseEnter={() => setIsHovering(index)}
                              onMouseLeave={() => setIsHovering(null)}
                              key={url} // 一意なBlob URLをkeyとして使用
                            >
                              <img
                                src={url}
                                alt="Image preview"
                                style={{ maxHeight: 60, margin: 2, borderRadius: 6 }}
                              />
                              {isHovering === index && (
                                <IconButton
                                  className="remove-icon"
                                  size="small"
                                  sx={{
                                    position: 'absolute',
                                    right: 0,
                                    top: 0,
                                    color: 'primary.main',
                                    backgroundColor: 'background.paper',
                                    '&:hover': {
                                      backgroundColor: 'grey.300'
                                    }
                                  }}
                                  onClick={() => removePreview(index)}
                                >
                                  <CancelIcon fontSize="small" />
                                </IconButton>
                              )}
                            </Box>
                          ))}
                        </div>
                      )}
                    </>
                  }
                  endDecorator={
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 'var(--Textarea-paddingBlock)',
                        pt: 'var(--Textarea-paddingBlock)',
                        borderTop: '1px solid',
                        borderColor: 'divider',
                        flex: 'auto'
                      }}
                    >
                      <div className={styles.text2imageContainer}>
                        <label className={styles[text2imageLabelClassName]}>
                          <input type="file" onChange={onChangeImage} />
                          <div className={styles.text2image}>画像 ▷ 文字</div>
                        </label>
                        {/* <label className={styles[text2imageLabelClassName]}>
                          <div
                            className={styles.text2image}
                            onClick={
                              false
                                ? () => getImageFromGPT()
                                : () =>
                                    alert(
                                      '画像生成機能のメンテナンスを行っており、現在ご利用いただけません。ご不便をおかけして申し訳ありませんが、もうしばらくお待ちください。'
                                    )
                            }
                          >
                            画像生成
                          </div>
                        </label> */}
                      </div>

                      <div className={styles['button-area']}>
                        {_gpt_model
                          .filter((model) => model.availableVision === true)
                          .map((model) => model.value)
                          .includes(model) &&
                          !_useAssistantApi && (
                            <>
                              <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="icon-button-file"
                                type="file"
                                multiple // 複数選択を許可
                                onChange={handleFilesChange}
                              />
                              <label className={styles.attach} htmlFor="icon-button-file">
                                <IconButton
                                  className={styles.attachButton}
                                  // color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <AttachFileIcon className={styles.attachIcon} />
                                </IconButton>
                              </label>
                            </>
                          )}
                        {/* <CustomWidthTooltip
                          title={`${JSON.parse(selectedLanguage).japaneseName}`}
                          arrow
                          enterTouchDelay={100}
                          open={true}
                        > */}
                        <Select
                          className={styles.select}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedLanguage}
                          onChange={changeLanguage}
                        >
                          {languageList2.map((language) => (
                            <MenuItem
                              key={JSON.stringify(language)}
                              value={JSON.stringify(language)}
                              className={styles.menuItem}
                              sx={{ fontSize: 12 }}
                            >
                              {language.shortenName}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* </CustomWidthTooltip> */}
                        <Button
                          className={isMute ? styles['send-deactive'] : styles[sendActiveClassName]}
                          sx={{ ml: 'auto', paddingInline: '2.5%', width: '10%', minWidth: '45px' }}
                          onClick={() => changeMute()}
                        >
                          {isMute ? <VolumeOffIcon /> : <VolumeUpIcon />}
                        </Button>
                        <Button
                          className={isLoading || islistening ? styles['send-deactive'] : styles[sendActiveClassName]}
                          sx={{ ml: 'auto', paddingInline: '2.5%', width: '10%', minWidth: '45px' }}
                          onClick={isLoading || islistening ? () => resetListening() : onVoiceInput}
                        >
                          {islistening ? (
                            <Bars
                              height="24"
                              width="24"
                              className="voice-icon"
                              color="#ffff"
                              ariaLabel="audio-loading"
                              wrapperStyle={{}}
                              wrapperClass="wrapper-class"
                              visible={true}
                            />
                          ) : (
                            <KeyboardVoiceIcon className="voice-icon" />
                          )}
                        </Button>
                        {isGenerating ? (
                          <Button
                            className={styles[sendActiveClassName]}
                            sx={{ ml: 'auto', paddingInline: '2.5%', width: '10%', minWidth: '45px' }}
                            onClick={() => {
                              handleCancelStream() // ここで引数を渡さないようにしています
                            }}
                          >
                            <StopCircleIcon />
                          </Button>
                        ) : (
                          <Button
                            className={
                              islistening || isLoading || !chatInput
                                ? styles['send-deactive']
                                : styles[sendActiveClassName]
                            }
                            sx={{ ml: 'auto', paddingInline: '2.5%', width: '10%', minWidth: '45px' }}
                            // onClick={islistening || isLoading || !chatInput ? () => {} : onTextInput}
                            onClick={() => {
                              if (!islistening && !isLoading && chatInput) {
                                onTextInput() // ここで引数を渡さないようにしています
                              }
                            }}
                          >
                            <SendIcon />
                          </Button>
                        )}
                      </div>
                    </Box>
                  }
                />
                <p variant="caption" style={{ bottom: '46px' }} className={styles['textarea-length']}>
                  {chatInput.replace(/\n/g, '').length} 文字
                </p>
              </FormControl>
            </div>
          )}

          {isModalOpenForSubmission && (
            <div className={styles.modalWrapperForSubmission}>
              <div className={styles.overlay}></div>
              <div ref={modalRef} className={styles.modal}>
                <h3>提出ボックス一覧</h3>

                <div className={styles['modes']}>
                  {submissionBoxes.map((item, index) => {
                    return (
                      <div className={styles.mode} key={index}>
                        <div className={styles.title}>
                          <h4 className={styles.title}>{item.submission_box_title}</h4>
                        </div>
                        <div className={styles.cardBottomContainer}>
                          <div className={styles.description}>{''}</div>
                          <div onClick={() => submissionConversation(item)} className={styles.start}>
                            提出する
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  {submissionBoxes.length === 0 && <p>提出ボックスがありません</p>}
                </div>
                <button className={styles['start']} onClick={closeModalForSubmission}>
                  <p className={styles['sub-button-text']}>閉じる</p>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {generatingImage ? <LoadingGrid text="ローディング中..." /> : <></>}
          <div className={styles['start-button-container']}>
            <button className={styles['round-button']} onClick={() => startHandler()}>
              <p className={styles['button-text']}>使い方を確認したので</p>
              <b className={styles['button-text']}>利用開始</b>
            </button>
            <div className={styles['start-explanation']}>
              <p className={styles['explanation']}>
                ■使い方の注意点
                <br />
                ① AIの回答が正しいかきちんと調べよう！
                <br />
                間違った情報がつくられることもあるよ。鵜呑みにしないようにしよう。
                <br />
                <br />
                ② 個人情報 は入力しないようにしよう！
                <br />
                名前や住所などの個人情報は、とても大切な情報です。質問に書くのはやめようね。
                <br />
                <br />③ 自分で考えるためのヒントにしよう！ <br />
                答えを聞いたら勉強にならないよ。出力結果はあくまで参考程度にしよう。
                <br />
                <br />
                ④保護者の方の承諾を得てから使おう！
                <br />
                情報を正しく判断できるようになってから活用しましょう。
                <br />
                （保護者の方に、事前に「保護者の方へ」を読んでもらってください）
                <br />
                <br />⑤ AIが作ったものをそのまま使わないようにしよう！
                <br />
                AIが作ったものをそのまま使うのではなく、自分の考えも大切にしよう。 <br />
                <br />
                ※わからないことがあったら 、先生や保護者の方に聞いてくださいね。
                <br />
                <br />
                ■　保護者の方へ
                <br />
                お子様が利用される前に、以下の内容をご確認ください。
              </p>
              <button className={styles['buttonForParent']} onClick={openModal}>
                <p className={styles['sub-button-text']}>保護者の方へ</p>
              </button>
            </div>
          </div>

          {isModalOpen && (
            <div className={styles.modalWrapper}>
              <div className={styles.overlay}></div>
              <div className={styles.modal}>
                <div className="modal">
                  <p className={styles['modal-text']}>※表示に時間が掛かるときがあります。</p>
                  <iframe
                    className={styles.iframe}
                    title="保護者の方向けの利用規約"
                    src="https://docs.google.com/file/d/1NJmhQwBq7ysM48Y_lfPMpf2Orawn0JUA/preview?usp=drivesdk"
                  ></iframe>
                </div>
                <button className={styles['close-button']} onClick={closeModal}>
                  <p className={styles['sub-button-text']}>閉じる</p>
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}
export default ChatScreen
