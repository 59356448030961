import * as React from 'react'
import { useState } from 'react'
import AppModule from '../../App.module.scss'
import ChatModule from './chat.module.scss'
import { useLocation } from 'react-router-dom'
import Header from '../../components/Header/Header'
import ChatMainLayout from '../../components/ChatMainLayout/MainLayout'
import { useSearchParams } from 'react-router-dom'

function Chat() {
  const { state } = useLocation()
  const [searchParams] = useSearchParams()
  const showheader = searchParams.get('showheader')
  const [mode, setMode] = useState('')
  const handleModeChange = (newMode) => {
    setMode(newMode)
  }
  const [start, setStart] = useState(false)
  const handleStartChange = (newStart) => {
    setStart(newStart)
  }
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1100)
  const currentPath = window.location.pathname
  return (
    <div className={ChatModule.main}>
      {!isTablet && currentPath === '/chat' && <div className={ChatModule['side-container']}></div>}
      <div
        className={
          !isTablet && currentPath === '/chat'
            ? start
              ? ChatModule['submain-with-side-container-transparent']
              : ChatModule['submain-with-side-container-transparent']
            : ChatModule['submain']
        }
      >
        {(showheader === 'true' || !showheader) && <Header title={state ? state.mode : mode} />}
        <ChatMainLayout
          conversationType={'conversations'}
          modePath={'/mode'}
          displayList={false}
          displayFooter={false}
          readOnly={false}
          onModeChange={handleModeChange}
          showheader={showheader}
          onStartChange={handleStartChange}
        />
      </div>
      {!isTablet && currentPath === '/chat' && <div className={ChatModule['side-container']}></div>}
    </div>
  )
}
export default Chat
