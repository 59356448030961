import { createContext, useContext, useState } from 'react'

// Contextの作成
const AuthContext = createContext()

const AuthData = [
  {
    uid: '',
    email: '',
    emailVerified: false
  }
]

export const AuthProvider = ({ children }) => {
  const [authdata, setAuthdata] = useState(AuthData)

  return <AuthContext.Provider value={[authdata, setAuthdata]}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => useContext(AuthContext)
