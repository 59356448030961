import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'
import { db, auth } from '../../firebase'
import { doc, updateDoc, setDoc } from 'firebase/firestore'
import LoginModule from './login.module.scss'
// import LoginModule from '../login/login.module.scss'
import { useLocation } from 'react-router-dom'
import OutlinedInput from '@mui/material/OutlinedInput'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Modal from '@mui/material/Modal'

function LoginForMingakuTutor() {
  const { state } = useLocation()
  const { email, redirectPath, isAlreadyLinked, linkageData, tutorDocData, schoolIdfromQueryParam, muid, role } =
    state ?? ''
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const [resetEmail, setResetEmail] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const signIn = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        //ログインが成功した時の処理
        await updateDoc(doc(db, 'users', userCredential.user.uid), {
          userId: userCredential.user.uid,
          email: userCredential.user.email ?? '',
          createdAt: userCredential.user.metadata.createdAt ?? null,
          lastLoginAt: userCredential.user.metadata.lastLoginAt ?? null,
          ...linkageData
        })

        if (role === 'tutor') {
          const schoolDocRef = doc(db, 'externalServices', 'mingaku', 'schools', schoolIdfromQueryParam)
          setDoc(schoolDocRef, { docId: schoolIdfromQueryParam }, { merge: true })
          const tutorDocRef = doc(schoolDocRef, 'tutors', muid)
          await setDoc(tutorDocRef, { ...tutorDocData }, { merge: true })
        }
        navigate(redirectPath)
        // sendEmailVerification(userCredential.user).then(() => navigate('/'))
      })
      .catch((error) => {
        if (error.code === 'auth/missing-password') {
          alert('パスワードが入力されていません')
        } else if (error.code === 'auth/invalid-email') {
          alert('メールアドレスの形式が正しくありません')
        } else if (error.code === 'auth/user-disabled') {
          alert('アカウントが無効化されています')
        } else if (error.code === 'auth/wrong-password') {
          alert('パスワードが間違っています')
        } else if (error.code === 'auth/user-not-found') {
          alert('指定されたメールアドレスのユーザーアカウントがありません')
        } else if (error.code === 'auth/operation-not-allowed') {
          alert('メールとパスワードによるサインインが許可されていません')
        } else if (error.code === 'auth/too-many-requests') {
          alert('セキュリティのためにアカウントへのアクセスが一時的に制限されました')
        } else if (error.code === ' auth/network-request-failed') {
          alert('ネットワークリクエストが失敗しました')
        } else {
          alert(error.code)
        }
      })
  }
  const handleCloseModal = () => {
    setModalOpen(false)
  }
  const submitPasswordResetEmail = async () => {
    const actionCodeSettings = {
      url: 'http://localhost:3000/login', // パスワード再設定後のリダイレクト URL
      handleCodeInApp: false
    }

    try {
      await sendPasswordResetEmail(auth, resetEmail, actionCodeSettings)
      alert('パスワードリセットメールを送信しました。')
    } catch (error) {
      console.error('パスワードリセットメール送信失敗:', error)
      alert('エラーが発生しました。もう一度お試しください。')
    }
    setModalOpen(false)
  }
  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const handleOpenModal = () => {
    setModalOpen(true)
  }
  const modalBody = (
    <Box
      sx={{
        bgcolor: 'background.paper'
      }}
      className={LoginModule['box-container']}
    >
      <h2>パスワードリセット</h2>
      <p>パスワードをリセットしたいアカウントのメールアドレスを入力してください。</p>
      <OutlinedInput
        className={LoginModule['outlined-input']}
        fullWidth
        type="email"
        placeholder="メールアドレス"
        value={resetEmail}
        onChange={(event) => setResetEmail(event.target.value)}
      />
      <button onClick={submitPasswordResetEmail} className={LoginModule.button}>
        パスワードリセットメールを送信
      </button>
    </Box>
  )
  return (
    <div className={LoginModule['main']}>
      <div className={LoginModule['title-container']}>
        <p className={LoginModule['service-title']}>
          スクールAIログイン画面 <br />
          {role === 'admin' ? '（みんがく管理人用）' : '（先生用）'}
        </p>
      </div>
      {!isAlreadyLinked && (
        <p>
          みんがくアカウントのEメールアドレス「{email}」
          で作成されたスクールAIアカウントがすでに存在していますが、スクールAIアカウントとみんがくアカウントとの連携がまだ完了していません。
          <br />
          スクールAIアカウントとみんがくアカウントを連携するために、このページからログインしてください。
        </p>
      )}
      <div className={LoginModule.input}>
        <FormControl className={LoginModule.inputbox} variant="outlined">
          <InputLabel htmlFor="email">メールアドレス(編集不可)</InputLabel>
          <OutlinedInput
            id="email"
            className={LoginModule.inputbox}
            label={'メールアドレス(編集不可)'}
            type="email"
            value={email}
            sx={{
              width: '70%',
              margin: '0 15%',
              backgroundColor: 'rgba(215, 215, 215, 1)',
              color: 'rgba(127, 127, 127, 1) 1)'
            }}
          />
        </FormControl>
      </div>
      <div className={LoginModule.input}>
        <FormControl className={LoginModule.inputbox} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">スクールAIで設定したパスワード</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            className={LoginModule.inputbox}
            label="パスワード"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </FormControl>
      </div>
      <div>
        <button className={LoginModule.button} onClick={signIn}>
          ログインする
        </button>
      </div>
      <div>
        <p className={LoginModule['description']}>
          パスワードを忘れた方は
          <button className={LoginModule['simple-text']} onClick={handleOpenModal}>
            こちら
          </button>
        </p>
      </div>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="password-reset-modal"
        aria-describedby="password-reset-form"
      >
        {modalBody}
      </Modal>
    </div>
  )
}
export default LoginForMingakuTutor
