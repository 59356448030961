import React, { useState } from 'react'
import ChatBubbleModule from './ChatBubble.module.scss'
import { ThreeDots, Audio } from 'react-loader-spinner'
import FaceIcon from '@mui/icons-material/Face'
import Avator from './Avator'
import 'highlight.js/styles/atom-one-dark.css'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import ModalForPicture from './ModalForPicture'
import 'katex/dist/katex.min.css'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import PrintIcon from '@mui/icons-material/Print'
import AllInboxIcon from '@mui/icons-material/AllInbox'
const ChatBubble = ({
  index,
  lastIndex,
  chatLength,
  isActiveAvator,
  role,
  messageText,
  messagePictures,
  avator_images,
  isLargeIcon,
  id,
  disableSpeaker,
  onVolumeUp,
  onVolumeOff,
  onPlaying,
  playingIndex,
  isGenerating,
  onButtonClick,
  displayMenuButton = true
}) => {
  const threeDotsColor = '#1e90ff'
  const [modalImage, setModalImage] = useState(null)
  const [hoveredIndex, setHoveredIndex] = useState(null)

  const handleImageClick = (url) => {
    setModalImage(url) // クリックされた画像のURLをモーダル表示用の状態にセット
  }

  const handleCloseModal = () => {
    setModalImage(null) // モーダルを閉じる
  }
  const imagesContent =
    messagePictures && messagePictures.length > 0 ? (
      <div className={ChatBubbleModule['images-container']}>
        {messagePictures.map((url, i) => (
          <img
            key={i}
            src={url}
            alt={`message-picture-${i}`}
            className={ChatBubbleModule['message-picture']}
            onClick={() => handleImageClick(url)}
          />
        ))}
      </div>
    ) : null
  const copyCodeToClipboard = (codeContent) => {
    const textArea = document.createElement('textarea')
    textArea.value = codeContent
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand('copy')
    document.body.removeChild(textArea)
    alert('コードをコピーしました!') // ユーザーにフィードバックを提供するためのアラート
  }

  const CodeBlock = ({ node, inline, className, children, ...props }) => {
    const match = /language-(\w+)/.exec(className || '')
    const codeContent = String(children).replace(/\n$/, '')
    // 言語名を取得
    const language = match && match[1] ? match[1] : 'markdown'
    return (
      <div {...props}>
        <div
          className={ChatBubbleModule['code-lang']}
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <span>{language}</span> {/* 言語名を表示 */}
          <FileCopyIcon className={ChatBubbleModule['copy-icon']} onClick={() => copyCodeToClipboard(codeContent)} />
        </div>
        <SyntaxHighlighter
          className={ChatBubbleModule['syntax-highlighter']}
          style={dark}
          language={language}
          PreTag="div"
        >
          {codeContent}
        </SyntaxHighlighter>
      </div>
    )
  }

  const preprocessMath = (markdown) => {
    return markdown
      .replace(/\\\(/g, '$') // \(\ を $ に変換
      .replace(/\\\)/g, '$') // \)\ を $ に変換
      .replace(/\\\[/g, '$$') // \[\ を $$ に変換
      .replace(/\\\]/g, '$$') // \]\ を $$ に変換
  }

  const Paragraph = ({ children }) => {
    const content = Array.isArray(children) ? children : [children]
    const modifiedChildren = content.map((child, i) =>
      typeof child === 'string'
        ? child.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index < child.split('\n').length - 1 && <br />}
            </React.Fragment>
          ))
        : child
    )
    return <p>{modifiedChildren}</p>
  }
  const handleVolumeUpClick = async () => {
    onVolumeUp() // 親コンポーネントから渡された音声再生関数を呼び出す
  }

  const handleVolumeOffClick = () => {
    onVolumeOff()
  }

  const handlePrintMessage = (e) => {
    e.stopPropagation()

    // setAnchorEl(null)
    const chatListElement = document.getElementById(`chat-bubble-${index}`) // 適切なIDに変更
    if (chatListElement) {
      const chatContainerClone = chatListElement.cloneNode(true)

      // スタイルシートを取得
      const stylesheets = document.querySelectorAll('link[rel="stylesheet"], style')
      let stylesHtml = ''
      stylesheets.forEach((link) => {
        stylesHtml += link.outerHTML
      })

      // MenuIconの削除
      const iconElement = chatContainerClone.querySelector('#icon')
      if (iconElement) {
        iconElement.parentNode.removeChild(iconElement)
      }

      const selectors = ['#largeIcon', '#chat', '#chat-icon']
      selectors.forEach((selector) => {
        const elements = chatContainerClone.querySelectorAll(selector)
        elements.forEach((element) => {
          element.style.boxShadow = 'none'
          element.style.border = '1px solid rgb(203, 203, 203)' // borderを追加
        })
      })
      // クローンのスタイル調整
      const chatContainerElement1 = chatContainerClone.querySelector('#chat-container1')
      const chatContainerElement2 = chatContainerClone.querySelector('#chat-container2')
      if (chatContainerElement1) {
        chatContainerElement1.style.width = '95vw'
      }
      if (chatContainerElement2) {
        chatContainerElement2.style.height = ''
        chatContainerElement2.style.width = '95vw'
      }

      // 隠しiframeの作成
      const iframe = document.createElement('iframe')
      iframe.style.position = 'absolute'
      iframe.style.width = '95vw'
      iframe.style.height = '0'
      iframe.style.border = '0'
      document.body.appendChild(iframe)

      setTimeout(() => {
        // iframeに内容を書き込む
        iframe.contentDocument.open()
        iframe.contentDocument.write('<html><head><title>Print</title>')
        iframe.contentDocument.write(stylesHtml)
        iframe.contentDocument.write('</head><body>')
        iframe.contentDocument.write(chatContainerClone.outerHTML)
        iframe.contentDocument.write('</body></html>')
        iframe.contentDocument.close()

        // iframeのロードが完了したら印刷ダイアログを表示
        setTimeout(() => {
          iframe.contentWindow.print()
          setTimeout(() => {
            document.body.removeChild(iframe)
          }, 1500)
        }, 500)
      }, 0)
    }
  }
  const preprocessMessageText = (text) => {
    return text.replace(/\n/g, '<br />')
  }
  const chatContainerContent = (
    <div id={id} className="chat-bubble-container">
      {role === 'assistant' || role === 'system' || role === 'loading' ? (
        <div
          className={ChatBubbleModule['chat-container']}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          {isLargeIcon === false && (
            <div className={ChatBubbleModule['icon-container']}>
              {index === chatLength - 1 ? (
                <div
                  id={'chat-icon'}
                  className={
                    (role === 'assistant' && isGenerating) || role === 'loading'
                      ? ChatBubbleModule['rounded-icon-generating']
                      : ChatBubbleModule['rounded-icon']
                  }
                >
                  <Avator isActiveAvator={isActiveAvator} avator_images={avator_images} isGenerating={isGenerating} />
                </div>
              ) : (
                <div id={'chat-icon'} className={ChatBubbleModule['rounded-icon']}>
                  <img src={avator_images[0]} alt="Icon" />
                </div>
              )}
            </div>
          )}
          {role === 'loading' ? (
            <div className={ChatBubbleModule['assistant']} id="chat">
              <ThreeDots
                className={ChatBubbleModule['three-dots']}
                height="45"
                width="60"
                radius="9"
                color={threeDotsColor}
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <div className={ChatBubbleModule[role]} id="chat">
                {imagesContent}
                <ReactMarkdown
                  remarkPlugins={[remarkGfm, remarkMath]}
                  rehypePlugins={[rehypeRaw, rehypeKatex]}
                  components={{
                    code: CodeBlock // コードブロックに対するカスタムコンポーネントを指定
                  }}
                  breaks={true}
                >
                  {preprocessMath(messageText)}
                </ReactMarkdown>
                {(!lastIndex || disableSpeaker === false) && displayMenuButton !== false && (
                  <div className={ChatBubbleModule['left-icons']} id={'icon'}>
                    <div className={ChatBubbleModule['menu-icon-container']} onClick={onButtonClick}>
                      <AllInboxIcon className={ChatBubbleModule['menu-icon']} />
                    </div>
                    <div className={ChatBubbleModule['menu-icon-container']} onClick={handlePrintMessage}>
                      <PrintIcon className={ChatBubbleModule['menu-icon']} />
                    </div>
                    {onPlaying === false && messageText.length !== 0 ? (
                      <div className={ChatBubbleModule['menu-icon-container']} onClick={handleVolumeUpClick}>
                        <VolumeUpIcon className={ChatBubbleModule['menu-icon']} style={{ cursor: 'pointer' }} />
                      </div>
                    ) : (
                      <>
                        {index === playingIndex ? (
                          <div className={ChatBubbleModule['menu-icon-container']} onClick={handleVolumeOffClick}>
                            <Audio
                              height="18"
                              width="18"
                              color="#5b9aff"
                              ariaLabel="audio-loading"
                              wrapperStyle={{}}
                              wrapperClass="wrapper-class"
                              visible={true}
                            />
                          </div>
                        ) : (
                          <div className={ChatBubbleModule['disable-menu-icon-container']}>
                            <VolumeUpIcon
                              // disable
                              className={ChatBubbleModule['disable-menu-icon']}
                              // onClick={handleVolumeUpClick}
                              style={{ cursor: 'pointer' }}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          className={ChatBubbleModule['chat-container-for-user']}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <div className={ChatBubbleModule[role]} id="chat">
            {imagesContent}
            <ReactMarkdown
              remarkPlugins={[remarkGfm, remarkMath]}
              rehypePlugins={[rehypeRaw, rehypeKatex]}
              components={{
                code: CodeBlock,
                p: Paragraph // コードブロックに対するカスタムコンポーネントを指定
              }}
              breaks={true}
            >
              {preprocessMath(messageText)}
              {/* {preprocessMessageText(preprocessMath(messageText))} */}
            </ReactMarkdown>
            {(!lastIndex || disableSpeaker === false) && (
              <div className={ChatBubbleModule['icons']} id={'icon'}>
                <div className={ChatBubbleModule['menu-icon-container']} onClick={onButtonClick}>
                  <AllInboxIcon className={ChatBubbleModule['menu-icon']} />
                </div>
                <div className={ChatBubbleModule['menu-icon-container']} onClick={handlePrintMessage}>
                  <PrintIcon className={ChatBubbleModule['menu-icon']} />
                </div>
                {onPlaying === false ? (
                  <div className={ChatBubbleModule['menu-icon-container']} onClick={handleVolumeUpClick}>
                    <VolumeUpIcon className={ChatBubbleModule['menu-icon']} style={{ cursor: 'pointer' }} />
                  </div>
                ) : (
                  <>
                    {index === playingIndex ? (
                      <div className={ChatBubbleModule['menu-icon-container']} onClick={handleVolumeOffClick}>
                        <Audio
                          height="18"
                          width="18"
                          color="#5b9aff"
                          ariaLabel="audio-loading"
                          wrapperStyle={{}}
                          wrapperClass="wrapper-class"
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className={ChatBubbleModule['disable-menu-icon-container']}>
                        <VolumeUpIcon
                          // disable
                          className={ChatBubbleModule['disable-menu-icon']}
                          // onClick={handleVolumeUpClick}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <div className={ChatBubbleModule['icon-container']}>
            <div id={'chat-icon'} className={ChatBubbleModule['rounded-icon']}>
              <FaceIcon className={ChatBubbleModule['face-icon']} sx={{ fontSize: '40px' }} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
  // const content = isBubbleLoading ? loadingContent : chatContainerContent
  const content = chatContainerContent

  return (
    <>
      {content}
      {modalImage && <ModalForPicture image={modalImage} onClose={handleCloseModal} />}
    </>
  )
}

export default ChatBubble
