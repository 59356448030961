import LoginComponent from '../../components/Login/Login'
import React from 'react'
import LoginModule from './login.module.scss'

function Login() {
  return (
    <div className={LoginModule['main']}>
      <div className={LoginModule['title-container']}>
        <div>
          <div className={LoginModule['service-title']}>スクールAI</div>
          <div className={LoginModule['term']}>
            ログインを以て{' '}
            <a
              href="https://drive.google.com/file/d/1WKY9OhXdgq6nQm197r7JwjBNoLStNHRs/view?usp=drive_link"
              target="_blank"
              className={LoginModule['term-link']}
            >
              利用規約
            </a>
            に同意します
          </div>
          <br />
        </div>
      </div>
      <LoginComponent type={'tutor'} path={'/'} signup={'/signup'} />
    </div>
  )
}
export default Login
