import AppModule from '../../App.module.scss'
import Footer from '../../components/Common/Footer'
import Header from '../../components/Header/Header'
import * as React from 'react'
import 'react-image-crop/dist/ReactCrop.css'
import InputScreen from '../../components/Input/InputScreen'
import { useLocation } from 'react-router-dom'

function Input() {
  const { state } = useLocation()
  let showheader = true
  if (state.showheader === undefined) {
    showheader = true
  } else if (state.showheader === 'false') {
    showheader = false
  } else {
    showheader = true
  }

  let showfooter = true
  if (state.showfooter === undefined) {
    showfooter = true
  } else if (state.showfooter === 'false') {
    showfooter = false
  } else {
    showfooter = true
  }

  return (
    <div className={AppModule.main}>
      {showheader && <Header title={state.mode} />}
      <InputScreen showheader={showheader} />
      {showheader && <Footer homePath="/" addChatPath="/mode" userPath="/mypage" />}
    </div>
  )
}
export default Input
