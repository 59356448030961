// gptStreaming.js
import { getGptStreamingMessageReaderAndDecoder2 } from './gpt.js'

export const processGptStream = async ({
  model,
  prompt,
  setLoading,
  setIsGenerating,
  setSummaryMessage,
  handleCancelStream
}) => {
  const controller = new AbortController()
  let reader, decoder
  let count = 0
  let message = ''

  try {
    ;[reader, decoder] = await getGptStreamingMessageReaderAndDecoder2({
      model,
      messages: prompt,
      controller
    })
  } catch (error) {
    setLoading(false)
    setIsGenerating(false)
    setSummaryMessage('')
    handleCancelStream()
    console.log('error.name', error.name)
    console.log('Error fetching GPT stream:', error)
    console.log('生成を途中で中断しました。')
    return
  }

  try {
    const read = async (missingJson = '') => {
      const { done, value } = await reader.read()

      if (done) {
        return reader.releaseLock()
      }
      const chunk = decoder.decode(value, { stream: true })
      const jsons = chunk
        .split('data:')
        .map((data) => {
          const trimData = data.trim()
          if (trimData === '' || trimData === '[DONE]') return undefined
          try {
            const jsonObj = JSON.parse(missingJson + trimData)
            missingJson = ''
            return jsonObj
          } catch (e) {
            missingJson = trimData
            return undefined
          }
        })
        .filter((data) => data)
      for (let test of jsons) {
        await new Promise((resolve) => {
          setTimeout(() => {
            if ((test.choices && test.choices.length !== 0) || test.text !== undefined) {
              if ((test.choices && test.choices[0].delta.content !== undefined) || test.text !== undefined) {
                message += test.choices ? test.choices[0].delta.content : test.text
                // console.log(message)
                count = count + 1
                if (count !== 0) {
                  setLoading(false)
                  setIsGenerating(true)
                }
                setSummaryMessage(message)
              }
            }
            resolve(null)
          }, 33)
        })
      }
      return read(missingJson)
    }
    await read()
    setLoading(false)
    setIsGenerating(false)
  } catch (error) {
    setLoading(false)
    setIsGenerating(false)
    setSummaryMessage('')
    handleCancelStream()
    console.log('error.name', error.name)
    console.log('Error fetching GPT stream:', error)
    console.log('生成を途中で中断しました。')
    return
  }
}
