import InputModule from './InputScreen.module.scss'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import 'react-image-crop/dist/ReactCrop.css'
import { useLocation } from 'react-router-dom'
import OCR from './OCR'
import SelectImage from './SelectImage'
function InputScreen(props) {
  const [initialPrompt, setInitialPrompt] = useState('')
  const [imageFiles, setImageFiles] = useState([])
  const handleImageFileChange = (file, index) => {
    const fileWithMetadata = {
      file: file,
      name: file.name
    }

    setImageFiles((prevFiles) => {
      const updatedFiles = [...prevFiles]
      updatedFiles[index] = fileWithMetadata // Store the file and its name
      return updatedFiles
    })
  }
  const { state } = useLocation()
  const navigate = useNavigate()
  const initialInputObject = state.formQuestions
    .sort((a, b) => a.order - b.order) // orderフィールドに基づいて並べ替える
    .map((question) => ({
      ...question, // すべてのプロパティを引き継ぐ
      value: question.value || '', // valueが定義されていない場合は空文字列
      missingMandatory: false // 必須項目の欠如を示すフラグ
    }))
  const [inputObject, setInputObject] = useState(initialInputObject)

  useEffect(() => {
    // const promptDocRef = doc(db, state.promptDocRefPath) // 渡されたpromptDocのfullPathからRefを作成
    setInitialPrompt(state.prompt)
  }, [state.prompt, state.promptDocRefPath])

  const inputObjectChangeHandler = (value, questionKey) => {
    setInputObject((prevObject) => {
      const index = prevObject.findIndex((obj) => obj.key === questionKey) // keyを使用して正しいindexを見つける
      if (index === -1) return prevObject // 該当するkeyが見つからない場合は変更なし

      const updatedObject = [...prevObject]
      updatedObject[index] = { ...updatedObject[index], value: value }
      return updatedObject
    })
  }

  const askGPT = async (model) => {
    const updatedInputObject = inputObject.map((obj, index) => {
      const isMandatory = state.formQuestions[index].mandatory
      const isImageType = state.formQuestions[index].type === 'image'
      const isImageProvided = imageFiles[index] !== undefined // Check if an image file exists for this index

      return {
        ...obj,
        missingMandatory:
          isMandatory && ((!isImageType && obj.value.trim() === '') || (isImageType && !isImageProvided))
      }
    })

    const allMandatoryFilled = updatedInputObject.every((obj) => !obj.missingMandatory)

    if (!allMandatoryFilled) {
      alert('必須項目を入力してください。')
      setInputObject(updatedInputObject) // 状態を更新して警告を表示
      return
    }

    let message = initialPrompt

    // 各オブジェクト内のkeyとvalueを使用してmessageを置き換える
    inputObject.forEach((obj) => {
      const placeholder = `{${obj.key}}`
      const replacement = obj.value
      const lengthPlaceholder = `{${obj.key}.length}`
      const lengthReplacement = obj.value.replace(/\n/g, '').length.toString() // 文字数を文字列に変換
      message = message.replaceAll(placeholder, replacement)
      message = message.replaceAll(lengthPlaceholder, lengthReplacement)
    })
    console.log('inputObject', inputObject)
    console.log('state.formQuestions', state.formQuestions)
    const hasImageQuestion = state.formQuestions.some((question) => question.type === 'image')
    navigate(`/chat?mode=${state.docId}&startover=true&showheader=${state.showheader}`, {
      state: {
        // showheader: props.showheader,
        userInputImages: imageFiles,
        existingUserInputImages: hasImageQuestion,
        showheader: state.showheader,
        conversationId: state.conversationId,
        replacedMessage: message,
        userInputValue: inputObject,
        model: model ?? state.model,
        mode: state.mode,
        startButton: state.startButton,
        autoSending: state.autoSending,
        defaultMute: state.defaultMute,
        speakingRate: state.speakingRate,
        temperature: state.temperature,
        promptDocRefPath: state.promptDocRefPath,
        redirectPageUrl: state.redirectPageUrl,
        largeIcon: state.largeIcon,
        displayInputContents: state.displayInputContents,
        userId: state.userId,
        useAssistantApi: state.useAssistantApi,
        assistantId: state.assistantId,
        assistantApiInstruction: state.assistantApiInstruction,
        language: state.language
          ? state.language
          : JSON.stringify({
              physicalName: 'ja',
              languageCode: 'ja-JP',
              type: 'ja-JP-Neural2-B',
              japaneseName: '日本語(女性)',
              shortenName: '日(女)'
            })
      }
    })
  }

  return (
    <>
      <div className={InputModule.inputfield}>
        {state.description !== '' && (
          <p className={InputModule.description}>
            {' '}
            {state.description.split('\n').map((line, d_index) => (
              <React.Fragment key={d_index}>
                <span>{line}</span>
                <br />
              </React.Fragment>
            ))}
          </p>
        )}

        {state.formQuestions
          .sort((a, b) => a.order - b.order)
          .map((_question, index) => (
            <div key={index}>
              <div className={InputModule['input-section']}>
                {_question.mandatory ? <span className={InputModule['mandatory']}>＊</span> : <></>}
                <b className={InputModule['question-title']}>{_question.title}</b>
                {inputObject[index].missingMandatory && <p className={InputModule['warning']}>入力してください</p>}
                {_question.type === 'pulldown' && (
                  <>
                    <p className={InputModule['question-description']}>
                      {_question.description && _question.description}
                    </p>
                    <FormControl className={InputModule.formcontrol} size="small">
                      <InputLabel className={InputModule.inputlabel} id="demo-simple-select-label"></InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={inputObject[index].value}
                        label=""
                        onChange={(e) => inputObjectChangeHandler(e.target.value, _question.key)}
                      >
                        {_question.options.map((option, o_index) => (
                          <MenuItem
                            key={o_index}
                            value={option.value ? option.value : option.displayName}
                            className={InputModule['menu-item']}
                          >
                            {option.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
                {_question.type === 'text' && (
                  <OCR
                    questionData={_question}
                    onValueChange={inputObjectChangeHandler}
                    answerCharacterLimit={_question.answerCharacterLimit}
                    answerMaxLength={_question.answerMaxLength}
                  />
                )}
                {_question.type === 'image' && (
                  <>
                    <p className={InputModule['question-description']}>
                      {_question.description && _question.description}
                    </p>
                    <SelectImage
                      key={index}
                      questionData={_question}
                      onValueChange={(file) => handleImageFileChange(file, index)}
                    />
                  </>
                )}
              </div>
            </div>
          ))}
      </div>

      <div className={InputModule.buttons}>
        <button className={InputModule.ask2} onClick={() => askGPT(state.model)}>
          <b>スタート</b>
        </button>
      </div>
    </>
  )
}
export default InputScreen
