import React, { useEffect, useState } from 'react'
import HeaderForTutor from '..//Common/Header2'
import HeaderForStudent from '..//Common/Header'
import { auth, db } from '../../firebase'
import { doc, getDoc, collection } from 'firebase/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useSearchParams } from 'react-router-dom'

function Header({ title }) {
  const [user] = useAuthState(auth)
  const [role, setRole] = useState('')
  const [userDocData, setUserDocData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  useEffect(() => {
    setLoading(true)
    ;(async () => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid)
        const userData = await getDoc(userDocRef)
        const { role } = userData.data()
        const { email } = userData.data()
        if (email) {
          setEmail(email)
        }
        setUserDocData(userData.data())
        setRole(role)
        setLoading(false)
        if (userData.schoolDocId) {
          setSchoolId(userData.data().schoolDocId)
          setSchoolIdFromDoc(userData.data().schoolDocId)
          setIsSchoolId(true)
        } else {
          setIsSchoolId(false)
        }
      }
    })()
  }, [user])
  useEffect(() => {
    if (user) {
      const userDocRef = doc(db, 'users', user.uid)
      getDoc(userDocRef).then((userDocSnapshot) => {
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data()
          if (userData.schoolDocId) {
            setSchoolId(userData.schoolDocId)
            setSchoolIdFromDoc(userData.schoolDocId)
            setIsSchoolId(true)
          } else {
            setIsSchoolId(false)
          }
        } else {
        }
      })
    }
  }, [user])
  const [headerTitle, setHeaderTitle] = useState('')
  const _title = title ? title : ''
  const [imageUrl, setImageUrl] = useState('')
  const [schoolId, setSchoolId] = useState('')
  const [isSchoolId, setIsSchoolId] = useState(false)
  const [studentId, setStudenetId] = useState('')
  const [studentName, setStudentName] = useState('')
  const [dataVisible, setDataVisible] = useState(true)
  const [searchParams] = useSearchParams()

  const _schoolId = searchParams.get('school_id')
  const [schoolName, setSchoolName] = useState('')
  const [tutorName, setTutorName] = useState('')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [schoolIdFromDoc, setSchoolIdFromDoc] = useState('')
  useEffect(() => {
    const fetchData = async () => {
      try {
        let promptDocRef
        if (schoolId || _schoolId) {
          const mingakuDocRef = doc(db, 'externalServices', 'mingaku')
          const schoolsCollectionRef = collection(mingakuDocRef, 'schools')
          promptDocRef = doc(schoolsCollectionRef, schoolId ? schoolId : _schoolId)
        } else {
          promptDocRef = doc(db, 'service_type', 'gpt-teacher')
        }
        const promptDocSnap = await getDoc(promptDocRef)
        if (promptDocSnap.exists()) {
          const {
            pageTitle: fetchedPageTitle,
            headerTitle: fetchedHeaderTitle,
            headerIconImageUrl: fetchedIconImageUrl
          } = promptDocSnap.data()
          if (fetchedHeaderTitle || fetchedIconImageUrl || fetchedPageTitle) {
            setHeaderTitle(fetchedHeaderTitle)
            document.title = fetchedPageTitle ? fetchedPageTitle : 'スクールAI'
            setImageUrl(fetchedIconImageUrl)
          } else {
            setHeaderTitle('Home')
          }
          if (promptDocSnap.data().dataVisible === false) {
            setDataVisible(false)
          }
        } else {
          console.error('Document not found')
        }
      } catch (error) {
        console.error('Error fetching document:', error)
      }
    }
    fetchData()
  }, [isSchoolId, schoolId, _schoolId])
  return loading ? (
    <></>
  ) : role === 'tutor' || role === 'admin' || email ? (
    <HeaderForTutor
      headerTitle={headerTitle}
      imageUrl={imageUrl}
      dataVisible={dataVisible}
      title={title}
      userDocData={userDocData}
      role={role}
    />
  ) : (
    <HeaderForStudent headerTitle={headerTitle} imageUrl={imageUrl} title={title} userDocData={userDocData} />
  )
}

export default Header
