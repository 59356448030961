import { getDoc } from 'firebase/firestore'

export const isWithinTimeWindow = async (timeWindows, ref, use_time_window) => {
  const currentTime = new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Tokyo' }))
  const currentHours = currentTime.getHours()
  const currentMinutes = currentTime.getMinutes()
  const currentTotalMinutes = currentHours * 60 + currentMinutes

  const checkTimeWindow = (windows) => {
    return windows.some((window) => {
      const [startHours, startMinutes] = window.start.split(':').map(Number)
      const [endHours, endMinutes] = window.end.split(':').map(Number)
      const startTotalMinutes = startHours * 60 + startMinutes
      const endTotalMinutes = endHours * 60 + endMinutes

      return startTotalMinutes <= currentTotalMinutes && currentTotalMinutes <= endTotalMinutes
    })
  }
  if (use_time_window) {
    return true
  } else if (timeWindows) {
    return checkTimeWindow(timeWindows)
  } else if (ref) {
    const modeDoc = await getDoc(ref)
    if (modeDoc.exists()) {
      const modeData = modeDoc.data()

      if (modeData.time_window_limit !== true) {
        return true
      } else if (modeData.time_window_limit === true && modeData.time_window) {
        return checkTimeWindow(modeData.time_window)
      }
    }
  }
  return false
}
