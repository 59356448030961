import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MenuIconForChatScreenModule from './MenuIconForChatScreen.module.scss'
import MenuIconForMessageModule from './MenuIconForMessage.module.scss'
import MenuIconForManagementScreen from './MenuIconForManagementScreen.module.scss'

const MenuIcon = (props) => {
  const {
    conversationId,
    type,
    onButtonClick,
    item,
    handleIconClick,
    handleDownloadSubmittedConversations,
    handleCopyModeLink,
    handleModeStart,
    handleCopyModeShareLink
  } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const currentPath = window.location.pathname
  let styles
  if (currentPath === '/submission_management' || currentPath === '/management') {
    styles = MenuIconForManagementScreen
  } else {
    styles =
      type === 'message-left' || type === 'message-right' ? MenuIconForMessageModule : MenuIconForChatScreenModule
  }

  const handlePrintChatScreen = (e, conversationId) => {
    e.stopPropagation()
    setAnchorEl(null)
    const chatListElement = document.getElementById(conversationId)

    if (chatListElement) {
      const chatContainerClone = chatListElement.cloneNode(true)

      // Remove elements by ID instead of class name
      const idsToRemove = ['icon', 'start-over']
      idsToRemove.forEach((id) => {
        const elements = chatContainerClone.querySelectorAll(`#${id}`)
        elements.forEach((element) => {
          element.parentNode.removeChild(element)
        })
      })

      // Adjust styles
      const elementsToAdjust = chatContainerClone.querySelectorAll(
        '#largeIcon, #chat, #chat-icon, #input,#system-bubble'
      )
      elementsToAdjust.forEach((element) => {
        element.style.boxShadow = 'none'
        element.style.border = '1px solid rgb(203, 203, 203)'
      })

      const chatContainerElement1 = chatContainerClone.querySelector('#chat-container1')
      const chatContainerElement2 = chatContainerClone.querySelector('#chat-container2')
      if (chatContainerElement1) {
        chatContainerElement1.style.width = '100vw'
      }
      if (chatContainerElement2) {
        chatContainerElement2.style.height = ''
        chatContainerElement2.style.width = '100vw'
      }

      const iframe = document.createElement('iframe')
      document.body.appendChild(iframe)
      iframe.style.width = '100vw'

      setTimeout(() => {
        const doc = iframe.contentDocument
        doc.open()
        doc.write('<html><head><title>スクールAI</title>')

        // Add stylesheets directly
        const stylesheets = Array.from(document.styleSheets)
        stylesheets.forEach((stylesheet) => {
          try {
            if (stylesheet.href) {
              doc.write(`<link rel="stylesheet" type="text/css" href="${stylesheet.href}">`)
            } else {
              const cssText = Array.from(stylesheet.cssRules)
                .map((rule) => rule.cssText)
                .join('\n')
              doc.write(`<style>${cssText}</style>`)
            }
          } catch (e) {
            console.log('Could not load stylesheet:', e)
          }
        })

        doc.write('</head><body>')
        doc.write(chatContainerClone.outerHTML)
        doc.write('</body></html>')
        doc.close()

        setTimeout(() => {
          iframe.contentWindow.print()
          setTimeout(() => {
            document.body.removeChild(iframe)
          }, 1500)
        }, 500)
      }, 0)
    }
  }

  const ITEM_HEIGHT = 48
  const open = Boolean(anchorEl)
  const handleClick = (e) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }

  const handleClose = (e) => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const optionsForHomeChatScreen = [
    { label: 'チャットを印刷', action: handlePrintChatScreen },
    { label: 'チャットを提出', action: onButtonClick }
  ]
  const optionsForSubmittedConversationScreen = [{ label: 'チャットを印刷', action: handlePrintChatScreen }]
  const optionsForManagementScreen = [
    { label: 'モード開始リンクをコピー', action: () => handleCopyModeLink(item) },
    { label: 'モード共有リンクをコピー', action: () => handleCopyModeShareLink(item) },
    { label: 'モードを開始', action: () => handleModeStart(item) }
  ]

  const optionsForSubmissionManagementScreen = [
    { label: '提出物を確認', action: () => handleIconClick(item) },
    { label: '提出物をダウンロード', action: () => handleDownloadSubmittedConversations(item) }
  ]

  let options = []

  if (currentPath === '/submission_management') {
    options = optionsForSubmissionManagementScreen
  } else if (currentPath === '/submitted_conversations') {
    options = optionsForSubmittedConversationScreen
  } else if (currentPath === '/management') {
    options = optionsForManagementScreen
  } else {
    options = optionsForHomeChatScreen
  }

  const determineTop = () => {
    if (currentPath === '/' || currentPath === '/thread_list') {
      return { top: '80px' }
    } else if (type === 'chat-screen-without-header') {
      return { top: '55px' }
    } else if (type === 'chat-screen') {
      return { top: '105px' }
    } else if (type === 'chat-screen-without-header') {
      return { top: '0px' }
    }
  }

  return (
    <div id={'icon'} className={styles['icon-container']}>
      <IconButton
        style={determineTop()}
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className={styles['icon']}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5
          }
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={(e) => {
              e.stopPropagation()
              option.action(e, conversationId)
              handleClose(e)
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default MenuIcon
