import React, { useEffect } from 'react'

function PrintOut() {
  useEffect(() => {
    window.print()
    window.close()
  }, [])

  const content = localStorage.getItem('printContent')

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
    </>
  )
}
export default PrintOut
