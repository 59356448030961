import React from 'react'
import PrintOutPage from '../../components/PrintOut/PrintOut'
import PrintOutModule from './print_out.module.scss'
function PrintOut() {
  return (
    <div className={PrintOutModule['all']}>
      <div className={PrintOutModule['sub']}>
        {/* <button onClick={handlePrint}>印刷する</button> */}
        <PrintOutPage />
      </div>
    </div>
  )
}
export default PrintOut
