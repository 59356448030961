import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ChatList from '../ChatList/ChatList'
import NameList from '../NameList/NameList'
import ChatScreen from '../ChatScreen/ChatScreen'
import StartButton from '../StartButton/StartButton'
import MainLayoutModule from './MainLayout.module.scss'
import MainLayoutModuleWithOutList from './MainLayoutModuleWithOutList.module.scss'
import { auth, db } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useSearchParams } from 'react-router-dom'

function MainLayoutWithNameList(props) {
  const { showheader, displayList, conversationType, onModeChange, submittionBox } = props
  const styles = displayList === true ? MainLayoutModule : MainLayoutModuleWithOutList
  const { state } = useLocation()
  const [chatScreenWidth, setChatScreenWidth] = useState(null)
  const [chatScreenBottom, setChatScreenBottom] = useState(0)
  const chatScreenRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [listWidth, setListWidth] = useState('30%')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1100)
  const [noConversation, setNoConversation] = useState(false)
  const [conversation, setConversation] = useState(state && state)
  const [searchParams] = useSearchParams()
  const submitter_id = searchParams.get('submitter_id')
  const conversation_id = searchParams.get('conversation_id')
  const thread_id = searchParams.get('thread_id')
  const [submitterUserId, setSubmitterUserId] = useState(submitter_id && submitter_id)
  const mainLayoutRef = useRef(null)
  const conversationListRef = useRef(null)
  const [chatScreenHeight, setChatScreenHeight] = useState(0)
  const [chatScreenHeightWithTextArea, setChatScreenHeightWithTextArea] = useState(0)
  const [chatScreenHeightWithTextAreaAndFooter, setChatScreenHeightWithTextAreaAndFooter] = useState(0)

  useEffect(() => {
    // クリックイベントのハンドラ
    const handleClickOutside = (event) => {
      if (isMenuOpen && conversationListRef.current && !conversationListRef.current.contains(event.target)) {
        setIsMenuOpen(false)
      }
    }

    // イベントリスナーを追加
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      // コンポーネントのアンマウント時にイベントリスナーを削除
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isMenuOpen, conversationListRef])
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
      setIsTablet(window.innerWidth <= 1100)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const handleSelectConversation = (_conversation) => {
    setConversation(_conversation)
  }
  const handleSelectSubmitter = (userId) => {
    setSubmitterUserId(userId)
  }
  // ハンバーガーメニューをトグルする関数
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }
  const onMenuItemClick = () => {
    if (isMobile) {
      setIsMenuOpen(false)
    }
  }

  const handleNoConversation = (value) => {
    setNoConversation(value)
  }
  // デフォルト会話を受け取るための関数
  const currentUrl = window.location.pathname + window.location.search

  const handleDefaultConversationSet = (_conversation) => {
    if (currentUrl === '/') return
    setConversation(_conversation)
  }
  const handleDefaultSubmitter = (submitter) => {
    if (window.location.pathname !== '/submitted_conversations') return
    setSubmitterUserId(submitter)
  }
  const [user] = useAuthState(auth)

  // スプリッターの状態
  const startDragging = useCallback((e) => {
    setIsDragging(true)
    e.preventDefault()
  }, [])

  const stopDragging = useCallback(() => {
    if (isDragging) {
      setIsDragging(false)
    }
  }, [isDragging])

  const MIN_WIDTH_PERCENTAGE = 20 // 最小幅をパーセンテージで設定
  const MAX_WIDTH_PERCENTAGE = 50 // 最大幅70%
  const onDrag = useCallback(
    (e) => {
      if (isDragging) {
        const percentageWidth = (e.clientX / mainLayoutRef.current.clientWidth) * 100
        // 最小値と最大値に基づいて幅を制限する
        const newWidth = Math.min(Math.max(percentageWidth, MIN_WIDTH_PERCENTAGE), MAX_WIDTH_PERCENTAGE).toFixed(2)
        setListWidth(`${newWidth}vw`)
      }
    },
    [isDragging, MIN_WIDTH_PERCENTAGE, MAX_WIDTH_PERCENTAGE]
  )

  useEffect(() => {
    // Function to update the chat screen width
    const updateChatScreenWidth = () => {
      if (chatScreenRef.current) {
        const width = chatScreenRef.current.offsetWidth // Get the width in pixels
        setChatScreenWidth(`${width}px`) // Update state with pixel value
      }
    }

    // Call update function on mount and whenever listWidth changes
    updateChatScreenWidth()

    // Add event listener for window resize
    window.addEventListener('resize', updateChatScreenWidth)

    // Cleanup event listener
    return () => window.removeEventListener('resize', updateChatScreenWidth)
  }, [listWidth])

  useEffect(() => {
    // chat-screen の位置を更新する関数
    const updateChatScreenPosition = () => {
      if (chatScreenRef.current) {
        const bottomPosition = chatScreenRef.current.getBoundingClientRect().bottom
        setChatScreenBottom(bottomPosition)
        setChatScreenHeight(chatScreenRef.current.offsetHeight - 24)
        let height = chatScreenRef.current.offsetHeight - 24
        if (isMobile & (showheader !== 'false')) {
          height = chatScreenRef.current.offsetHeight - 8
        } else if (isMobile & (showheader === 'false')) {
          height = chatScreenRef.current.offsetHeight + 34
        } else if (isTablet & (showheader !== 'false')) {
          // 修正済み
          height = chatScreenRef.current.offsetHeight + 20
        } else if (isTablet & (showheader === 'false')) {
          // 修正済み
          height = chatScreenRef.current.offsetHeight + 60
        } else if (showheader === 'false') {
          // 修正済み
          height = chatScreenRef.current.offsetHeight + 54
        } else {
          // 修正済み
          height = chatScreenRef.current.offsetHeight + 14
        }

        setChatScreenHeightWithTextArea(height)

        if (isMobile) {
          setChatScreenHeightWithTextAreaAndFooter(chatScreenRef.current.offsetHeight - 154)
        } else if (isTablet) {
          setChatScreenHeightWithTextAreaAndFooter(chatScreenRef.current.offsetHeight - 164)
        } else {
          setChatScreenHeightWithTextAreaAndFooter(chatScreenRef.current.offsetHeight - 154)
        }
      }
    }

    // コンポーネントのマウント時とウィンドウのリサイズ時に実行
    updateChatScreenPosition()
    window.addEventListener('resize', updateChatScreenPosition)
    return () => window.removeEventListener('resize', updateChatScreenPosition)
  }, [])
  const [submitters, setSubmitters] = useState([])

  return (
    <>
      {noConversation ? (
        <>
          <p className={styles['noData']}>提出済みの会話がありません</p>
        </>
      ) : (
        <div
          className={styles['main-layout']}
          ref={mainLayoutRef}
          onMouseMove={onDrag}
          onMouseUp={stopDragging}
          onMouseLeave={stopDragging}
        >
          <div className={styles['name-list']} ref={conversationListRef}>
            <NameList
              submitters={submitters}
              setSubmitters={setSubmitters}
              onDefaultConversationSet={handleDefaultConversationSet}
              onSelectSubmitter={handleSelectSubmitter}
              onMenuItemClick={onMenuItemClick}
              onNoConversation={handleNoConversation}
              {...props}
              onDefaultSubmitter={handleDefaultSubmitter}
            />
          </div>
          <div
            className={styles['conversation-list']}
            ref={conversationListRef}
            style={
              isMobile
                ? {
                    transform: isMenuOpen ? 'translateX(0)' : 'translateX(-100%)'
                  }
                : { width: listWidth }
            }
          >
            <ChatList
              onDefaultConversationSet={handleDefaultConversationSet}
              onSelectConversation={handleSelectConversation}
              onMenuItemClick={onMenuItemClick}
              onNoConversation={handleNoConversation}
              submitterUserId={submitterUserId}
              {...props}
              onDefaultSubmitter={handleDefaultSubmitter}
            />
          </div>
          {!isMobile && <div className={styles['splitter']} onMouseDown={startDragging}></div>}

          <div
            className={styles['chat-screen']}
            ref={chatScreenRef}
            style={{ width: `calc(100vw - ${listWidth} - 2.5%)` }}
          >
            {state || conversation ? (
              <ChatScreen
                conversation={conversation}
                submitterUserId={submitterUserId}
                conversationType={'conversations'}
                startButton={false}
                chatScreenWidth={chatScreenWidth}
                userInputWidth={chatScreenWidth}
                chatScreenBottom={chatScreenBottom}
                chatScreenHeight={chatScreenHeight}
                chatScreenHeightWithTextArea={chatScreenHeightWithTextArea}
                chatScreenHeightWithTextAreaAndFooter={chatScreenHeightWithTextAreaAndFooter}
                {...props}
              />
            ) : (
              <>{currentUrl === '/' ? <StartButton chatScreenHeight={chatScreenHeight} /> : <></>}</>
            )}
          </div>
        </div>
      )}
    </>
  )
}
export default MainLayoutWithNameList
