import AppModule from '../../App.module.scss'
import Footer from '../../components/Common/Footer'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import UserInfo from '../../components/UserInfo/UserInfo'
import Header from '../../components/Header/Header'
import React from 'react'

function MyPage() {
  const [user] = useAuthState(auth)
  // useAuthCheck()
  return (
    <div className={AppModule.main}>
      <Header title="マイページ" />
      {user ? <UserInfo /> : <></>}
      <Footer homePath="/" addChatPath="/mode" userPath="/mypage" />
    </div>
  )
}
export default MyPage
