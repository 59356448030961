import { auth } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import UserInfoModule from '../UserInfo/UserInfo.module.scss'

function SignOutButton({ loginPath }) {
  const buttonClassName = 'button'

  const navigate = useNavigate()
  const logout = () => {
    auth.signOut().then((userCredential) => {
      navigate(loginPath)
    })
  }
  return (
    <button className={UserInfoModule[buttonClassName]} onClick={() => logout()}>
      ログアウト
    </button>
  )
}
export default SignOutButton
