import { _language_list_v2 } from '../localConstant'
// export const getMatchingLanguage = (promptLanguage) => {
//   let transformedPromptLanguage = JSON.parse(promptLanguage)

//   if (transformedPromptLanguage.japaneseName.startsWith('【試験運用版】')) {
//     transformedPromptLanguage.japaneseName = transformedPromptLanguage.japaneseName.replace('【試験運用版】', '')
//   }

//   return _language_list_v2.find(
//     (lang) =>
//       lang.physicalName === transformedPromptLanguage.physicalName &&
//       lang.languageCode === transformedPromptLanguage.languageCode &&
//       lang.type === transformedPromptLanguage.type
//   )
// }

export const getMatchingLanguage = (promptLanguage) => {
  let transformedPromptLanguage

  try {
    transformedPromptLanguage = JSON.parse(promptLanguage)
  } catch (error) {
    console.error('Error parsing JSON:', error)
    return null // 必要に応じてデフォルトの値を返すか、エラーハンドリングを行う
  }

  if (transformedPromptLanguage?.japaneseName?.startsWith('【試験運用版】')) {
    transformedPromptLanguage.japaneseName = transformedPromptLanguage.japaneseName.replace('【試験運用版】', '')
  }

  return _language_list_v2.find(
    (lang) =>
      lang.physicalName === transformedPromptLanguage?.physicalName &&
      lang.languageCode === transformedPromptLanguage?.languageCode &&
      lang.type === transformedPromptLanguage?.type
  )
}
