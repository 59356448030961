import ManagementModule from './AdminManagementScreen.module.scss'
import { useState, useEffect, useRef, useCallback } from 'react'
import LoadingGrid from '../LoadingGrid/LoadingGrid'
import FieldComponent from '../Field/Field'
import { collection, getDocs, doc, setDoc, query, updateDoc, deleteDoc } from 'firebase/firestore'
import { db } from '../../firebase'
import * as c_data from '../../localConstant'
import ModeSearch from '../ModeSearch/ModeSearch' // 新しいコンポーネントのパスは適宜調整してください
import Switch2 from '../Switch/Switch'

function useEditedState(initialValue) {
  const [value, setValue] = useState(initialValue)
  const [isEdited, setIsEdited] = useState(false)
  // setValueをラップする関数
  const setEditedValue = useCallback((newValue) => {
    setValue(newValue)
    setIsEdited(true)
  }, [])
  return [value, setEditedValue, isEdited, setIsEdited]
}

function ManagementScreen() {
  const selectedItemClassName = window.location.pathname.startsWith('/management')
    ? 'selected-item'
    : 'selected-item-interview'

  const [selectedItem, setSelectedItem, isEdited, setIsEdited] = useEditedState('')
  const [systemItems, setSystemItems] = useState([])

  const [filteredChildrenItems, setFilteredChildrenItems] = useState([])
  const managementModuleRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [viewableSearchQuery, setViewableSearchQuery] = useState('')
  const [searchText, setSearchText] = useState('')
  const [filteredItems, setFilteredItems] = useState([])
  const fetchChildrenData = async (item) => {
    const itemChildrenDataRef = collection(item.ref, 'itemChildrenData')
    const itemChildrenDataSnapshot = await getDocs(itemChildrenDataRef)
    return itemChildrenDataSnapshot.docs.map((childDoc) => ({
      ...childDoc.data(),
      ref: childDoc.ref
    }))
  }
  const addMissingObjects = async (itemChildrenData, referenceData) => {
    const referenceValues = referenceData.map((item) => item.value)
    const filteredItemChildrenData = []
    for (const child of itemChildrenData) {
      if (referenceValues.includes(child.value)) {
        filteredItemChildrenData.push(child)
      } else {
        await deleteDoc(child.ref)
      }
    }
    const missingObjects = referenceData.filter(
      (item) => !filteredItemChildrenData.some((child) => child.value === item.value)
    )
    const combinedData = [...filteredItemChildrenData, ...missingObjects]
    combinedData.sort((a, b) => referenceValues.indexOf(a.value) - referenceValues.indexOf(b.value))
    return combinedData
  }
  const fetchChildrenDataAndSetSelectedItem = async (item, setSelectedItem, setIsEdited) => {
    const itemChildrenData = await fetchChildrenData(item)
    // if (itemChildrenData.length > 0) {
    const referenceData = c_data[item.item_id]
    if (referenceData) {
      const updatedItemChildrenData = await addMissingObjects(itemChildrenData, referenceData)
      const finalItemChildrenData = updatedItemChildrenData.map((child) => ({
        ...child,
        displayName2: child.displayName2 ? child.displayName2 : child.displayName,
        active: child.active !== undefined ? child.active : true
      }))
      setSelectedItem({
        ...item,
        item_children_data: finalItemChildrenData
      })
    } else {
      console.error(`Reference data for item_id ${item.item_id} not found in c_data.`)
    }
    // } else {
    //   item.item_children_data = []
    //   setSelectedItem(item)
    // }
    setIsEdited(false)
  }

  const switchBody = async (item) => {
    if (
      isEdited &&
      !window.confirm(
        '変更がまだ保存されていないようですが、よろしいですか？\n保存しないと、変更内容は消えてしまいます。'
      )
    ) {
      return
    }
    if (managementModuleRef.current) {
      managementModuleRef.current.scrollTop = 0
    }
    await fetchChildrenDataAndSetSelectedItem(item, setSelectedItem, setIsEdited)
  }
  const filterItems = (items, query, key) => {
    return items.filter((item) => item[key].toLowerCase().includes(query.toLowerCase()))
  }

  const filterChildrenItems = (items, query) => {
    return items.filter((child) => {
      if (child) {
        return child.displayName.includes(query)
      }
      return false
    })
  }
  const handleSetAllAvailable = () => {
    const updatedChildrenData = selectedItem.item_children_data.map((child) => ({ ...child, active: true }))
    setSelectedItem({ ...selectedItem, item_children_data: updatedChildrenData })
  }
  const handleSetAllUnavailable = () => {
    const updatedChildrenData = selectedItem.item_children_data.map((child) => ({ ...child, active: false }))
    setSelectedItem({ ...selectedItem, item_children_data: updatedChildrenData })
  }
  const handleAvailableCheckboxChange = (e, item) => {
    const fixedValue = e.target.value === 'true' ? true : false
    const updatedChildrenData = selectedItem.item_children_data.map((child) =>
      child.value === item.value ? { ...child, active: !fixedValue } : child
    )
    const updatedSelectedItem = {
      ...selectedItem,
      item_children_data: updatedChildrenData
    }
    setSelectedItem(updatedSelectedItem)
  }

  const changeItemValue = useCallback(
    (value, item) => {
      const updatedChildrenData = selectedItem.item_children_data.map((child) =>
        child.value === item.value ? { ...child, displayName2: value } : child
      )
      setSelectedItem((prevSelectedItem) => ({
        ...prevSelectedItem,
        item_children_data: updatedChildrenData
      }))
      // filteredChildrenItems も更新します
      const updatedFilteredChildrenItems = filteredChildrenItems.map((child) =>
        child.value === item.value ? { ...child, displayName2: value } : child
      )
      setFilteredChildrenItems(updatedFilteredChildrenItems)
    },
    [selectedItem, filteredChildrenItems] // filteredChildrenItems を依存関係に追加します
  )
  const handleSaveItem = async (item) => {
    const { item_children_data, ...restItem } = item
    const itemChildrenDataRef = collection(item.ref, 'itemChildrenData')

    await Promise.all([
      // systemItem
      updateDoc(item.ref, { ...restItem, item_updated_at: new Date().getTime() }),

      // systemItemのchildData
      ...item_children_data.map(async (child) => {
        if (child.ref) {
          return updateDoc(child.ref, child)
        } else {
          const newDocRef = doc(itemChildrenDataRef)
          return setDoc(newDocRef, child)
        }
      })
    ])

    window.location.reload()
  }
  useEffect(() => {
    const fetchData = async () => {
      const systemItemsRef = collection(db, 'systemSettings')
      const systemItemsQuery = query(systemItemsRef)
      const systemItemsSnapshot = await getDocs(systemItemsQuery)
      let systemItemsData = await Promise.all(
        systemItemsSnapshot.docs.map(async (doc) => ({
          ...doc.data(),
          ref: doc.ref
        }))
      )
      setSystemItems(systemItemsData)
      await fetchChildrenDataAndSetSelectedItem(systemItemsData[0], setSelectedItem, setIsEdited)
    }
    fetchData()
  }, [])

  useEffect(() => {
    const filtered = filterItems(systemItems, searchText, 'item_name')
    setFilteredItems(filtered)
  }, [searchText, systemItems])

  useEffect(() => {
    if (selectedItem.item_children_data) {
      const filteredItem = filterChildrenItems(selectedItem.item_children_data, viewableSearchQuery)
      setFilteredChildrenItems(filteredItem)
    }
    // filteredChildrenItems の依存関係を削除して、選択アイテムの変更時のみ再実行されるようにします
  }, [selectedItem, viewableSearchQuery])

  /* モード選択・新規作成時に画面が切り替わるときのハンドラ */
  const handleViewableSearchChange = (event) => {
    setViewableSearchQuery(event.target.value)
  }
  const [text, setText] = useState('')
  return (
    <>
      {loading ? (
        <LoadingGrid />
      ) : (
        <div className={ManagementModule.container}>
          <div className={ManagementModule.sidebar}>
            <div className={ManagementModule['sidebarheader-container']}>
              <div className={ManagementModule.sidebarheader}>
                <ModeSearch
                  placeholderMessage="管理項目を検索..."
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </div>
            </div>
            <div className={ManagementModule['list-container']}>
              <ul className={ManagementModule.list}>
                {filteredItems.map((item, index) => {
                  return (
                    <div key={index} className={ManagementModule.list}>
                      <li
                        key={index}
                        className={`${ManagementModule.list} ${
                          selectedItem === item.id ? ManagementModule[selectedItemClassName] : ''
                        }`}
                        onClick={() => switchBody(item)}
                      >
                        <p className={ManagementModule['list-title']}>{item.item_name}</p>
                      </li>
                    </div>
                  )
                })}
              </ul>
            </div>
          </div>
          <>
            <div className={ManagementModule.body}>
              <>
                <div className={ManagementModule['prompt-body-header']}>
                  <div className={ManagementModule['sub-title-container']}>
                    <h3 className={ManagementModule['sub-title']}>{selectedItem ? selectedItem.item_name : '新規'}</h3>
                  </div>
                  <div className={ManagementModule['button-container']}>
                    <button
                      className={ManagementModule['copy']}
                      onClick={() => handleSaveItem(selectedItem)}
                      type="submit"
                    >
                      保存
                    </button>
                  </div>
                </div>
                <div className={ManagementModule.main} ref={managementModuleRef}>
                  {/* <div className={ManagementModule['prompt-body']}>
                    <FieldComponent fieldItem={selectedItem} fieldName={c_data._field_name_of_admin_management} />
                  </div> */}
                  <div className={ManagementModule['prompt-body']}>
                    {/* <div className={ManagementModule['section-title-group']}>
                      <h3 className={ManagementModule['section-title']}>{selectedItem.item_name}の詳細設定</h3>
                    </div> */}
                    <div className={ManagementModule['search-container']}>
                      <input
                        className={ManagementModule['search']}
                        type="text"
                        placeholder={`デフォルト名を検索...`}
                        value={viewableSearchQuery}
                        onChange={handleViewableSearchChange}
                      />
                      <div className={ManagementModule['button-container']}>
                        <button className={ManagementModule['show']} onClick={handleSetAllAvailable}>
                          全て表示
                        </button>
                      </div>
                      <div className={ManagementModule['button-container']}>
                        <button className={ManagementModule['show']} onClick={handleSetAllUnavailable}>
                          全て非表示
                        </button>
                      </div>
                    </div>
                    <p className={ManagementModule.tips}>※表示名が空欄の場合はデフォルト名が表示名になります。</p>
                    <div className={ManagementModule['doc-list']}>
                      <table className={ManagementModule['options-table']}>
                        <thead>
                          <tr>
                            <th className={ManagementModule.order}>表示</th>
                            <th className={ManagementModule.option}>デフォルト名</th>
                            <th className={ManagementModule.optionContent}>表示名</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredChildrenItems.map((item, index) => (
                            // {selectedItem.item_children_data.map((item, index) => (
                            <tr key={index} className={ManagementModule['tr-container']}>
                              <td>
                                <Switch2
                                  value={item.active}
                                  checked={item.active}
                                  onText={'表示'}
                                  offText={'非表示'}
                                  onChange={(e) => handleAvailableCheckboxChange(e, item)}
                                />
                              </td>
                              <td>
                                <textarea value={item.displayName} disabled className={ManagementModule['option']} />
                              </td>
                              <td>
                                <textarea
                                  className={ManagementModule['optionContent']}
                                  value={item.displayName2}
                                  style={{ imeMode: 'auto' }}
                                  onChange={(e) => changeItemValue(e.target.value, item)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </>
        </div>
      )}
    </>
  )
}
export default ManagementScreen
