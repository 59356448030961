import HeaderModule from './Header.module.scss'
import FaceIcon from '@mui/icons-material/Face'
import { useState, useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { doc, collection, getDoc } from 'firebase/firestore'
import { auth, db, functions } from '../../firebase'
import { httpsCallable } from 'firebase/functions'
import { ProgressBar } from 'react-loader-spinner'

function Header({ headerTitle, imageUrl, title, userDocData }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600)
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600) // 同様に、スマホサイズの閾値を変更できます
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const [user] = useAuthState(auth)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const _title = title ? title : ''
  const [studentId, setStudenetId] = useState('')
  const [studentName, setStudentName] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user && !!userDocData) {
      setLoading(true)
      const userDocRef = doc(db, 'users', user.uid)
      getDoc(userDocRef).then(async (userDocSnapshot) => {
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data()
          if (userData.schoolDocId) {
            const getStudentInfo = httpsCallable(functions, 'get_student_info_of_mingaku')
            const getSchoolsInfo = httpsCallable(functions, 'get_schools_info')
            const [studentInfo, schoolInfo] = await Promise.all([
              getStudentInfo({ nandeUserId: user.uid }),
              getSchoolsInfo({ schoolIds: [userData.schoolDocId] })
            ])
            // const schoolname = schoolInfo.data.schoolInfo[0].data.name
            const schoolId = schoolInfo.data.schoolInfo[0].data.id
            const studentName = studentInfo.data.data.name
            const studentId = studentInfo.data.data.id
            // const studentGrade = studentInfo.data.data.grade
            const loginId = `${schoolId}-${studentId}`
            setStudenetId(loginId)
            setStudentName(studentName)
            setLoading(false)
          } else {
          }
        } else {
        }
      })
    }
  }, [user, userDocData])

  useEffect(() => {
    // ウィンドウの幅が変更された時に実行される関数
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // コンポーネントがアンマウントされる際にイベントリスナーを解除する
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const truncatedTitle = windowWidth <= 768 ? (_title.length > 11 ? _title.slice(0, 11) + '...' : _title) : _title
  return (
    <div className={HeaderModule.header}>
      <a className={HeaderModule.left} href="/">
        {imageUrl && <img className={HeaderModule['icon-image']} src={imageUrl} alt="" />}
        {((!isMobile && headerTitle) || (isMobile && !imageUrl)) && (
          <p className={HeaderModule['service-title']}>{headerTitle}</p>
        )}
      </a>
      <p className={HeaderModule['title']}>{truncatedTitle}</p>
      <div className={HeaderModule.right}>
        <div className={HeaderModule.rightName}>
          {loading ? (
            <ProgressBar
              height="40"
              width="40"
              ariaLabel="progress-bar-loading"
              wrapperStyle={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              wrapperClass="progress-bar-wrapper"
              borderColor="#a9a9a9"
              barColor="#e3e3e3"
            />
          ) : (
            <>
              <div>{studentId}</div>
              <div>{studentName}</div>
            </>
          )}
        </div>
        <a href="/mypage">
          <FaceIcon className={HeaderModule.icon} />
        </a>
      </div>
    </div>
  )
}

export default Header
