import React, { useEffect, useState } from 'react'
import AppModule from '../../../App.module.scss'
import Footer from '../../../components/Common/Footer'
import { useNavigate } from 'react-router-dom'
import Header from '../../../components/Header/Header'
import ChatMainLayout from '../../../components/ChatMainLayout/MainLayout'
import { useSearchParams } from 'react-router-dom'
import { _table_filter_periods, _logs_filter_periods } from '../../../localConstant'
import { useAuthState } from 'react-firebase-hooks/auth'
import LoadingGrid from '../../../components/LoadingGrid/LoadingGrid'
import { db, auth } from '../../../firebase'
import { doc, getDoc } from 'firebase/firestore'
function ThreadList() {
  const [searchParams] = useSearchParams()
  const filter = parseInt(searchParams.get('filter'), 10)
  const student_id = searchParams.get('student_id')
  const [user] = useAuthState(auth)
  const [displayName, setDisplayName] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    console.log('ddd', filter)

    if (filter || filter === 0) {
      console.log('ddd')
      const period = _table_filter_periods.find((p) => p.id === filter)
      if (period) {
        setDisplayName(period.displayName)
      }
    } else if (student_id) {
      const userDocRef = doc(db, 'users', student_id)
      getDoc(userDocRef).then((userDoc) => {
        const user_name = userDoc.data().name
          ? userDoc.data().name
          : userDoc.data().lastName || userDoc.data().firstName
          ? userDoc.data().lastName + ' ' + userDoc.data().firstName
          : userDoc.data().schoolDocId
          ? '先生用アカウント'
          : 'みんがく管理人'
        setDisplayName(`【${user_name}】`)
      })
    }
  }, [filter, student_id])
  useEffect(() => {
    // 生徒アカウントがモード管理画面にアクセスした場合、top画面に強制遷移
    if (user) {
      const userDocRef = doc(db, 'users', user.uid)
      getDoc(userDocRef).then((docSnapshot) => {
        if (docSnapshot.exists() && docSnapshot.data().studentDocId) {
          navigate('/')
        }
      })
    }
  }, [user])
  return (
    <div className={AppModule.main}>
      {user ? (
        <>
          <Header title={`会話ログ閲覧 ${displayName}`} />
          {/* {user ? ( */}
          <ChatMainLayout conversationType={'conversations'} displayList={true} displayFooter={true} readOnly={true} />
          {/* ) : (
            <></>
          )} */}
          <Footer homePath="/" addChatPath="/mode" userPath="/mypage" />
        </>
      ) : (
        <LoadingGrid />
      )}
    </div>
  )
}
export default ThreadList
