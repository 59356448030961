import React, { useEffect, useState } from 'react'
import Footer from '../../components/Common/Footer'
import Header from '../../components/Header/Header'
import { useSearchParams } from 'react-router-dom'
import { auth, db, functions } from '../../firebase'
import { httpsCallable } from 'firebase/functions'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { doc, updateDoc, collection, getDoc, addDoc } from 'firebase/firestore'
import CloneModule from './clone.module.scss'
import LoadingGrid from '../../components/LoadingGrid/LoadingGrid'
function Clone() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const ref = searchParams.get('ref')
  const uidFrom = searchParams.get('uidFrom')
  const [user] = useAuthState(auth)
  const [fromSchoolName, setFromSchoolName] = useState('')
  const [fromUserName, setFromUserName] = useState('')
  const [fromRole, setFromRole] = useState('')
  const [mode, setMode] = useState({})
  const [userData, setUserData] = useState({})
  const [promptsRef, setPromptsRef] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getUidFrom = async () => {
      const userDocRef = doc(db, 'users', uidFrom)
      setIsLoading(true)
      const userinfo = await getDoc(userDocRef)
      if (userinfo.data().role === 'tutor') {
        setFromRole('tutor')
        const getTutorInfo = httpsCallable(functions, 'get_tutor_info_of_mingaku')
        const getSchoolsInfo = httpsCallable(functions, 'get_schools_info')
        const [tutorIndo, schoolInfo] = await Promise.all([
          getTutorInfo({ userId: userinfo.data().tutorDocId, schoolId: userinfo.data().schoolDocId }),
          getSchoolsInfo({ schoolIds: [userinfo.data().schoolDocId] })
        ])
        setFromSchoolName(schoolInfo.data.schoolInfo[0].data.name)
        setFromUserName(tutorIndo.data.tutorDocData.name ? tutorIndo.data.tutorDocData.name : '')
      } else {
        setFromRole('admin')
        const { mingakuUserId, email } = userinfo.data()
        const getAuth = httpsCallable(functions, 'get_admin_info_of_mingaku')
        const { data } = await getAuth({ userId: mingakuUserId })
        const { adminAuthData, adminDocData } = data
        setFromUserName(adminDocData.name ? adminDocData.name : '')
      }
      setIsLoading(false)
    }
    getUidFrom()
  }, [])

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid)
        getDoc(userDocRef).then(async (userDocSnapshot) => {
          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data()
            setUserData(userData)
            let promptsCollectionRef
            if (!!userData.studentDocId) {
              // 生徒アカウントの場合
              navigate('/')
            } else if (userData.role === 'tutor') {
              // 先生アカウントの場合
              const mingakuDocRef = doc(db, 'externalServices', 'mingaku')
              promptsCollectionRef = collection(mingakuDocRef, 'schools', userData.schoolDocId, 'prompts')
            } else {
              // 管理人アカウントの場合
              promptsCollectionRef = collection(db, 'service_type', 'gpt-teacher', 'prompts')
            }

            setPromptsRef(promptsCollectionRef)

            const docRef = doc(db, ref)
            getDoc(docRef)
              .then((docSnap) => {
                if (docSnap.exists()) {
                  setMode({ ...docSnap.data() })
                } else {
                  console.log('No such document!')
                }
              })
              .catch((error) => {
                console.error('Error getting document:', error)
              })
          } else {
          }
        })
      } else {
        alert('現在はログアウト状態となっていますので複製ができません。お使いのアカウントにログインしてください。')
      }
    })
  }, [])

  const clone = async () => {
    setIsLoading(true)
    console.log(mode)
    console.log(promptsRef)
    const newDocRef = await addDoc(promptsRef, { ...mode, updatedAt: new Date().getTime() })
    await updateDoc(newDocRef, { ref: newDocRef })
    setIsLoading(false)
    alert('複製に成功しました！')
    if (userData.role === 'tutor') {
      navigate(`/management?school_id=${userData.schoolDocId}`)
    } else {
      navigate(`/management`)
    }
  }

  return (
    <div>
      <Header />
      {isLoading ? <LoadingGrid /> : <></>}
      <div className={CloneModule.cloneMessage}>
        <div>
          <div>
            {fromRole === 'tutor' ? (
              <div className={CloneModule.message}>
                {fromSchoolName}が作成したモード「{mode.prompt_title}」を複製できます
              </div>
            ) : (
              <div className={CloneModule.message}>
                みんがく管理人が作成したモード「{mode.prompt_title}」を複製できます
              </div>
            )}
          </div>
          <div className={CloneModule.modeContainer}>
            <div className={CloneModule.mode}>
              <div className={CloneModule.title}>
                <h4 className={CloneModule.title}>{mode.prompt_title}</h4>
              </div>
              <div className={CloneModule.cardBottomContainer}>
                {mode.prompt_description && mode.prompt_description !== '' ? (
                  <div className={CloneModule.description}>{mode.prompt_description}</div>
                ) : (
                  <div className={CloneModule.description}>{''}</div>
                )}
                <div onClick={() => clone()} className={CloneModule.start}>
                  複製する +
                </div>
              </div>
            </div>
          </div>
          <div>
            {fromRole === 'tutor' ? (
              <div className={CloneModule.subMessage}>
                ※ {fromSchoolName}の{fromUserName}さんから共有されました
              </div>
            ) : (
              <div className={CloneModule.subMessage}>※ みんがく管理人の{fromUserName}さんから共有されました</div>
            )}
          </div>
        </div>
      </div>
      <Footer homePath="/" addChatPath="/mode" userPath="/mypage" />
    </div>
  )
}
export default Clone
