import React from 'react'
import ModeSearch from '../ModeSearch/ModeSearch' // 新しいコンポーネントのパスは適宜調整してください
import Switch2 from '../Switch/Switch'
import MenuIcon2 from '../MenuIcon/MenuIcon'
import ManagementModule from './ManagementScreen.module.scss'

const ManagementScreenList = ({
  filteredModes,
  searchText,
  setSearchText,
  displayAddBody,
  switchBody,
  selectedItem,
  selectedItemClassName,
  schoolIdName,
  handleCopyModeLink,
  handleCopyModeShareLink,
  handleModeStart,
  updateActive,
  updateActiveForTeacher
}) => {
  return (
    <div className={ManagementModule.sidebar}>
      <div className={ManagementModule['sidebarheader-container']}>
        <div className={ManagementModule.sidebarheader}>
          <button className={ManagementModule['new-button']} onClick={() => displayAddBody()}>
            作成 +
          </button>
          <ModeSearch placeholderMessage="モードを検索..." searchText={searchText} setSearchText={setSearchText} />
        </div>
        <p>
          ※モードのテンプレートは
          <a target="_blank" rel="noreferrer" href={'https://www.notion.so/100-ab8069bf3ab444918766b2d417860e01'}>
            こちら
          </a>
        </p>
      </div>
      <div className={ManagementModule['list-container']}>
        <ul className={ManagementModule.list}>
          {filteredModes.map((item, index) => {
            return (
              <div key={index} className={ManagementModule.list}>
                {item.editable === true ? (
                  <Switch2
                    id="themeDeafault"
                    value={item.id}
                    checked={item.enable}
                    onChange={(e) => updateActive(e, e.target.value, item)}
                  />
                ) : (
                  <Switch2
                    id="themeDeafault"
                    value={item.id}
                    checked={item.active}
                    onChange={(e) => updateActiveForTeacher(e, item)}
                  />
                )}
                <li
                  onClick={() => switchBody(item)}
                  key={index}
                  className={`${ManagementModule.list} ${
                    selectedItem === item.id ? ManagementModule[selectedItemClassName] : ''
                  }`}
                >
                  <p className={ManagementModule['list-title']}>{item.prompt_title}</p>
                  <p className={ManagementModule['list-title-sub']}>表示順：{item.prompt_order}</p>
                  {item.editable === false && (
                    <b className={ManagementModule['list-title-sub2']}>
                      ※ {item.parentSchoolId !== 'gpt-teacher' ? schoolIdName[item.parentSchoolId] : '管理人'}
                      が作成したモードです
                    </b>
                  )}
                </li>
                <MenuIcon2
                  item={item}
                  handleCopyModeLink={handleCopyModeLink}
                  handleCopyModeShareLink={handleCopyModeShareLink}
                  handleModeStart={handleModeStart}
                />
              </div>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default ManagementScreenList
