import React from 'react'
import SwitchModule from './Switch.module.scss'

const Switch = ({ id, value, checked, onChange, onText = '有効', offText = '無効', disabled }) => {
  const handleChange = (e) => {
    e.stopPropagation()
    if (disabled) {
      return // Prevent onChange from being called if the switch is disabled
    }
    onChange(e)
  }
  return (
    <label className={SwitchModule['switch']}>
      <input id={id} type="checkbox" value={value} checked={checked} onChange={handleChange} />
      <span className={SwitchModule['slider']}>
        <span className={`${SwitchModule['slider-on']}${checked ? `${SwitchModule['visible']}` : ''}`}>
          {checked && onText}
        </span>
        <span
          className={`${SwitchModule['slider-off']} ${!checked ? ` ${SwitchModule['visible']}` : ''} ${
            offText.length >= 3 ? SwitchModule['small-font-slider-off'] : ''
          }`}
        >
          {!checked && offText}
        </span>
      </span>
    </label>
  )
}

export default Switch
