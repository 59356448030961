import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { auth } from '../../firebase'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { doc, setDoc } from 'firebase/firestore'
import { db } from '../../firebase'
import SignUpModule from './SignUp.module.scss'
import OutlinedInput from '@mui/material/OutlinedInput'

function SignUp({ path }) {
  const buttonClassName =
    window.location.pathname === '/login' || window.location.pathname === '/signup' ? 'button' : 'button-interview'
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const signUp = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        //登録が成功した時の処理
        setDoc(doc(db, 'users', userCredential.user.uid), {
          userId: userCredential.user.uid,
          email: userCredential.user.email ?? '',
          createdAt: userCredential.user.metadata.creationTime ?? null,
          lastSignInAt: userCredential.user.metadata.lastSignInTime ?? null
        })
        sendEmailVerification(userCredential.user).then(() => navigate(path))
      })
      .catch((error) => {
        if (error.code === 'auth/email-already-in-use') {
          alert('このメールアドレスはすでに使用されています')
        } else if (error.code === 'auth/invalid-email') {
          alert('メールアドレスの形式が正しくありません')
        } else if (error.code === 'auth/user-disabled') {
          alert('サービスの利用が停止されています')
        } else if (error.code === 'auth/weak-password') {
          alert('パスワードは6文字以上にしてください')
        } else if (error.code === 'auth/popup-blocked') {
          alert('認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください')
        } else if (
          error.code === 'auth/operation-not-supported-in-this-environment' ||
          error.code === 'auth/auth-domain-config-required' ||
          error.code === 'auth/operation-not-allowed' ||
          error.code === 'auth/unauthorized-domain'
        ) {
          alert('現在この認証方法はご利用頂けません')
        } else if (error.code === 'auth/requires-recent-login') {
          alert('認証の有効期限が切れています')
        } else {
          alert(error.code)
        }
      })
  }

  return (
    <div className={SignUpModule.container}>
      <div className={SignUpModule.input}>
        <p className={SignUpModule.inputtitle}>メールアドレス</p>
        <OutlinedInput
          className={SignUpModule.inputbox}
          type="email"
          sx={{ width: '70%', margin: '0 15%', height: '40px' }}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </div>
      <div className={SignUpModule.input}>
        <p className={SignUpModule.inputtitle}>パスワード</p>
        <OutlinedInput
          className={SignUpModule.inputbox}
          type="password"
          sx={{ width: '70%', margin: '0 15%', height: '40px' }}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </div>
      <div>
        <button className={SignUpModule[buttonClassName]} onClick={signUp}>
          アカウントを作成する
        </button>
      </div>
      <div>
        <p className={SignUpModule['description']}>
          アカウントをすでにお持ちの方は<a href={path}>こちら</a>からログインしてください
        </p>
      </div>
    </div>
  )
}
export default SignUp
