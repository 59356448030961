import AppModule from '../../App.module.scss'
import Footer from '../../components/Common/Footer'
import UISettingScreen from '../../components/UISettingScreen/UISettingScreen'
import Header from '../../components/Header/Header'
import React from 'react'

function UISetting() {
  return (
    <div className={AppModule.main}>
      <Header title="UI設定" />
      <UISettingScreen loginPath={'/login'} />
      <Footer homePath="/" addChatPath="/mode" userPath="/mypage" />
    </div>
  )
}
export default UISetting
