export const getClaudeStreamingMessageReaderAndDecoder = async ({
  model,
  messages,
  temperature,
  speakingRate,
  controller
}) => {
  //   const controller = new AbortController()
  //   const URL = 'http://localhost:8080/claude'
  const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/claude'
  console.log('URL', URL)
  try {
    let requestJson = {
      messages,
      model,
      temperature,
      max_tokens: 1024
    }

    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestJson),
      signal: controller.signal // AbortController の signal を渡す
    })
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }
    const reader = response.body.getReader()
    const decoder = new TextDecoder('utf-8')
    return [reader, decoder]
  } catch (error) {
    console.error('Error:', error)
  }
}
