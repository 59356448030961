import FooterModule from './Footer.module.scss'
import FaceIcon from '@mui/icons-material/Face'
import HomeIcon from '@mui/icons-material/Home'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import WindowIcon from '@mui/icons-material/Window'
function Footer({ homePath, addChatPath, userPath }) {
  const linearColors = 'linearColors'
  const iconClassName = 'linearColors'
  return (
    <div className={FooterModule.footer}>
      <ul className={FooterModule['bottom-menu']}>
        <li>
          <a className={FooterModule[iconClassName]} href={homePath}>
            <svg width={0} height={0}>
              <linearGradient id="linearColors" gradientTransform="rotate(90)">
                <stop offset={0} stopColor="#0080FE" />
                <stop offset={1} stopColor="#7eaef5" />
              </linearGradient>
            </svg>
            {window.location.pathname === homePath ? (
              <>
                <HomeIcon className={FooterModule[iconClassName]} sx={{ fill: 'url(#' + linearColors + ')' }} />
                <p style={{ color: '#7eaef5' }}>ホーム</p>
              </>
            ) : (
              <>
                <HomeIcon className={FooterModule[iconClassName]} />
                <p>ホーム</p>
              </>
            )}
          </a>
        </li>
        <li>
          <a className={FooterModule[iconClassName]} href={addChatPath} target="_blank">
            {window.location.pathname === addChatPath ? (
              <>
                <WindowIcon className={FooterModule[iconClassName]} sx={{ fill: 'url(#' + linearColors + ')' }} />
                <p style={{ color: '#7eaef5' }}>モード一覧</p>
              </>
            ) : (
              <>
                <WindowIcon className={FooterModule[iconClassName]} />
                <p>モード一覧</p>
              </>
            )}
          </a>
        </li>
        <li>
          <a className={FooterModule[iconClassName]} href={userPath}>
            {window.location.pathname === userPath ? (
              <>
                <FaceIcon className={FooterModule[iconClassName]} sx={{ fill: 'url(#' + linearColors + ')' }} />
                <p style={{ color: '#7eaef5' }}>マイページ</p>
              </>
            ) : (
              <>
                <FaceIcon className={FooterModule[iconClassName]} />
                <p>マイページ</p>
              </>
            )}
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Footer
