import React, { useEffect } from 'react'
import { auth } from '../../firebase'
import { sendEmailVerification } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import SignOutButton from '../../components/Button/SignOutButton'
import c from './email_authentication_request.module.scss'

function EmailAuthenticationRequest() {
  const navigate = useNavigate()

  const [user] = useAuthState(auth)
  const resendEmailVerificatinoLink = () => {
    sendEmailVerification(auth.currentUser).then(() => {
      alert(`${user.email}に認証用リンクを送信しました`)
    })
  }

  useEffect(() => {}, [user])

  const reload = () => {
    navigate('/')
  }

  return (
    user && (
      <div className={c.main}>
        <h4>スクールAIのご利用にはメールアドレスの認証が必要です。</h4>
        <div className={c.subDiscription}>
          {user.email}
          に送信された認証用リンクをクリックして認証を完了させてください。
          <br />
          認証用リンクをクリックした後は、下の更新ボタンをクリックすることでスクールAIをご利用いただけます。
          <br />
          画面が切り替わらない場合は、少し時間を置いてから更新ボタンをクリックしてください。
        </div>
        <div>
          <button className={c.button} onClick={resendEmailVerificatinoLink}>
            認証用リンクを再送する
          </button>
        </div>
        <div>
          <button className={c.button} onClick={() => reload()}>
            更新
          </button>
        </div>
        <SignOutButton loginPath={'/'} />
      </div>
    )
  )
}

export default EmailAuthenticationRequest
