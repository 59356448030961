import React from 'react'
import { useNavigate } from 'react-router-dom'
import StartButtonModule from './StartButton.module.scss'

function StartButton(props) {
  const { chatScreenHeight } = props
  const navigate = useNavigate()
  return (
    <div className={StartButtonModule.introduction} style={{ height: chatScreenHeight }}>
      <div>
        <p>まずは、スクールAIを使ってみましょう！</p>
        <button
          className={StartButtonModule.startButton}
          onClick={() => {
            window.open('/mode', '_blank')
          }}
        >
          はじめる
        </button>
      </div>
    </div>
  )
}
export default StartButton
