import React, { useEffect } from 'react'
import AppModule from './App.module.scss'
import Chat from './pages/chat/chat'
import EmailAuthenticationRequest from './pages/email_authentication_request/email_authentication_request'
import Input from './pages/input/input'
import Login from './pages/login/login'
import LoginForStudent from './pages/login_for_student/login_for_student'
import Signup from './pages/signup/signup'
import Management from './pages/management/management'
import SubmissionManagement from './pages/submission_management/submission_management'
import ModeSelect from './pages/mode_select/mode_select'
import Redirect from './pages/redirect/redirect'
import Clone from './pages/clone/clone'
import Mypage from './pages/mypage/mypage'
import Top from './pages/top/top'
import PrintOut from './pages/print_out/print_out'
import ChatData from './pages/chat_data/chat_data'
import UISetting from './pages/ui_setting/ui_setting'
import ThreadList from './pages/thread_list/read_only/thread_list'
import SubmittedConversations from './pages/submitted_conversations/read_only/submitted_conversations'
import LoginForMingaku from './pages/login_for_mingaku_tutor/login_for_mingaku_tutor'
import SignupForMingaku from './pages/signup_for_mingaku_tutor/signup_for_mingaku_tutor'
import Test from './pages/test/test'
import Test2 from './pages/test/test2'
import FromMingaku from './pages/from_mingaku/from_mingaku'
import AnalysisStudent from './pages/analysis_student/analysis_student'
import AdminManagement from './pages/admin_management/admin_management'
import { Routes, Route } from 'react-router-dom'
import { AuthProvider } from './context/AuthContext'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { auth, db } from './firebase'
import { doc, getDoc } from 'firebase/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import { _tutor_pages, _login_pages } from './localConstant'
import { ImageProvider } from './context/ImageUtils' // Ima
function App() {
  const navigate = useNavigate()
  const [user] = useAuthState(auth)
  useEffect(() => {
    // 生徒アカウントが先生の管理画面にアクセスした場合、top画面に強制遷移
    if (_tutor_pages.includes(window.location.pathname)) {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid)
        getDoc(userDocRef).then((docSnapshot) => {
          if (docSnapshot.exists() && docSnapshot.data().studentDocId) {
            navigate('/')
          }
        })
      }
    }
  }, [user])
  useEffect(() => {
    if (!_login_pages.includes(window.location.pathname)) {
      const path =
        window.location.pathname === '/mode' || window.location.pathname === '/mypage' ? '/' : window.location.pathname
      const queryParams =
        window.location.pathname === '/mode' || window.location.pathname === '/mypage' ? '' : window.location.search
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (!user && !(window.location.pathname === '/login' || window.location.pathname === '/login_for_student')) {
          navigate('/login', {
            state: {
              redirectPath: `${path}${queryParams}`
            }
          })
        }
      })
      // Clean up subscription on unmount
      return () => unsubscribe()
    }
  })
  const [searchParams] = useSearchParams()
  const showheader = searchParams.get('showheader')
  const styles = AppModule
  return (
    <div className={showheader === 'false' ? styles['App2'] : styles['App']} translate="no">
      <main>
        <ImageProvider>
          <AuthProvider>
            <Routes>
              <Route path="/chat" element={<Chat />} />
              <Route path="/email_authentication_request" element={<EmailAuthenticationRequest />} />
              <Route path="/input" element={<Input />} />
              <Route path="/login" element={<Login />} />
              <Route path="/login_for_student" element={<LoginForStudent />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/login_for_mingaku_tutor" element={<LoginForMingaku />} />
              <Route path="/signup_for_mingaku_tutor" element={<SignupForMingaku />} />
              <Route path="/submission_management" element={<SubmissionManagement />} />
              <Route path="/management" element={<Management />} />
              <Route path="/management/:schoolId" element={<Management />} />
              <Route path="/mode" element={<ModeSelect />} />
              <Route path="/mypage" element={<Mypage />} />
              <Route path="/" element={<Top />} />
              <Route path="/print_out" element={<PrintOut />} />
              <Route path="/redirect" element={<Redirect />} />
              <Route path="/clone" element={<Clone />} />
              <Route path="/chat_data" element={<ChatData />} />
              <Route path="/ui_setting" element={<UISetting />} />
              <Route path="/thread_list" element={<ThreadList />} />
              <Route path="/submitted_conversations" element={<SubmittedConversations />} />
              <Route path="/test" element={<Test />} />
              <Route path="/test2" element={<Test2 />} />
              <Route path="/from_mingaku" element={<FromMingaku />} />
              <Route path="/analysis_student" element={<AnalysisStudent />} />
              <Route path="/admin_management" element={<AdminManagement />} />
            </Routes>
          </AuthProvider>
        </ImageProvider>
      </main>
    </div>
  )
}

export default App
