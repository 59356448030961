import ManagementModule from './ManagementScreen.module.scss'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { getAssistantApi } from '../../utils/assistantApi'
import { useState, useEffect, useRef, useCallback } from 'react'
import LoadingGrid from '../LoadingGrid/LoadingGrid'
import { getMatchingLanguage } from '../../utils/getMachingLanguage'
import {
  collection,
  getDocs,
  doc,
  setDoc,
  query,
  getDoc,
  updateDoc,
  deleteDoc,
  addDoc,
  where,
  deleteField,
  collectionGroup
} from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { db, auth, storage, functions } from '../../firebase'
import * as c_data from '../../localConstant'
import { useParams, useSearchParams } from 'react-router-dom'
import LinkIcon from '@mui/icons-material/Link'
import CopyToClipboard from 'react-copy-to-clipboard'
import { httpsCallable } from 'firebase/functions'
import { signInWithCustomToken } from 'firebase/auth'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import Switch2 from '../Switch/Switch'
import { _fields_of_user_data_in_mypage } from '../../localConstant'
import ManagementScreenList from './ManagementScreenList'
function useEditedState(initialValue) {
  const [value, setValue] = useState(initialValue)
  const [isEdited, setIsEdited] = useState(false)
  // setValueをラップする関数
  const setEditedValue = useCallback((newValue) => {
    setValue(newValue)
    setIsEdited(true)
  }, [])
  return [value, setEditedValue, isEdited, setIsEdited]
}

function ManagementScreen({ serviceType }) {
  const {
    _responseOptions,
    _pulldownTemplates,
    _inserted_default_target_student_info,
    _language_list_v2,
    _auto_sending,
    _default_mute,
    _gpt_model,
    _question_type
  } = c_data
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 150
    }
  })

  const [constantData, setConstantData] = useState({
    _responseOptions,
    _pulldownTemplates,
    _inserted_default_target_student_info,
    _language_list_v2,
    _auto_sending,
    _default_mute,
    _gpt_model,
    _question_type
  })
  const selectedItemClassName = window.location.pathname.startsWith('/management')
    ? 'selected-item'
    : 'selected-item-interview'
  const defaultAutoSending = false
  const defaultDefaultMute = true
  const navigate = useNavigate()
  const [user] = useAuthState(auth)
  const [selectedPrompt, setSelectedPrompt, isEdited, setIsEdited] = useEditedState('')
  const [prompt, setPrompt] = useState([])
  const [inputResponseOptions, setInputResponseOptions] = useState(_responseOptions)
  const [inputSearchQuery, setInputSearchQuery] = useState('')
  const initialPromptState = {
    prompt_title: '',
    prompt_description: '',
    prompt_language2: JSON.stringify({
      physicalName: 'ja',
      languageCode: 'ja-JP',
      type: 'ja-JP-Neural2-B',
      japaneseName: '日本語(女性)',
      shortenName: '日本語(女性)'
    }),
    prompt_type: '',
    prompt_order: 0,
    prompt: '',
    time_window_limit: false,
    time_window: [],
    character_limit: false,
    maximum_character_count: 0,
    character_limit_per_month: false,
    maximum_character_count_per_month: 0,
    sending_count_limit: false,
    maximum_sending_count_limit: 0,
    is_past_message_limit_count_set: false,
    maximum_past_message_limit_count: 0,
    enable: false,
    responseOptions: _responseOptions,
    formQuestions: [],
    autoSending: false,
    defaultMute: true,
    temperature: 1.0,
    speakingRate: 1.1,
    model: 'azure-gpt-4o',
    largeIcon: false,
    displayInputContents: true,
    expandInputContents: false,
    useAssistantApi: false,
    availableAllSchools: false,
    viewableAllSchools: true,
    editable: true,
    assistantId: ''
  }

  const [addBody, setAddBody] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedLanguage2, setSelectedLanguage2] = useState('')
  const [image, setImage] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [image2, setImage2] = useState('')
  const [imageUrl2, setImageUrl2] = useState('')
  const languageList2 = _language_list_v2
  const textAreaRef = useRef(null)
  const managementModuleRef = useRef(null)
  const [promptTitle, setPromptTitle] = useState('')
  const [pulldownTemplate, setPulldownTemplate] = useState(JSON.stringify(_pulldownTemplates[0]))
  const [searchParams] = useSearchParams()
  const uid = searchParams.get('uid')
  const muid = searchParams.get('muid')
  const schoolIdfromQueryParam = searchParams.get('school_id')
  const roleFromQueryParam = searchParams.get('role')
  const { schoolId } = useParams()
  const paramSchoolId = searchParams.get('school_id') ? searchParams.get('school_id') : schoolId
  const origin = window.location.origin
  const linkToCopy = uid
    ? `${origin}/redirect?mode=${selectedPrompt.id}&uid=${uid}`
    : `${origin}/redirect?mode=${selectedPrompt.id}`
  const linkForReproduction = uid
    ? `${origin}/clone?mode=${selectedPrompt.id}&uid=${uid}`
    : `${origin}/clone?mode=${selectedPrompt.id}`
  const [isCopied, setIsCopied] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [modalIndex, setModalIndex] = useState()
  const [loading, setLoading] = useState(false)
  const [documentIds, setDocumentIds] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [viewableSelectedIds, setViewableSelectedIds] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [viewableSearchQuery, setViewableSearchQuery] = useState('')
  const [schoolIdName, setSchoolIdName] = useState({}) // schoolIdとschoolNameの対応表
  const [studentInfoItemsForInsertion, setStudentInfoItemsForInsertion] = useState([])
  const [searchText, setSearchText] = useState('')
  const [filteredModes, setFilteredModes] = useState([])
  const [selectedAssistantApiFiles, setSelectedAssistantApiFiles] = useState([])
  const [roleInMingaku, setRoleInMingaku] = useState('')
  const [loadingText, setLoadingText] = useState('')
  const path = window.location.pathname + window.location.search

  const getSystemSettingData = async () => {
    const systemItemsRef = collection(db, 'systemSettings')
    const systemItemsQuery = query(systemItemsRef)
    const systemItemsSnapshot = await getDocs(systemItemsQuery)
    let systemItemsData = await Promise.all(
      systemItemsSnapshot.docs.map(async (doc) => ({
        ...doc.data()
      }))
    )
    return systemItemsData
  }

  useEffect(() => {
    const fetchSystemSettings = async () => {
      try {
        const systemSettings = await getSystemSettingData()
        const processMatchingItems = async () => {
          const newData = { ...c_data } // c_dataのコピーを作成
          for (const setting of systemSettings) {
            const originalMatchingItem = newData[setting.item_id]
            let matchingItem = newData[setting.item_id]
            if (matchingItem) {
              const itemChildrenDataRef = collection(setting.ref, 'itemChildrenData')
              const itemChildrenDataSnapshot = await getDocs(itemChildrenDataRef)

              const systemChildData = itemChildrenDataSnapshot.docs.map((childDoc) => ({
                ...childDoc.data()
              }))

              if (systemChildData.length !== 0) {
                let filteredChildData = systemChildData.filter((child) => child.active !== false)
                // matchingItemのchildrenからactive:falseを除去
                matchingItem = matchingItem.filter((child) =>
                  filteredChildData.some((filteredChild) => filteredChild.value === child.value)
                )
                // selectedItem.modelの値をvalueプロパティに持つオブジェクトが存在しない場合、originalMatchingItemから追加
                if (!matchingItem.some((item) => item.value === selectedPrompt.model)) {
                  const itemToAdd = originalMatchingItem.find((item) => item.value === selectedPrompt.model)
                  if (itemToAdd) {
                    matchingItem.push(itemToAdd)
                  }
                }
                // newDataのdisplayNameを更新
                matchingItem.forEach((item) => {
                  const matchedChild = filteredChildData.find((child) => child.value === item.value)
                  if (matchedChild) {
                    item.displayName = matchedChild.displayName2 ? matchedChild.displayName2 : matchedChild.displayName
                  }
                })
                setConstantData((prevState) => ({
                  ...prevState,
                  [setting.item_id]: matchingItem
                }))
              } else {
                setConstantData((prevState) => ({
                  ...prevState
                }))
              }
              // systemChildDataのフィルタリング
            }
          }
        }
        await processMatchingItems()
      } catch (error) {
        console.error('Error fetching system settings:', error)
      }
    }
    fetchSystemSettings()
  }, [selectedPrompt])

  const getSchoolsInfo = async () => {
    const getSchools = httpsCallable(functions, 'get_schools_info')
    const res = await getSchools() // schoolAIのschook情報を全件取得用
    const { data } = res
    return data
  }
  const signInWithUid = async (uid) => {
    const signIn = httpsCallable(functions, 'sign_in_with_uid')
    try {
      const res = await signIn({ uid })
      const { customToken } = res.data
      const userCredential = await signInWithCustomToken(auth, customToken)
      updateDoc(doc(db, 'users', userCredential.user.uid), {
        userId: userCredential.user.uid,
        email: userCredential.user.email ?? '',
        createdAt: userCredential.user.metadata.createdAt ?? null,
        lastLoginAt: userCredential.user.metadata.lastLoginAt ?? null
      })
    } catch (e) {
      navigate('/login')
    } finally {
    }
  }

  /*****  useEffect *****/
  useEffect(() => {
    const filtered = prompt.filter((mode) => mode.prompt_title.toLowerCase().includes(searchText.toLowerCase()))
    setFilteredModes(filtered)
  }, [searchText, prompt])

  const fetchDocumentIds = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, '/externalServices/mingaku/schools'))
      const ids = querySnapshot.docs.map((doc) => doc.id)
      if (!!paramSchoolId) {
        const getSchoolInfoOfMingaku = httpsCallable(functions, 'get_school_info_of_mingaku')
        const schoolDataOfMingaku = await getSchoolInfoOfMingaku({ schoolId: paramSchoolId }) // schoolAIのschool情報を全件取得用
        const { data, configData } = schoolDataOfMingaku.data
        const { role = [], parentSchools = [], childrenSchools = [] } = configData
        if (!!role) {
          setRoleInMingaku(role[0])
        }

        if (!!role && role[0] === 'serviceProvider') {
          const childrenSchoolIds = (childrenSchools || []).map((childrenSchool) => childrenSchool.childSchoolDocId)
          const commonSchoolIds = ids.filter((id) => childrenSchoolIds.includes(id))
          setDocumentIds(commonSchoolIds)
        } else {
          setDocumentIds([])
        }
        return { role: role ? role[0] : '', parentSchools, childrenSchools }
      } else {
        setDocumentIds(ids)
        return { role: '', parentSchools: [], childrenSchools: [] }
      }
    } catch (error) {
      console.error('Error fetching document IDs:', error)
    }
  }

  useEffect(() => {
    // selectedPromptが更新されたときにselectedIdsを更新する
    const newSelectedIds = selectedPrompt.selectedSchools ? selectedPrompt.selectedSchools : []
    setSelectedIds(newSelectedIds)
  }, [selectedPrompt.selectedSchools])

  useEffect(() => {
    // selectedPromptが更新されたときにselectedIdsを更新する
    const newViewableSelectedIds = selectedPrompt.viewableSelectedSchools ? selectedPrompt.viewableSelectedSchools : []
    setViewableSelectedIds(newViewableSelectedIds)
  }, [selectedPrompt.viewableSelectedSchools])

  useEffect(() => {
    ;(async () => {
      const { schoolInfo } = await getSchoolsInfo() // schoolAIのschool情報を全件取得用
      const schoolsOfSchoolAI = schoolInfo.filter((school) => !!school.data) // dataがないschoolは除外
      let schoolIdName = {} // schoolIdとschoolNameの対応表
      schoolsOfSchoolAI.map((school) => {
        return (schoolIdName[school.schoolId] = school.data.name) //
      })
      setSchoolIdName(schoolIdName)
    })()
  }, [])

  useEffect(() => {
    if (!roleFromQueryParam || !muid) return
    auth.onAuthStateChanged(async (user) => {
      setLoading(true)
      const getAdminInfo = httpsCallable(functions, 'get_admin_info_of_mingaku')
      const { data } = await getAdminInfo({ userId: muid })
      const { adminAuthData, adminDocData } = data
      const email = data.adminAuthData.email
      const checkRegisteredEmail = httpsCallable(functions, 'check_regsitered_email')
      const res = await checkRegisteredEmail({ email })
      const emailRegisterd = res.data.exists
      const matchingUserDocRef = query(
        collection(db, 'users'),
        where('email', '==', email),
        where('type', '==', 'mingaku'),
        where('role', '==', 'admin')
      )
      const matchingDocSnapshot = await getDocs(matchingUserDocRef)
      const isAlreadyLinked = matchingDocSnapshot.docs.length !== 0
      const linkageData = {
        mingakuUserId: muid,
        type: 'mingaku',
        role: 'admin'
      }
      if (user) {
        if (!emailRegisterd) {
          setLoading(false)
          navigate('/signup_for_mingaku_tutor', {
            state: {
              redirectPath: `${path}`,
              email,
              adminAuthData,
              adminDocData,
              muid,
              role: roleFromQueryParam,
              linkageData
            }
          })
        } else {
          if (user.email === email) {
            const userDocRef = doc(db, 'users', user.uid)
            await setDoc(userDocRef, linkageData, { merge: true })
            setLoading(false)
          } else {
            setLoading(false)
            navigate('/login_for_mingaku_tutor', {
              state: {
                redirectPath: `${path}`,
                email,
                adminAuthData,
                adminDocData,
                muid,
                role: roleFromQueryParam,
                isAlreadyLinked,
                linkageData
              }
            })
          }
        }
      } else {
        if (!emailRegisterd) {
          setLoading(false)
          navigate('/signup_for_mingaku_tutor', {
            state: {
              redirectPath: `${path}`,
              email,
              adminAuthData,
              adminDocData,
              muid,
              role: roleFromQueryParam,
              linkageData
            }
          })
        } else {
          setLoading(false)
          navigate('/login_for_mingaku_tutor', {
            state: {
              redirectPath: `${path}`,
              email,
              adminAuthData,
              adminDocData,
              muid,
              role: roleFromQueryParam,
              isAlreadyLinked,
              linkageData
            }
          })
        }
      }
    })
  }, [muid, path, roleFromQueryParam])

  useEffect(() => {
    if (!schoolIdfromQueryParam || !muid) return
    auth.onAuthStateChanged(async (user) => {
      const getTutorInfo = httpsCallable(functions, 'get_tutor_info_of_mingaku')
      setLoading(true)
      const { data } = await getTutorInfo({ userId: muid, schoolId: schoolIdfromQueryParam })
      const { tutorAuthData, tutorDocData } = data
      const emailFromTutorAuthData = data.tutorAuthData.email
      const checkRegisteredEmail = httpsCallable(functions, 'check_regsitered_email')
      const res = await checkRegisteredEmail({ email: emailFromTutorAuthData })
      const emailRegisterd = res.data.exists
      const matchingUserDocRef = query(
        collection(db, 'users'),
        where('email', '==', emailFromTutorAuthData),
        where('schoolDocId', '==', schoolIdfromQueryParam),
        where('type', '==', 'mingaku'),
        where('role', '==', 'tutor')
      )
      const matchingDocSnapshot = await getDocs(matchingUserDocRef)
      const isAlreadyLinked = matchingDocSnapshot.docs.length !== 0
      const linkageData = {
        schoolDocId: schoolIdfromQueryParam,
        tutorDocId: muid,
        mingakuUserId: muid,
        type: 'mingaku',
        role: 'tutor'
      }
      if (user) {
        if (user.email !== emailFromTutorAuthData) {
          if (!emailRegisterd) {
            setLoading(false)
            navigate('/signup_for_mingaku_tutor', {
              state: {
                redirectPath: `${path}`,
                email: emailFromTutorAuthData,
                isAlreadyLinked,
                linkageData,
                tutorAuthData,
                tutorDocData,
                schoolIdfromQueryParam,
                muid,
                role: 'tutor'
              }
            })
          } else {
            setLoading(false)
            navigate('/login_for_mingaku_tutor', {
              state: {
                redirectPath: `${path}`,
                email: emailFromTutorAuthData,
                isAlreadyLinked,
                linkageData,
                tutorAuthData,
                tutorDocData,
                schoolIdfromQueryParam,
                muid,
                role: 'tutor'
              }
            })
          }
        } else {
          const userDocRef = doc(db, 'users', user.uid)
          await setDoc(userDocRef, linkageData, { merge: true })
          const schoolDocRef = doc(db, 'externalServices', 'mingaku', 'schools', schoolIdfromQueryParam)
          setDoc(schoolDocRef, { docId: schoolIdfromQueryParam }, { merge: true })
          const tutorDocRef = doc(schoolDocRef, 'tutors', muid)
          await setDoc(tutorDocRef, { ...data.tutorDocData }, { merge: true })
          setLoading(false)
          navigate(`/management?school_id=${schoolIdfromQueryParam}`)
        }
      } else {
        setLoading(false)
        if (!!uid) {
          ;(async () => {
            await signInWithUid(uid)
          })()
        } else {
          if (!emailRegisterd) {
            setLoading(false)
            navigate('/signup_for_mingaku_tutor', {
              state: {
                redirectPath: `${path}`,
                email: emailFromTutorAuthData,
                isAlreadyLinked,
                linkageData,
                tutorAuthData,
                tutorDocData,
                schoolIdfromQueryParam,
                muid,
                role: 'tutor'
              }
            })
          } else {
            setLoading(false)
            navigate('/login_for_mingaku_tutor', {
              state: {
                redirectPath: `${path}`,
                email: emailFromTutorAuthData,
                isAlreadyLinked,
                linkageData,
                tutorAuthData,
                tutorDocData,
                schoolIdfromQueryParam,
                muid,
                role: 'tutor'
              }
            })
          }
        }
      }
    })
  }, [muid, navigate, path, schoolIdfromQueryParam, signInWithUid, uid])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        const fetchDataPromises = []

        // Promptsすべて
        let promptDocRef
        let additionalPrompts = []

        const { parentSchools } = await fetchDocumentIds()
        if (paramSchoolId) {
          const getSchools = httpsCallable(functions, 'get_schools_info')
          const res = await getSchools({ schoolIds: [paramSchoolId] }) // schoolAIのschook情報を全件取得用
          const { data } = res
          const customPropertiesForInsertion = data.schoolInfo[0].customProperties.map((property) => ({
            id: property.id,
            title: property.title,
            key: property.title
          }))
          setStudentInfoItemsForInsertion([...customPropertiesForInsertion, ..._inserted_default_target_student_info])
          const mingakuDocRef = doc(db, 'externalServices', 'mingaku')
          const schoolsCollectionRef = collection(mingakuDocRef, 'schools')
          promptDocRef = doc(schoolsCollectionRef, paramSchoolId)
          // ①　全データの中で、selectedSchools登録されており、その中でdoc.id===schoolIdがあるモードを取得
          const selectedSchoolsQuery = query(collectionGroup(db, 'selectedSchools'), where('id', '==', paramSchoolId))
          fetchDataPromises.push(
            getDocs(selectedSchoolsQuery).then((snapshot) => {
              const selectedPrompts = snapshot.docs.map(async (doc) => {
                const promptRef = doc.ref.parent.parent
                const parentSchoolId = promptRef.parent.parent.id
                const promptSnapshot = await getDoc(promptRef)
                const promptData = promptSnapshot.data() // データ取得
                if (promptData) {
                  return {
                    ...promptData,
                    responseOptions:
                      promptData.responseOptions.length > 0 ? promptData.responseOptions : _responseOptions,
                    id: promptSnapshot.id,
                    // 編集不可
                    editable: false,
                    // selectedSchoolsにあるactiveフィールド
                    active: doc.data().active,
                    // 親スクールのID
                    parentSchoolId: parentSchoolId
                  }
                } else {
                  // console.log('promptプロパティが存在しません。')
                  return
                }
              })
              return Promise.all(selectedPrompts)
            })
          )

          // ②　adminのpromptの中でselectedSchoolsがないモード(全スクール公開)を取得
          const gptPromptDocRef = doc(db, 'service_type', 'gpt-teacher')
          const promptsCollectionRef = collection(gptPromptDocRef, 'prompts')
          const allPromptsQuery = query(promptsCollectionRef)
          fetchDataPromises.push(
            getDocs(allPromptsQuery).then((snapshot) => {
              const allPrompts = snapshot.docs.map(async (doc) => {
                const prompt = doc.data()
                if (prompt.availableAllSchools === true) {
                  return {
                    ...prompt,
                    id: doc.id,
                    editable: false,
                    active: true,
                    parentSchoolId: 'gpt-teacher'
                  }
                } else if (!prompt.hasOwnProperty('availableAllSchools')) {
                  const selectedSchoolsRef = collection(doc.ref, 'selectedSchools')
                  const selectedSchoolsSnapshot = await getDocs(selectedSchoolsRef)
                  // availableAllSchoolsフィールドがなく、selectedSchoolsコレクションもないprompt→以前の実装事情によりこれもavailableAllとする
                  if (selectedSchoolsSnapshot.docs.length === 0) {
                    return {
                      ...prompt,
                      id: doc.id,
                      editable: false,
                      active: true,
                      parentSchoolId: 'gpt-teacher'
                    }
                  }
                }
                return null
              })
              return Promise.all(allPrompts)
            })
          )

          // ③親スクールの中で全共有のモードを取得
          console.log('親スクールあるか', parentSchools)
          if (parentSchools && parentSchools.length > 0) {
            parentSchools.forEach((parentSchool) => {
              console.log('親スクール', parentSchool.schoolDocId)
              const parentPromptDocRef = doc(schoolsCollectionRef, parentSchool.schoolDocId)
              const _promptsCollectionRef = collection(parentPromptDocRef, 'prompts')
              const _allPromptsQuery = query(_promptsCollectionRef)
              fetchDataPromises.push(
                getDocs(_allPromptsQuery).then((snapshot) => {
                  const _allPrompts = snapshot.docs.map(async (doc) => {
                    const prompt = doc.data()
                    if (prompt.availableAllSchools === true) {
                      return {
                        ...prompt,
                        id: doc.id,
                        editable: false,
                        active: true,
                        parentSchoolId: parentSchool.schoolDocId
                      }
                    } else if (!prompt.hasOwnProperty('availableAllSchools')) {
                      const selectedSchoolsRef = collection(doc.ref, 'selectedSchools')
                      const selectedSchoolsSnapshot = await getDocs(selectedSchoolsRef)
                      // availableAllSchoolsフィールドがなく、selectedSchoolsコレクションもないprompt→以前の実装事情によりこれもavailableAllとする
                      if (selectedSchoolsSnapshot.docs.length === 0) {
                        return {
                          ...prompt,
                          id: doc.id,
                          editable: false,
                          active: true,
                          parentSchoolId: parentSchool.schoolDocId
                        }
                      }
                    }

                    return null
                  })
                  return Promise.all(_allPrompts)
                })
              )
            })
          }

          const [selectedSchoolsPrompts, allPrompts, ...parentPrompts] = await Promise.all(fetchDataPromises)

          additionalPrompts = selectedSchoolsPrompts.filter((prompt) => prompt !== null)
          additionalPrompts = additionalPrompts.concat(allPrompts.filter((prompt) => prompt !== null))
          parentPrompts.forEach((prompts) => {
            additionalPrompts = additionalPrompts.concat(prompts.filter((prompt) => prompt !== null))
          })

          additionalPrompts = additionalPrompts.filter((prompt) => prompt && prompt.enable === true)

          // viewableAllSchools===falseのものに関しては別途、閲覧制御するためデータを修正する
          const filteredPrompts = additionalPrompts.filter((prompt) => prompt && prompt.viewableAllSchools === false)

          const nonViewableAllPrompts = await Promise.all(
            filteredPrompts.map(async (prompt) => {
              const selectedSchoolsRef = collection(prompt.ref, 'viewableSelectedSchools')
              const selectedSchoolsSnapshot = await getDocs(selectedSchoolsRef)
              // 表示可能なSchoolIdだけある
              let hidden
              if (selectedSchoolsSnapshot.docs.length === 0) {
                hidden = true
              } else {
                hidden = selectedSchoolsSnapshot.docs.every((doc) => {
                  if (doc.id === schoolIdfromQueryParam) {
                    return false
                  } else {
                    return true
                  }
                })
              }

              if (hidden) {
                return { ...prompt, hidden: true }
              } else if (!hidden) {
                return prompt
              }
            })
          )
          // additionalPromptsをviewableAllSchools!==false(全スクール閲覧可能)のものだけにする
          additionalPrompts = additionalPrompts.filter((prompt) => prompt && prompt.viewableAllSchools !== false)
          // additionalPromptsをnonViewableAllPromptsと結合
          additionalPrompts = additionalPrompts.concat(nonViewableAllPrompts)
        } else {
          promptDocRef = doc(db, 'service_type', serviceType)
        }

        // あとは自分の塾のpromptsを取得
        const propmts_q = query(collection(promptDocRef, 'prompts'))
        const querySnapshot = await getDocs(propmts_q)
        console.log(querySnapshot)
        let prompts = querySnapshot.docs.map((doc) => {
          const matchingLanguage = getMatchingLanguage(doc.data().prompt_language2)

          return {
            prompt_title: doc.data().prompt_title ? doc.data().prompt_title : '',
            prompt_description: doc.data().prompt_description ? doc.data().prompt_description : '',
            prompt_language: doc.data().prompt_language ? doc.data().prompt_language : '',
            prompt_language2: matchingLanguage ? JSON.stringify(matchingLanguage) : '',
            prompt_type: doc.data().prompt_type ? doc.data().prompt_type : '',
            prompt_order: doc.data().prompt_order !== undefined ? doc.data().prompt_order : 0,
            time_window_limit: doc.data().time_window_limit !== undefined ? doc.data().time_window_limit : false,
            time_window: doc.data().time_window !== undefined ? doc.data().time_window : [],
            character_limit: doc.data().character_limit !== undefined ? doc.data().character_limit : false,
            maximum_character_count:
              doc.data().maximum_character_count !== undefined ? doc.data().maximum_character_count : 0,
            character_limit_per_month:
              doc.data().character_limit_per_month !== undefined ? doc.data().character_limit_per_month : false,
            maximum_character_count_per_month:
              doc.data().maximum_character_count_per_month !== undefined
                ? doc.data().maximum_character_count_per_month
                : 0,
            sending_count_limit: doc.data().sending_count_limit !== undefined ? doc.data().sending_count_limit : false,
            maximum_sending_count_limit:
              doc.data().maximum_sending_count_limit !== undefined ? doc.data().maximum_sending_count_limit : 0,
            is_past_message_limit_count_set:
              doc.data().is_past_message_limit_count_set !== undefined
                ? doc.data().is_past_message_limit_count_set
                : false,
            maximum_past_message_limit_count:
              doc.data().maximum_past_message_limit_count !== undefined
                ? doc.data().maximum_past_message_limit_count
                : 0,
            prompt: doc.data().prompt,
            enable: doc.data().enable,
            id: doc.id,
            ref: doc.ref,
            updatedAt: doc.data().updatedAt ? doc.data().updatedAt : '',
            responseOptions:
              doc.data().responseOptions && doc.data().responseOptions.length > 0
                ? doc.data().responseOptions
                : _responseOptions,
            iconImageUrl: doc.data().iconImageUrl ? doc.data().iconImageUrl : '',
            iconImagePath: doc.data().iconImagePath ? doc.data().iconImagePath : '',
            iconImageUrl2: doc.data().iconImageUrl2 ? doc.data().iconImageUrl2 : '',
            iconImagePath2: doc.data().iconImagePath2 ? doc.data().iconImagePath2 : '',
            autoSending: doc.data().autoSending === undefined ? defaultAutoSending : doc.data().autoSending,
            defaultMute: doc.data().defaultMute === undefined ? defaultDefaultMute : doc.data().defaultMute,
            temperature: doc.data().temperature !== undefined ? doc.data().temperature : 1,
            speakingRate: doc.data().speakingRate ? doc.data().speakingRate : '',
            model: doc.data().model === 'gpt-4' ? 'gpt-4-1106-preview' : doc.data().model,
            largeIcon: doc.data().largeIcon ?? false,
            displayInputContents: doc.data().displayInputContents ?? true,
            expandInputContents: doc.data().expandInputContents ?? false,
            formQuestions: doc.data().formQuestions ? doc.data().formQuestions : [],
            useAssistantApi: doc.data().useAssistantApi ?? false,
            assistantId: doc.data().assistantId ? doc.data().assistantId : '',
            availableAllSchools: doc.data().availableAllSchools ?? false,
            viewableAllSchools: doc.data().viewableAllSchools ?? true,
            // 編集可能
            editable: true
          }
        })
        console.log('途中additionalPrompts', additionalPrompts)
        console.log('途中prompt1', prompts)

        prompts = prompts.concat(additionalPrompts)
        console.log('途中prompt2', prompts)
        if (prompts.length !== 0) {
          const results = prompts.sort(function (a, b) {
            return a.updatedAt > b.updatedAt ? -1 : 1
          })

          let additionalData = {}
          let assistantApiFiles
          if (results[0].assistantId) {
            // assistantIdがある場合、assistantApiの情報を取得してselectedPromptに追加
            const assistantId = results[0].assistantId
            const myAssistantAndFiles = await retrieveAssistant(assistantId)
            console.log('myAssistantAndFiles', myAssistantAndFiles)
            const instructions = myAssistantAndFiles.assistant.instructions
            const files = myAssistantAndFiles.files.map((file) => ({ id: file.id, name: file.filename }))
            // const myAssistant = await getAssistantApi(assistantId)
            // additionalData.assistantApiInstruction = myAssistant.instructions
            // assistantApiFiles = await getAssistantApiFiles(assistantId)
            additionalData.assistantApiInstruction = instructions ?? ''
            assistantApiFiles = files
          } else {
            setSelectedPrompt(results[0])
          }
          const selectedSchoolsRef = collection(results[0].ref, 'selectedSchools')
          const selectedSchoolsSnapshot = await getDocs(selectedSchoolsRef)
          additionalData.selectedSchools = selectedSchoolsSnapshot.docs.map((doc) => doc.id)
          const viewableSelectedSchoolsRef = collection(results[0].ref, 'viewableSelectedSchools')
          const viewableSelectedSchoolsSnapshot = await getDocs(viewableSelectedSchoolsRef)
          additionalData.viewableSelectedSchools = viewableSelectedSchoolsSnapshot.docs.map((doc) => doc.id)
          setSelectedPrompt({
            ...results[0],
            ...additionalData
          })
          setSelectedAssistantApiFiles(assistantApiFiles)
          setUpdatedAssistantFiles(assistantApiFiles)
          setIsEdited(false)
          setSelectedItem(results[0].id)
          setPromptTitle(results[0].prompt_title)
          setSelectedLanguage2(results[0].prompt_language2)

          const uniqueResults = results.filter(
            (result, index, self) => index === self.findIndex((r) => r.ref.path === result.ref.path)
          )
          console.log('setPrompt', uniqueResults)
          setPrompt(uniqueResults)
        } else {
          setSelectedPrompt(initialPromptState)
          setAddBody(true)
          setPromptTitle('新規')
          setIsEdited(false)
        }
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error)
        setLoading(false)
      }
    })()
  }, [defaultAutoSending, defaultDefaultMute, paramSchoolId, schoolIdfromQueryParam, serviceType])

  useEffect(() => {
    setSelectedLanguage2(selectedPrompt.prompt_language2)
  }, [selectedPrompt])

  /******* 更新用ハンドラ ********/

  const handleInputPromptChange = (updates) => {
    setSelectedPrompt((prevData) => ({
      ...prevData,
      ...updates
    }))
  }
  // 使用例
  // handleInputChange({ prompt_title: 'New Title', prompt_description: 'New Description' })

  const handleCheckboxChange = (event) => {
    const id = event.target.value
    const isChecked = event.target.checked

    setSelectedIds((prevSelectedIds) => {
      // 新しい選択状態のIDリストを作成
      const newSelectedIds = isChecked
        ? [...prevSelectedIds, id]
        : prevSelectedIds.filter((selectedId) => selectedId !== id)

      // selectedPromptのselectedSchoolsを更新
      handleInputPromptChange({ selectedSchools: newSelectedIds })
      return newSelectedIds
    })

    if (!isChecked) {
      setViewableSelectedIds((prevViewableSelectedIds) => {
        // inputViewableSelectedIdsから指定されたidを削除
        const newViewableSelectedIds = prevViewableSelectedIds.filter((selectedId) => selectedId !== id)
        return newViewableSelectedIds
      })
    }
  }

  // 全選択ボタンのハンドラ
  const handleSelectAll = () => {
    setSelectedIds(filteredIds)
    handleInputPromptChange({ selectedSchools: filteredIds })
  }
  // 全削除ボタンのハンドラ
  const handleDeselectAll = () => {
    setSelectedIds([])
    handleInputPromptChange({ selectedSchools: [] })
  }
  let filteredIds = documentIds.filter((id) => {
    if (schoolIdName[id]) {
      return schoolIdName[id].includes(searchQuery)
    } else {
      return false
    }
  })

  const closeModalAndChangeFormQuestions = (template, index) => {
    handleInputPromptChange({
      formQuestions: selectedPrompt.formQuestions.map((formQuestion, i) => {
        if (i === index) {
          return {
            ...formQuestion,
            options: JSON.parse(template).selectOptions
          }
        } else {
          return formQuestion
        }
      })
    })
    setModalOpen(false)
    setModalIndex()
  }
  // モーダルを開く関数
  const openModal = (index) => {
    setModalOpen(true)
    setModalIndex(index)
  }

  // モーダルを閉じる関数
  const closeModal = () => {
    setModalOpen(false)
    setModalIndex()
  }

  // プロンプトに質問IDを入れる
  const handleInsertClick = (questionKey, additionalText) => {
    // <a>要素がクリックされたときに呼ばれるハンドラ
    const textArea = textAreaRef.current
    const startPos = textArea.selectionStart // カーソルの開始位置を取得
    const endPos = textArea.selectionEnd // カーソルの終了位置を取得
    const currentText = textArea.value
    let newText

    // カーソルの位置に<a>のquestion.keyを挿入
    if (additionalText) {
      newText =
        currentText.substring(0, startPos) + '{' + questionKey + '}' + additionalText + currentText.substring(endPos)
    } else {
      newText = currentText.substring(0, startPos) + '{' + questionKey + '}' + currentText.substring(endPos)
    }

    // テキストエリアと状態を更新
    textArea.value = newText
    textArea.selectionStart = startPos + questionKey.length + 2
    textArea.selectionEnd = startPos + questionKey.length + 2
    handleInputPromptChange({ prompt: newText })
  }

  const updatePromptModel = (value) => {
    const modelInfo = constantData._gpt_model.find((item) => item.value === value)
    if (modelInfo && modelInfo.availableAssistApi === false) {
      handleInputPromptChange({ model: value, useAssistantApi: false })
      setSelectedAssistantApiFiles([])
    } else {
      handleInputPromptChange({ model: value })
    }
  }

  const updatePromptSpeakingRate = (value) => {
    const floatValue = parseFloat(value)

    if (!isNaN(floatValue) && floatValue >= 0.25 && floatValue <= 4.0) {
      handleInputPromptChange({ speakingRate: value })
    } else {
      alert('入力値は0.25から4.0の範囲内である必要があります。')
    }
  }
  const updatePromptTemperature = (value) => {
    const floatValue = parseFloat(value)

    if (!isNaN(floatValue) && floatValue >= 0 && floatValue <= 2.0) {
      handleInputPromptChange({ temperature: floatValue })
    } else {
      alert('入力値は0から2.0の範囲内である必要があります。')
    }
  }

  const updatePromptOrder = (value) => {
    const isValidInteger = /^\d*$/.test(value)
    if (!isValidInteger) {
      alert('半角数字で入力してください')
    } else {
      handleInputPromptChange({ prompt_order: Number(value) })
    }
  }

  const updatePromptFormQuestionCharacterLimit = (value, index) => {
    handleInputPromptChange({
      formQuestions: selectedPrompt.formQuestions.map((formQuestion, i) => {
        if (i === index) {
          return {
            ...formQuestion,
            answerCharacterLimit: !value,
            answerMaxLength: 0
          }
        } else {
          return formQuestion
        }
      })
    })
  }
  const updatePromptFormQuestionMandatory = (value, index) => {
    handleInputPromptChange({
      formQuestions: selectedPrompt.formQuestions.map((formQuestion, i) => {
        if (i === index) {
          return {
            ...formQuestion,
            mandatory: !value
          }
        } else {
          return formQuestion
        }
      })
    })
  }
  const updatePromptFormQuestionChangeable = (value, index) => {
    handleInputPromptChange({
      formQuestions: selectedPrompt.formQuestions.map((formQuestion, i) => {
        if (i === index) {
          return {
            ...formQuestion,
            changeable: !value
          }
        } else {
          return formQuestion
        }
      })
    })
  }

  const updateActive = async (e, id, item) => {
    e.stopPropagation()

    if (id === selectedPrompt.id) {
      handleInputPromptChange({
        enable: !selectedPrompt.enable
      })
    }
    // if editable==falseならitem.idのselectedSchoolsコレクションのschoolParamドキュメントのactiveフィールドを更新
    setPrompt(
      prompt.map((i) => {
        if (i.id === id) {
          return {
            ...i,
            enable: !i.enable
          }
        } else {
          return {
            ...i
          }
        }
      })
    )

    prompt.forEach(async (i) => {
      if (i.id === id) {
        // 管理者編集　selectedSchoolsがundefinedの場合、フィールドを削除または別の値に設定
        const updatedItem = {
          ...i,
          enable: !i.enable,
          updatedAt: new Date().getTime()
          // 管理者編集　selectedSchoolsがundefinedでなければその値を使用し、そうでなければnullまたは空の配列を設定
          // selectedSchools: i.selectedSchools ? [...i.selectedSchools] : null // または []
        }
        const { selectedSchools, viewableSelectedSchools, ...newUpdatedItem } = updatedItem
        await updateDoc(i.ref, newUpdatedItem)
      }
    })
    setIsEdited(false)
  }

  const updateActiveForTeacher = async (e, item) => {
    // 関数内でのイベント伝播を防ぐ
    e.stopPropagation()

    // selectedSchoolsコレクションの参照を取得（適切なFirebaseの参照を設定してください）
    const collectionRef = collection(item.ref, 'selectedSchools')
    const selectedSchoolsRef = doc(collectionRef, paramSchoolId)
    const docSnap = await getDoc(selectedSchoolsRef)
    // selectedSchoolsドキュメントを更新
    if (docSnap.exists()) {
      await updateDoc(selectedSchoolsRef, {
        active: !item.active,
        updatedAt: new Date().getTime()
      })
    } else {
      await setDoc(selectedSchoolsRef, {
        active: !item.active,
        updatedAt: new Date().getTime(),
        id: paramSchoolId
      })
    }

    // ローカルのstateも更新
    setPrompt(
      prompt.map((i) => {
        if (i.id === item.id && item.editable === false) {
          return {
            ...i,
            active: !i.active
          }
        } else {
          return i
        }
      })
    )
  }

  const addToDeleteArray = async (i) => {
    handleInputPromptChange({
      responseOptions: selectedPrompt.responseOptions.filter((_, index) => index !== i)
    })
  }

  const handleImage = (event) => {
    const image = event.target.files[0]
    setImageUrl(window.URL.createObjectURL(image))
    setImage(image)
  }
  const handleImage2 = (event) => {
    const image = event.target.files[0]
    setImageUrl2(window.URL.createObjectURL(image))
    setImage2(image)
  }
  const [updatedAssistantFiles, setUpdatedAssistantFiles] = useState([])
  const [assistantFileChanged, setAssistantFileChanged] = useState(false)
  const handleDeleteFile = (file, index) => {
    setAssistantFileChanged(true)
    // 選択されたインデックスのファイルを除外して新しい配列を作成
    if (updatedAssistantFiles !== undefined) {
      const newAssistantApiFiles = updatedAssistantFiles.filter((_, i) => i !== index)
      setUpdatedAssistantFiles(newAssistantApiFiles) // 状態を更新
    }
  }

  const handleAssistantApiFileUpload = (event) => {
    const assistantApiFiles = event.target.files
    // ファイルが20個を超える場合にconfirmダイアログを表示
    if (assistantApiFiles.length > 20) {
      const isConfirmed = window.confirm(
        '選択されたファイルが20個を超えています。最初の20個のファイルのみを処理しますか？'
      )
      if (!isConfirmed) {
        // ユーザーがキャンセルを選択した場合、ここで処理を停止
        return
      }
    }
    setAssistantFileChanged(true)
    const filesToProcess = Array.from(assistantApiFiles).slice(0, 20)
    const assistantApiFilesInfo = filesToProcess.map((file) => ({
      name: file.name,
      // object_url: window.URL.createObjectURL(file),
      id: '',
      file: file
    }))
    // UI上のFile配列
    if (updatedAssistantFiles === undefined) {
      setUpdatedAssistantFiles(assistantApiFilesInfo)
    } else {
      setUpdatedAssistantFiles((prevFiles) => [...prevFiles, ...assistantApiFilesInfo])
    }
  }
  const deleteImage = async () => {
    setImage('')
    setImageUrl('')
    handleInputPromptChange({
      iconImageUrl: ''
    })
  }

  const deleteImage2 = async () => {
    setImage2('')
    setImageUrl2('')
    handleInputPromptChange({
      iconImageUrl2: ''
    })
  }

  const addToAddArray = () => {
    const addEmpyOption = { order: selectedPrompt.responseOptions.length + 1, option: '', content: '' }
    handleInputPromptChange({
      responseOptions: [...selectedPrompt.responseOptions, addEmpyOption]
    })
  }

  const addFormQuestionFields = () => {
    const addEmptyQuestion = {
      order: selectedPrompt.formQuestions.length + 1,
      title: '',
      type: 'text',
      key: `input${selectedPrompt.formQuestions.length + 1}`,
      options: []
    }
    handleInputPromptChange({
      formQuestions: [...selectedPrompt.formQuestions, addEmptyQuestion]
    })
  }

  const addToDeleteQuestionArray = async (i) => {
    handleInputPromptChange({
      formQuestions: selectedPrompt.formQuestions.filter((_, index) => index !== i)
    })
  }

  const handleQuestionTypeChange = (value, index) => {
    if (value === 'pulldown') {
      handleInputPromptChange({
        formQuestions: selectedPrompt.formQuestions.map((formQuestion, i) => {
          if (i === index) {
            return {
              ...formQuestion,
              type: value,
              options: [{ order: 1, displayName: '', value: '' }]
            }
          } else {
            return formQuestion
          }
        })
      })
    } else if (value === 'text' || value === 'image') {
      // テキストに変更された場合は、選択用のoptionsは不要なのでを初期値に戻す
      handleInputPromptChange({
        formQuestions: selectedPrompt.formQuestions.map((formQuestion, i) => {
          if (i === index) {
            return {
              ...formQuestion,
              type: value,
              options: [],
              mandatory: value === 'image' ? true : formQuestion.mandatory
            }
          } else {
            return formQuestion
          }
        })
      })
    }

    setPulldownTemplate(JSON.stringify(_pulldownTemplates[0]))
  }
  const handleQuestionKeyChange = (value, index) => {
    if (/[^a-zA-Z0-9_]/.test(value)) {
      alert('半角英数字以外の文字は入力できません。')
      return // 非半角英数字が入力された場合、処理を中断
    }
    handleInputPromptChange({
      formQuestions: selectedPrompt.formQuestions.map((formQuestion, i) => {
        if (i === index) {
          return {
            ...formQuestion,
            key: value
          }
        } else {
          return formQuestion
        }
      })
    })
  }

  const handleQuestionOrderChange = (value, index) => {
    if (isNaN(value)) {
      alert('数字を入力してください')
      return
    }
    handleInputPromptChange({
      formQuestions: selectedPrompt.formQuestions.map((formQuestion, i) => {
        if (i === index) {
          return {
            ...formQuestion,
            order: value && Number(value)
          }
        } else {
          return formQuestion
        }
      })
    })
  }

  const handleQuestionTitleChange = (value, index) => {
    handleInputPromptChange({
      formQuestions: selectedPrompt.formQuestions.map((formQuestion, i) => {
        if (i === index) {
          return {
            ...formQuestion,
            title: value
          }
        } else {
          return formQuestion
        }
      })
    })
  }
  const handleQuestionDescriptionChange = (value, index) => {
    handleInputPromptChange({
      formQuestions: selectedPrompt.formQuestions.map((formQuestion, i) => {
        if (i === index) {
          return {
            ...formQuestion,
            description: value
          }
        } else {
          return formQuestion
        }
      })
    })
  }

  const addToDeleteFormQuestionOptionsArray = async (index, o_index) => {
    handleInputPromptChange({
      formQuestions: selectedPrompt.formQuestions.map((formQuestion, i) => {
        if (i === index) {
          if (formQuestion.options.length === 1) {
            alert('最低１つの選択肢が必要です')
            return formQuestion // 選択肢が1つしかない場合、変更を行わずに元のフォーム質問を返す
          } else {
            return {
              ...formQuestion,
              options: formQuestion.options.filter((_, index) => index !== o_index)
            }
          }
        } else {
          return formQuestion
        }
      })
    })
  }

  const addFormQuestionOptionsFields = (index) => {
    handleInputPromptChange({
      formQuestions: selectedPrompt.formQuestions.map((formQuestion, i) => {
        if (i === index) {
          return {
            ...formQuestion,
            options: [
              ...formQuestion.options,
              {
                order: formQuestion.options.length + 1,
                displayName: '',
                value: ''
              }
            ]
          }
        } else {
          return formQuestion
        }
      })
    })
  }

  const handleFormQuestionsOptionsOrderChange = (value, index, o_index) => {
    if (value && isNaN(value)) {
      alert('数字を入力してください')
      return
    }

    handleInputPromptChange({
      formQuestions: selectedPrompt.formQuestions.map((formQuestion, i) => {
        if (i === index) {
          const updatedOptions = formQuestion.options.map((option, o_i) => {
            if (o_i === o_index) {
              return {
                ...option,
                order: Number(value) // 新しい値に変更
              }
            }
            return option
          })

          return {
            ...formQuestion,
            options: updatedOptions
          }
        } else {
          return formQuestion
        }
      })
    })
  }

  const handleFormQuestionsOptionsDisplayNameChange = (value, index, o_index) => {
    handleInputPromptChange({
      formQuestions: selectedPrompt.formQuestions.map((formQuestion, i) => {
        if (i === index) {
          const updatedOptions = formQuestion.options.map((option, o_i) => {
            if (o_i === o_index) {
              return {
                ...option,
                displayName: value // 新しい値に変更
              }
            }
            return option
          })

          return {
            ...formQuestion,
            options: updatedOptions
          }
        } else {
          return formQuestion
        }
      })
    })
  }

  const handleFormQuestionsOptionsValueChange = (value, index, o_index) => {
    handleInputPromptChange({
      formQuestions: selectedPrompt.formQuestions.map((formQuestion, i) => {
        if (i === index) {
          const updatedOptions = formQuestion.options.map((option, o_i) => {
            if (o_i === o_index) {
              return {
                ...option,
                value: value // 新しい値に変更
              }
            }
            return option
          })
          return {
            ...formQuestion,
            options: updatedOptions
          }
        } else {
          return formQuestion
        }
      })
    })
  }
  const handleFormQuestionAnswerMaxLengthChange = (value, index) => {
    if (/[^a-zA-Z0-9_]/.test(value)) {
      alert('半角英数字以外の文字は入力できません。')
      return // 非半角英数字が入力された場合、処理を中断
    }
    handleInputPromptChange({
      formQuestions: selectedPrompt.formQuestions.map((formQuestion, i) => {
        if (i === index) {
          return {
            ...formQuestion,
            answerMaxLength: value
          }
        } else {
          return formQuestion
        }
      })
    })
  }
  const handleOptionOrderChange = (value, index) => {
    if (isNaN(value)) {
      alert('数字を入力してください')
      return
    }
    handleInputPromptChange({
      responseOptions: selectedPrompt.responseOptions.map((responseOption, i) => {
        if (i === index) {
          return { order: value && Number(value), option: responseOption.option, content: responseOption.content }
        } else {
          return responseOption
        }
      })
    })
  }

  const handleOptionChange = (value, index) => {
    handleInputPromptChange({
      responseOptions: selectedPrompt.responseOptions.map((responseOption, i) => {
        if (i === index) {
          return { order: Number(responseOption.order), option: value, content: responseOption.content }
        } else {
          return responseOption
        }
      })
    })
  }

  const handleOptionContentChange = (value, index) => {
    handleInputPromptChange({
      responseOptions: selectedPrompt.responseOptions.map((responseOption, i) => {
        if (i === index) {
          return { order: Number(responseOption.order), option: responseOption.option, content: value }
        } else {
          return responseOption
        }
      })
    })
  }

  const handleInputAvailableAllSchoolsChange = (value) => {
    handleInputPromptChange({
      availableAllSchools: !value,
      selectedSchools: []
    })
    const filteredIds = viewableSelectedIds.filter((id) => selectedIds.includes(id))
    setViewableSelectedIds(filteredIds)
  }

  const handleInputViewableAllSchoolsChange = (value) => {
    handleInputPromptChange({
      viewableAllSchools: !value,
      viewableSelectedSchools: []
    })
  }
  const handleViewableSearchChange = (event) => {
    setViewableSearchQuery(event.target.value)
  }
  const handleViewableSelectAll = () => {
    setViewableSelectedIds(_filteredIds)
    setSelectedPrompt({ ...selectedPrompt, viewableSelectedSchools: filteredIds })
  }

  // 全削除ボタンのハンドラ
  const handleViewableDeselectAll = () => {
    setViewableSelectedIds([])
    setSelectedPrompt({ ...selectedPrompt, viewableSelectedSchools: [] })
  }

  let filteredViewableIds
  if (selectedPrompt.availableAllSchools) {
    filteredViewableIds = documentIds.filter((id) => {
      if (schoolIdName[id]) {
        return schoolIdName[id].includes(viewableSearchQuery)
      } else {
        return false
      }
    })
  } else {
    filteredViewableIds = selectedIds.filter((id) => {
      if (schoolIdName[id]) {
        return schoolIdName[id].includes(viewableSearchQuery)
      } else {
        return false
      }
    })
  }

  const handleViewableCheckboxChange = (event) => {
    const id = event.target.value
    const isChecked = event.target.checked
    setViewableSelectedIds((prevSelectedIds) => {
      // 新しい選択状態のIDリストを作成
      const newSelectedIds = isChecked
        ? [...prevSelectedIds, id]
        : prevSelectedIds.filter((selectedId) => selectedId !== id)
      setSelectedPrompt({ ...selectedPrompt, viewableSelectedSchools: newSelectedIds })
      return newSelectedIds
    })
  }
  /******* 新規用ハンドラ ********/

  let _filteredIds = documentIds.filter((id) => {
    if (schoolIdName[id]) {
      return schoolIdName[id].includes(inputSearchQuery)
    } else {
      return false
    }
  })

  const handleCopy = () => {
    setIsCopied(true)
    // 2秒後にコピー成功のメッセージを非表示にする
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  const handleCopyModeLink = (item) => {
    let link
    if (item.editable) {
      link = `${linkToCopy}&common=false&showheader=false&showfooter=false&ref=${item.ref.path}`
    } else {
      link = `${linkToCopy}&common=true&showheader=false&showfooter=false&ref=${item.ref.path}`
    }
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert('モード開始リンクがクリップボードにコピーされました！')
      })
      .catch((err) => {
        console.error('リンクのコピーに失敗しました: ', err)
      })
  }
  const handleCopyModeShareLink = (item) => {
    let link
    if (item.editable) {
      link = `${linkForReproduction}&common=false&showheader=false&ref=${item.ref.path}&uidFrom=${auth.currentUser.uid}`
    } else {
      link = `${linkForReproduction}&common=true&showheader=false&ref=${item.ref.path}&uidFrom=${auth.currentUser.uid}`
    }
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert('モード共有リンクがクリップボードにコピーされました！')
      })
      .catch((err) => {
        console.error('リンクのコピーに失敗しました: ', err)
      })
  }

  const handleModeStart = (item) => {
    const url = `/redirect?mode=${item.ref.id}&common=${
      item.common ? item.common.toString() : 'false'
    }&showheader=true&time_window=false&ref=${item.ref.path}`
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  /* モード選択・新規作成時に画面が切り替わるときのハンドラ */
  const switchBody = async (item) => {
    console.log(item.model)
    if (
      isEdited === true &&
      !window.confirm(
        '変更がまだ保存されていないようですが、よろしいですか？\n保存しないと、変更内容は消えてしまいます。'
      )
    ) {
      return
    }
    if (managementModuleRef.current) {
      managementModuleRef.current.scrollTop = 0
    }
    let additionalData = {}
    const selectedSchoolsRef = collection(item.ref, 'selectedSchools')
    const selectedSchoolsSnapshot = await getDocs(selectedSchoolsRef)
    additionalData.selectedSchools = selectedSchoolsSnapshot.docs.map((doc) => doc.id)
    const viewableSelectedSchoolsRef = collection(item.ref, 'viewableSelectedSchools')
    const viewableSelectedSchoolsSnapshot = await getDocs(viewableSelectedSchoolsRef)
    additionalData.viewableSelectedSchools = viewableSelectedSchoolsSnapshot.docs.map((doc) => doc.id)

    // AssisntantAPIの情報の取得に時間がかかるため、AssisntantAPIの情報の取得する前にselectedPromptをsetしておく。これによりモード切り替え時のラグを軽減
    let newItem = {
      ...item,
      ...additionalData
    }
    setSelectedItem(item.id)
    setSelectedPrompt({
      ...item,
      ...additionalData
    })

    // ここでAssistantAPIの情報を取得して、selectedPromptに追加する
    let assistantApiFiles
    if (item.assistantId && item.assistantId !== '') {
      const myAssistantAndFiles = await retrieveAssistant(item.assistantId)
      console.log('myAssistantAndFiles', myAssistantAndFiles)
      console.log('myAssistantAndFiles', myAssistantAndFiles)
      const instructions = myAssistantAndFiles.assistant.instructions
      const files = myAssistantAndFiles.files.map((file) => ({ id: file.id, name: file.filename }))
      // const myAssistant = await getAssistantApi(item.assistantId) // この関数は定義が必要
      // additionalData.assistantApiInstruction = myAssistant && myAssistant.instructions
      // assistantApiFiles = await getAssistantApiFiles(item.assistantId)
      additionalData.assistantApiInstruction = instructions ?? ''
      assistantApiFiles = files
    }
    newItem = {
      ...newItem,
      ...additionalData
    }
    setSelectedItem(item.id)
    setSelectedPrompt(newItem)
    setPromptTitle(item.prompt_title)
    setAddBody(false)
    setPulldownTemplate(JSON.stringify(_pulldownTemplates[0]))
    setInputSearchQuery('')
    setSelectedAssistantApiFiles(assistantApiFiles)
    setUpdatedAssistantFiles(assistantApiFiles)
    setIsEdited(false)
  }
  const displayAddBody = () => {
    if (
      isEdited === true &&
      !window.confirm(
        '変更がまだ保存されていないようですが、よろしいですか？\n保存しないと、変更内容は消えてしまいます。'
      )
    ) {
      return
    }
    if (managementModuleRef.current) {
      managementModuleRef.current.scrollTop = 0
    }
    setSelectedAssistantApiFiles()
    setUpdatedAssistantFiles()
    setAddBody(true)
    setSelectedItem()
    setSelectedPrompt(initialPromptState)
    setPromptTitle('新規')
    setIsEdited(false)
  }
  const getAssistantApiFiles = async (assistant_id) => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_get_assistant_files'
    const res = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        assistant_id: assistant_id
      })
    })
    const data = await res.json()
    const assistantFiles = data.assistantFiles.data
    const file_data = await Promise.all(
      assistantFiles.map(async (file) => ({ id: file.id, name: (await getFileInfo(file.id)).filename }))
    )
    return file_data
  }
  const getFileInfo = async (fileId) => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/get_file_info'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ file_id: fileId })
    })
    const data = await res.json()
    return data.file
  }
  const validatePromptData = (promptData) => {
    const selectedModel = _gpt_model.find((model) => model.value === promptData.model)
    const checks = [
      { condition: promptData.prompt_title === '', message: 'タイトルは必須入力です。\n' },
      { condition: promptData.prompt === '', message: 'プロンプトは必須入力です。\n' },
      {
        condition: promptData.responseOptions.some((response) => response.option === ''),
        message: '返答選択肢の選択肢名は必須入力です。\n'
      },
      {
        condition: promptData.formQuestions.some((question) => question.title === ''),
        message: 'フォームの各質問タイトルは必須入力です。\n'
      },
      {
        condition: promptData.formQuestions.some((question) => question.key === ''),
        message: 'フォームの質問の差し込みコードは必須入力です。\n'
      },
      {
        condition:
          new Set(promptData.formQuestions.map((optionObj) => optionObj.key)).size !== promptData.formQuestions.length,
        message: 'フォームの質問の差し込みコードの値が被っています。一意になるように設定してください。\n'
      },
      {
        condition:
          new Set(promptData.formQuestions.map((question) => question.order)).size !== promptData.formQuestions.length,
        message: 'フォームの質問の順番は被らないように設定してください。\n'
      },
      {
        condition: promptData.formQuestions.some(
          (question) =>
            question.type === 'pulldown' && question.options.some((option) => option.displayName.trim() === '')
        ),
        message: 'フォームのプルダウンの選択肢名は必須入力です。\n'
      },
      {
        condition: promptData.formQuestions.some(
          (question) => question.type === 'text' && question.answerCharacterLimit && question.answerMaxLength <= 0
        ),
        message: 'フォームの質問の最大文字数を設定する場合は1以上にしてください。\n'
      },
      {
        condition: promptData.character_limit && promptData.maximum_character_count <= promptData.prompt.length,
        message: `文字数制限の最大文字数はプロンプトの文字数(${promptData.prompt.length}文字)より多くする必要があります。\n`
      },
      {
        condition:
          promptData.character_limit_per_month &&
          promptData.maximum_character_count_per_month <= promptData.prompt.length,
        message: `・1ヶ月あたりの文字数制限の最大文字数はプロンプトの文字数(${promptData.prompt.length}文字)より多くする必要があります。\n`
      },
      {
        condition: promptData.sending_count_limit && promptData.maximum_sending_count_limit <= 0,
        message: '・送信回数制限は1以上を設定してください。\n'
      },
      {
        condition: promptData.is_past_message_limit_count_set && promptData.maximum_past_message_limit_count <= 0,
        message: '・送信時に含める過去のメッセージ数制限は1以上を設定してください。\n'
      },
      {
        condition: promptData.useAssistantApi && promptData.assistantApiInstruction === '',
        message: '・アシスタントAPIの役割を設定してください。\n'
      },
      {
        condition: promptData.useAssistantApi && (!updatedAssistantFiles || updatedAssistantFiles.length === 0),
        message: '・アシスタントAPIのファイルは1つ以上設定してください。\n'
      },
      {
        condition:
          promptData.time_window_limit === true &&
          promptData.time_window.length > 0 &&
          promptData.time_window.some((window) => window.start >= window.end),
        message: '・時間ウィンドウの開始時間は終了時間より早くしてください。\n'
      },
      {
        condition:
          selectedModel &&
          !selectedModel.availableVision &&
          promptData.formQuestions.some((question) => question.type === 'image'),
        message: `・${selectedModel.displayName}モデルは画像対応しておりません。モデルを変更するか、画像タイプの質問を削除してください。\n`
      },
      {
        condition: promptData.useAssistantApi && promptData.formQuestions.some((question) => question.type === 'image'),
        message: `・アシスタントAPIを使用しているため画像対応できません。アシスタントAPIを無効にするか、画像タイプの質問を削除してください。\n`
      }
    ]

    return checks
      .filter((check) => check.condition)
      .map((check) => check.message)
      .join('')
  }

  const savePrompt = async (promptData) => {
    setLoadingText('モードを保存中...')
    console.log('promptData', promptData)
    const alertMessage = validatePromptData(promptData)

    if (alertMessage !== '') {
      alert(alertMessage)
      return
    }
    setLoading(true)

    let newDocRef
    if (addBody) {
      newDocRef = await addDoc(promptDocRefToUpdate, {
        ...promptData,
        createdAt: new Date().getTime(),
        updatedAt: new Date().getTime()
      })
      await updateDoc(newDocRef, { ref: newDocRef })
    } else {
      const { selectedSchools, viewableSelectedSchools, ...newItem } = promptData
      newItem.updatedAt = new Date().getTime()
      await updateDoc(promptData.ref, newItem)
      newDocRef = promptData.ref
    }

    // 利用可能スクールの登録・削除
    const selectedSchoolsRef = collection(newDocRef, 'selectedSchools')
    const currentSchoolsSnapshot = await getDocs(selectedSchoolsRef)
    const currentSchools = currentSchoolsSnapshot.docs.map((doc) => doc.id)

    const schoolsToDelete = currentSchools.filter((id) => !promptData.selectedSchools.includes(id))
    for (const id of schoolsToDelete) {
      const docRef = doc(selectedSchoolsRef, id)
      await deleteDoc(docRef)
    }
    if (promptData.selectedSchools !== undefined) {
      const filteredSelectedSchools = promptData.selectedSchools.filter((id) => schoolIdName[id])
      for (const id of filteredSelectedSchools) {
        if (!currentSchools.includes(id)) {
          const newDocRef = doc(selectedSchoolsRef, id)
          await setDoc(newDocRef, {
            active: true,
            id: id
          })
        }
      }
    }

    // 閲覧可能スクールの登録・削除
    const viewableSelectedSchoolsRef = collection(newDocRef, 'viewableSelectedSchools')
    const viewableCurrentSchoolsSnapshot = await getDocs(viewableSelectedSchoolsRef)
    const viewableCurrentSchools = viewableCurrentSchoolsSnapshot.docs.map((doc) => doc.id)

    const viewableSchoolsToDelete = viewableCurrentSchools.filter(
      (id) => !promptData.viewableSelectedSchools.includes(id)
    )
    for (const id of viewableSchoolsToDelete) {
      const docRef = doc(viewableSelectedSchoolsRef, id)
      await deleteDoc(docRef)
    }
    if (promptData.viewableSelectedSchools !== undefined) {
      const filteredViewableSelectedSchools = promptData.viewableSelectedSchools.filter((id) => schoolIdName[id])
      for (const id of filteredViewableSelectedSchools) {
        if (!viewableCurrentSchools.includes(id)) {
          const newDocRef = doc(viewableSelectedSchoolsRef, id)
          await setDoc(newDocRef, {
            active: true,
            id: id
          })
        }
      }
    }

    // アイコン更新
    const imageArray = [image ? image : '', image2 ? image2 : '']
    await Promise.all(
      imageArray.map(async (image, index) => {
        if (image) {
          const iconImagePath = `promptIcons/${newDocRef.path}/${index + 1}_${image.name}`
          const storageRef = ref(storage, iconImagePath)
          try {
            // 既存の画像があればstorageに新しい画像追加する前に削除
            if (index === 0 && promptData.iconImagePath) {
              try {
                await deleteObject(ref(storage, promptData.iconImagePath))
              } catch (error) {
                console.error(`削除しようとした画像が存在しません。`)
              }
            } else if (index === 1 && promptData.iconImagePath2) {
              try {
                await deleteObject(ref(storage, promptData.iconImagePath2))
              } catch (error) {
                console.error(`削除しようとした画像が存在しません。`)
              }
            }
            // 画像をアップロードして、その結果を取得
            const uploadSnapshot = await uploadBytes(storageRef, image)
            // アップロード情報をオブジェクトに追加
            let imageInfo
            if (index === 0) {
              imageInfo = {
                iconImageUrl: await getDownloadURL(uploadSnapshot.ref),
                iconImagePath: uploadSnapshot.metadata.fullPath || '',
                updatedAt: new Date().getTime()
              }
            } else {
              imageInfo = {
                [`iconImageUrl${index + 1}`]: await getDownloadURL(uploadSnapshot.ref),
                [`iconImagePath${index + 1}`]: uploadSnapshot.metadata.fullPath || '',
                updatedAt: new Date().getTime()
              }
            }
            // Firestore ドキュメントを更新
            await updateDoc(newDocRef, imageInfo)
          } catch (error) {
            console.error(`画像${index + 1}のエラー:`, error)
          }
        }
      })
    )

    // アシスタントAPIの処理
    if (
      promptData.assistantId === '' &&
      promptData.assistantApiInstruction !== '' &&
      updatedAssistantFiles &&
      updatedAssistantFiles.length > 0 &&
      promptData.useAssistantApi === true
    ) {
      const addedFiles = updatedAssistantFiles
      const [file_ids, vectorStore] = await Promise.all([
        uploadSelectedFiles(addedFiles),
        createVectorStore(promptData.prompt_title)
      ])
      const vector_store_id = vectorStore.id
      const requestBodyForCreateAssistant = {
        model: 'gpt4o',
        name: promptData.prompt_title,
        instructions: promptData.assistantApiInstruction,
        tools: [{ type: 'file_search' }],
        tool_resources: {
          file_search: {
            vector_store_ids: [vector_store_id]
          }
        }
      }
      const [myVectorStoreFileBatch, myAssistant] = await Promise.all([
        createVectorStoreFileBatch({ vector_store_id: vectorStore.id, file_ids }),
        createAssistant(requestBodyForCreateAssistant)
      ])
      console.log('file_ids', file_ids)
      console.log('vectorStore', vectorStore)
      console.log('myVectorStoreFileBatch', myVectorStoreFileBatch)
      console.log('myAssistant', myAssistant)
      const assistantId = myAssistant.id
      await updateDoc(newDocRef, { assistantId, updatedAt: new Date().getTime() })
      setAssistantFileChanged(false)
    } else if (promptData.assistantId && promptData.assistantId !== '' && promptData.useAssistantApi === true) {
      if (updatedAssistantFiles !== undefined) {
        const addedFiles = updatedAssistantFiles.filter(
          (f2) => !(selectedAssistantApiFiles || []).some((f1) => f1.id === f2.id)
        )
        const deletedFiles = (selectedAssistantApiFiles || []).filter(
          (f1) => !(updatedAssistantFiles || []).some((f2) => f2.id === f1.id)
        )
        console.log('addedFiles', addedFiles)
        console.log('deletedFiles', deletedFiles)
        if (deletedFiles.length > 0) {
          await deleteFiles(deletedFiles, promptData.assistantId)
        }
        if (addedFiles.length > 0) {
          const myAssistant = await retrieveAssistant(promptData.assistantId)
          const vectorStoreId = myAssistant.assistant.tool_resources.file_search.vector_store_ids[0] // vectorStoreIdは1つしかない前提
          const file_ids = await uploadSelectedFiles(addedFiles)
          await createVectorStoreFileBatch({ vector_store_id: vectorStoreId, file_ids })
        }
        const requestBodyForModifyAssistant = {
          instructions: promptData.assistantApiInstruction,
          name: promptData.prompt_title,
          tools: [{ type: 'file_search' }],
          model: 'gpt4o'
        }
        await modifyAssistant(promptData.assistantId, requestBodyForModifyAssistant)
        // await uploadAllFiles(addedFiles, promptData.assistantId, null, promptData.ref) // 単一ファイルを配列に入れて渡す
      }
      setAssistantFileChanged(false)
    }
    setLoadingText('')
    setLoading(false)
    window.location.reload()
  }
  //////////////////////////////////////AssistantAPI関連の関数

  const retrieveAssistant = async (assistant_id) => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/azure_retrieve_assistant'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ assistant_id })
    })
    const data = await res.json()
    const filesPromise = data.myAssistant.tool_resources.file_search.vector_store_ids.map((vector_store_id) => {
      return getVectorStoreFiles(vector_store_id)
    })
    const [files] = await Promise.all(filesPromise)
    return {
      assistant: data.myAssistant,
      files
    }
  }

  const deleteAssistant = async (assistant_id) => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/azure_delete_assistant'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ assistant_id })
    })
    const data = await res.json()
    console.log(data)
  }

  const getVectorStoreFiles = async (vector_store_id) => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/azure_get_vector_store_files'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ vector_store_id })
    })
    const { files, error } = await res.json()
    console.log(error)
    console.log(files)
    return files
  }

  const createVectorStore = async (mode_name) => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/azure_create_vector_store'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ mode_name })
    })
    const { vectorStore } = await res.json()
    console.log(vectorStore)
    return vectorStore
  }

  const uploadSelectedFiles = async (selectedFiles) => {
    if (updatedAssistantFiles.length === 0) {
      alert('ファイルが選択されていません。')
      return []
    }
    const uploadPromises = selectedFiles.map(async (fileObject) => {
      console.log('file', fileObject.file)
      return uploadFile(fileObject.file)
    })
    const uploadedFileIds = await Promise.all(uploadPromises)
    console.log('uploadedFileIds', uploadedFileIds)
    setUpdatedAssistantFiles([])
    return uploadedFileIds
  }

  const uploadFile = async (selectedFile) => {
    if (!selectedFile) {
      alert('ファイルが選択されていません。')
      return
    }
    console.log('selectedFile', selectedFile)
    const formData = new FormData()
    formData.append('file', selectedFile, encodeURIComponent(selectedFile.name))
    console.log('formData', formData)
    try {
      const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/azure_upload_file'
      const res = await fetch(URL, {
        method: 'POST',
        body: formData
      })
      const { response } = await res.json() // 追加: レスポンスのJSONを解析
      console.log('response', response)
      const uploadedFileId = response.id
      return uploadedFileId
    } catch (error) {
      console.error('Error uploading file:', error)
      alert('ファイルのアップロード中にエラーが発生しました。')
    }
  }

  const createAssistant = async (body) => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/azure_create_assistant'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    const { myAssistant } = await res.json()
    console.log(myAssistant)
    return myAssistant
  }

  const createVectorStoreFileBatch = async ({ vector_store_id, file_ids }) => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/azure_create_vector_store_file_batch'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ vector_store_id, file_ids })
    })
    const { myVectorStoreFileBatch } = await res.json()
    console.log(myVectorStoreFileBatch)
    return myVectorStoreFileBatch
  }

  const deleteAllFilesOfVectorStoreAndVectorStore = async (vector_store_id) => {
    const vectorStoreFiles = await getVectorStoreFiles(vector_store_id)
    console.log('vectorStoreFiles', vectorStoreFiles)
    const deletePromises = vectorStoreFiles.map(async (file) => {
      console.log('file', file)
      return deleteFile(file.id)
    })
    const deletedFiles = await Promise.all(deletePromises)
    console.log('deletedFiles', deletedFiles)
    const deletedVectorStore = deleteVectorStore(vector_store_id)
    console.log('deletedVectorStore', deletedVectorStore)
  }

  const deleteVectorStore = async (vector_store_id) => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/azure_delete_vector_store'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ vector_store_id })
    })
    const data = await res.json()
    console.log(data)
    return data
  }

  const deleteFiles = async (deletedFiles, assistantId) => {
    try {
      // 各ファイルIDに対してdeleteFileを並列実行
      const deletePromises = deletedFiles.map((file) => deleteFile(file.id))
      // すべての削除処理が完了するのを待つ
      await Promise.all(deletePromises)
      console.log('All files deleted successfully')
    } catch (error) {
      console.error('Error deleting files:', error)
      alert('ファイルの削除中にエラーが発生しました。')
    }
  }

  const deleteFile = async (file_id) => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/azure_delete_file'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ file_id })
    })
    const data = await res.json()
    console.log(data)
    return data.file
  }

  const modifyAssistant = async (assistant_id, modifyContent) => {
    const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/azure_modify_assistant'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ assistant_id, modifyContent })
    })
    const data = await res.json()
    console.log(data)
    return data
  }
  //////////////////////////////////////

  // const deleteFiles = async (deletedFiles, assistantId) => {
  //   try {
  //     // 各ファイルIDに対してdeleteFileを並列実行
  //     const deletePromises = deletedFiles.map((file) => deleteFile(file.id, assistantId))
  //     // すべての削除処理が完了するのを待つ
  //     await Promise.all(deletePromises)
  //     console.log('All files deleted successfully')
  //   } catch (error) {
  //     console.error('Error deleting files:', error)
  //     alert('ファイルの削除中にエラーが発生しました。')
  //   }
  // }

  // const deleteFile = async (fileId, assistantId) => {
  //   const URL1 = 'https://school-ai-r3y4on4j2a-an.a.run.app/delete_assistant_file' // AssisntantAPIからファイルを削除するAPI (物理削除ではない)
  //   await fetch(URL1, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({ file_id: fileId, assistant_id: assistantId })
  //   })
  //   const URL2 = 'https://school-ai-r3y4on4j2a-an.a.run.app/delete_file' // ファイル自体を物理削除
  //   await fetch(URL2, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({ file_id: fileId })
  //   })
  //   getAssistantApiFiles(selectedPrompt.assistantId) // ファイル一覧を再取得
  // }

  const uploadAllFiles = async (selectedFiles, assistant_id, requestBodyForCreate = null, newDocRef = null) => {
    try {
      // 各ファイルをアップロードし、それぞれのIDを取得する
      const uploadPromises = selectedFiles.map(async (fileObject) => {
        // handleUploadにfileプロパティを渡す
        const uploadedFileId = await handleUpload(fileObject.file)
        // アップロード後にIDを返す
        return uploadedFileId
      })
      // 全てのアップロードが完了するのを待つ
      const uploadedFileIds = await Promise.all(uploadPromises)

      if (assistant_id && assistant_id !== '') {
        const selectedIds = selectedAssistantApiFiles.map((file) => file.id)
        // 更新
        await updateAssistantAPIWithFileId(assistant_id, [...selectedIds, ...uploadedFileIds])
        await updateDoc(selectedPrompt.ref, {
          updatedAt: new Date().getTime()
        })
      } else {
        console.log('新規アシスタントID作成')
        const _assistantId = await createAssistantAPIwithFileId(uploadedFileIds, requestBodyForCreate)
        await updateDoc(newDocRef, {
          assistantId: _assistantId,
          updatedAt: new Date().getTime()
        })
      }
    } catch (error) {
      console.error('Error uploading files:', error)
      alert('ファイルのアップロード中にエラーが発生しました。')
    }
  }

  const updateAssistantAPIWithFileId = async (assistantId, newFileIds) => {
    try {
      const modifyContent = {
        instructions: selectedPrompt.assistantApiInstruction,
        name: selectedPrompt.prompt_title,
        tools: [{ type: 'retrieval' }],
        model: selectedPrompt.model,
        file_ids: newFileIds // 新しいファイルIDの配列
      }
      const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/modify_assistant'
      const res = await fetch(URL, {
        method: 'POST', // 更新は通常PATCHまたはPUTメソッドを使用します。
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ assistant_id: assistantId, modifyContent })
      })
      const updatedAssistant = await res.json()
      // 必要に応じて、更新後のAssistant IDをセットするなどの処理を追加
      // setAssistantId(updatedAssistant.id);
    } catch (error) {
      console.error('updateAssistantAPI failed:', error)
    }
    console.log('updateAssistantAPI終了')
  }

  const handleUpload = async (selectedFile) => {
    if (!selectedFile) {
      alert('ファイルが選択されていません。')
      return
    }
    console.log('handleUpload: selectedFile', selectedFile)
    const formData = new FormData()
    formData.append('file', selectedFile, encodeURIComponent(selectedFile.name))
    try {
      // const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/upload_file_to_openai'
      const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/upload_file_to_openai'
      const res = await fetch(URL, {
        method: 'POST',
        body: formData
      })
      const { response } = await res.json() // 追加: レスポンスのJSONを解析
      const uploadedFileId = response.id
      // createAssistantAPIwithFileId(uploadedFileId)
      // setUploadedFile({ ...response })
      // alert('ファイルがアップロードされました。')
      // setSelectedFile(null) // ファイル選択状態のリセット
      // setFileName('') // ファイル名表示のリセット
      return uploadedFileId
    } catch (error) {
      console.error('Error uploading file:', error)
      alert('ファイルのアップロード中にエラーが発生しました。')
    }
  }

  const createAssistantAPIwithFileId = async (fileIds, requestBodyForCreate = null) => {
    try {
      let requestBody = {}
      if (!requestBodyForCreate) {
        requestBody = {
          name: selectedPrompt.prompt_title,
          description: selectedPrompt.prompt_description,
          instructions: selectedPrompt.assistantApiInstruction,
          tools: [{ type: 'retrieval' }],
          model: selectedPrompt.model,
          file_ids: fileIds // csvファイルは失敗するっぽい
        }
      } else {
        requestBody = { ...requestBodyForCreate, file_ids: fileIds }
      }
      const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/open_ai_create_assistant_api'
      const res = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...requestBody })
      })
      const { myAssistant } = await res.json() // 追加: レスポンスのJSONを解析
      return myAssistant.id
    } catch (error) {
      console.error('createAssistantAPI failed:', error)
    }
    console.log('createAssistantAPI終了')
  }

  const [promptDocRefToUpdate, setPromptDodRefToUpdate] = useState()
  useEffect(() => {
    let ref
    if (paramSchoolId) {
      // paramSchoolIdがあれば塾用docrefをセット
      const mingakuDocRef = doc(db, 'externalServices', 'mingaku')
      ref = collection(mingakuDocRef, 'schools', paramSchoolId, 'prompts')
    } else {
      // paramSchoolIdがなければ通常サービスごとのdocrefをセット
      ref = collection(db, 'service_type', serviceType, 'prompts')
    }
    setPromptDodRefToUpdate(ref)
  }, [paramSchoolId])

  /* 複製ボタンのハンドラ */
  const copyPrompt = async (e, item) => {
    const alertMessage = validatePromptData(item)
    if (alertMessage !== '') {
      alert(alertMessage)
      return
    }

    const newDocRef = await addDoc(promptDocRefToUpdate, {
      prompt_title: item.prompt_title + 'コピー',
      prompt_description: item.prompt_description,
      prompt_language2: item.prompt_language2,
      prompt_type: item.prompt_type,
      prompt_order: Number(item.prompt_order),
      prompt: item.prompt,
      character_limit: item.character_limit ? item.character_limit : false,
      maximum_character_count: item.maximum_character_count ? item.maximum_character_count : 0,
      character_limit_per_month: item.character_limit_per_month ? item.character_limit_per_month : false,
      maximum_character_count_per_month: item.maximum_character_count_per_month
        ? item.maximum_character_count_per_month
        : 0,
      sending_count_limit: item.sending_count_limit ? item.sending_count_limit : false,
      maximum_sending_count_limit: item.maximum_sending_count_limit ? item.maximum_sending_count_limit : 0,
      enable: false,
      responseOptions: item.responseOptions.filter((optionObj) => optionObj.option !== ''),
      formQuestions: item.formQuestions.filter((optionObj) => optionObj.title !== ''),
      autoSending: item.autoSending,
      defaultMute: item.defaultMute,
      temperature: Number.parseFloat(item.temperature),
      speakingRate: item.speakingRate,
      model: item.model,
      largeIcon: item.largeIcon ? item.largeIcon : false,
      displayInputContents: item.displayInputContents ? item.displayInputContents : true,
      expandInputContents: item.expandInputContents ? item.expandInputContents : false,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime()
      // assistantAPI周りは複製なし
      // 利用・閲覧可能設定も複製なし
    })
    await updateDoc(newDocRef, { ref: newDocRef })
    window.location.reload()
  }

  /* 削除ボタンのハンドラ */
  const deletePrompt = async (id, _ref, imagePath, imagePath2) => {
    try {
      const docSnapshot = await getDoc(_ref)
      const data = docSnapshot.data()
      if (data.enable === true) {
        alert(
          '現在使用されており削除できません。\n削除するにはプロンプトのチェックボックスの✓を外して、アクティブ状態をオフにしてください。'
        )
      } else {
        const confirmed = window.confirm('本当に削除しますか？')
        if (confirmed) {
          setLoading(true)
          setLoadingText('モードを削除中...')
          await deleteDoc(_ref)
          console.log(`モードの削除完了`)
          const { assistantId } = selectedPrompt
          if (assistantId && assistantId !== '') {
            const myAssistant = await retrieveAssistant(assistantId)
            const vectorStoreId = myAssistant.assistant.tool_resources.file_search.vector_store_ids[0] // 1つしかない前提
            await Promise.all([deleteAllFilesOfVectorStoreAndVectorStore(vectorStoreId), deleteAssistant(assistantId)])

            // const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/delete_assistant_and_assistant_files'
            // await fetch(URL, {
            //   method: 'POST',
            //   headers: {
            //     'Content-Type': 'application/json'
            //   },
            //   body: JSON.stringify({ assistant_id: assistantId })
            // })
            console.log(`${assistantId}の削除完了`)
          }
          setLoadingText('')
          setLoading(false)
          window.location.reload()
        }
      }
    } catch (error) {
      setLoadingText('')
      setLoading(false)
      return
    }
  }
  const promptLimitSettings = [
    {
      label: '1日あたりの文字数制限',
      checkedCondition: selectedPrompt.character_limit,
      onChange: () =>
        handleInputPromptChange({ character_limit: !selectedPrompt.character_limit, maximum_character_count: 0 }),
      value: selectedPrompt.maximum_character_count,
      field: 'maximum_character_count',
      unit: '文字まで',
      tooltip:
        'ユーザーがこのモードの１スレッドで1日あたり利用できる文字数を設定します。※文字数はインプット・アウトプットの合計文字数です。対話を続ける場合はそれまでの対話履歴の文字数もインプットの文字数にカウントされます。'
    },
    {
      label: '1ヶ月あたりの文字数制限',
      checkedCondition: selectedPrompt.character_limit_per_month,
      onChange: () =>
        handleInputPromptChange({
          character_limit_per_month: !selectedPrompt.character_limit_per_month,
          maximum_character_count_per_month: 0
        }),
      value: selectedPrompt.maximum_character_count_per_month,
      field: 'maximum_character_count_per_month',
      unit: '文字まで',
      tooltip:
        'ユーザーがこのモードの１スレッドで1ヶ月あたり利用できる文字数を設定します。※文字数はインプット・アウトプットの合計文字数です。対話を続ける場合はそれまでの対話履歴の文字数もインプットの文字数にカウントされます。'
    },
    {
      label: '1スレッドあたりの送信回数制限',
      checkedCondition: selectedPrompt.sending_count_limit,
      onChange: () =>
        handleInputPromptChange({
          sending_count_limit: !selectedPrompt.sending_count_limit,
          maximum_sending_count_limit: 20
        }),
      value: selectedPrompt.maximum_sending_count_limit,
      field: 'maximum_sending_count_limit',
      unit: '回まで',
      tooltip: 'ユーザーがこのモードで1スレッドあたりメッセージを送信できる回数を設定します。'
    },
    {
      label: '送信時に含めるメッセージ件数制限',
      checkedCondition: selectedPrompt.is_past_message_limit_count_set,
      onChange: () =>
        handleInputPromptChange({
          is_past_message_limit_count_set: !selectedPrompt.is_past_message_limit_count_set,
          maximum_past_message_limit_count: 10
        }),
      value: selectedPrompt.maximum_past_message_limit_count,
      field: 'maximum_past_message_limit_count',
      unit: '件まで',
      tooltip:
        'メッセージを送信する時、メッセージを何件含めるかを設定できます。設定されていない場合は、過去のメッセージが全件送信されます。'
    }
  ]
  const updatePromptLimitValue = (field, value) => {
    const isValidInteger = /^\d*$/.test(value)
    if (!isValidInteger) {
      alert('半角数字で入力してください')
    } else {
      handleInputPromptChange({ [field]: Number(value) })
    }
  }
  const handleTimeWindowChange = (index, field, value) => {
    const newTimeWindows = [...selectedPrompt.time_window]
    newTimeWindows[index] = { ...newTimeWindows[index], [field]: value }
    handleInputPromptChange({ time_window: newTimeWindows })
    console.log(newTimeWindows)
  }

  const addTimeWindow = () => {
    const newTimeWindows = [...selectedPrompt.time_window, { start: '', end: '' }]
    handleInputPromptChange({ time_window: newTimeWindows })
  }

  const removeTimeWindow = (index) => {
    const newTimeWindows = selectedPrompt.time_window.filter((_, i) => i !== index)
    handleInputPromptChange({ time_window: newTimeWindows })
  }
  console.log('selectedPrompt.expandInputContents', selectedPrompt.expandInputContents)
  return (
    <>
      {loading ? (
        <LoadingGrid text={loadingText} />
      ) : (
        <div className={ManagementModule.container}>
          <ManagementScreenList
            filteredModes={filteredModes}
            searchText={searchText}
            setSearchText={setSearchText}
            displayAddBody={displayAddBody}
            switchBody={switchBody}
            selectedItem={selectedItem}
            selectedItemClassName={selectedItemClassName}
            schoolIdName={schoolIdName}
            handleCopyModeLink={handleCopyModeLink}
            handleCopyModeShareLink={handleCopyModeShareLink}
            handleModeStart={handleModeStart}
            updateActive={updateActive}
            updateActiveForTeacher={updateActiveForTeacher}
          />
          <>
            <div className={ManagementModule.body}>
              <>
                <div className={ManagementModule['prompt-body-header']}>
                  <div className={ManagementModule['sub-title-container']}>
                    <h3 className={ManagementModule['sub-title']}>{selectedPrompt ? promptTitle : '新規'}</h3>
                    {selectedPrompt.editable === false && (
                      <b className={ManagementModule['list-title-sub2']}>
                        ※{' '}
                        {selectedPrompt.parentSchoolId !== 'gpt-teacher'
                          ? schoolIdName[selectedPrompt.parentSchoolId]
                          : '管理人'}
                        が作成したモードです
                      </b>
                    )}
                  </div>
                  <div className={ManagementModule['button-container']}>
                    {addBody === true || !selectedPrompt ? (
                      <button className={ManagementModule['copy']} onClick={() => savePrompt(selectedPrompt)}>
                        作成
                      </button>
                    ) : (
                      <>
                        {selectedPrompt.length !== 0 && (
                          <>
                            <div className={ManagementModule['copy-link-container']}>
                              <CopyToClipboard
                                text={
                                  selectedPrompt.editable
                                    ? `${linkToCopy}&common=false&showheader=false&ref=${selectedPrompt.ref.path}`
                                    : `${linkToCopy}&common=true&showheader=false&ref=${selectedPrompt.ref.path}`
                                }
                                onCopy={handleCopy}
                              >
                                <CustomWidthTooltip
                                  title="このリンクの受信者は、このモードを利用することができます。"
                                  arrow
                                  enterTouchDelay={100}
                                >
                                  <LinkIcon className={ManagementModule['copy-link']} />
                                </CustomWidthTooltip>
                              </CopyToClipboard>
                              <p className={ManagementModule['copy-link-message']}>モード開始リンク</p>
                              {isCopied && (
                                <p className={ManagementModule['copied-message']}>リンクをコピーしました！</p>
                              )}
                            </div>
                            {auth.currentUser && (
                              <>
                                <div
                                  className={
                                    selectedPrompt.hidden === true
                                      ? ManagementModule['disabled-copy-link-container']
                                      : ManagementModule['copy-link-container']
                                  }
                                >
                                  <CopyToClipboard
                                    text={
                                      selectedPrompt.editable
                                        ? `${linkForReproduction}&common=false&showheader=false&ref=${selectedPrompt.ref.path}&uidFrom=${auth.currentUser.uid}`
                                        : `${linkForReproduction}&common=true&showheader=false&ref=${selectedPrompt.ref.path}&uidFrom=${auth.currentUser.uid}`
                                    }
                                    onCopy={handleCopy}
                                  >
                                    <CustomWidthTooltip
                                      title={
                                        selectedPrompt.hidden === true
                                          ? 'このモードは共有できません。'
                                          : 'このリンクの受信者(生徒アカウント以外)は、受信者のモード管理画面で、このモードを複製することができます。( ※ 複製後にあなたがこのモードを更新した場合、複製されたモードの方は更新されません。更新させたい場合は再度複製してもらう必要があります。)'
                                      }
                                      arrow
                                      className={ManagementModule['tooltip-arrow']}
                                      enterTouchDelay={100}
                                    >
                                      <LinkIcon className={ManagementModule['copy-link']} />
                                    </CustomWidthTooltip>
                                  </CopyToClipboard>
                                  <p className={ManagementModule['copy-link-message']}>モード共有リンク</p>
                                </div>
                                {selectedPrompt.useAssistantApi || selectedPrompt.hidden ? (
                                  <>
                                    <CustomWidthTooltip
                                      title="アシスタントAPIを使用しているため、このモードは複製できません。"
                                      arrow
                                      className={ManagementModule['tooltip-arrow']}
                                      enterTouchDelay={100}
                                    >
                                      <button className={ManagementModule['copy-disabled']}>複製</button>
                                    </CustomWidthTooltip>
                                  </>
                                ) : (
                                  <>
                                    <CustomWidthTooltip
                                      title="モード設定をコピーします(※アイコンは複製されません)"
                                      arrow
                                      className={ManagementModule['tooltip-arrow']}
                                      enterTouchDelay={100}
                                    >
                                      <button
                                        className={ManagementModule['copy1']}
                                        onClick={(e) => copyPrompt(e, selectedPrompt)}
                                      >
                                        複製
                                      </button>
                                    </CustomWidthTooltip>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                        {selectedPrompt.editable === true ? (
                          <button
                            className={ManagementModule['copy']}
                            onClick={() => savePrompt(selectedPrompt)}
                            type="submit"
                          >
                            保存
                          </button>
                        ) : (
                          <button
                            disabled
                            className={ManagementModule['copy-disabled']}
                            onClick={() => savePrompt(selectedPrompt)}
                            type="submit"
                          >
                            保存
                          </button>
                        )}
                        {selectedPrompt.editable === true ? (
                          <button
                            className={ManagementModule['copy']}
                            onClick={() =>
                              deletePrompt(
                                selectedPrompt.id,
                                selectedPrompt.ref,
                                selectedPrompt.iconImagePath,
                                selectedPrompt.iconImagePath2
                              )
                            }
                          >
                            削除
                          </button>
                        ) : (
                          <button
                            disabled
                            className={ManagementModule['copy-disabled']}
                            onClick={() =>
                              deletePrompt(
                                selectedPrompt.id,
                                selectedPrompt.ref,
                                selectedPrompt.iconImagePath,
                                selectedPrompt.iconImagePath2
                              )
                            }
                          >
                            削除
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {selectedPrompt.hidden ? (
                  <div className={ManagementModule.main}>
                    <p className={ManagementModule['prompt-body']}>モードの詳細は非公開です。</p>
                  </div>
                ) : (
                  <div className={ManagementModule.main} ref={managementModuleRef}>
                    <div className={ManagementModule['prompt-body']}>
                      <p className={ManagementModule.tips}>
                        <span className={ManagementModule['mandatory']}>＊</span>は必須入力です。
                      </p>
                      {/* タイトル */}
                      <p className={ManagementModule.label}>
                        <span className={ManagementModule['mandatory']}>＊</span>タイトル
                      </p>
                      <textarea
                        className={ManagementModule['prompt-title']}
                        value={selectedPrompt.prompt_title}
                        onChange={(e) => handleInputPromptChange({ prompt_title: e.target.value })}
                      />
                      {/* 説明*/}
                      <p className={ManagementModule.label}>説明</p>
                      <textarea
                        className={ManagementModule['prompt-title']}
                        value={selectedPrompt.prompt_description}
                        onChange={(e) => handleInputPromptChange({ prompt_description: e.target.value })}
                      />
                      <div className={ManagementModule['prompt-input']}>
                        {/* 順番 */}
                        <div className={ManagementModule['prompt-input-fields']}>
                          <p className={ManagementModule.label}>順番</p>
                          <textarea
                            className={ManagementModule['prompt-order']}
                            value={selectedPrompt.prompt_order}
                            onChange={(e) => updatePromptOrder(e.target.value)}
                          />
                        </div>
                      </div>
                      {promptLimitSettings.map((setting, index) => (
                        <div className={ManagementModule['prompt-input3']} key={index}>
                          <div className={ManagementModule['prompt-input-fields-container1']}>
                            <span className={ManagementModule['sub-field']}>{setting.label}</span>
                            <Switch2 checked={setting.checkedCondition} onChange={setting.onChange} />
                          </div>
                          {setting.checkedCondition && (
                            <div className={ManagementModule['prompt-input-fields-container2']}>
                              <div className={ManagementModule['prompt-input-fields5']}>
                                <div className={ManagementModule['field-container']}>
                                  <textarea
                                    className={ManagementModule['prompt-order2']}
                                    value={setting.value}
                                    onChange={(e) => updatePromptLimitValue(setting.field, e.target.value)}
                                  />
                                  <span>{setting.unit}</span>
                                </div>
                              </div>
                              <div>
                                <p className={ManagementModule['p-tooltip']}>{setting.tooltip}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      <div className={ManagementModule['prompt-input3']}>
                        <div className={ManagementModule['prompt-input-fields-container1']}>
                          <span className={ManagementModule['sub-field']}>{'モードの利用時間制限'}</span>
                          <Switch2
                            checked={selectedPrompt.time_window_limit}
                            onChange={() =>
                              handleInputPromptChange({
                                time_window_limit: !selectedPrompt.time_window_limit,
                                time_window: [{ start: '00:00', end: '23:59' }]
                              })
                            }
                          />
                        </div>
                        {selectedPrompt.time_window_limit && (
                          <div className={ManagementModule['prompt-input-fields-container2']}>
                            <div className={ManagementModule['prompt-input-fields5']}>
                              <div className={ManagementModule['field-container2']}>
                                {selectedPrompt.time_window.map((time, index) => (
                                  <div key={index} className={ManagementModule['time-window-item']}>
                                    <input
                                      type="time"
                                      className={ManagementModule['prompt-order2']}
                                      value={time.start}
                                      onChange={(e) => handleTimeWindowChange(index, 'start', e.target.value)}
                                    />
                                    〜
                                    <input
                                      type="time"
                                      className={ManagementModule['prompt-order2']}
                                      value={time.end}
                                      onChange={(e) => handleTimeWindowChange(index, 'end', e.target.value)}
                                    />
                                    <div className={ManagementModule['delete']} onClick={() => removeTimeWindow(index)}>
                                      <DeleteOutlineIcon />
                                    </div>
                                  </div>
                                ))}
                                <div className={ManagementModule['add-container']}>
                                  <div className={ManagementModule['add']}>
                                    <AddCircleIcon className={ManagementModule.icon} onClick={addTimeWindow} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <p className={ManagementModule['p-tooltip']}>
                                {'ユーザーがこのモードを利用できる時間帯を設定します。'}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className={ManagementModule['prompt-input-4']}>
                        <div className={ManagementModule['prompt-input-fields3']}>
                          {/* プロンプト */}
                          <p className={ManagementModule.label}>
                            <span className={ManagementModule['mandatory']}>＊</span>プロンプト(現在
                            {selectedPrompt && selectedPrompt.prompt ? selectedPrompt.prompt.length : 0}文字)
                          </p>
                          <textarea
                            ref={textAreaRef}
                            className={ManagementModule.prompt}
                            value={selectedPrompt.prompt}
                            onChange={(e) => handleInputPromptChange({ prompt: e.target.value })}
                          />
                        </div>
                        <div className={ManagementModule['prompt-input-fields-container']}>
                          <div className={ManagementModule['prompt-input-fields4']}>
                            <p className={ManagementModule.label2}>
                              差し込みコード{' '}
                              <CustomWidthTooltip
                                title="フォームの各質問でユーザーに入力してもらった値がプロンプトに入ります"
                                arrow
                                className={ManagementModule['tooltip-arrow']}
                                enterTouchDelay={100}
                              >
                                <mark className={ManagementModule['tooltip2']}>？</mark>
                              </CustomWidthTooltip>
                            </p>
                            <div className={ManagementModule['codes-container']}>
                              {selectedPrompt.length !== 0 && selectedPrompt.formQuestions.length > 0 ? (
                                <>
                                  <ul className={ManagementModule['codes-container-ul']}>
                                    {selectedPrompt.formQuestions
                                      .filter((question) => question.type !== 'image') // ここで 'image' タイプを除外
                                      .map((question, index) => (
                                        <li key={index}>
                                          <button
                                            className={ManagementModule['codes']}
                                            onClick={() => handleInsertClick(question.key)}
                                          >
                                            {question.title ? question.title : 'タイトルなし'}
                                          </button>
                                        </li>
                                      ))}
                                  </ul>
                                  <div className={ManagementModule['codes-small-container']}>
                                    <p className={ManagementModule['codes-small-container-p']}>
                                      質問の文字数
                                      <CustomWidthTooltip
                                        title="実際にユーザーが質問(テキスト)に入力した文字数が入ります"
                                        arrow
                                        className={ManagementModule['tooltip-arrow']}
                                        enterTouchDelay={100}
                                      >
                                        <mark className={ManagementModule['tooltip2']}>？</mark>
                                      </CustomWidthTooltip>
                                    </p>
                                    <ul className={ManagementModule['codes-small-container-ul']}>
                                      {selectedPrompt.formQuestions
                                        .filter((question) => question.type === 'text')
                                        .map((filteredQuestion, index) => (
                                          <li key={`text-${index}`}>
                                            <button
                                              onClick={() => handleInsertClick(`${filteredQuestion.key}.length`)}
                                              className={ManagementModule['codes']}
                                            >
                                              {filteredQuestion.title
                                                ? filteredQuestion.title + 'の文字数'
                                                : 'タイトルなしの文字数'}
                                            </button>
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                </>
                              ) : (
                                <p>なし</p>
                              )}
                            </div>
                          </div>
                          <br />
                          <div className={ManagementModule['prompt-input-fields4']}>
                            <p className={ManagementModule.label2}>
                              生徒情報の差し込み{' '}
                              <CustomWidthTooltip
                                title="みんがくに登録されている生徒情報を差し込めます"
                                arrow
                                className={ManagementModule['tooltip-arrow']}
                                enterTouchDelay={100}
                              >
                                <mark className={ManagementModule['tooltip2']}>？</mark>
                              </CustomWidthTooltip>
                            </p>
                            <div className={ManagementModule['codes-container']}>
                              {studentInfoItemsForInsertion.length > 0 ? (
                                <ul className={ManagementModule['codes-container-ul']}>
                                  {studentInfoItemsForInsertion.map((item, index) => (
                                    <li key={index}>
                                      <button
                                        className={ManagementModule['codes']}
                                        onClick={() => handleInsertClick(item.key)}
                                      >
                                        {item.title ? item.title : 'タイトルなし'}
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <p>なし</p>
                              )}
                            </div>
                          </div>
                          <div className={ManagementModule['prompt-input-fields4']}>
                            <p className={ManagementModule.label2}>
                              マイページ情報の差し込み{' '}
                              <CustomWidthTooltip
                                title="スクールAIのマイページに登録されている情報を差し込めます"
                                arrow
                                className={ManagementModule['tooltip-arrow']}
                                enterTouchDelay={100}
                              >
                                <mark className={ManagementModule['tooltip2']}>？</mark>
                              </CustomWidthTooltip>
                            </p>
                            <div className={ManagementModule['codes-container']}>
                              <ul className={ManagementModule['codes-container-ul']}>
                                {/* {_fields_of_user_data_in_mypage.map((item, index) => (
                                  <li key={index}>
                                    <button
                                      className={ManagementModule['codes']}
                                      onClick={() => handleInsertClick(`${item.value}.mypage`)}
                                    >
                                      {item.displayName ? item.displayName : 'タイトルなし'}
                                    </button>
                                  </li>
                                ))} */}
                                {_fields_of_user_data_in_mypage
                                  .filter((item) => item.mypage !== false) // Filter out items where mypage is false
                                  .map((item, index) => (
                                    <li key={index}>
                                      <button
                                        className={ManagementModule['codes']}
                                        onClick={() => handleInsertClick(`${item.value}.mypage`)}
                                      >
                                        {item.displayName ? item.displayName : 'タイトルなし'}
                                      </button>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* アイコン */}
                      <p className={ManagementModule.label}>アイコン</p>
                      <div className={ManagementModule['prompt-input3']}>
                        <div className={ManagementModule['prompt-input-fields-container1']}>
                          <span className={ManagementModule['sub-field']}>アイコンの大きさ</span>
                          <Switch2
                            onText=" 大"
                            offText="小"
                            checked={selectedPrompt.largeIcon}
                            onChange={() => handleInputPromptChange({ largeIcon: !selectedPrompt.largeIcon })}
                          />
                        </div>
                      </div>
                      <div className={ManagementModule['prompt-input']}>
                        <div className={ManagementModule['prompt-input-fields']}>
                          <label className={ManagementModule['icon-select']} htmlFor="file_upload1">
                            アイコン①を選ぶ
                            <input id="file_upload1" type="file" onChange={handleImage} />
                          </label>
                          <CustomWidthTooltip
                            title="デフォルト画像です"
                            arrow
                            className={ManagementModule['tooltip-arrow']}
                            enterTouchDelay={100}
                          >
                            <mark className={ManagementModule['tooltip2']}>？</mark>
                          </CustomWidthTooltip>
                          {imageUrl && (
                            <>
                              <div className={ManagementModule['icon-container']}>
                                <img className={ManagementModule['icon-image']} src={imageUrl} alt="" />
                              </div>
                              <button className={ManagementModule['icon-delete']} onClick={() => deleteImage()}>
                                アイコン①を削除する...
                              </button>
                            </>
                          )}
                          {selectedPrompt.iconImageUrl && !imageUrl ? (
                            <>
                              <div className={ManagementModule['icon-container']}>
                                <img
                                  className={ManagementModule['icon-image']}
                                  src={selectedPrompt.iconImageUrl}
                                  alt=""
                                />
                              </div>
                              <button
                                className={ManagementModule['icon-delete']}
                                onClick={() =>
                                  handleInputPromptChange({
                                    iconImageUrl: ''
                                  })
                                }
                              >
                                アイコン①を削除する
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className={ManagementModule['prompt-input-fields']}>
                          <label className={ManagementModule['icon-select']} htmlFor="file_upload2">
                            アイコン②を選ぶ
                            <input id="file_upload2" type="file" onChange={handleImage2} />
                          </label>
                          <CustomWidthTooltip
                            title="動作時にデフォルトと交互にでる画像です"
                            arrow
                            className={ManagementModule['tooltip-arrow']}
                            enterTouchDelay={100}
                          >
                            <mark className={ManagementModule['tooltip2']}>？</mark>
                          </CustomWidthTooltip>
                          {imageUrl2 && (
                            <>
                              <div className={ManagementModule['icon-container']}>
                                <img className={ManagementModule['icon-image']} src={imageUrl2} alt="" />
                              </div>
                              <button className={ManagementModule['icon-delete']} onClick={() => deleteImage2()}>
                                アイコン②を削除する...
                              </button>
                            </>
                          )}
                          {selectedPrompt.iconImageUrl2 && !imageUrl2 ? (
                            <>
                              <div className={ManagementModule['icon-container']}>
                                <img
                                  className={ManagementModule['icon-image']}
                                  src={selectedPrompt.iconImageUrl2}
                                  alt=""
                                />
                              </div>
                              <button
                                className={ManagementModule['icon-delete']}
                                onClick={() =>
                                  handleInputPromptChange({
                                    iconImageUrl2: ''
                                  })
                                }
                              >
                                アイコン②を削除する
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <p className={ManagementModule.label}>返答選択肢</p>
                      <div className={ManagementModule['response-options-container']}>
                        {selectedPrompt && selectedPrompt.responseOptions.length > 0 && (
                          <table className={ManagementModule['options-table']}>
                            <thead>
                              <tr>
                                <th className={ManagementModule.order}>順番</th>
                                <th className={ManagementModule.option}>選択肢名</th>
                                <th className={ManagementModule.optionContent}>送信される内容</th>
                                <th className={ManagementModule.delete}>　</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedPrompt.responseOptions.map((responseOption, index) => (
                                <tr key={index}>
                                  <td>
                                    <textarea
                                      value={responseOption.order}
                                      className={ManagementModule['order']}
                                      onChange={(e) => handleOptionOrderChange(e.target.value, index)}
                                    />
                                  </td>
                                  <td>
                                    <textarea
                                      value={responseOption.option}
                                      className={ManagementModule['option']}
                                      onChange={(e) => handleOptionChange(e.target.value, index)}
                                    />
                                  </td>
                                  <td>
                                    <textarea
                                      value={responseOption.content}
                                      className={ManagementModule['optionContent']}
                                      onChange={(e) => handleOptionContentChange(e.target.value, index)}
                                    />
                                  </td>
                                  <td>
                                    <div className={ManagementModule['add-container']}>
                                      <div
                                        className={ManagementModule['delete']}
                                        onClick={() => addToDeleteArray(index)}
                                      >
                                        <DeleteOutlineIcon />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                        <div className={ManagementModule['add-container']}>
                          <div className={ManagementModule['add']}>
                            <AddCircleIcon className={ManagementModule.icon} onClick={addToAddArray} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={ManagementModule['prompt-body']}>
                      <h3 className={ManagementModule['section-title']}>API設定</h3>
                      <div className={ManagementModule['prompt-input']}>
                        <div className={ManagementModule['prompt-input-fields']}>
                          <p className={ManagementModule.label}>生成AIモデル</p>
                          <FormControl fullWidth className={ManagementModule.formcontrol}>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedPrompt.model || ''}
                              onChange={(e) => updatePromptModel(e.target.value)}
                              sx={{
                                height: '35px',
                                backgroundColor: 'white'
                              }}
                            >
                              {constantData._gpt_model.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.displayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        {/* デフォルト言語 */}
                        <div className={ManagementModule['prompt-input-fields']}>
                          <p className={ManagementModule.label}>言語</p>
                          <FormControl fullWidth className={ManagementModule.formcontrol}>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedPrompt.prompt_language2 ? selectedPrompt.prompt_language2 : ''}
                              onChange={(e) =>
                                handleInputPromptChange({
                                  prompt_language2: e.target.value
                                })
                              }
                              sx={{
                                height: '35px',
                                backgroundColor: 'white'
                              }}
                            >
                              {languageList2.map((language) => (
                                <MenuItem key={JSON.stringify(language)} value={JSON.stringify(language)}>
                                  {language.japaneseName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {(() => {
                        const selectedModel = constantData._gpt_model.find(
                          (item) => item.value === selectedPrompt.model
                        )
                        return selectedModel?.availableAssistApi && selectedModel?.provider === 'microsoft'
                      })() ? (
                        <div className={ManagementModule['prompt-input']}>
                          <div className={ManagementModule['prompt-assistant-api-container']}>
                            <div className={ManagementModule['prompt-input3']}>
                              <div className={ManagementModule['assistant-api-container']}>
                                <span className={ManagementModule['sub-field']}>
                                  【試験運用中】ファイルを学習させる(AssisntantAPIを利用する)
                                </span>
                                <Switch2
                                  checked={!!selectedPrompt.useAssistantApi ? selectedPrompt.useAssistantApi : false}
                                  onChange={() =>
                                    handleInputPromptChange({ useAssistantApi: !selectedPrompt.useAssistantApi })
                                  }
                                />
                              </div>
                            </div>
                            {selectedPrompt.useAssistantApi && (
                              <div className={ManagementModule['prompt-assistant-api-sub-container']}>
                                <p className={ManagementModule['instruction']}>役割</p>
                                <textarea
                                  value={
                                    selectedPrompt.assistantApiInstruction ? selectedPrompt.assistantApiInstruction : ''
                                  }
                                  size="sm"
                                  className={ManagementModule['instruction-area']}
                                  onChange={(e) => {
                                    handleInputPromptChange({ assistantApiInstruction: e.target.value })
                                  }}
                                />
                                <div className={ManagementModule['select-assistant-api-files']}>
                                  <label
                                    className={ManagementModule['icon-select']}
                                    htmlFor="assistant_api_file_upload"
                                  >
                                    ファイルを選ぶ
                                    <input
                                      id="assistant_api_file_upload"
                                      type="file"
                                      multiple
                                      onChange={handleAssistantApiFileUpload}
                                    />
                                  </label>
                                </div>
                                <div className={ManagementModule['file-container']}>
                                  <table className={ManagementModule['options-table2']}>
                                    <thead>
                                      <tr>
                                        <th className={ManagementModule.order}></th>
                                        <th className={ManagementModule.delete}></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {updatedAssistantFiles &&
                                        updatedAssistantFiles.map((file, index) => (
                                          <tr key={index}>
                                            <td>
                                              <p className={ManagementModule['uploaded-file-name']}>・{file.name}</p>
                                            </td>
                                            <td>
                                              <div className={ManagementModule['add-container2']}>
                                                <div
                                                  className={ManagementModule.delete}
                                                  onClick={() => handleDeleteFile(file, index)}
                                                >
                                                  <DeleteOutlineIcon />
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                        // <div className={ManagementModule['prompt-input']}>
                        //   <div className={ManagementModule['prompt-assistant-api-container']}>
                        //     <div className={ManagementModule['prompt-input3']}>
                        //       <div className={ManagementModule['assistant-api-container']}>
                        //         <span className={ManagementModule['sub-field']}>AssistantAPIを使用</span>
                        //         <Switch2
                        //           checked={!!selectedPrompt.useAssistantApi ? selectedPrompt.useAssistantApi : false}
                        //           onChange={() =>
                        //             handleInputPromptChange({ useAssistantApi: !selectedPrompt.useAssistantApi })
                        //           }
                        //         />
                        //       </div>
                        //     </div>
                        //     {selectedPrompt.useAssistantApi && (
                        //       <div className={ManagementModule['prompt-assistant-api-sub-container']}>
                        //         <p className={ManagementModule['instruction']}>AssistantAPIの役割</p>
                        //         <textarea
                        //           value={
                        //             selectedPrompt.assistantApiInstruction ? selectedPrompt.assistantApiInstruction : ''
                        //           }
                        //           size="sm"
                        //           className={ManagementModule['instruction-area']}
                        //           onChange={(e) => {
                        //             handleInputPromptChange({ assistantApiInstruction: e.target.value })
                        //           }}
                        //         />
                        //         <div className={ManagementModule['select-assistant-api-files']}>
                        //           <label
                        //             className={ManagementModule['icon-select']}
                        //             htmlFor="assistant_api_file_upload"
                        //           >
                        //             ファイルを選ぶ
                        //             <input
                        //               id="assistant_api_file_upload"
                        //               type="file"
                        //               multiple
                        //               onChange={handleAssistantApiFileUpload}
                        //             />
                        //           </label>
                        //         </div>
                        //         <div className={ManagementModule['file-container']}>
                        //           <table className={ManagementModule['options-table2']}>
                        //             <thead>
                        //               <tr>
                        //                 <th className={ManagementModule.order}></th>
                        //                 <th className={ManagementModule.delete}></th>
                        //               </tr>
                        //             </thead>
                        //             <tbody>
                        //               {updatedAssistantFiles &&
                        //                 updatedAssistantFiles.map((file, index) => (
                        //                   <tr key={index}>
                        //                     <td>
                        //                       <p className={ManagementModule['uploaded-file-name']}>{file.name}</p>
                        //                     </td>
                        //                     <td>
                        //                       <div className={ManagementModule['add-container2']}>
                        //                         <div
                        //                           className={ManagementModule.delete}
                        //                           onClick={() => handleDeleteFile(file, index)}
                        //                         >
                        //                           <DeleteOutlineIcon />
                        //                         </div>
                        //                       </div>
                        //                     </td>
                        //                   </tr>
                        //                 ))}
                        //             </tbody>
                        //           </table>
                        //         </div>
                        //       </div>
                        //     )}
                        //   </div>
                        // </div>
                      )}
                      <div className={ManagementModule['prompt-input']}>
                        <div className={ManagementModule['prompt-input-fields']}>
                          <p className={ManagementModule.label}>音声の出力</p>
                          <FormControl fullWidth className={ManagementModule.formcontrol}>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedPrompt.defaultMute}
                              onChange={(e) => handleInputPromptChange({ defaultMute: e.target.value })}
                              sx={{
                                height: '35px',
                                backgroundColor: 'white'
                              }}
                            >
                              {_default_mute.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.displayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        {/* 音声自動送信 */}
                        <div className={ManagementModule['prompt-input-fields']}>
                          <p className={ManagementModule.label}>音声入力の自動送信</p>
                          <FormControl fullWidth className={ManagementModule.formcontrol}>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedPrompt.autoSending || false}
                              onChange={(e) => handleInputPromptChange({ autoSending: e.target.value })}
                              sx={{
                                height: '35px',
                                backgroundColor: 'white'
                              }}
                            >
                              {_auto_sending.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.displayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div className={ManagementModule['prompt-input']}>
                        {/* gpt精度 */}
                        <div className={ManagementModule['prompt-input-fields']}>
                          <p className={ManagementModule.label}>GPTの返答のランダム性(0 - 2.0)</p>
                          <input
                            className={ManagementModule['number-select']}
                            type="number"
                            step="0.1"
                            value={selectedPrompt.temperature}
                            onChange={(e) => updatePromptTemperature(e.target.value)}
                          ></input>
                        </div>
                        {/* 会話速度 */}
                        <div className={ManagementModule['prompt-input-fields']}>
                          <p className={ManagementModule.label}>音声の会話速度(0.25 - 4.0)</p>
                          <input
                            className={ManagementModule['number-select']}
                            type="number"
                            step="0.1"
                            value={selectedPrompt.speakingRate ? selectedPrompt.speakingRate : 1.1}
                            onChange={(e) => updatePromptSpeakingRate(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className={ManagementModule['prompt-body']}>
                      <h3 className={ManagementModule['section-title']}>
                        フォーム設定
                        <CustomWidthTooltip
                          title="チャット開始前にユーザーに入力してもらい、「差し込みコード」を用いてプロンプトの値として使用することができます"
                          arrow
                          className={ManagementModule['tooltip-arrow']}
                          enterTouchDelay={100}
                        >
                          <span className={ManagementModule['tooltip2']}>？</span>
                        </CustomWidthTooltip>
                      </h3>
                      <div className={ManagementModule['prompt-input3']}>
                        <div className={ManagementModule['prompt-input-fields-container4']}>
                          <span className={ManagementModule['sub-field']}>フォームの入力内容をチャットに表示</span>
                          <Switch2
                            checked={selectedPrompt.displayInputContents}
                            onChange={() =>
                              handleInputPromptChange({ displayInputContents: !selectedPrompt.displayInputContents })
                            }
                          />
                        </div>
                      </div>
                      {selectedPrompt.displayInputContents && (
                        <div className={ManagementModule['prompt-input3']}>
                          <div className={ManagementModule['prompt-input-fields-container4']}>
                            <span className={ManagementModule['sub-field']}>
                              フォームの入力内容を展開したままチャットに表示
                            </span>

                            <Switch2
                              checked={selectedPrompt.expandInputContents ?? false}
                              onChange={() =>
                                handleInputPromptChange({ expandInputContents: !selectedPrompt.expandInputContents })
                              }
                            />
                          </div>
                        </div>
                      )}
                      {selectedPrompt &&
                        selectedPrompt.formQuestions &&
                        selectedPrompt.formQuestions.map((formQuestions, index) => (
                          <div key={index} className={ManagementModule['input-container']}>
                            <div className={ManagementModule['prompt-input-container']}>
                              <div className={ManagementModule['prompt-input2']}>
                                <div className={ManagementModule['prompt-input-fields2-order']}>
                                  <p className={ManagementModule.label}>表示順</p>
                                  <textarea
                                    className={ManagementModule['question-order']}
                                    value={formQuestions.order}
                                    size="sm"
                                    onChange={(e) => {
                                      handleQuestionOrderChange(e.target.value, index)
                                    }}
                                  />
                                </div>
                                <div className={ManagementModule['prompt-input-fields2']}>
                                  <p className={ManagementModule.label}>質問のタイプ</p>
                                  <FormControl fullWidth className={ManagementModule.formcontrol}>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={formQuestions.type}
                                      onChange={(e) => handleQuestionTypeChange(e.target.value, index)}
                                      sx={{
                                        height: '35px',
                                        backgroundColor: 'white'
                                      }}
                                    >
                                      {_question_type.map((item, index) => (
                                        <MenuItem key={index} value={item.value}>
                                          {item.displayName}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className={ManagementModule['prompt-input-fields2']}>
                                  <p className={ManagementModule.label}>
                                    <span className={ManagementModule['mandatory']}>＊</span>差し込みコード
                                  </p>
                                  <textarea
                                    placeholder="半角英数で設定"
                                    className={ManagementModule['question-title']}
                                    onChange={(e) => handleQuestionKeyChange(e.target.value, index)}
                                    required
                                    value={formQuestions.key}
                                    size="sm"
                                  >
                                    {formQuestions.key}
                                  </textarea>
                                </div>
                              </div>
                              <div
                                className={ManagementModule['question-delete']}
                                onClick={() => addToDeleteQuestionArray(index)}
                              >
                                <DeleteOutlineIcon />
                              </div>
                            </div>
                            {formQuestions.type == 'image' && (
                              <p className={ManagementModule['tips2']}>
                                ※画像タイプは入力必須です。また、プロンプトには差し込めません。
                              </p>
                            )}
                            {formQuestions.type === 'text' && (
                              <>
                                <div className={ManagementModule['prompt-input3']}>
                                  <div className={ManagementModule['prompt-input-fields-container1']}>
                                    <span className={ManagementModule['sub-field']}>回答の文字数制限</span>
                                    <Switch2
                                      checked={
                                        !!formQuestions.answerCharacterLimit
                                          ? formQuestions.answerCharacterLimit
                                          : false
                                      }
                                      onChange={() =>
                                        updatePromptFormQuestionCharacterLimit(
                                          formQuestions.answerCharacterLimit,
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                  {formQuestions.type === 'text' && formQuestions.answerCharacterLimit && (
                                    <div className={ManagementModule['prompt-input-fields-container2']}>
                                      <div className={ManagementModule['prompt-input-fields5']}>
                                        <div className={ManagementModule['field-container']}>
                                          <textarea
                                            size="sm"
                                            placeholder=""
                                            className={ManagementModule['prompt-order2']}
                                            onChange={(e) =>
                                              handleFormQuestionAnswerMaxLengthChange(e.target.value, index)
                                            }
                                            required
                                            value={formQuestions.answerMaxLength}
                                          >
                                            {formQuestions.answerMaxLength}
                                          </textarea>
                                          <span>文字まで</span>
                                        </div>
                                      </div>
                                      <div>
                                        <p className={ManagementModule['p-tooltip']}>
                                          ユーザーが質問に入力できる最大文字数を設定します
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                            <>
                              <div className={ManagementModule['prompt-input3']}>
                                <div className={ManagementModule['prompt-input-fields-container1']}>
                                  <span className={ManagementModule['sub-field']}>入力の必須</span>
                                  <Switch2
                                    onText="必須"
                                    offText="任意"
                                    checked={formQuestions.type === 'image' ? true : !!formQuestions.mandatory}
                                    onChange={() => updatePromptFormQuestionMandatory(formQuestions.mandatory, index)}
                                    disabled={formQuestions.type === 'image'}
                                  />
                                </div>
                              </div>
                              <div className={ManagementModule['prompt-input3']}>
                                <div className={ManagementModule['prompt-input-fields-container1']}>
                                  <span className={ManagementModule['sub-field']}>途中変更可能</span>
                                  <Switch2
                                    onText="有効"
                                    offText="無効"
                                    checked={!!formQuestions.changeable ? formQuestions.changeable : false}
                                    onChange={() => updatePromptFormQuestionChangeable(formQuestions.changeable, index)}
                                  />
                                </div>
                              </div>
                            </>
                            <div className={ManagementModule['input-section2']}>
                              <div>
                                <p className={ManagementModule.label3}>
                                  <span className={ManagementModule['mandatory']}>＊</span>質問タイトル
                                </p>
                                <textarea
                                  className={ManagementModule['question-title']}
                                  onChange={(e) => handleQuestionTitleChange(e.target.value, index)}
                                  value={formQuestions.title}
                                >
                                  {formQuestions.title}
                                </textarea>
                                <p className={ManagementModule.label3}>質問の説明</p>
                                <textarea
                                  className={ManagementModule['question-instruction']}
                                  onChange={(e) => handleQuestionDescriptionChange(e.target.value, index)}
                                  value={formQuestions.description}
                                >
                                  {formQuestions.description}
                                </textarea>
                              </div>

                              {formQuestions.type === 'pulldown' && (
                                <>
                                  <div className={ManagementModule['title-container']}>
                                    <p className={ManagementModule['label3']}>選択肢</p>
                                    <button
                                      className={ManagementModule['title-container-right']}
                                      onClick={() => openModal(index)}
                                    >
                                      ※テンプレートを使う
                                    </button>
                                  </div>
                                  <div className={ManagementModule['response-options-container']}>
                                    {inputResponseOptions.length > 0 && (
                                      <table className={ManagementModule['options-table']}>
                                        <thead>
                                          <tr>
                                            <th className={ManagementModule.order}>順番</th>
                                            <th className={ManagementModule.option}>
                                              <span className={ManagementModule['mandatory']}>＊</span>選択肢名
                                            </th>
                                            <th className={ManagementModule.optionContent}>
                                              選択肢の値（選択肢が選ばれた時に、AIに送信される文言）
                                              <CustomWidthTooltip
                                                title="選択肢の値がブランクの場合、選択肢名が値として入ります"
                                                arrow
                                                className={ManagementModule['tooltip-arrow']}
                                                enterTouchDelay={100}
                                              >
                                                <mark className={ManagementModule['tooltip2']}>？</mark>
                                              </CustomWidthTooltip>
                                            </th>
                                            <th className={ManagementModule.delete}>　</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {formQuestions &&
                                            formQuestions.options &&
                                            formQuestions.options.map((option, o_index) => (
                                              <tr key={o_index}>
                                                <td>
                                                  <textarea
                                                    value={option.order}
                                                    className={ManagementModule['order']}
                                                    onChange={(e) => {
                                                      handleFormQuestionsOptionsOrderChange(
                                                        e.target.value,
                                                        index,
                                                        o_index
                                                      )
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  <textarea
                                                    value={option.displayName}
                                                    className={ManagementModule['option']}
                                                    onChange={(e) => {
                                                      handleFormQuestionsOptionsDisplayNameChange(
                                                        e.target.value,
                                                        index,
                                                        o_index
                                                      )
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  <textarea
                                                    value={option.value}
                                                    className={ManagementModule['optionContent']}
                                                    onChange={(e) => {
                                                      handleFormQuestionsOptionsValueChange(
                                                        e.target.value,
                                                        index,
                                                        o_index
                                                      )
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  <div className={ManagementModule['add-container']}>
                                                    <div
                                                      className={ManagementModule['delete']}
                                                      onClick={() =>
                                                        addToDeleteFormQuestionOptionsArray(index, o_index)
                                                      }
                                                    >
                                                      <DeleteOutlineIcon />
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            ))}
                                        </tbody>
                                      </table>
                                    )}
                                    <div className={ManagementModule['add-container']}>
                                      <div className={ManagementModule['add']}>
                                        <AddCircleIcon
                                          className={ManagementModule.icon}
                                          onClick={() => addFormQuestionOptionsFields(index)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {isModalOpen && (
                                <div className={ManagementModule.modalWrapper}>
                                  <div className={ManagementModule.overlay}></div>
                                  <div className={ManagementModule.modal}>
                                    <p className={ManagementModule['modal-text']}>プルダウン　選択肢テンプレート</p>
                                    <FormControl fullWidth className={ManagementModule.formcontrol}>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={pulldownTemplate}
                                        sx={{
                                          height: '35px',
                                          backgroundColor: 'white'
                                        }}
                                        onChange={(e) => setPulldownTemplate(e.target.value)}
                                      >
                                        {_pulldownTemplates.map((template, p_index) => (
                                          <MenuItem key={p_index} value={JSON.stringify(template)}>
                                            {template.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    <p className={ManagementModule['modal-text']}>※プレビュー</p>
                                    {JSON.parse(pulldownTemplate).selectOptions.map((option, o_index) => (
                                      <div key={o_index} className={ManagementModule['option-list']}>
                                        <div className={ManagementModule['option-fields']}>
                                          <textarea
                                            disabled
                                            value={option.order}
                                            size="sm"
                                            className={ManagementModule.order}
                                          />
                                          <textarea
                                            disabled
                                            value={option.displayName}
                                            size="sm"
                                            className={ManagementModule.option}
                                          />
                                          <textarea
                                            disabled
                                            value={option.value}
                                            size="sm"
                                            className={ManagementModule.optionContent}
                                          />
                                        </div>
                                      </div>
                                    ))}
                                    <button className={ManagementModule['close-button']} onClick={closeModal}>
                                      <p className={ManagementModule['sub-button-text']}>キャンセル</p>
                                    </button>
                                    <button
                                      className={ManagementModule['close-button']}
                                      onClick={() => closeModalAndChangeFormQuestions(pulldownTemplate, modalIndex)}
                                    >
                                      <p className={ManagementModule['sub-button-text']}>確定</p>
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}

                      <div className={ManagementModule['add-container']}>
                        <div className={ManagementModule['add']}>
                          <AddCircleIcon className={ManagementModule.icon} onClick={addFormQuestionFields} />
                        </div>
                      </div>
                    </div>
                    {(!paramSchoolId || roleInMingaku === 'serviceProvider') && selectedPrompt.editable === true && (
                      <div className={ManagementModule['prompt-body']}>
                        <div className={ManagementModule['section-title-group']}>
                          <h3 className={ManagementModule['section-title']}>スクールへのモード利用・閲覧設定</h3>
                        </div>
                        <div className={ManagementModule['prompt-input3']}>
                          <div className={ManagementModule['prompt-input-fields-container4']}>
                            <span className={ManagementModule['sub-field']}>
                              全スクールに対してモードを利用可能にする
                            </span>
                            <Switch2
                              checked={selectedPrompt.availableAllSchools}
                              onChange={(e) => handleInputAvailableAllSchoolsChange(selectedPrompt.availableAllSchools)}
                            />
                          </div>
                        </div>
                        {selectedPrompt.availableAllSchools === false && (
                          <>
                            <div className={ManagementModule['search-container']}>
                              <input
                                className={ManagementModule['search']}
                                type="text"
                                placeholder="検索..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                              />
                              <button className={ManagementModule['check']} onClick={handleSelectAll}>
                                全選択
                              </button>
                              <button className={ManagementModule['check']} onClick={handleDeselectAll}>
                                全解除
                              </button>
                            </div>
                            <ul className={ManagementModule['doc-list']}>
                              {filteredIds.map(
                                (id) =>
                                  schoolIdName[id] && (
                                    <li className={ManagementModule['doc-list']} key={id}>
                                      <div className={ManagementModule['doc-list']}>
                                        <Switch2
                                          value={id}
                                          checked={selectedIds.includes(id)}
                                          onChange={handleCheckboxChange}
                                        />
                                        {schoolIdName[id]}
                                      </div>
                                    </li>
                                  )
                              )}
                            </ul>
                          </>
                        )}
                        <div className={ManagementModule['prompt-input3']}>
                          <div className={ManagementModule['prompt-input-fields-container4']}>
                            <span className={ManagementModule['sub-field']}>利用スクールに編集画面を表示する</span>
                            <Switch2
                              checked={selectedPrompt.viewableAllSchools}
                              onChange={(e) => handleInputViewableAllSchoolsChange(selectedPrompt.viewableAllSchools)}
                            />
                          </div>
                        </div>
                        {selectedPrompt.viewableAllSchools === false && (
                          <>
                            <div className={ManagementModule['search-container']}>
                              <input
                                className={ManagementModule['search']}
                                type="text"
                                placeholder="検索..."
                                value={viewableSearchQuery}
                                onChange={handleViewableSearchChange}
                              />
                              <button className={ManagementModule['check']} onClick={handleViewableSelectAll}>
                                全選択
                              </button>
                              <button className={ManagementModule['check']} onClick={handleViewableDeselectAll}>
                                全解除
                              </button>
                            </div>
                            <ul className={ManagementModule['doc-list']}>
                              {filteredViewableIds.map(
                                (id, index) =>
                                  schoolIdName[id] && (
                                    <li className={ManagementModule['doc-list']} key={index}>
                                      <div className={ManagementModule['doc-list']}>
                                        <Switch2
                                          value={id}
                                          checked={viewableSelectedIds.includes(id)}
                                          onChange={handleViewableCheckboxChange}
                                        />
                                        {schoolIdName[id]}
                                      </div>
                                    </li>
                                  )
                              )}
                            </ul>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </>
            </div>
          </>
          {/* )} */}
        </div>
      )}
    </>
  )
}
export default ManagementScreen
