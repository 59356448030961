const apiKey = process.env['REACT_APP_GCLOUD_API_KEY']

function base64ToBlob(base64Data, contentType) {
  const byteCharacters = atob(base64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const getAudioUrl = async ({ text, languageCode, type, speakingRate }) => {
  const response = await fetch(`https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      input: {
        text
      },
      voice: {
        languageCode,
        name: type
      },
      audioConfig: {
        audioEncoding: 'MP3',
        speakingRate: speakingRate
      }
    })
  })

  const data = await response.json()

  if (data.audioContent) {
    const audioBlob = base64ToBlob(data.audioContent, 'audio/mp3')
    const audioUrl = URL.createObjectURL(audioBlob)
    return audioUrl
  } else {
    return null
  }
}

export const getAudioUrlFromOpenAi = async ({ text, languageCode, type, speakingRate }) => {
  const requestUrl = 'https://school-ai-r3y4on4j2a-an.a.run.app/openai_tts'
  // const requestUrl = 'http://localhost:8080/openai_tts'
  const requestJson = {
    model: 'tts-1',
    voice: languageCode,
    input: text,
    speed: speakingRate
  }
  const res = await fetch(requestUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestJson)
  })
  const data = await res.arrayBuffer()
  const audioBlob = new Blob([data], { type: 'audio/mp3' })
  const audioUrl = URL.createObjectURL(audioBlob)
  return audioUrl
}
