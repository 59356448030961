import Footer from '../../components/Common/Footer'
import Header from '../../components/Header/Header'
import AppModule from '../../App.module.scss'
import AdminManagementScreen from '../../components/AdminManagementScreen/AdminManagementScreen'
import { _prompt_type_choice_NANDE } from '../../localConstant'
import TocIcon from '@mui/icons-material/Toc'
import { useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { doc, collection, getDoc } from 'firebase/firestore'
import { auth, db, functions } from '../../firebase'
import { useNavigate } from 'react-router-dom'

function Management() {
  const [user] = useAuthState(auth)
  const { schoolId } = useParams()
  const listItem = [
    { name: '会話データ', path: schoolId ? `/chat_data?school_id=${schoolId}` : `/chat_data`, icon: <TocIcon /> }
  ]
  const navigate = useNavigate()
  useEffect(() => {
    if (user) {
      const userDocRef = doc(db, 'users', user.uid)
      getDoc(userDocRef).then((userDocSnapshot) => {
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data()
          if (userData.schoolDocId) {
            navigate('/')
          } else {
            return
          }
        } else {
        }
      })
    }
  }, [user])
  return (
    <div className={AppModule.main}>
      <Header title="システム管理画面" listItem={listItem} />
      <AdminManagementScreen />
      <Footer homePath="/" addChatPath="/mode" userPath="/mypage" />
    </div>
  )
}
export default Management
