import AppModule from '../../App.module.scss'
import { useState, useEffect } from 'react'
import { AssistantsClient, AzureKeyCredential } from '@azure/openai-assistants'

function MyPage() {
  const [assistant, setAssistant] = useState(null)
  const [assistantThread, setAssistantThread] = useState(null)
  const [message, setMessage] = useState(null)
  const [threadId, setThreadId] = useState(null)
  const [runResponse, setRunResponse] = useState(null)
  const [runMessages, setRunMessages] = useState(null)
  const [inputText, setInputText] = useState('')

  const endpoint = 'https://gpt-eastus2-mingaku.openai.azure.com'
  const azure_api_key = 'dfb62f7193e6430c9f6f0d45cbab557f'
  const assistantsClient = new AssistantsClient(endpoint, new AzureKeyCredential(azure_api_key))
  // const createAssistant = async (data) => {
  //   const assistant = await assistantsClient.createAssistant({ ...data })
  //   setAssistant(assistant)
  //   console.log('assistant', assistant)
  // }

  const createThread = async () => {
    const assistantThread = await assistantsClient.createThread(assistant.id)
    setAssistantThread(assistantThread)
    console.log('assistantThread', assistantThread)
  }

  // const createMessage = async () => {
  //   console.log('inputText', inputText)
  //   const messageResponse = await assistantsClient.createMessage(assistantThread.id, 'user', inputText)
  //   setMessage(messageResponse)
  //   console.log('messageResponse', messageResponse)
  // }

  const run = async () => {
    console.log('assistantThread.id, assistant.id', assistantThread.id, assistant.id)
    let runResponse = await assistantsClient.createRun(assistantThread.id, {
      assistantId: assistant.id,
      instructions: 'Please address the user as Jane Doe. The user has a premium account.'
    })
    console.log('runResponse', runResponse)
    setRunResponse(runResponse)
    do {
      await new Promise((resolve) => setTimeout(resolve, 800))
      runResponse = await assistantsClient.getRun(assistantThread.id, runResponse.id)
      setRunResponse(runResponse)
    } while (runResponse.status === 'queued' || runResponse.status === 'in_progress')

    const runMessages = await assistantsClient.listMessages(assistantThread.id)
    setRunMessages(runMessages)
    for (const runMessageDatum of runMessages.data) {
      for (const item of runMessageDatum.content) {
        if (item.type === 'text') {
          console.log(item.text.value)
        } else if (item.type === 'image_file') {
          console.log(item.imageFile.fileId)
        }
      }
    }
  }

  const [vectorStoreFiles, setVectorStoreFiles] = useState([])
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [updatedAssistantFiles, setUpdatedAssistantFiles] = useState([])
  const handleFileChange = (event) => {
    const assistantApiFiles = event.target.files
    const filesToProcess = Array.from(assistantApiFiles).slice(0, 20)
    const assistantApiFilesInfo = filesToProcess.map((file) => ({
      name: file.name,
      id: '',
      file: file
    }))
    setUpdatedAssistantFiles(assistantApiFilesInfo)
  }

  const [uploadAssistantFile, setUploadAssistantFile] = useState(null)
  const createAssistantWithFile = async () => {
    // if (!file) {
    //   console.log('No file selected')
    //   return
    // }
    // const arrayBuffer = await file.arrayBuffer()
    // const uint8array = new Uint8Array(arrayBuffer)
    // const uploadAssistantFile = await assistantsClient.uploadFile(uint8array, 'assistants', { filename: file.name })
    // console.log('uploadAssistantFile', uploadAssistantFile)
    // setUploadAssistantFile(uploadAssistantFile)
    // const fileAssistant = await createAssistant({
    //   model: 'gpt-4-1106-Preview',
    //   name: '研究者',
    //   instructions: 'あなたは研究者です。ファイルからデータを取得することができます。',
    //   tools: [{ type: 'code_interpreter' }, { type: 'retrieval' }],
    //   fileIds: [uploadAssistantFile.id]
    // })
  }

  const azureCreateAssistantWithFiles = async () => {
    const selectedFiles = updatedAssistantFiles
    const uploadPromises = selectedFiles.map(async (fileObject) => {
      console.log('file', fileObject.file)
      const uploadedFileId = await azureFileUpload(fileObject.file)
      return uploadedFileId
    })
    const uploadedFileIds = await Promise.all(uploadPromises)
    console.log('uploadedFileIds', uploadedFileIds)
    // const formData = new FormData()
    // formData.append('file', selectedFile, encodeURIComponent(selectedFile.name))
    // const URL = 'https://school-ai-r3y4on4j2a-an.a.run.app/azure_upload_file_for_assistant_api'
    // const res = await fetch(URL, {
    //   method: 'POST',
    //   body: formData
    // })
  }

  const uploadFile = async (selectedFile) => {
    if (!selectedFile) {
      alert('ファイルが選択されていません。')
      return
    }
    console.log('selectedFile', selectedFile)
    const formData = new FormData()
    formData.append('file', selectedFile, encodeURIComponent(selectedFile.name))
    console.log('formData', formData)
    try {
      const URL = 'http://localhost:8080/azure_upload_file'
      const res = await fetch(URL, {
        method: 'POST',
        body: formData
      })
      const { response } = await res.json() // 追加: レスポンスのJSONを解析
      console.log('response', response)
      const uploadedFileId = response.id
      return uploadedFileId
    } catch (error) {
      console.error('Error uploading file:', error)
      alert('ファイルのアップロード中にエラーが発生しました。')
    }
  }

  const uploadSelectedFiles = async () => {
    if (updatedAssistantFiles.length === 0) {
      alert('ファイルが選択されていません。')
      return []
    }
    const selectedFiles = updatedAssistantFiles
    const uploadPromises = selectedFiles.map(async (fileObject) => {
      console.log('file', fileObject.file)
      return uploadFile(fileObject.file)
    })
    const uploadedFileIds = await Promise.all(uploadPromises)
    console.log('uploadedFileIds', uploadedFileIds)
    setUpdatedAssistantFiles([])
    return uploadedFileIds
  }

  const azureFileUpload = async (selectedFile) => {
    if (!selectedFile) {
      alert('ファイルが選択されていません。')
      return
    }
    console.log('handleUpload: selectedFile', selectedFile)
    const formData = new FormData()
    formData.append('file', selectedFile, encodeURIComponent(selectedFile.name))
    try {
      const URL = 'http://localhost:8080/azure_upload_file_for_assistant_api'
      const res = await fetch(URL, {
        method: 'POST',
        body: formData
      })
      const { response } = await res.json() // 追加: レスポンスのJSONを解析
      console.log('response', response)
      const uploadedFileId = response.id
      return uploadedFileId
    } catch (error) {
      console.error('Error uploading file:', error)
      alert('ファイルのアップロード中にエラーが発生しました。')
    }
  }

  const azureCreateAssistant = async () => {
    const URL = 'http://localhost:8080/azure_create_assistant_api'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ test: 'testaaa' })
    })
    const data = await res.json()
    console.log(data)
    return data.file
  }

  const getVectorStoreFiles = async (vector_store_id) => {
    setVectorStoreFiles([])
    const URL = 'http://localhost:8080/azure_get_vector_store_files'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ vector_store_id })
    })
    const { files, error } = await res.json()
    console.log(error)
    console.log(files)
    setVectorStoreFiles(files)
    return files
  }

  const deleteFile = async (file_id) => {
    const URL = 'http://localhost:8080/azure_delete_file'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ file_id })
    })
    const data = await res.json()
    console.log(data)
    return data.file
  }

  const deleteVectorStore = async (vector_store_id) => {
    const URL = 'http://localhost:8080/azure_delete_vector_store'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ vector_store_id })
    })
    const data = await res.json()
    console.log(data)
    return data
  }

  const createVectorStore = async (mode_id) => {
    const URL = 'http://localhost:8080/azure_create_vector_store'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ mode_id })
    })
    const { vectorStore } = await res.json()
    console.log(vectorStore)
    return vectorStore
  }

  const createVectorStoreFileBatch = async ({ vector_store_id, file_ids }) => {
    const URL = 'http://localhost:8080/azure_create_vector_store_file_batch'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ vector_store_id, file_ids })
    })
    const { myVectorStoreFileBatch } = await res.json()
    console.log(myVectorStoreFileBatch)
    return myVectorStoreFileBatch
  }

  const createAssistant = async (body) => {
    const URL = 'http://localhost:8080/azure_create_assistant'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    const { myAssistant } = await res.json()
    console.log(myAssistant)
    return myAssistant
  }

  const createThreadAndRun = async () => {
    setThreadId('')
    setMessage('')
    const controller = new AbortController()
    const requestJson = {
      assistant_id: 'asst_QgoOESsASf9kPJrRUymcO8RW',
      messages: [{ role: 'user', content: '浴室の種類はなんですか？' }]
    }
    const URL = 'http://localhost:8080/azure_create_thread_and_run'
    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestJson),
      signal: controller.signal // AbortController の signal を渡す
    })
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }
    const reader = response.body.getReader()
    const decoder = new TextDecoder('utf-8')
    let count = 0
    let jsons = []
    let message = ''
    const read = async (missingJson = '') => {
      const { done, value } = await reader.read()
      if (done) {
        return reader.releaseLock()
      }
      const chunk = decoder.decode(value, { stream: true })
      const trimData = chunk.trim()
      if (trimData === '' || trimData === '[DONE]') {
        jsons = []
      }
      try {
        const jsonObj = JSON.parse(trimData)
        jsons = [jsonObj]
        missingJson = ''
      } catch (e) {
        missingJson = trimData
      }
      const messageTime = new Date().getTime()
      for (let json of jsons) {
        console.log(json.event)
        if (json.event === 'thread.created') {
          setThreadId(json.data.id)
          console.log('thread_id', json.data.id)
        }
        if (json.event === 'thread.message.delta') {
          message += json.data.delta.content[0].text.value
          setMessage(message)
          console.log('message', message)
        }
      }
      return read(missingJson)
    }
    await read()
  }

  const createMessage = async () => {
    const content = { role: 'user', content: 'そのキャッチコピーを作成して' }
    const URL = 'http://localhost:8080/azure_create_message'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        thread_id: threadId,
        message: content
      })
    })
    const { threadMessages } = await res.json()
    console.log(threadMessages)
    return threadMessages
  }

  const createRun = async () => {
    const controller = new AbortController()
    const assistant_id = 'asst_QgoOESsASf9kPJrRUymcO8RW'
    const thread_id = threadId
    const URL = 'http://localhost:8080/azure_create_run'
    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ assistant_id, thread_id }),
      signal: controller.signal // AbortController の signal を渡す
    })
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }
    const reader = response.body.getReader()
    const decoder = new TextDecoder('utf-8')
    let count = 0
    let jsons = []
    let message = ''
    const read = async (missingJson = '') => {
      const { done, value } = await reader.read()
      if (done) {
        return reader.releaseLock()
      }
      const chunk = decoder.decode(value, { stream: true })
      const trimData = chunk.trim()
      if (trimData === '' || trimData === '[DONE]') {
        jsons = []
      }
      try {
        const jsonObj = JSON.parse(trimData)
        jsons = [jsonObj]
        missingJson = ''
      } catch (e) {
        missingJson = trimData
      }
      const messageTime = new Date().getTime()
      for (let json of jsons) {
        console.log(json.event)
        if (json.event === 'thread.created') {
          setThreadId(json.data.id)
          console.log('thread_id', json.data.id)
        }
        if (json.event === 'thread.message.delta') {
          message += json.data.delta.content[0].text.value
          setMessage(message)
          console.log('message', message)
        }
      }
      return read(missingJson)
    }
    await read()
  }

  const save = async () => {
    const timestamp = new Date().getTime()
    const mode_id = `mode_${timestamp}`
    if (updatedAssistantFiles.length === 0) {
      alert('ファイルが選択されていません。')
      return
    } else {
      const [file_ids, vectorStore] = await Promise.all([uploadSelectedFiles(), createVectorStore(mode_id)])
      const vector_store_id = vectorStore.id
      const requestBodyForCreateAssistant = {
        model: 'gpt4o',
        name: mode_id,
        instructions: 'ファイルの情報をベースに会話をしてください',
        tools: [{ type: 'file_search' }],
        tool_resources: {
          file_search: {
            vector_store_ids: [vector_store_id]
          }
        }
      }
      const [myVectorStoreFileBatch, myAssistant] = await Promise.all([
        createVectorStoreFileBatch({ vector_store_id: vectorStore.id, file_ids }),
        createAssistant(requestBodyForCreateAssistant)
      ])
      console.log('file_ids', file_ids)
      console.log('vectorStore', vectorStore)
      console.log('myVectorStoreFileBatch', myVectorStoreFileBatch)
      console.log('myAssistant', myAssistant)
    }
  }

  const addFileToVectorStore = async (vector_store_id) => {
    const [file_ids] = await Promise.all([uploadSelectedFiles()])
    console.log('file_ids', file_ids)
    if (file_ids.length === 0) {
      return
    }
    createVectorStoreFileBatch({ vector_store_id, file_ids })
  }

  const deleteAllFilesOfVectorStoreAndVectorStore = async (vector_store_id) => {
    const vectorStoreFiles = await getVectorStoreFiles(vector_store_id)
    console.log('vectorStoreFiles', vectorStoreFiles)
    const deletePromises = vectorStoreFiles.map(async (file) => {
      console.log('file', file)
      return deleteFile(file.id)
    })
    const deletedFiles = await Promise.all(deletePromises)
    console.log('deletedFiles', deletedFiles)
    const deletedVectorStore = deleteVectorStore(vector_store_id)
    console.log('deletedVectorStore', deletedVectorStore)
  }

  const deleteAssistant = async (assistant_id) => {
    const URL = 'http://localhost:8080/azure_delete_assistant'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ assistant_id })
    })
    const data = await res.json()
    console.log(data)
  }

  const retrieveAssistant = async (assistant_id) => {
    const URL = 'http://localhost:8080/azure_retrieve_assistant'
    const res = await fetch(URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ assistant_id })
    })
    const data = await res.json()
    const filesPromise = data.myAssistant.tool_resources.file_search.vector_store_ids.map((vector_store_id) => {
      return getVectorStoreFiles(vector_store_id)
    })
    const [files] = await Promise.all(filesPromise)
    console.log('files', files)
  }

  return (
    <div>
      <div>
        <label>
          ファイルを選択してください
          <input type="file" multiple onChange={handleFileChange} />
        </label>
        {updatedAssistantFiles && updatedAssistantFiles.map((file) => <div key={file.name}>{file.name}</div>)}
      </div>
      <br></br>
      <button onClick={() => save()}>
        選択したファイルのアップロードとVectorStoreの作成を同時に行い、VectoreStoreFileとAssistantの作成を同時に行う
      </button>
      <br></br>
      <button onClick={() => retrieveAssistant('asst_C7HnUUHMcUFk9WeLpaLyK87Y')}>特定のAssistantAPIを取得する</button>
      <br></br>
      <button onClick={() => getVectorStoreFiles('vs_IWnu46sY28eXBmphkZumjXSe')}>
        特定のvectorStoreに紐づくファイルを取得する
      </button>
      {vectorStoreFiles &&
        vectorStoreFiles.map((file) => (
          <div key={file.id}>
            {file.id} / {file.filename}
          </div>
        ))}
      <br></br>
      <button onClick={() => addFileToVectorStore('vs_IWnu46sY28eXBmphkZumjXSe')}>
        選択したファイルを特定のvectorStoreに追加する
      </button>
      <br></br>
      <button onClick={() => deleteAllFilesOfVectorStoreAndVectorStore('vs_IWnu46sY28eXBmphkZumjXSe')}>
        特定のvectorStoreに紐づくファイルを全て削除し、vectorStoreを削除する
      </button>
      <br></br>
      <button onClick={() => createVectorStore('mode_1234')}>createVectorStore</button>
      <br></br>
      <button onClick={() => uploadSelectedFiles()}>uploadSelectedFiles</button>
      <br></br>
      <button onClick={() => deleteFile('assistant-h5sTEi8FUf31qCHesuOTonvp')}>deleteFile</button>
      <br></br>
      <button onClick={() => deleteVectorStore('vs_c9mtb7l9tLdQCdNRLBXbChTR')}>deleteVectorStore</button>
      <br></br>
      <button onClick={() => deleteAssistant('asst_PWjP2sHWrREXKGjmNMdqOtrw')}>deleteAssistant</button>
      <hr></hr>
      <button onClick={() => createThreadAndRun()}>createThreadAndRun</button>
      <br></br>
      <button onClick={() => createMessage()}>createMessage</button>
      <br></br>
      <button onClick={() => createRun()}>createRun</button>
      <br></br>
      {threadId && <div>threadId: {threadId}</div>}
      {message && <div>{message}</div>}
      <br></br>

      {/* <div>
        <button onClick={() => azureCreateAssistantWithFiles()}>選択したファイルでアシスタントを作成する</button>
        {assistant && <div>assistant.id: {assistant.id}</div>}
      </div>
      <div>
        <button onClick={() => createaAssistant()}>アシスタントを作成する</button>
        {assistant && <div>assistant.id: {assistant.id}</div>}
      </div>
      <div>
        <button onClick={() => createThread()}>スレッドを作成する</button>
        {assistantThread && <div>assistantThread.id: {assistantThread.id}</div>}
      </div>
      <div>
        <input type="text" placeholder="メッセージを入力" onChange={(e) => setInputText(e.target.value)} />
        <button onClick={() => createMessage()}>メッセージを作成する</button>
        {message && <div>message.id: {message.id}</div>}
      </div>
      <div>
        <button onClick={() => run()}>RUNする</button>
        {runResponse && <div>runResponse.id: {runResponse.id}</div>}
      </div>

      {runMessages &&
        runMessages.data.map((runMessage, index) => (
          <div key={index}>
            <div>runMessage.id: {runMessage.id}</div>
            {runMessage.content.map((contentItem, contentIndex) => (
              <div key={contentIndex}>
                {contentItem.type === 'text' && <div>{contentItem.text.value}</div>}
                {contentItem.type === 'image_file' && <div>{contentItem.imageFile.fileId}</div>}
              </div>
            ))}
          </div>
        ))} */}
    </div>
  )
}
export default MyPage
