import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ChatList from '../ChatList/ChatList'
import ChatScreen from '../ChatScreen/ChatScreen'
import StartButton from '../StartButton/StartButton'
import MainLayoutModule from './MainLayout.module.scss'
import MainLayoutModuleWithOutList from './MainLayoutModuleWithOutList.module.scss'
import { auth, db } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { useSearchParams } from 'react-router-dom'
import { collection, getDocs, getDoc, doc, query, orderBy, limit } from 'firebase/firestore'

function MainLayout(props) {
  const { showheader, displayList, conversationType, onModeChange, submittionBox } = props
  const styles = displayList === true ? MainLayoutModule : MainLayoutModuleWithOutList
  const { state } = useLocation()
  const [chatScreenWidth, setChatScreenWidth] = useState(null)
  const [chatScreenBottom, setChatScreenBottom] = useState(0)
  const chatScreenRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [listWidth, setListWidth] = useState('30%')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1100)
  const [noConversation, setNoConversation] = useState(false)
  const [conversation, setConversation] = useState(state && state)
  const mainLayoutRef = useRef(null)
  const conversationListRef = useRef(null)
  const [searchParams] = useSearchParams()
  const [chatScreenHeight, setChatScreenHeight] = useState(0)
  const [chatScreenHeightWithTextArea, setChatScreenHeightWithTextArea] = useState(0)
  const [chatScreenHeightWithTextAreaAndFooter, setChatScreenHeightWithTextAreaAndFooter] = useState(0)
  const conversation_id = searchParams.get('conversation_id')
  const thread_id = searchParams.get('thread_id')
  useEffect(() => {
    // クリックイベントのハンドラ
    const handleClickOutside = (event) => {
      if (isMenuOpen && conversationListRef.current && !conversationListRef.current.contains(event.target)) {
        setIsMenuOpen(false)
      }
    }

    // イベントリスナーを追加
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      // コンポーネントのアンマウント時にイベントリスナーを削除
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isMenuOpen, conversationListRef])
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
      setIsTablet(window.innerWidth <= 1100)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const handleSelectConversation = (_conversation) => {
    setConversation(_conversation)
  }
  // ハンバーガーメニューをトグルする関数
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }
  const onMenuItemClick = () => {
    if (isMobile) {
      setIsMenuOpen(false)
    }
  }

  const handleNoConversation = (value) => {
    setNoConversation(value)
  }
  // デフォルト会話を受け取るための関数
  const currentUrl = window.location.pathname + window.location.search

  const handleDefaultConversationSet = (_conversation) => {
    if (currentUrl === '/') return
    setConversation(_conversation)
  }
  const [user] = useAuthState(auth)

  // スプリッターの状態
  const startDragging = useCallback((e) => {
    setIsDragging(true)
    e.preventDefault()
  }, [])

  const stopDragging = useCallback(() => {
    if (isDragging) {
      setIsDragging(false)
    }
  }, [isDragging])

  const MIN_WIDTH_PERCENTAGE = 20 // 最小幅をパーセンテージで設定
  const MAX_WIDTH_PERCENTAGE = 50 // 最大幅70%
  const onDrag = useCallback(
    (e) => {
      if (isDragging) {
        const percentageWidth = (e.clientX / mainLayoutRef.current.clientWidth) * 100
        // 最小値と最大値に基づいて幅を制限する
        const newWidth = Math.min(Math.max(percentageWidth, MIN_WIDTH_PERCENTAGE), MAX_WIDTH_PERCENTAGE).toFixed(2)
        setListWidth(`${newWidth}vw`)
      }
    },
    [isDragging, MIN_WIDTH_PERCENTAGE, MAX_WIDTH_PERCENTAGE]
  )

  useEffect(() => {
    ;(async () => {
      if (displayList || user === null || !conversation_id) {
        // ユーザーがいない、またはconversation_idが指定されていない場合の処理
        // setNoConversation(true)
      } else {
        const userDocRef = doc(db, 'users', user.uid)
        const conversationDocRef = doc(userDocRef, conversationType, conversation_id)
        const conversationDocSnapshot = await getDoc(conversationDocRef)

        if (conversationDocSnapshot.exists()) {
          // conversationDocSnapshot から会話の基本情報を取得
          let conversationWithLatestInfo = {
            conversationId: conversation_id,
            conversationDocRef: conversationDocRef
            // 他に必要な基本情報があればここに追加
          }
          let mode = ''
          let autoSending = false
          let defaultMute = true
          let temperature = 1
          let speakingRate = '1.1'
          let largeIcon = false
          let displayInputContents = true
          let useAssistantApi
          let assistantId
          let threadId
          let promptDocRefPath
          if (!!conversationDocSnapshot.data().promptRef) {
            const promptDocRef = conversationDocSnapshot.data().promptRef
            const promptDocSnapshot = await getDoc(promptDocRef)
            if (!!promptDocSnapshot.data()) {
              mode = promptDocSnapshot.data().prompt_title
              autoSending = promptDocSnapshot.data().hasOwnProperty('autoSending')
                ? promptDocSnapshot.data().autoSending
                : false
              defaultMute = promptDocSnapshot.data().hasOwnProperty('defaultMute')
                ? promptDocSnapshot.data().defaultMute
                : true

              temperature = promptDocSnapshot.data().hasOwnProperty('temperature')
                ? promptDocSnapshot.data().temperature
                : 1
              speakingRate =
                promptDocSnapshot.data().hasOwnProperty('speakingRate') && promptDocSnapshot.data().speakingRate !== ''
                  ? promptDocSnapshot.data().speakingRate
                  : '1.1'
              largeIcon = promptDocSnapshot.data().hasOwnProperty('largeIcon')
                ? promptDocSnapshot.data().largeIcon
                : false
              displayInputContents = promptDocSnapshot.data().hasOwnProperty('displayInputContents')
                ? promptDocSnapshot.data().displayInputContents
                : true
              useAssistantApi = promptDocSnapshot.data().hasOwnProperty('useAssistantApi')
                ? promptDocSnapshot.data().useAssistantApi
                : ''
              assistantId = promptDocSnapshot.data().hasOwnProperty('assistantId')
                ? promptDocSnapshot.data().assistantId
                : ''
              threadId = conversationDocSnapshot.data().hasOwnProperty('threadId')
                ? conversationDocSnapshot.data().threadId
                : ''
              promptDocRefPath = promptDocSnapshot.ref.path
            } else {
              mode = '削除されたモード'
            }
          }
          // 会話の最新情報を取得
          const messagesCollectionRef = collection(conversationDocRef, 'messages')
          const q = query(messagesCollectionRef, orderBy('createdAt', 'desc'), limit(1))
          const querySnapshot = await getDocs(q)
          if (!querySnapshot.empty) {
            const lastMessageDoc = querySnapshot.docs[0]
            const systemPromptOfChatDoc = querySnapshot.docs[querySnapshot.docs.length - 1]
            const lastMessageData = lastMessageDoc.data()
            const systemPromptOfChatMessage = systemPromptOfChatDoc.data()
            const { role, model, content, messageTime } = lastMessageData
            const { userInput, userInputValue } = systemPromptOfChatMessage
            console.log('systemPromptOfChatMessage', systemPromptOfChatMessage)
            // 会話の最新情報を加工してセット
            conversationWithLatestInfo = {
              conversationId: conversation_id,
              mode,
              conversationDocRef: conversationDocRef,
              lastChatMessage: content,
              lastChatRole: role,
              lastMessageTime: messageTime,
              autoSending: autoSending,
              defaultMute: defaultMute,
              model: model,
              temperature: temperature,
              speakingRate: speakingRate,
              largeIcon: largeIcon,
              displayInputContents,
              useAssistantApi,
              assistantId,
              userId: user.uid,
              threadId,
              promptDocRefPath,
              userInput,
              userInputValue
              // 他に必要な最新情報があればここに追加
            }
          }
          // conversationWithLatestInfo.mode を親に渡す
          onModeChange(conversationWithLatestInfo.mode)
          // 取得した会話の詳細情報をセット

          setConversation(conversationWithLatestInfo)
        } else {
          // 指定された conversationId の会話が存在しない場合の処理
          setNoConversation(true)
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, conversation_id])

  useEffect(() => {
    // Function to update the chat screen width
    const updateChatScreenWidth = () => {
      if (chatScreenRef.current) {
        const width = chatScreenRef.current.offsetWidth // Get the width in pixels
        setChatScreenWidth(`${width}px`) // Update state with pixel value
      }
    }

    // Call update function on mount and whenever listWidth changes
    updateChatScreenWidth()

    // Add event listener for window resize
    window.addEventListener('resize', updateChatScreenWidth)

    // Cleanup event listener
    return () => window.removeEventListener('resize', updateChatScreenWidth)
  }, [listWidth])

  useEffect(() => {
    // chat-screen の位置を更新する関数
    const updateChatScreenPosition = () => {
      if (chatScreenRef.current) {
        const bottomPosition = chatScreenRef.current.getBoundingClientRect().bottom
        setChatScreenBottom(bottomPosition)
        setChatScreenHeight(chatScreenRef.current.offsetHeight - 24)
        let height = chatScreenRef.current.offsetHeight - 24
        if (isMobile & (showheader !== 'false')) {
          height = chatScreenRef.current.offsetHeight - 8
        } else if (isMobile & (showheader === 'false')) {
          height = chatScreenRef.current.offsetHeight + 34
        } else if (isTablet & (showheader !== 'false')) {
          // 修正済み
          height = chatScreenRef.current.offsetHeight + 20
        } else if (isTablet & (showheader === 'false')) {
          // 修正済み
          height = chatScreenRef.current.offsetHeight + 60
        } else if (showheader === 'false') {
          // 修正済み
          height = chatScreenRef.current.offsetHeight + 54
        } else {
          // 修正済み
          height = chatScreenRef.current.offsetHeight + 14
        }

        setChatScreenHeightWithTextArea(height)

        if (isMobile) {
          setChatScreenHeightWithTextAreaAndFooter(chatScreenRef.current.offsetHeight - 154)
        } else if (isTablet) {
          setChatScreenHeightWithTextAreaAndFooter(chatScreenRef.current.offsetHeight - 164)
        } else {
          setChatScreenHeightWithTextAreaAndFooter(chatScreenRef.current.offsetHeight - 154)
        }
      }
    }

    // コンポーネントのマウント時とウィンドウのリサイズ時に実行
    updateChatScreenPosition()
    window.addEventListener('resize', updateChatScreenPosition)
    return () => window.removeEventListener('resize', updateChatScreenPosition)
  }, [])
  const currentPath = window.location.pathname

  return (
    <>
      {noConversation ? (
        <>
          {' '}
          {currentPath === '/thread_list' ? (
            <p className={styles['noData']}>ログがありません</p>
          ) : (
            <StartButton chatScreenHeight={chatScreenHeight} />
          )}
        </>
      ) : (
        <div
          className={styles['main-layout']}
          ref={mainLayoutRef}
          onMouseMove={onDrag}
          onMouseUp={stopDragging}
          onMouseLeave={stopDragging}
        >
          {displayList & isMobile & !isMenuOpen ? (
            <ArrowForwardIosIcon onClick={toggleMenu} className={styles['menu-button']}></ArrowForwardIosIcon>
          ) : (
            <></>
          )}
          {displayList & isMobile & isMenuOpen ? (
            <ArrowBackIosNewIcon onClick={toggleMenu} className={styles['close-menu-button']}></ArrowBackIosNewIcon>
          ) : (
            <></>
          )}
          {displayList ? (
            <>
              <div
                className={styles['conversation-list']}
                ref={conversationListRef}
                style={
                  isMobile
                    ? {
                        transform: isMenuOpen ? 'translateX(0)' : 'translateX(-100%)'
                      }
                    : { width: listWidth }
                }
              >
                <ChatList
                  onDefaultConversationSet={handleDefaultConversationSet}
                  onSelectConversation={handleSelectConversation}
                  onMenuItemClick={onMenuItemClick}
                  onNoConversation={handleNoConversation}
                  {...props}
                />
              </div>
              {!isMobile && <div className={styles['splitter']} onMouseDown={startDragging}></div>}
            </>
          ) : (
            <></>
          )}

          <div
            className={styles['chat-screen']}
            ref={chatScreenRef}
            style={{ width: `calc(100vw - ${listWidth} - 2.5%)` }}
          >
            {state || conversation ? (
              <ChatScreen
                conversation={conversation}
                conversationType={'conversations'}
                startButton={false}
                chatScreenWidth={chatScreenWidth}
                userInputWidth={chatScreenWidth}
                chatScreenBottom={chatScreenBottom}
                chatScreenHeight={chatScreenHeight}
                chatScreenHeightWithTextArea={chatScreenHeightWithTextArea}
                chatScreenHeightWithTextAreaAndFooter={chatScreenHeightWithTextAreaAndFooter}
                {...props}
              />
            ) : (
              <>{currentUrl === '/' ? <StartButton chatScreenHeight={chatScreenHeight} /> : <></>}</>
            )}
          </div>
        </div>
      )}
    </>
  )
}
export default MainLayout
