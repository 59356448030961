import InputModule from './InputScreen.module.scss'
import { useState, useEffect, useRef } from 'react'
import * as React from 'react'
import ReactCrop from 'react-image-crop' //https://codesandbox.io/s/react-image-crop-demo-with-react-hooks-forked-9yr5dv?file=/src/App.tsx
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import 'react-image-crop/dist/ReactCrop.css'
import { ocr, imageBase64ToText } from '../../utils/ocr'
import kuromoji from 'kuromoji'
function OCR({ questionData, onValueChange, answerCharacterLimit, answerMaxLength }) {
  const [imageDataURL, setImageDataURL] = useState(null)
  const [completedCrop, setCompletedCrop] = useState()
  const [imageText, setImageText] = useState('')
  const [imgSrc, setImgSrc] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [crop, setCrop] = useState()
  const scale = 1
  const rotate = 0
  const [showElement, setShowElement] = useState(false)
  const [debouncedText, setDebouncedText] = useState('')
  const [wordsCount, setWordsCount] = useState(0)
  useEffect(() => {
    setShowElement(!!imgSrc) // imgSrcがnullでない場合にshowElementをtrueに設定
  }, [imgSrc])
  // const _previewCanvasRef=previewCanvasRef
  const previewCanvasRefs = {}

  const propValue = questionData.order
  previewCanvasRefs[propValue] = useRef(null)
  const hiddenAnchorRefs = {}

  hiddenAnchorRefs[propValue] = useRef(null)
  const imgRefs = {}
  imgRefs[propValue] = useRef(null)

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      onChangeImage(e)
      setOpenModal(true)
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onConverCropImageToText = async () => {
    if (!previewCanvasRefs[propValue].current) {
      throw new Error('Crop canvas does not exist')
    }
    try {
      const imageBase64 = previewCanvasRefs[propValue].current
        .toDataURL('image/jpeg')
        .replace(/^data:image\/[a-z]+;base64,/, '')
      const text = await imageBase64ToText(imageBase64)
      setImageText(text)
      setDebouncedText(text)
      onValueChange(text, questionData.key)
      setImageDataURL(previewCanvasRefs[propValue].current.toDataURL('image/jpeg'))
      setOpenModal(false)
    } catch (error) {
      alert(error.message)
      setOpenModal(false)
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRefs[propValue].current &&
        previewCanvasRefs[propValue].current
      ) {
        canvasPreview(imgRefs[propValue].current, previewCanvasRefs[propValue].current, completedCrop, scale, rotate)
      }
    },
    100,
    [completedCrop, scale, rotate]
  )

  const onChangeImage = async (e, data) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = async (e) => {
      setImageDataURL(() => e.target.result)
      try {
        const text = await ocr(file)
        setImageText(text)
        onValueChange(text, questionData.key)
      } catch (error) {
        alert('error.message')
        setOpenModal(false)
      }
    }
    reader.readAsDataURL(file)
  }
  const onTextChanged = (e) => {
    const inputText = e.target.value
    // 改行を除外して文字列を分割
    const charsWithoutNewLines = inputText.replace(/\n/g, '').split('')
    // 改行の位置を保持
    const newLinePositions = [...inputText]
      .map((char, index) => (char === '\n' ? index : -1))
      .filter((index) => index !== -1)

    setImageText(inputText)
    setDebouncedText(inputText)

    if (!answerCharacterLimit || charsWithoutNewLines.length <= answerMaxLength) {
      onValueChange(inputText, questionData.key)
    } else {
      // 最大文字数を超える場合は切り捨てるが、改行の位置は保持する
      let trimmedText = charsWithoutNewLines.slice(0, answerMaxLength).join('')
      newLinePositions.forEach((pos) => {
        if (pos <= trimmedText.length) {
          trimmedText = [trimmedText.slice(0, pos), '\n', trimmedText.slice(pos)].join('')
        }
      })
      setImageText(trimmedText)
      onValueChange(trimmedText, questionData.key)
      alert('最大文字数に達しました。これ以上の入力はできません。')
    }
  }

  useDebounceEffect(
    () => {
      countJapaneseWords(debouncedText).then((wordCount) => {
        setWordsCount(wordCount)
      })
    },
    300,
    [debouncedText]
  )

  const countJapaneseWords = (text) => {
    return new Promise((resolve, reject) => {
      kuromoji.builder({ dicPath: '/dict' }).build((err, tokenizer) => {
        if (err) {
          reject(err)
          return
        }
        // 除外する文字を定義
        const excludeChars = /[.,;:?!'"(){}\[\]\-—'/\\]/g

        // 除外する文字を除去
        const cleanedText = text.replace(excludeChars, '')

        const tokens = tokenizer.tokenize(cleanedText)
        // フィルタリングしてスペースを除外
        const filteredTokens = tokens.filter((token) => token.surface_form.trim() !== '')
        resolve(filteredTokens.length)
      })
    })
  }

  return (
    <>
      <p className={InputModule['question-description']}>{questionData.description && questionData.description}</p>
      <label htmlFor={`file_upload${questionData.order}`} className={InputModule.upload}>
        画像を文字に変換する
        <input
          id={`file_upload${questionData.order}`}
          className={InputModule.upload}
          type="file"
          onChange={(e) => onSelectFile(e)}
        />
      </label>
      <div className={InputModule.selectedImageContainer}>
        <img src={imageDataURL} alt="" className={InputModule.selectedImage} />
        {!!completedCrop && (
          <>
            <div hidden>
              <canvas
                id={questionData.order}
                ref={previewCanvasRefs[propValue]}
                style={{
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: '350px',
                  maxHeight: '200px'
                }}
              />
            </div>
          </>
        )}
      </div>
      <textarea className={InputModule.prompt} value={imageText} onChange={onTextChanged} />
      <div className={InputModule['answer-length-container']}>
        <p className={InputModule['answer-length']}>
          (現在 {imageText.replace(/\n/g, '').length}文字)
          <br />
          (現在 {wordsCount}単語)
        </p>
        {!!answerCharacterLimit && (
          <p className={InputModule['answer-length-red']}>
            ※残り
            {answerMaxLength - imageText.replace(/\n/g, '').length}文字
          </p>
        )}
      </div>
      {showElement === true && openModal && (
        <div className={InputModule.modalWrapper}>
          <div className={InputModule.overlay}></div>
          <div className={InputModule.modal}>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              // aspect={aspect}
            >
              <img
                id={questionData.order}
                ref={imgRefs[propValue]}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, maxHeight: '62vh', minWidth: '100%' }}
              />
            </ReactCrop>
            <div className={InputModule.cropButtonArea}>
              <button className={InputModule.crop} onClick={onConverCropImageToText}>
                切り抜く
              </button>
              <button className={InputModule.nonCrop} onClick={() => setOpenModal(false)}>
                切り抜かない
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default OCR
