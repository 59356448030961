import axios from 'axios'

const apiKey = process.env['REACT_APP_GCLOUD_API_KEY']
const visionApiUrl = `https://vision.googleapis.com/v1/images:annotate?key=${apiKey}`

export const ocr_gcp = async (file) => {
  let imageBase64 = null
  try {
    imageBase64 = await toBase64(file)
    imageBase64 = imageBase64.replace(/^data:image\/[a-z]+;base64,/, '')
  } catch (error) {
    return null
  }
  return imageBase64ToText(imageBase64)
}

export const imageBase64ToText = async (imageBase64) => {
  const options = {
    requests: [
      {
        image: {
          content: imageBase64
        },
        features: [
          {
            type: 'TEXT_DETECTION'
          }
        ]
      }
    ]
  }
  try {
    const response = await axios.post(visionApiUrl, options)
    let text = ''
    if (!response.data.responses[0].fullTextAnnotation) {
      text = '文字を検出できませんでした。別の画像を試してください'
    } else {
      text = response.data.responses[0].fullTextAnnotation.text
    }
    return text
  } catch (error) {
    console.error('OCR error:', error)
    throw new Error('OCRリクエスト中にエラーが発生しました')
  }
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const ocr = async (file) => {
  const COMPUTER_VISION_API_ENDPOINT_URL =
    'https://ocr-mingaku.cognitiveservices.azure.com/computervision/imageanalysis:analyze?api-version=2024-02-01&model-version=latest&features=read'
  const API_KEY = '8fdbd3ff860f4191949a3feeeab2b752'
  const formData = new FormData()
  formData.append('file', file)

  try {
    const response = await fetch(COMPUTER_VISION_API_ENDPOINT_URL, {
      method: 'POST',
      headers: {
        'Ocp-Apim-Subscription-Key': API_KEY,
        'Content-Type': 'application/octet-stream'
      },
      body: file
    })
    const data = await response.json()
    // JSONレスポンスからブロックの中のラインをイテレートする
    const text = data.readResult.blocks.map((block) => block.lines.map((line) => line.text).join('\n')).join('\n')
    console.log('text extracted by azure: ', text)
    return text
  } catch (error) {
    console.error('OCR error:', error)
    throw new Error('文字を検出できませんでした。')
  }
}
